import * as React from 'react'
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
} from '../helpers/image-imports/IconComponent'

export enum ShareOption {
  Email = 'mailto:?subject=Codeanywhere Referral&amp;body=Hey,%0D%0A%0D%0A sign up for a Codeanywhere premium account and we both get $15 in credit:%0D%0A ',
  Facebook = 'https://www.facebook.com/sharer/sharer.php?u=http%3A//',
  Twitter = 'https://twitter.com/home?status=Hey,%20sign%20up%20for%20a%20Codeanywhere%20premium%20account%20and%20we%20both%20get%20$15%20in%20credit%3A%0Ahttps%3A//',
}

type Props = {
  option: ShareOption
}

export class ReferralShareOption extends React.PureComponent<Props> {
  render() {
    return (
      <a href={this.props.option} target="_blank" rel="noopener noreferrer">
        {this.props.option === ShareOption.Email ? (
          <EmailIcon title="Share via Email" />
        ) : this.props.option === ShareOption.Facebook ? (
          <FacebookIcon title="Share via Facebook" />
        ) : (
          <TwitterIcon title="Share via Twitter" />
        )}
      </a>
    )
  }
}
