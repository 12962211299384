import * as React from 'react'
import { DestroyIcon } from '../helpers/image-imports/IconComponent'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { Truncate } from './Truncate'
import { getTemplateIconSourceUrl, onKey } from '../helpers/Util'
import { TemplateData, TemplateType } from '../helpers/TransformTemplates'
import { Tooltip, TooltipPosition } from './Tooltip'

type Props = {
  template: TemplateData
  accountDetails: AccountDetails
  onRemoveTemplate: () => void
}

export class TemplateTableRow extends React.Component<Props> {
  render() {
    return (
      <tr className="table-row" style={{ cursor: 'default' }}>
        <td className="table-column" style={{ width: '140px' }}>
          {this.props.template.name}
        </td>
        <td className="table-column" style={{ width: '160px' }}>
          <Truncate>
            {this.props.template.type === TemplateType.Predefined
              ? 'Predefined'
              : this.props.template.type === TemplateType.Custom
              ? `${this.props.accountDetails.name} (me)`
              : this.props.template.owner
              ? `${this.props.template.owner.name}${
                  this.props.accountDetails.email ===
                  this.props.template.owner.email
                    ? ' (me)'
                    : ''
                }`
              : 'Unknown owner'}
          </Truncate>
        </td>
        <td className="table-column">
          <Truncate line={2}>
            {this.props.template.type === TemplateType.Team
              ? this.props.template.desc?.substring(
                  this.props.template.desc.indexOf('-') + 2
                )
              : this.props.template.desc}
          </Truncate>
        </td>
        <td
          className="table-column"
          style={{ width: '130px', textAlign: 'center' }}
        >
          <img
            src={getTemplateIconSourceUrl(this.props.template.id)}
            className="template-row-image"
            alt="Stack logo"
          />
        </td>
        {/* {
          // TODO: Rename --is-destroy class to -is-icon and change :last-child rule
        }
        <td
          className="table-column table-column--is-destroy"
          style={{ width: '4rem' }}
        >
          <Tooltip position={TooltipPosition.Left} content="New Container">
            <AddOptionIcon />
          </Tooltip>
        </td> */}
        <td
          className="table-column table-column--is-destroy tab-focus"
          onKeyDown={e => onKey(e, 'Enter', this.props.onRemoveTemplate)}
          onKeyUp={e => onKey(e, ' ', this.props.onRemoveTemplate)}
          tabIndex={0}
        >
          {this.props.template.type === TemplateType.Custom && (
            <Tooltip
              position={TooltipPosition.Left}
              content="Remove template"
              onClick={this.props.onRemoveTemplate}
            >
              <DestroyIcon />
            </Tooltip>
          )}
        </td>
      </tr>
    )
  }
}
