import * as React from 'react'
import {
  OptionsIcon,
  EditIcon,
  CreateTemplateIcon,
  StopIcon,
  RestartIcon,
  DestroyIcon,
  InfoIcon,
  PeopleIcon,
  LogoutIcon,
  RemoveConnectionIcon,
  DomainsIcon,
} from '../helpers/image-imports/IconComponent'
import { onKey } from '../helpers/Util'

export enum CardOption {
  Rename = 'Rename',
  Collaborators = 'Collaborators',
  CreateTemplate = 'Create Template',
  Domains = 'Domains',
  Stop = 'Stop',
  Restart = 'Restart',
  ForceRestart = 'Force Restart',
  Destroy = 'Destroy',
  Info = 'Info',
  Leave = 'Leave',
  Edit = 'Edit',
  Disconnect = 'Disconnect',
  RemoveCollaborator = 'Remove',
}

const CardOptionIcons: { [x in CardOption]: React.ReactElement } = {
  Rename: <EditIcon />,
  Collaborators: <PeopleIcon />,
  'Create Template': <CreateTemplateIcon />,
  Domains: <DomainsIcon />,
  Stop: <StopIcon />,
  Restart: <RestartIcon />,
  'Force Restart': <RestartIcon />,
  Destroy: <DestroyIcon />,
  Info: <InfoIcon />,
  Leave: <LogoutIcon />,
  Edit: <EditIcon />,
  Disconnect: <RemoveConnectionIcon />,
  Remove: <DestroyIcon />,
}

type OptionProps = {
  children: CardOption
  disabled?: boolean
  onClick: () => void | Promise<void>
}

class Option extends React.PureComponent<OptionProps> {
  render() {
    return (
      <div
        className={`options-menu__item tab-focus${
          this.props.disabled ? ' disabled' : ''
        }`}
        onClick={() => this.handleClickOptionEvent()}
        onKeyDown={e => this.handleClickOptionEvent(e, 'Enter')}
        onKeyUp={e => this.handleClickOptionEvent(e, ' ')}
        tabIndex={0}
      >
        <div className="options-menu-item__icon">
          {CardOptionIcons[this.props.children as CardOption]}
        </div>
        {this.props.children}
      </div>
    )
  }

  private handleClickOptionEvent = (e?: React.KeyboardEvent, key?: string) => {
    if (this.props.disabled) {
      return undefined
    } else if (!e || !key) {
      this.props.onClick()
    } else {
      onKey(e, key, this.props.onClick)
    }
  }
}

class Separator extends React.PureComponent {
  render() {
    return <div className="options-menu__separator"></div>
  }
}

type Props = {
  children: React.ReactNode
}

type State = {
  visible: boolean
}

export class CardOptions extends React.Component<Props, State> {
  private optionRef = React.createRef<HTMLDivElement>()

  public state: State = {
    visible: false,
  }

  private toggleMenu = () => {
    this.setState({ visible: !this.state.visible })
  }

  private handleClickOutside = (event: MouseEvent) => {
    if (
      this.state?.visible &&
      !this.optionRef?.current?.contains(event.target as Node)
    ) {
      this.setState({ visible: false })
    }
  }

  public componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  static Option = (props: OptionProps) => <Option {...props} />

  static Separator = () => <Separator />

  render() {
    return (
      <div
        className="card-options tab-focus"
        ref={this.optionRef}
        onClick={this.toggleMenu}
        onKeyDown={e => onKey(e, 'Enter', this.toggleMenu)}
        onKeyUp={e => onKey(e, ' ', this.toggleMenu)}
        tabIndex={0}
      >
        <OptionsIcon />
        <div className="options-menu" hidden={!this.state.visible}>
          {React.Children.map(this.props.children, (child, i) => {
            if (!React.isValidElement(child)) return null
            const { disabled } = child.props

            if (disabled) {
              return (
                <div onClick={e => e.stopPropagation()} key={i}>
                  {child}
                </div>
              )
            }

            return (
              <div onClick={this.toggleMenu} key={i}>
                {child}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
