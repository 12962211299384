import * as React from 'react'
import { onKey } from '../helpers/Util'

type Props = {
  value: number
  disabled: boolean
  processingAction: boolean
  onChangeValue: (value: number, assigning: boolean) => void
}

export class TeamResourceNumberInput extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={`team-resource-number-input${
          this.props.disabled
            ? ' disabled'
            : this.props.processingAction
            ? ' processing'
            : ''
        }`}
      >
        <div
          className="team-resource-number-input__top tab-focus"
          onClick={this.handleClickIncrease}
          onKeyDown={e => onKey(e, 'Enter', this.handleClickIncrease)}
          onKeyUp={e => onKey(e, ' ', this.handleClickIncrease)}
          tabIndex={0}
        >
          <svg
            height="100%"
            viewBox="0 0 24 24"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 5v14l11-7z"></path>
          </svg>
        </div>
        <div className="team-resource-number-input__mid">
          {this.props.value}
        </div>
        <div
          className="team-resource-number-input__bot tab-focus"
          onClick={this.handleClickDecrease}
          onKeyDown={e => onKey(e, 'Enter', this.handleClickDecrease)}
          onKeyUp={e => onKey(e, ' ', this.handleClickDecrease)}
          tabIndex={0}
        >
          <svg
            height="100%"
            viewBox="0 0 24 24"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 5v14l11-7z"></path>
          </svg>
        </div>
      </div>
    )
  }

  private handleClickIncrease = () => {
    if (!this.props.disabled) {
      this.props.onChangeValue(this.props.value + 1, true)
    } else {
      return undefined
    }
  }

  private handleClickDecrease = () => {
    if (!this.props.disabled && this.props.value > 0) {
      this.props.onChangeValue(this.props.value - 1, true)
    } else {
      return undefined
    }
  }
}
