import React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Heading, HeadingLevel } from '../components/Heading'
import { ReferralStep } from '../components/ReferralStep'
import { ReferralTitle } from '../components/ReferralTitle'
import { ShareReferralLink } from '../components/ShareReferralLink'
import { ReferralTracking } from '../components/ReferralTracking'
import { notification } from '../components/Notification'
import { Spinner } from '../components/Spinner'
import { triggerOpenedScreenEvent } from '../helpers/Util'
import { AccountDetails } from '../api-client/interface/AccountDetails'

type Props = {
  apiClient: ApiClient
  accountDetails: AccountDetails
  loadingInitContent: boolean
  onDidLoadInitContent: () => void
}

type State = {
  loading: boolean

  earnedCredit: number
  referralCode: string
}

export class ReferFriendPage extends React.Component<Props> {
  public state: State = {
    loading: true,

    earnedCredit: 0,
    referralCode: '',
  }

  componentDidMount() {
    this.getData()
    triggerOpenedScreenEvent(
      'openedReferFriendScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (this.props.loadingInitContent || this.state.loading) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    const referralLink = `https://codeanywhere.com/referralsignup/?r=${this.state.referralCode}`
    const discountAmmount = 15

    return (
      <div className="page-content">
        {this.state.loading && <Spinner size={'cover'} />}
        <Heading heading="Refer a Friend" level={HeadingLevel.First} />
        <div className="referral-steps">
          <ReferralStep
            step={1}
            description={`Refer friends with a $${discountAmmount} discount using your link.`}
          />
          <ReferralStep
            step={2}
            description={`Friends subscribe with your referral link.`}
          />
          <ReferralStep
            step={3}
            description={`You also receive $${discountAmmount} off your subscription.`}
          />
        </div>
        <div className="refer-page-container">
          <ReferralTitle
            title={`Refer a friend and get $${discountAmmount} discount!`}
            level={1}
          />
          <ReferralTitle
            title={`Refer friends with a $${discountAmmount} discount using your personal
          referral link.`}
            level={2}
          />
          <ShareReferralLink referralLink={referralLink} />
          <div className={'referral-spacer'}></div>
          <ReferralTracking earnedCredit={this.state.earnedCredit} />
        </div>
      </div>
    )
  }

  private getData = async () => {
    await this.getReferralData()
    this.props.onDidLoadInitContent()
  }

  private async getReferralData() {
    try {
      const widgetData = await this.props.apiClient.getReferralWidgetData()
      this.setState({
        earnedCredit: widgetData.account_credit_in_cents / 100,
        referralCode: widgetData.referral_code,
        loading: false,
      })
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while getting your referral data.',
        description: e,
      })
    }
  }
}
