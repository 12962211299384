import * as React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { CreateContainer } from '../api-client/interface/Connection'
import { AlwaysOn } from '../components/AlwaysOn'
import { Button } from '../components/Button'
import { ContainerLabel, Input } from '../components/Input'
import { Modal } from '../components/Modal'
import { notification } from '../components/Notification'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { Templates } from '../helpers/TransformTemplates'
import { focusOnElement } from '../helpers/Util'
import {
  EMPTY_SELECTED_TEMPLATE,
  SelectedTemplate,
  TemplatesList,
} from './TemplatesList'
import { generateSlug } from 'random-word-slugs'
import { PREDEFINED_STACKS } from '../helpers/StackName'
import { Page } from '../App'

type Props = {
  apiClient: ApiClient
  accountDetails: AccountDetails
  templates: Templates
  remainingAlwaysOn: number
  switchPage: (page: string) => void
  refreshVerificationStatus: () => Promise<void>
}

type State = {
  selectedTemplate: SelectedTemplate
  newContainerName?: string
  randomContainerName: string
  alwaysOn: boolean
  loading: boolean
  notVerifiedModalVisible: boolean
  alwaysOnLimitDialogVisible: boolean
}

export class NewContainerForm extends React.Component<Props, State> {
  public state: State = {
    selectedTemplate: EMPTY_SELECTED_TEMPLATE,
    randomContainerName: '',
    alwaysOn: false,
    loading: false,
    notVerifiedModalVisible: false,
    alwaysOnLimitDialogVisible: false,
  }

  render() {
    return (
      <form
        className="form-wrapper new-container-form"
        onSubmit={e => {
          e.preventDefault()
          this.handleCreateContainer()
        }}
      >
        <TemplatesList
          apiClient={this.props.apiClient}
          fetchedTemplates={this.props.templates}
          selectedTemplate={this.state.selectedTemplate}
          onChangeSelectedTemplate={this.handleChangeSelectedTemplate}
        />
        <div>
          <AlwaysOn
            id="new-container"
            toggled={this.state.alwaysOn}
            onChangeAlwaysOn={() => {
              if (this.props.remainingAlwaysOn <= 0) {
                this.setState({
                  alwaysOnLimitDialogVisible: true,
                })
              } else {
                this.setState({ alwaysOn: !this.state.alwaysOn })
              }
            }}
          />
        </div>
        <div className="new-container-confirm-row">
          <Input
            label={ContainerLabel.Name}
            value={
              this.state.newContainerName !== undefined
                ? this.state.newContainerName
                : this.state.randomContainerName
            }
            placeholder="e.g. React project"
            required={true}
            whiteBackground={true}
            onChangeInput={(newContainerName: string) =>
              this.setState({ newContainerName })
            }
          />
          <div className="new-container-actions">
            <Button value="Cancel" onClick={() => window.history.back()} />
            <Button
              type={'success'}
              value="Create"
              submit={true}
              disabled={
                this.state.loading || this.state.newContainerName === ''
              }
              loading={this.state.loading}
            />
          </div>
        </div>
        {this.renderNotVerifiedModal()}
        {this.renderAlwaysOnLimitModal()}
      </form>
    )
  }

  private renderAlwaysOnLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={this.state.alwaysOnLimitDialogVisible}
        resource="Always-on add-ons"
        accountDetails={this.props.accountDetails}
        onClose={() => this.setState({ alwaysOnLimitDialogVisible: false })}
      />
    )
  }

  private renderNotVerifiedModal = () => {
    if (this.state.notVerifiedModalVisible) {
      return (
        <Modal
          visible={this.state.notVerifiedModalVisible}
          closeable={true}
          onClose={() => this.setState({ notVerifiedModalVisible: false })}
        >
          <Modal.Header>Warning</Modal.Header>
          <Modal.Body>
            <span className="modal-warning-message">
              You have to verify your account before you create a container.
            </span>
            <div className="button-row bottom-margin">
              <Button
                value="Cancel"
                onClick={() =>
                  this.setState({ notVerifiedModalVisible: false })
                }
              />
            </div>
          </Modal.Body>
        </Modal>
      )
    }

    return null
  }

  private handleCreateContainer = async () => {
    this.setState({ loading: true })

    if (!this.props.accountDetails.verified) {
      await this.props.refreshVerificationStatus()
    }

    if (!this.props.accountDetails.verified) {
      this.setState({ notVerifiedModalVisible: true, loading: false })
      return
    }

    try {
      const container: CreateContainer = {
        type: 'devbox',
        name: this.state.newContainerName || this.state.randomContainerName,
        alwaysOn: this.state.alwaysOn,
        stack: this.state.selectedTemplate.id,
        distro: this.state.selectedTemplate.distro,
      }

      await this.props.apiClient.createContainer(container)

      notification.success({
        message: 'You have successfully created a new container.',
      })

      this.props.switchPage(Page.Containers)
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while creating the container.',
        description: e,
      })
      focusOnElement(ContainerLabel.Name)
    } finally {
      this.setState({ loading: false })
    }
  }

  private handleChangeSelectedTemplate = async (
    selectedTemplate: SelectedTemplate
  ) => {
    this.setState({
      selectedTemplate,
    })

    if (!this.state.newContainerName) {
      const templateSlug = PREDEFINED_STACKS.includes(selectedTemplate.id)
        ? selectedTemplate.id
        : 'custom'
      const randomSlug = generateSlug(2, {
        categories: { adjective: ['color'], noun: ['animals'] },
      })

      this.setState({
        newContainerName: undefined,
        randomContainerName: templateSlug.concat('-', randomSlug),
      })
    }
  }
}
