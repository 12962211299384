import * as React from 'react'
import { CONNECTED_ACCOUNT } from '../helpers/image-imports/ConnectedAccount'
import { onKey } from '../helpers/Util'
import { ConnectedAccount } from './ConnectedAccounts'

export const PROVIDER_NAMES = new Map<string, string>([
  ['github', 'GitHub'],
  ['bitbucket', 'Bitbucket'],
  ['google', 'Google'],
  ['digitalocean', 'Digital Ocean'],
  ['dropbox', 'Dropbox'],
  ['onedriveconsumer', 'OneDrive'],
  ['facebook', 'Facebook'],
])

type Props = {
  account: ConnectedAccount
  onDisconnectClick: () => void
}

export class ConnectedAccountCard extends React.PureComponent<Props> {
  render() {
    const { provider, username } = { ...this.props.account }
    return (
      <div className="connected-account-card">
        <div className="provider-container">
          <img
            src={CONNECTED_ACCOUNT[provider]}
            alt={PROVIDER_NAMES.get(provider) || 'Provider icon'}
          />
          <span className="provider-name">
            {username || PROVIDER_NAMES.get(provider)}
          </span>
        </div>
        <span
          className="disconnect-connected-account tab-focus"
          onClick={this.props.onDisconnectClick}
          onKeyDown={e => onKey(e, 'Enter', this.props.onDisconnectClick)}
          onKeyUp={e => onKey(e, ' ', this.props.onDisconnectClick)}
          tabIndex={0}
        >
          Disconnect
        </span>
      </div>
    )
  }
}
