import * as React from 'react'

export type SelectOption = {
  value: any
  label?: string
}

type Props = {
  label?: string
  value: any
  values: SelectOption[]
  required?: boolean
  className?: string
  onChangeValue: (value: any) => void
}

export class SelectInput extends React.Component<Props> {
  render() {
    return (
      <div
        className={`input-wrapper${
          this.props.className ? ` ${this.props.className}` : ''
        }`}
      >
        {this.props.label && (
          <label
            htmlFor={this.props.label}
            className={`input-label${this.props.required ? ' required' : ''}`}
          >
            {this.props.label}
          </label>
        )}
        <select
          className="text-input"
          name={this.props.label}
          value={this.props.value}
          required={this.props.required}
          onChange={e => this.props.onChangeValue(e.target.value)}
        >
          {this.props.values.map(option => (
            <option value={option.value} key={option.value}>
              {option.label || option.value}
            </option>
          ))}
        </select>
      </div>
    )
  }
}
