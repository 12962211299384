import * as React from 'react'
import { onKey } from '../helpers/Util'

type Props = {
  name: string
  active: boolean
  onSelect: () => Promise<void> | void
}

export class Filter extends React.PureComponent<Props> {
  render() {
    return (
      <span
        className={`filter tab-focus${
          this.props.active ? ' filter--is-active' : ''
        }`}
        onClick={this.props.onSelect}
        onKeyDown={e => onKey(e, 'Enter', this.props.onSelect)}
        onKeyUp={e => onKey(e, ' ', this.props.onSelect)}
        tabIndex={0}
      >
        {this.props.name}
      </span>
    )
  }
}
