import React from 'react'
import { Redirect } from 'react-router-dom'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Connection, ConnectionsList } from '../containers/ConnectionsList'
import { Welcome } from '../components/Welcome'
import { Page } from '../App'
import { Heading, HeadingLevel } from '../components/Heading'
import { CreateIcon, CreateOption } from '../components/CreateOption'
import { Spinner } from '../components/Spinner'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { triggerOpenedScreenEvent } from '../helpers/Util'
import { Container } from '../containers/ContainersList'
import { notification } from '../components/Notification'

type Props = {
  apiClient: ApiClient

  accountDetails: AccountDetails
  connections: Connection[]
  containers: Container[]
  googleAuthLink?: string
  remainingConnections: number

  connectionsAlreadyFetched: boolean
  devboxesAlreadyFetched: boolean
  connectedAccountsAlreadyFetched: boolean
  loadingInitContent: boolean
  onDidLoadInitContent: () => void

  getConnections: () => Promise<Connection[]>
  getDevboxes: () => Promise<void>
  getConnectedAccounts: () => Promise<void>
}

type State = {
  loading: boolean
  page: string
  limitModalVisible: boolean
}

export class ConnectionsPage extends React.Component<Props> {
  public state: State = {
    loading: false,
    page: Page.Connections,
    limitModalVisible: false,
  }

  private switchPage = (page: string) => {
    if (page === Page.NewConnection && this.props.remainingConnections <= 0) {
      this.setState({
        limitModalVisible: true,
      })
    } else {
      window.history.pushState({ prevUrl: window.location.href }, '', page)
      this.setState({ page })
    }
  }

  componentDidMount() {
    this.getData()
    triggerOpenedScreenEvent(
      'openedConnectionsScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (this.state.page === Page.NewConnection) {
      window.scrollTo(0, 0)
      return <Redirect to={Page.NewConnection} />
    }

    if (
      !this.props.connectionsAlreadyFetched ||
      this.props.loadingInitContent ||
      this.state.loading
    ) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    return (
      <>
        <div className="page-content">
          {!this.props.connections.length ? (
            <Welcome
              currentPage={Page.Connections}
              switchPage={(page: Page) => this.switchPage(page)}
              handleConnectGDrive={this.handleConnectGDrive}
            />
          ) : (
            <>
              <div className="page-group">
                <Heading heading="Create" level={HeadingLevel.First} />
                <div className="create__options">
                  <CreateOption
                    option="New Connection"
                    icon={CreateIcon.AddConnection}
                    onClick={() => this.switchPage(Page.NewConnection)}
                  />
                  <CreateOption
                    option="Google Drive Connection"
                    icon={CreateIcon.GoogleDrive}
                    onClick={this.handleConnectGDrive}
                  />
                </div>
              </div>
              <div className="page-group">
                <ConnectionsList
                  apiClient={this.props.apiClient}
                  accountDetails={this.props.accountDetails}
                  connections={this.props.connections}
                  containers={this.props.containers}
                  sshAmount={this.props.accountDetails.usage.ssh}
                  ftpAmount={this.props.accountDetails.usage.ftp}
                  gdriveAmount={this.props.accountDetails.usage.gdrive}
                  refreshConnectionsList={async () => {
                    await this.props.getConnections()
                  }}
                  switchPage={this.switchPage}
                />
              </div>
            </>
          )}
        </div>
        {this.renderLimitModal()}
      </>
    )
  }

  private renderLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={this.state.limitModalVisible}
        resource="Connections"
        accountDetails={this.props.accountDetails}
        onClose={() => this.setState({ limitModalVisible: false })}
      />
    )
  }

  private async getData() {
    const {
      onDidLoadInitContent,
      connectionsAlreadyFetched,
      devboxesAlreadyFetched,
      connectedAccountsAlreadyFetched,
      getConnections,
      getDevboxes,
      getConnectedAccounts,
    } = {
      ...this.props,
    }

    if (this.props.loadingInitContent) {
      await getDevboxes()
      await getConnections()
      await getConnectedAccounts()
    } else if (
      !devboxesAlreadyFetched ||
      !connectionsAlreadyFetched ||
      !connectedAccountsAlreadyFetched
    ) {
      this.setState({ loading: true })
      if (!devboxesAlreadyFetched) await getDevboxes()
      if (!connectionsAlreadyFetched) await getConnections()
      if (!connectedAccountsAlreadyFetched) await getConnectedAccounts()
      this.setState({ loading: false })
    }

    onDidLoadInitContent()
  }

  private handleConnectGDrive = async () => {
    if (this.props.remainingConnections <= 0) {
      this.setState({
        limitModalVisible: true,
      })
      return
    }

    let authTab = window.open(this.props.googleAuthLink)

    if (authTab) {
      const timer = setInterval(async () => {
        if (authTab && authTab.closed) {
          clearInterval(timer)

          //make sure that connection was successful/unsuccessful
          let previousConnections = await this.props.getConnections()
          if (this.props.connections.length === previousConnections.length) {
            previousConnections = await this.props.getConnections()
          }

          if (this.props.connections.length !== previousConnections.length) {
            notification.success({
              message:
                'You have successfully created a new Google Drive connection.',
            })
          } else {
            notification.warn({
              message:
                'Creating a new Google Drive connection was unsuccessful.',
            })
          }
        }
      }, 200)
    }
  }
}
