import DefaultUserIcon from '../../assets/img/illustrations/default-user-icon-light.svg'
import QuestionMark from '../../assets/img/illustrations/question.svg'
import Empty from '../../assets/img/illustrations/empty.svg'
import Checkmark from '../../assets/img/illustrations/checkmark.svg'
import PageNotFound from '../../assets/img/illustrations/404.svg'

export const ILLUSTRATION: { [x: string]: string } = {
  DefaultUserIcon,
  QuestionMark,
  Empty,
  Checkmark,
  PageNotFound,
}
