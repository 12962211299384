import * as React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { HeaderProfileDropdown } from './HeaderProfileDropdown'
import { ProfileImage } from './ProfileImage'

type Props = {
  accountDetails: AccountDetails
  profileDropdownExpanded: boolean
  onToggleMobileNavigation: (state: boolean) => void
  onToggleProfileDropdown: (state: boolean) => void
}

export class HeaderProfile extends React.Component<Props> {
  render() {
    const { imageurl, firstname, lastname, username, email } = {
      ...this.props.accountDetails,
    }

    return (
      <>
        <HeaderProfileDropdown
          accountDetails={this.props.accountDetails}
          profileDropdownExpanded={this.props.profileDropdownExpanded}
          expandDropdown={this.handleExpandDropdown}
          collapseDropdown={this.handleCollapseDropdown}
        />
        <div
          className="header-profile"
          id="header-profile"
          onClick={this.handleToggleDropdown}
          tabIndex={1}
        >
          <div className="profile-avatar__hook"></div>
          <ProfileImage
            profileImageUrl={imageurl}
            email={email}
            inHeader={true}
          />
          <span className="profile__name">
            {!firstname && !lastname
              ? username
              : `${firstname}, ${lastname.substring(0, 1)}.`}
          </span>
          <span
            className="profile-dropdown__icon"
            data-expanded={this.props.profileDropdownExpanded}
          />
        </div>
      </>
    )
  }

  private handleToggleDropdown = () => {
    if (this.props.profileDropdownExpanded) {
      this.handleCollapseDropdown()
    } else {
      this.handleExpandDropdown()
    }
  }

  private handleCollapseDropdown = () => {
    this.props.onToggleProfileDropdown(false)
  }

  private handleExpandDropdown = () => {
    this.props.onToggleProfileDropdown(true)
  }
}
