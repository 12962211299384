import * as React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  FTPConnection,
  FTPSConnection,
  FTPSConnectionAuth,
  SSHConnection,
  SSHConnectionAuth,
} from '../api-client/interface/Connection'
import { Button } from '../components/Button'
import { ConnectionLabel, Input, InputType } from '../components/Input'
import { Modal } from '../components/Modal'
import { notification } from '../components/Notification'
import { SelectInput } from '../components/SelectInput'
import { TextArea } from '../components/TextArea'
import { focusOnElement } from '../helpers/Util'
import { Connection } from './ConnectionsList'

export enum ConnectionType {
  SSH = 'SSH',
  FTP = 'FTP Server',
  FTPS = 'FTPS Server',
}

export enum AuthorizationType {
  Password = 'Password',
  PrivateKey = 'Private Key',
  PublicKey = 'Public Key',
  ExplicitFTP = 'Explicit FTP over TLS',
  ImplicitFTP = 'Implicit FTP over TLS',
}

const mapAuthorizationTypeToConnectionAuth: {
  [x in AuthorizationType]: number
} = {
  Password: SSHConnectionAuth.Password,
  'Private Key': SSHConnectionAuth['Private Key'],
  'Public Key': SSHConnectionAuth['Public Key'],
  'Explicit FTP over TLS': FTPSConnectionAuth.Control,
  'Implicit FTP over TLS': FTPSConnectionAuth.Implicit,
}

const transformConnectionType: {
  [x: string]: ConnectionType
} = {
  ssh: ConnectionType.SSH,
  ftp: ConnectionType.FTP,
  ftps: ConnectionType.FTPS,
}

const mapSSHConnectionAuthToAuthorizationType: {
  [x in SSHConnectionAuth]: AuthorizationType
} = {
  1: AuthorizationType.Password,
  2: AuthorizationType.PrivateKey,
  3: AuthorizationType.PublicKey,
}

const mapFTPSConnectionAuthToAuthorizationTyped: {
  [x in FTPSConnectionAuth]: AuthorizationType
} = {
  1: AuthorizationType.ExplicitFTP,
  2: AuthorizationType.ImplicitFTP,
}

type Props = {
  apiClient: ApiClient
  editConnection?: Connection
  onCancel: () => void
  onFinish: () => void
}

type State = {
  loading: boolean

  connectionName: string
  connectionType: ConnectionType
  hostname: string
  authorizationType: AuthorizationType
  username: string
  timeout: number
  port: number
  initialDirectory: string
  webURL: string
  rootDirectory: string
  password: string
  privateKey: string

  errorModalVisible: boolean
  errorLog: string
}

const sshPort = 22
const ftpPort = 21
const implicitFTPPort = 990

export class ConnectionForm extends React.Component<Props, State> {
  public state: State = {
    loading: false,

    connectionName: '',
    connectionType: ConnectionType.SSH,
    hostname: '',
    authorizationType: AuthorizationType.Password,
    username: '',
    timeout: 20,
    port: 22,
    initialDirectory: '',
    webURL: '',
    rootDirectory: '',
    password: '',
    privateKey: '',

    errorModalVisible: false,
    errorLog: '',
  }

  componentDidMount() {
    if (this.props.editConnection) this.getEditConnectionInfo()
  }

  render() {
    const authorizationOptions =
      this.state.connectionType === ConnectionType.SSH
        ? [
            AuthorizationType.Password,
            AuthorizationType.PrivateKey,
            AuthorizationType.PublicKey,
          ]
        : [AuthorizationType.ExplicitFTP, AuthorizationType.ImplicitFTP]

    const requiredInputsMissing =
      !this.state.connectionName ||
      !this.state.connectionType ||
      !this.state.hostname ||
      (!this.state.authorizationType &&
        this.state.connectionType !== ConnectionType.FTP) ||
      !this.state.username ||
      !this.state.timeout ||
      !this.state.port ||
      (!this.props.editConnection &&
        !this.state.password &&
        !(
          this.state.connectionType === ConnectionType.SSH &&
          this.state.authorizationType !== AuthorizationType.Password
        )) ||
      (!this.state.privateKey &&
        this.state.connectionType === ConnectionType.SSH &&
        this.state.authorizationType === AuthorizationType.PrivateKey)

    return (
      <form
        className={
          this.props.editConnection ? '' : 'form-wrapper new-connection-form'
        }
        onSubmit={e => {
          e.preventDefault()
          this.handleCreateEditConnection()
        }}
      >
        <div className="two-inputs-container">
          <Input
            label={ConnectionLabel.Name}
            value={this.state.connectionName}
            required={true}
            onChangeInput={connectionName => this.setState({ connectionName })}
          />
          <SelectInput
            label={ConnectionLabel.Type}
            value={this.state.connectionType}
            values={[
              { value: ConnectionType.SSH },
              { value: ConnectionType.FTP },
              { value: ConnectionType.FTPS },
            ]}
            required={true}
            className="connection-type-select"
            onChangeValue={type => {
              const connectionType = type as ConnectionType
              this.setState({
                connectionType: connectionType,
                port: connectionType === ConnectionType.SSH ? sshPort : ftpPort,
              })
            }}
          />
        </div>
        <div className="two-inputs-container">
          <Input
            label={ConnectionLabel.Host}
            value={this.state.hostname}
            required={true}
            onChangeInput={hostname => this.setState({ hostname })}
          />
          {this.state.connectionType !== ConnectionType.FTP && (
            <SelectInput
              label="Authorization"
              value={this.state.authorizationType}
              values={authorizationOptions.map(option => {
                return {
                  value: option,
                }
              })}
              required={true}
              className="connection-type-select"
              onChangeValue={type => {
                const authorizationType = type as AuthorizationType
                this.setState({
                  authorizationType,
                  port:
                    authorizationType === AuthorizationType.ImplicitFTP
                      ? implicitFTPPort
                      : this.state.connectionType === ConnectionType.SSH
                      ? sshPort
                      : ftpPort,
                })
              }}
            />
          )}
          {this.state.connectionType === ConnectionType.FTP && ( //render in place of Authorization for FTP form
            <Input
              label={ConnectionLabel.Password}
              value={this.state.password}
              required={
                !this.props.editConnection &&
                this.state.authorizationType === AuthorizationType.Password
              }
              type={InputType.Password}
              onChangeInput={password => this.setState({ password })}
            />
          )}
        </div>
        <div className="two-inputs-container">
          {!(
            (this.state.connectionType === ConnectionType.SSH &&
              this.state.authorizationType === AuthorizationType.PublicKey) ||
            this.state.connectionType === ConnectionType.FTP
          ) && (
            <Input
              label={ConnectionLabel.Password}
              value={this.state.password}
              required={
                !this.props.editConnection &&
                this.state.authorizationType === AuthorizationType.Password
              }
              type={InputType.Password}
              onChangeInput={password => this.setState({ password })}
            />
          )}
          {this.state.connectionType === ConnectionType.SSH &&
            this.state.authorizationType === AuthorizationType.PrivateKey && (
              <TextArea
                label={ConnectionLabel.PrivateKey}
                value={this.state.privateKey}
                required={true}
                onChangeInput={privateKey => this.setState({ privateKey })}
              />
            )}
        </div>
        <div className="two-inputs-container">
          <Input
            label={ConnectionLabel.Username}
            value={this.state.username}
            required={true}
            onChangeInput={username => this.setState({ username })}
          />
          <Input
            label={ConnectionLabel.Timeout}
            value={this.state.timeout}
            required={true}
            type={InputType.Number}
            onChangeInput={timeout =>
              this.setState({ timeout: parseInt(timeout) })
            }
          />
        </div>
        <div className="two-inputs-container">
          <Input
            label={ConnectionLabel.Port}
            value={this.state.port}
            required={true}
            type={InputType.Number}
            onChangeInput={port => this.setState({ port: parseInt(port) })}
          />
          <Input
            label={ConnectionLabel.WebURL}
            value={this.state.webURL}
            onChangeInput={webURL => this.setState({ webURL })}
          />
        </div>
        <div className="two-inputs-container">
          <Input
            label={ConnectionLabel.InitialDir}
            value={this.state.initialDirectory}
            onChangeInput={initialDirectory =>
              this.setState({ initialDirectory })
            }
          />
          <Input
            label={ConnectionLabel.RootDir}
            value={this.state.rootDirectory}
            onChangeInput={rootDirectory => this.setState({ rootDirectory })}
          />
        </div>
        <div className="button-row">
          <Button
            value="Cancel"
            disabled={this.state.loading}
            onClick={this.props.onCancel}
          />
          <Button
            value={this.props.editConnection ? 'Edit' : 'Create'}
            type="success"
            submit={true}
            disabled={requiredInputsMissing || this.state.loading}
            loading={this.state.loading}
          />
        </div>
        {this.renderErrorModal()}
      </form>
    )
  }

  private renderErrorModal = () => {
    if (this.state.errorModalVisible) {
      return (
        <Modal
          visible={this.state.errorModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({ errorModalVisible: false, errorLog: '' })
          }
        >
          <Modal.Header>Connection error</Modal.Header>
          <Modal.Body>
            <div className="connection-error">
              {this.state.errorLog
                .split('\\r')
                .join('')
                .split('\\n')
                .map(s => {
                  return <span key={Math.random().toPrecision(4)}>{s}</span>
                })}
            </div>
            <div className="button-row bottom-margin">
              <Button
                value="Close"
                onClick={() =>
                  this.setState({ errorModalVisible: false, errorLog: '' })
                }
              />
            </div>
          </Modal.Body>
        </Modal>
      )
    }
  }

  private handleCreateEditConnection = async () => {
    this.setState({ loading: true })
    try {
      const commonAttributes = {
        id: this.props.editConnection ? this.props.editConnection.id : -1,
        name: this.state.connectionName,
        hostname: this.state.hostname,
        password: this.state.password,
        username: this.state.username,
        timeout: this.state.timeout,
        port: this.state.port,
        webUrl: this.state.webURL,
        initialdir: this.state.initialDirectory,
        previewRoot: this.state.rootDirectory,
      }

      const newConnection: SSHConnection | FTPConnection | FTPSConnection =
        this.state.connectionType === ConnectionType.SSH
          ? {
              ...commonAttributes,
              type: 'ssh',
              auth: mapAuthorizationTypeToConnectionAuth[
                this.state.authorizationType
              ],
              privateKey: this.state.privateKey,
            }
          : this.state.connectionType === ConnectionType.FTP
          ? {
              ...commonAttributes,
              type: 'ftp',
            }
          : {
              ...commonAttributes,
              type: 'ftps',
              auth: mapAuthorizationTypeToConnectionAuth[
                this.state.authorizationType
              ],
            }

      if (this.props.editConnection) {
        await this.props.apiClient.editConnection(newConnection)
        notification.success({
          message: 'You have successfully edited the connection.',
        })
      } else {
        await this.props.apiClient.addConnection(newConnection)
        notification.success({
          message: 'You have successfully created a new connection.',
        })
      }
      this.props.onFinish()
    } catch (e: any) {
      notification.error({
        message: `An error ocurred while ${
          this.props.editConnection ? 'editing' : 'creating'
        } the connection.`,
      })
      this.setState({
        loading: false,
        errorModalVisible: true,
        errorLog: e.log || e.toString(),
      })
      focusOnElement(ConnectionLabel.Name)
    }
  }

  private getEditConnectionInfo = async () => {
    if (!this.props.editConnection) {
      return
    }

    try {
      const connectionInfo = await this.props.apiClient.getConnection(
        this.props.editConnection.id
      )

      this.setState({
        connectionName: connectionInfo.name,
        connectionType: transformConnectionType[connectionInfo.type],
        hostname: connectionInfo.hostname,
        authorizationType:
          transformConnectionType[connectionInfo.type] === ConnectionType.SSH
            ? mapSSHConnectionAuthToAuthorizationType[
                (connectionInfo as SSHConnection).auth
              ]
            : mapFTPSConnectionAuthToAuthorizationTyped[
                (connectionInfo as FTPSConnection).auth
              ],
        username: connectionInfo.username,
        timeout: connectionInfo.timeout,
        port: connectionInfo.port,
        initialDirectory: connectionInfo.initialdir,
        webURL: connectionInfo.webUrl,
        rootDirectory: connectionInfo.previewRoot,
        password:
          (connectionInfo as SSHConnection | FTPConnection | FTPSConnection)
            .password || '',
        privateKey:
          transformConnectionType[connectionInfo.type] === ConnectionType.SSH
            ? (connectionInfo as SSHConnection).privateKey || ''
            : '',
      })
    } catch (e: any) {
      notification.error({
        message: 'An error occured while getting the connection info.',
        description: e,
      })
    }
  }
}
