import * as React from 'react'
import { Invoice } from '../api-client/interface/Invoice'
import { ApiClient } from '../api-client/interface/ApiClient'
import moment from 'moment'
import { DownloadIcon } from '../helpers/image-imports/IconComponent'
import { notification } from '../components/Notification'
import { Spinner } from '../components/Spinner'
import { Pagination, PaginationSize } from '../components/Pagination'
import { onKey } from '../helpers/Util'

type Props = {
  apiClient: ApiClient

  loadingInitContent: boolean
  onDidLoadInitContent: () => void
}

type State = {
  invoices: Invoice[]
  loading: boolean

  currentPaginationPage: number
  paginationSize: PaginationSize
}

export class InvoicesTable extends React.Component<Props, State> {
  public state: State = {
    invoices: [],
    loading: true,

    currentPaginationPage: 1,
    paginationSize: PaginationSize.Nine,
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    if (this.props.loadingInitContent || this.state.loading) {
      return <Spinner size={'cover'} />
    }

    const displayedInvoices = this.state.invoices.slice(
      (this.state.currentPaginationPage - 1) * this.state.paginationSize,
      this.state.currentPaginationPage * this.state.paginationSize
    )

    return (
      <>
        <div className="table-wrapper">
          <table className="table table--is-invoices">
            <thead>
              <tr className="table-header">
                <th className="table-column">Invoice</th>
                <th className="table-column">Status</th>
                <th className="table-column">Date</th>
                <th className="table-column">Amount</th>
                <th className="table-column">PDF</th>
              </tr>
            </thead>
            <tbody>
              {displayedInvoices.map(invoice => (
                <tr className="invoice table-row" key={invoice.id}>
                  <td className="table-column">{invoice.number}</td>
                  <td className="status table-column">
                    <span className={invoice.status}>{invoice.status}</span>
                  </td>
                  <td className="table-column">
                    {moment(invoice.created).format('MMM Do YYYY')}
                  </td>
                  <td className="table-column">
                    {invoice.total.startsWith('-') && '-'}
                    {`$${Math.abs(parseInt(invoice.total)) / 100}`}
                  </td>
                  <td className="table-column">
                    <DownloadIcon
                      className="tab-focus"
                      onClick={() => this.downloadInvoicePDF(invoice.number)}
                      onKeyDown={e =>
                        onKey(e, 'Enter', () =>
                          this.downloadInvoicePDF(invoice.number)
                        )
                      }
                      onKeyUp={e =>
                        onKey(e, ' ', () =>
                          this.downloadInvoicePDF(invoice.number)
                        )
                      }
                      tabIndex={0}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          total={this.state.invoices.length}
          paginationSize={this.state.paginationSize}
          currentPage={this.state.currentPaginationPage}
          onChangePageSize={paginationSize =>
            this.setState({ paginationSize, currentPaginationPage: 1 })
          }
          onChangePage={currentPaginationPage =>
            this.setState({ currentPaginationPage })
          }
        />
      </>
    )
  }

  private getData = async () => {
    await this.getInvoices()
    this.props.onDidLoadInitContent()
  }

  private async getInvoices() {
    try {
      this.setState({
        invoices: await this.props.apiClient.getInvoices(),
      })
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while getting your list of invoices',
        description: e,
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  private downloadInvoicePDF = async (invoiceNumber: string) => {
    try {
      await this.props.apiClient.downloadInvoicePdf(invoiceNumber)
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while downloading the invoice PDF.',
        description: e,
      })
    }
  }
}
