import React from 'react'
import { Redirect } from 'react-router-dom'
import { ApiClient } from '../api-client/interface/ApiClient'
import { BillingInfo } from '../api-client/interface/BillingInfo'
import { Subscription } from '../api-client/interface/Subscription'
import { Page } from '../App'
import { CurrentPlan } from '../components/CurrentPlan'
import { Heading, HeadingLevel } from '../components/Heading'
import { PaymentInformation } from '../containers/PaymentInformation'
import { Spinner } from '../components/Spinner'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { onKey, triggerOpenedScreenEvent } from '../helpers/Util'

export enum PlanPeriod {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
  BIYEARLY = 'BiYearly',
  THREE_YEAR = '3-Yearly',
  FIVE_YEAR = '5-Yearly',
  INVALID = 'Invalid period',
}

type Props = {
  apiClient: ApiClient

  accountDetails: AccountDetails
  subscription: Subscription | null
  onMonthlyPlan: boolean

  loadingInitContent: boolean
  onDidLoadInitContent: () => void
}

type State = {
  page: Page
  loading: boolean
  billingInfo: BillingInfo
  billingInfoEmpty: boolean
  paymentInformationExpanded: boolean
}

export class BillingPage extends React.Component<Props, State> {
  public state: State = {
    page: Page.Billing,
    loading: true,
    billingInfo: {
      firstName: '',
      lastName: '',
      company: '',
      vatNumber: '',
      address: {
        phone: '',
        street1: '',
        street2: '',
        city: '',
        region: '',
        postalCode: '',
        country: '',
      },
      type: '',
      cardType: '',
    },
    billingInfoEmpty: true,
    paymentInformationExpanded: false,
  }

  componentDidMount() {
    if (this.props.accountDetails.child_account) {
      window.location.href = Page.Home + '?child=true'
    }

    this.getData()
    triggerOpenedScreenEvent(
      'openedBillingScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (this.state.page === Page.PaymentHistory) {
      window.scrollTo(0, 0)
      return <Redirect to={Page.PaymentHistory} />
    }

    if (this.props.loadingInitContent || this.state.loading) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    /*  const paymentMethodIcon =
      this.state.billingInfo.type === 'paypal'
        ? 'PayPal'
        : this.state.billingInfo.type === 'apple_pay'
        ? 'Apple Pay'
        : this.state.billingInfo.type === 'amazon_pay'
        ? 'Amazon Pay'
        : this.state.billingInfo.type === 'venmo'
        ? 'Venmo'
        : this.state.billingInfo.cardType! */

    return (
      <div className="page-content">
        {/* <div className="page-group">
          <Heading heading="Billing" level={HeadingLevel.First} />
          <div className="billing-info-cards-container">
            <BillingInfoCard
              title="Current monthly bill"
              action={
                this.props.onMonthlyPlan
                  ? 'Switch to yearly billing'
                  : 'Upgrade your subscription'
              }
              onClickAction={() => (window.location.href = Page.Checkout)}
            >
              {this.props.subscription?.unit_amount_in_cents
                ? `$${
                    parseInt(this.props.subscription.unit_amount_in_cents) / 100
                  }`
                : '-'}
            </BillingInfoCard>
            <BillingInfoCard
              title="Next payment due"
              action="View payment history"
              onClickAction={() => this.switchPage(Page.PaymentHistory)}
            >
              {this.props.subscription
                ? moment(this.props.subscription.current_period_ends_at).format(
                    'MMM Do YYYY'
                  )
                : '-'}
            </BillingInfoCard>
            <BillingInfoCard
              title="Payment information"
              action="Update payment method"
              onClickAction={() => {
                this.setState({ paymentInformationExpanded: true }, () =>
                  document
                    .getElementById('payment-information-heading')!
                    .scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'nearest',
                    })
                )
              }}
            >
              {this.props.subscription ? (
                <img
                  src={PAYMENT[paymentMethodIcon]}
                  alt="Payment method icon"
                />
              ) : (
                '-'
              )}
            </BillingInfoCard>
          </div>
        </div> */}
        <div className="page-group">
          <Heading
            heading="Current plan"
            level={HeadingLevel.First}
            divider={true}
          />
          <CurrentPlan
            subscription={this.props.subscription}
            accountDetails={this.props.accountDetails}
          />
          <div
            className="view-payment-history tab-focus"
            onClick={this.handleViewPaymentHistoryClick}
            onKeyDown={e =>
              onKey(e, 'Enter', this.handleViewPaymentHistoryClick)
            }
            onKeyUp={e => onKey(e, ' ', this.handleViewPaymentHistoryClick)}
            tabIndex={0}
          >
            View payment history
          </div>
        </div>
        <div className="page-group">
          <Heading
            heading="Payment information"
            level={HeadingLevel.First}
            divider={true}
            elementId="payment-information-heading"
            scrollTarget={true}
          />
          <PaymentInformation
            apiClient={this.props.apiClient}
            expanded={this.state.paymentInformationExpanded}
            billingInfo={this.state.billingInfo}
            billingInfoEmpty={this.state.billingInfoEmpty}
            onBillingInfoInputChange={this.handleBillingInfoInputChange}
            onAddressInputChange={this.handleAddressInputChange}
            onCountrySelectChange={this.handleCountrySelectChange}
            refreshBillingInfo={this.getBillingInfo}
            toggleForm={(paymentInformationExpanded: boolean) =>
              this.setState({ paymentInformationExpanded }, () =>
                document
                  .getElementById('payment-information-heading')!
                  .scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  })
              )
            }
          />
        </div>
      </div>
    )
  }

  private getData = async () => {
    await this.getBillingInfo()
    this.props.onDidLoadInitContent()
  }

  private getBillingInfo = async () => {
    this.setState({ loading: true })
    try {
      this.setState({
        billingInfo: await this.props.apiClient.getBillingInfo(),
        billingInfoEmpty: false,
      })
    } catch (e) {
      /* notification.error({
        message: 'An error ocurred while getting your billing information.',
        description: e.response.data.err.message || String(e),
      }) */
    } finally {
      this.setState({ loading: false })
    }
  }

  private handleBillingInfoInputChange = (dataKey: string, value: string) => {
    const billingInfo: { [key: string]: string } = {}
    billingInfo[dataKey] = value
    this.setState({
      billingInfo: {
        ...this.state.billingInfo,
        ...billingInfo,
      },
    })
  }

  private handleAddressInputChange = (dataKey: string, value: string) => {
    const address: { [key: string]: string } = {}
    address[dataKey] = value
    this.setState({
      billingInfo: {
        ...this.state.billingInfo,
        address: {
          ...this.state.billingInfo.address,
          ...address,
        },
      },
    })
  }

  private handleCountrySelectChange = (value: string) => {
    this.setState({
      billingInfo: {
        ...this.state.billingInfo,
        address: {
          ...this.state.billingInfo.address,
          country: value,
        },
      },
    })
  }

  private switchPage = (page: Page) => {
    window.history.pushState({ prevUrl: window.location.href }, '', page)
    this.setState({ page })
  }

  private handleViewPaymentHistoryClick = () => {
    this.switchPage(Page.PaymentHistory)
  }
}
