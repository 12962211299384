import Add from '../../assets/img/checkout/add.svg'
import Check from '../../assets/img/checkout/check.svg'
import Close from '../../assets/img/checkout/close.svg'
import CouponClose from '../../assets/img/checkout/coupon-close.svg'
import MoneyBackGuarantee from '../../assets/img/checkout/money-back-guarantee.svg'
import MoneyBack from '../../assets/img/checkout/money-back.svg'
import PaymentInformation from '../../assets/img/checkout/payment-information.svg'
import PurchaseSecurely from '../../assets/img/checkout/purchase-securely.svg'
import SuccessInfo from '../../assets/img/checkout/success-info.svg'
import WarningSign from '../../assets/img/checkout/warning-sign.svg'

export const CHECKOUT: { [x: string]: string } = {
  Add,
  Check,
  Close,
  CouponClose,
  MoneyBackGuarantee,
  MoneyBack,
  PaymentInformation,
  PurchaseSecurely,
  SuccessInfo,
  WarningSign,
}
