import * as React from 'react'

interface Props {
  line?: number
  children: React.ReactNode
}

export class Truncate extends React.PureComponent<Props> {
  render() {
    const { line = 1, children } = this.props

    return (
      <div
        style={{ '--line-cut': line } as React.CSSProperties}
        className={'truncate'}
      >
        {children}
      </div>
    )
  }
}
