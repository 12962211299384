import * as React from 'react'
import { Page } from '../App'
import { LOGO } from '../helpers/image-imports/Logo'
import { onKey } from '../helpers/Util'

export class HeaderLogo extends React.PureComponent {
  render() {
    return (
      <picture className="header__logo">
        <source srcSet={LOGO['CodeanywhereLogo']} media="(min-width: 992px)" />
        <img
          src={LOGO['CodeanywhereGlyph']}
          alt="Logo"
          className="tab-focus"
          onClick={this.handleClickLogo}
          onKeyDown={e => onKey(e, 'Enter', this.handleClickLogo)}
          onKeyUp={e => onKey(e, ' ', this.handleClickLogo)}
          tabIndex={0}
        />
      </picture>
    )
  }

  private handleClickLogo = () => {
    window.location.href = Page.Home
  }
}
