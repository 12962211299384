import * as React from 'react'

type Props = {
  title: string
  value: string
}

export class PlanStatusTab extends React.PureComponent<Props> {
  render() {
    return (
      <div className="tab">
        <span className="title">{this.props.title}</span>
        <span>{this.props.value}</span>
      </div>
    )
  }
}
