import * as React from 'react'
import { Page } from '../App'
import { ILLUSTRATION } from '../helpers/image-imports/Illustration'
import { CreateIcon, CreateOption } from './CreateOption'

type Props = {
  currentPage: Page
  switchPage: (page: Page) => void
  handleConnectGDrive?: () => Promise<void>
}

export class Welcome extends React.PureComponent<Props> {
  render() {
    return (
      <div className="page-ilustration">
        <div className="title">
          {this.props.currentPage === Page.Home
            ? 'Welcome to Codeanywhere Dashboard!'
            : this.props.currentPage === Page.Containers
            ? "Looks like you don't have any containers!"
            : "Looks like you don't have any connections!"}
        </div>
        <div className="subtitle">
          {this.props.currentPage === Page.Home
            ? 'This is a place where you start your journey. You can create your first container or a connection.'
            : this.props.currentPage === Page.Containers
            ? 'Create a new container using our predefined templates or create a blank container and build your own configuration.'
            : 'Connect your SSH or FTP servers with only a couple of clicks and access their files directly from the editor.'}
        </div>
        <div>
          {(this.props.currentPage === Page.Home ||
            this.props.currentPage === Page.Containers) && (
            <>
              <CreateOption
                option="New Container"
                icon={CreateIcon.Plus}
                onClick={() => this.props.switchPage(Page.NewContainer)}
              />
              {/* <CreateOption
                option="Clone from GitHub"
                icon={CreateIcon.Plus}
                onClick={() => this.props.switchPage(Page.CloneFromGithub)}
              /> */}
            </>
          )}
          {(this.props.currentPage === Page.Home ||
            this.props.currentPage === Page.Connections) &&
            !!this.props.handleConnectGDrive && (
              <>
                <CreateOption
                  option="New Connection"
                  icon={CreateIcon.AddConnection}
                  onClick={() => this.props.switchPage(Page.NewConnection)}
                />
                <CreateOption
                  option="Google Drive Connection"
                  icon={CreateIcon.GoogleDrive}
                  onClick={this.props.handleConnectGDrive}
                />
              </>
            )}
        </div>
        <img src={ILLUSTRATION['Empty']} alt="Empty page" />
      </div>
    )
  }
}
