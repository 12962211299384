import * as React from 'react'
import { Container } from '../containers/ContainersList'
import { CONTAINER_STATE } from '../helpers/ContainerState'
import { onKey } from '../helpers/Util'
import { Truncate } from './Truncate'

type Props = {
  containers: Container[]
  selectedContainer?: Container
  onChangeSelectedContainer: (container: Container) => void
  isContainerStateValid(containerState: number): boolean
}

export class ContainersTable extends React.Component<Props> {
  render() {
    return (
      <div className="table-wrapper">
        <table className="table table--is-custom-template">
          <thead>
            <tr className="table-header">
              <th className="table-column"></th>
              <th className="table-column">Name</th>
              <th className="table-column">State</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {this.props.containers.map(container => (
              <tr
                key={container.id}
                className={`table-row tab-focus${
                  !this.props.isContainerStateValid(container.state)
                    ? ' disabled'
                    : ''
                }${
                  this.props.selectedContainer?.id === container.id
                    ? ' selected'
                    : ''
                }`}
                onClick={() => this.handleChangeChecked(container)}
                onKeyDown={e =>
                  onKey(e, 'Enter', () => this.handleChangeChecked(container))
                }
                onKeyUp={e =>
                  onKey(e, ' ', () => this.handleChangeChecked(container))
                }
                tabIndex={0}
              >
                <td className="table-column">
                  <label className="radio-input">
                    <input
                      name="radio"
                      type="radio"
                      className="radio-input__field"
                      disabled={
                        !this.props.isContainerStateValid(container.state)
                      }
                      checked={
                        this.props.selectedContainer?.id === container.id
                      }
                      onChange={() => this.handleChangeChecked(container)}
                    />
                    <span className="radio-input__checkmark" />
                  </label>
                </td>
                <td className="table-column">
                  <Truncate>{container.name}</Truncate>
                </td>
                <td className="table-column">
                  {CONTAINER_STATE[container.state]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  private handleChangeChecked = (container: Container) => {
    if (this.props.isContainerStateValid(container.state)) {
      this.props.onChangeSelectedContainer(container)
    } else {
      return undefined
    }
  }
}
