import * as React from 'react'

type Props = {
  id: string
  label?: string
  toggled: boolean
  disabled?: boolean
  loading?: boolean
  onChangeToggleState: () => Promise<void> | void
}

export class ToggleSwitch extends React.PureComponent<Props> {
  render() {
    return (
      <label
        htmlFor={this.props.id}
        className={`toggle-switch${this.props.disabled ? ' disabled' : ''}${
          this.props.loading ? ' loading' : ''
        }`}
      >
        <input
          id={this.props.id}
          type="checkbox"
          checked={this.props.toggled}
          disabled={this.props.disabled}
          onChange={
            this.props.loading
              ? undefined
              : () => this.props.onChangeToggleState()
          }
        />
        <span className="slider round"></span>
        <span className="label">{this.props.label}</span>
      </label>
    )
  }
}
