import { AuthProvider } from '../interface/AuthProvider'
import { parse as parseCookie } from 'cookie'

export class CodeanywhereAuthProvider implements AuthProvider {
  private loginUrl: string

  constructor(loginUrl: string) {
    this.loginUrl = loginUrl
  }

  public authorize() {
    //  @ts-ignore
    window.location = `${this.loginUrl}?callback_uri=${window.location.href}`
  }

  public async getAccessToken() {
    const cookie = parseCookie(document.cookie)

    if (!cookie.token) {
      this.authorize()
      throw new Error('Unauthorized')
    }
    return cookie.token
  }
}
