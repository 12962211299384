import * as React from 'react'
import { ILLUSTRATION } from '../helpers/image-imports/Illustration'

type Props = {
  benefit: string
}

export class PlanBenefit extends React.PureComponent<Props> {
  render() {
    return (
      <span className="plan-benefit">
        <img
          src={ILLUSTRATION['Checkmark']}
          alt="Question icon"
          className="icon"
        />
        {this.props.benefit}
      </span>
    )
  }
}
