import * as React from 'react'
import { LeftArrowIcon } from '../helpers/image-imports/IconComponent'
import { onKey } from '../helpers/Util'
import { ConnectionFilters, ConnectionFiltersData } from './ConnectionFilters'
import { DevboxFilters, DevboxFiltersData } from './DevboxFilters'

export enum HeadingLevel {
  First = 'level-1',
  Second = 'level-2',
  Third = 'level-3',
}

type Props = {
  heading: string
  level: HeadingLevel
  displaySeparator?: boolean
  goBack?: boolean
  divider?: boolean
  elementId?: string
  scrollTarget?: boolean

  devboxFiltersData?: DevboxFiltersData
  connectionFiltersData?: ConnectionFiltersData

  children?: React.ReactNode
}

export class Heading extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={`page-heading${' ' + this.props.level}${
          this.props.divider ? ' page-heading--has-divider' : ''
        }${this.props.scrollTarget ? ' scroll-margin-top' : ''}`}
        id={this.props.elementId ? this.props.elementId : undefined}
      >
        <span>
          {this.props.goBack && (
            <LeftArrowIcon
              className={'page-heading__arrow tab-focus'}
              onClick={() => window.history.back()}
              onKeyDown={e => onKey(e, 'Enter', () => window.history.back())}
              onKeyUp={e => onKey(e, ' ', () => window.history.back())}
              tabIndex={0}
            />
          )}
          <span className={'page-heading__content'}>{this.props.heading}</span>
          {this.props?.children}
        </span>
        {this.props.devboxFiltersData && (
          <DevboxFilters filtersData={this.props.devboxFiltersData} />
        )}
        {this.props.connectionFiltersData && (
          <ConnectionFilters filtersData={this.props.connectionFiltersData} />
        )}
      </div>
    )
  }
}
