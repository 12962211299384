import * as React from 'react'
import { Heading, HeadingLevel } from './Heading'
import { Button } from './Button'
import { Input, AccountLabel, InputType } from './Input'

type Props = {
  loading: boolean
  hasInitialPassword: boolean
  accountDeleted: boolean
  onDelete: (passwordConfirmation: string) => Promise<void>
}

type State = {
  passwordConfirmation: string
  passwordConfirmationVisible: boolean
  accountDeleted: boolean
}

export class DeleteAccountAction extends React.PureComponent<Props, State> {
  public state: State = {
    passwordConfirmation: '',
    passwordConfirmationVisible: false,
    accountDeleted: false,
  }

  render() {
    return (
      <div className="account-section">
        <Heading heading="Danger Zone" level={HeadingLevel.Second} />
        {this.state.passwordConfirmationVisible ? (
          <>
            <Input
              label={AccountLabel.DeleteAccountConfirmation}
              value={this.state.passwordConfirmation}
              type={InputType.Password}
              required={true}
              disabled={!this.props.hasInitialPassword}
              small={true}
              onChangeInput={passwordConfirmation => {
                this.setState({
                  passwordConfirmation,
                })
              }}
            />
            <Button
              value="Delete my account"
              type="danger"
              loading={this.props.loading}
              disabled={!this.state.passwordConfirmation}
              onClick={() =>
                this.props.onDelete(this.state.passwordConfirmation)
              }
            />
          </>
        ) : (
          <>
            <Heading
              heading={
                this.props.hasInitialPassword
                  ? 'Delete your account and account data.'
                  : 'You have to set your password before deleting your account.'
              }
              level={HeadingLevel.Third}
            />
            <Button
              value="Delete my account"
              type="danger"
              loading={this.props.loading}
              disabled={
                !this.props.hasInitialPassword || this.props.accountDeleted
              }
              onClick={() =>
                this.setState({ passwordConfirmationVisible: true })
              }
            />
          </>
        )}
      </div>
    )
  }
}
