import * as React from 'react'
import { ConnectionFilter } from '../containers/ConnectionsList'
import { Filter } from './Filter'

export type ConnectionFiltersData = {
  activeFilter: ConnectionFilter
  onChangeActiveFilter: (activeFilter: ConnectionFilter) => void
  sshAmount: number
  ftpAmount: number
  gdriveAmount: number
}

type Props = {
  filtersData: ConnectionFiltersData
}

export class ConnectionFilters extends React.PureComponent<Props> {
  render() {
    const {
      activeFilter,
      onChangeActiveFilter,
      sshAmount,
      ftpAmount,
      gdriveAmount,
    } = {
      ...this.props.filtersData,
    }

    return (
      <div className="filters">
        <Filter
          name={`All Connections ${sshAmount + ftpAmount}`}
          active={activeFilter === ConnectionFilter.All}
          onSelect={() => onChangeActiveFilter(ConnectionFilter.All)}
        />
        <Filter
          name={`SSH ${sshAmount}`}
          active={activeFilter === ConnectionFilter.SSH}
          onSelect={() => onChangeActiveFilter(ConnectionFilter.SSH)}
        />
        <Filter
          name={`FTP/FTPS ${ftpAmount}`}
          active={activeFilter === ConnectionFilter.FTP}
          onSelect={() => onChangeActiveFilter(ConnectionFilter.FTP)}
        />
        <Filter
          name={`Google Drive ${gdriveAmount}`}
          active={activeFilter === ConnectionFilter.GoogleDrive}
          onSelect={() => onChangeActiveFilter(ConnectionFilter.GoogleDrive)}
        />
      </div>
    )
  }
}
