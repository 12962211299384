import React from 'react'

type Props = {
  paypalAgreement?: string
  amazonPayAgreement?: string
  type?: string
}

export class BillingAgreementLabel extends React.Component<Props> {
  render() {
    const agreement = this.props.paypalAgreement
      ? this.props.paypalAgreement
      : this.props.amazonPayAgreement

    return (
      <>
        {(this.props.type === 'paypal' || this.props.type === 'amazon') && (
          <span>Billing Agreement: {agreement}</span>
        )}
      </>
    )
  }
}
