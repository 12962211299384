import blank from '../../assets/img/stack-types/default.svg'
import c from '../../assets/img/stack-types/c.svg'
import dotnet from '../../assets/img/stack-types/dotnet.svg'
import go from '../../assets/img/stack-types/go.svg'
import html from '../../assets/img/stack-types/html.svg'
import java from '../../assets/img/stack-types/java.svg'
import node from '../../assets/img/stack-types/nodejs.svg'
import php from '../../assets/img/stack-types/php.svg'
import python from '../../assets/img/stack-types/python.svg'
import ruby from '../../assets/img/stack-types/ruby.svg'
import wordpress from '../../assets/img/stack-types/wordpress.svg'
import custom from '../../assets/img/stack-types/custom.svg'
import rust from '../../assets/img/stack-types/rust.svg'

export const STACK_TYPE: { [x: string]: string } = {
  default: blank,
  c,
  cpp: c,
  'C#': dotnet,
  dotnet,
  go,
  html,
  java,
  node,
  nodejs: node,
  php,
  python,
  ruby,
  wordpress,
  custom,
  rust,
}
