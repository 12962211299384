import * as React from 'react'
import { SearchIcon } from '../helpers/image-imports/IconComponent'

type Props = {
  placeholder: string
  onUpdateSearchValue: (searchValue: string) => void
}

export class SearchInput extends React.PureComponent<Props> {
  render() {
    return (
      <div className="search-wrapper input-wrapper">
        <SearchIcon />
        <input
          type="search"
          className="search text-input white"
          placeholder={this.props.placeholder}
          onChange={e => this.props.onUpdateSearchValue(e.target.value)}
        />
      </div>
    )
  }
}
