import * as React from 'react'
import { Heading, HeadingLevel } from './Heading'
import { Button } from './Button'

type Props = {
  onClick: () => void
}

export class CancelMembershipAction extends React.PureComponent<Props> {
  render() {
    return (
      <div className="account-section">
        <Heading heading="Danger Zone" level={HeadingLevel.Second} />
        <Heading
          heading="If you want to start the cancellation process of your account, click the button below."
          level={HeadingLevel.Third}
        />
        <Button
          value="Cancel my membership"
          type="danger"
          onClick={this.props.onClick}
        />
      </div>
    )
  }
}
