import * as React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { CreateCustomStack } from '../api-client/interface/Connection'
import { Button } from '../components/Button'
import { ContainersTable } from '../components/ContainersTable'
import { CustomTemplateLabel, Input } from '../components/Input'
import { notification } from '../components/Notification'
import { focusOnElement } from '../helpers/Util'
import { Container } from './ContainersList'

type Props = {
  apiClient: ApiClient
  containers: Container[]
}

type State = {
  loading: boolean
  selectedContainer?: Container
  customTemplate: CreateCustomStack
}

export class CustomTemplateForm extends React.Component<Props, State> {
  public state: State = {
    loading: false,
    customTemplate: {
      id: -1,
      name: '',
      description: '',
    },
  }

  render() {
    const requiredInputsMissing =
      this.state.customTemplate.id === -1 ||
      !this.state.customTemplate.name ||
      !this.state.customTemplate.description

    return (
      <form
        className="form-wrapper custom-template-form"
        onSubmit={e => {
          e.preventDefault()
          this.handleCreateCustomTemplate()
        }}
      >
        <div className="two-inputs-container">
          <Input
            label={CustomTemplateLabel.Name}
            value={this.state.customTemplate.name}
            required={true}
            onChangeInput={name =>
              this.setState({
                customTemplate: { ...this.state.customTemplate, name },
              })
            }
          />
          <Input
            label={CustomTemplateLabel.Description}
            value={this.state.customTemplate.description}
            required={true}
            onChangeInput={description =>
              this.setState({
                customTemplate: { ...this.state.customTemplate, description },
              })
            }
          />
        </div>
        <div className="input-wrapper">
          <label className="input-label required">Select container</label>
          <ContainersTable
            containers={this.props.containers}
            selectedContainer={this.state.selectedContainer}
            onChangeSelectedContainer={selectedContainer =>
              this.setState({
                selectedContainer,
                customTemplate: {
                  ...this.state.customTemplate,
                  id: selectedContainer.id,
                },
              })
            }
            isContainerStateValid={(containerState: number) =>
              containerState === 10 || containerState === 20
            }
          />
        </div>
        <div className="button-row">
          <Button
            value="Cancel"
            onClick={() => {
              window.history.back()
            }}
            disabled={this.state.loading}
          />
          <Button
            value="Create"
            type="success"
            submit={true}
            loading={this.state.loading}
            disabled={requiredInputsMissing || this.state.loading}
          />
        </div>
      </form>
    )
  }

  private handleCreateCustomTemplate = async () => {
    this.setState({ loading: true })
    try {
      await this.props.apiClient.createCustomStack(this.state.customTemplate)

      notification.success({
        message: 'Your custom template will be ready to use in a few minutes.',
      })

      window.history.back()
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while creating the custom template.',
        description: e,
      })
      focusOnElement(CustomTemplateLabel.Name)
    } finally {
      this.setState({ loading: false })
    }
  }
}
