import { PlanPeriodAbbreviation } from '../../containers/CheckoutStep1Container'
import { PricingPlanCode } from './PricingPlan'

export interface Subscription {
  uuid: string
  state: string
  plan_code: string
  current_period_ends_at: string
  current_period_started_at: string
  activated_at: string
  plan_name: string
  pending_subscription?: {
    plan_name: string
    plan_code: string
  }
  subscription_add_ons: {
    subscription_add_on: SubscriptionAddon[]
  }
  unit_amount_in_cents?: string
}

export enum AddonName {
  MultipleAccounts = 'multipleaccounts',
  Extra15Remote = 'extra15remote',
  Extra15Domains = 'extra15domains',
  DevboxAlwaysOn = 'devboxalwayson',
  AdditionalContainer = 'additionalcontainer',
}

export type SubscriptionAddons = {
  [x in AddonName]: number
}

export const ADDON_TITLES: { [x in AddonName]: string } = {
  multipleaccounts: 'Team Accounts',
  extra15remote: 'Additional 15 Connections',
  extra15domains: 'Additional 15 Custom Domains',
  devboxalwayson: 'Always-On Option',
  additionalcontainer: 'Additional Container',
}

export const ADDON_DESCRIPTIONS: { [x in AddonName]: string } = {
  multipleaccounts:
    'Owning team accounts allows you to invite your students and colleagues, share custom stacks and more.',
  extra15remote: 'Connect to remote environments via SSH, FTP, FTPS or SFTP.',
  extra15domains:
    'Custom Domains enable you to add any domain you wish to our containers (for example: example.domain.com).',
  devboxalwayson:
    'Regular containers will be stopped when you sign out. Containers with the applied “Always-On” option will keep running even if you’re not working.',
  additionalcontainer:
    'Additional Containers give you the freedom to work with multiple workspaces inside Codeanywhere',
}

export const ADDON_INCLUDED_PRICE = 99999
export const ADDON_PRICE_NEW: {
  [x in PricingPlanCode]: {
    [z in AddonName]: { [y in PlanPeriodAbbreviation]: number }
  }
} = {
  basic_buy: {
    multipleaccounts: {
      m: -1,
      y: -1,
      '2y': -1,
    },
    extra15remote: {
      m: 5,
      y: 4,
      '2y': 3,
    },
    extra15domains: {
      m: 5,
      y: 4,
      '2y': 3,
    },
    devboxalwayson: {
      m: -1,
      y: -1,
      '2y': -1,
    },
    additionalcontainer: {
      m: -1,
      y: -1,
      '2y': -1,
    },
  },
  standard_buy: {
    multipleaccounts: {
      m: 15,
      y: 12,
      '2y': 9,
    },
    extra15remote: {
      m: 5,
      y: 4,
      '2y': 3,
    },
    extra15domains: {
      m: 5,
      y: 4,
      '2y': 3,
    },
    devboxalwayson: {
      m: 7.5,
      y: 6,
      '2y': 4.5,
    },
    additionalcontainer: {
      m: 5,
      y: 4,
      '2y': 3,
    },
  },
  premium_buy: {
    multipleaccounts: {
      m: 40,
      y: 32,
      '2y': 24,
    },
    extra15remote: {
      m: ADDON_INCLUDED_PRICE,
      y: ADDON_INCLUDED_PRICE,
      '2y': ADDON_INCLUDED_PRICE,
    },
    extra15domains: {
      m: 5,
      y: 4,
      '2y': 3,
    },
    devboxalwayson: {
      m: 12.5,
      y: 10,
      '2y': 7.5,
    },
    additionalcontainer: {
      m: 7.5,
      y: 6,
      '2y': 4.5,
    },
  },
}

export const calculateAddonDiscount = (
  name: AddonName,
  planCode: PricingPlanCode,
  planPeriod: PlanPeriodAbbreviation
) => {
  if (planPeriod === PlanPeriodAbbreviation.MONTHLY) {
    return '0'
  }

  return (
    (1 -
      ADDON_PRICE_NEW[planCode][name][planPeriod] /
        ADDON_PRICE_NEW[planCode][name][PlanPeriodAbbreviation.MONTHLY]) *
    100
  ).toFixed(2)
}

export interface SubscriptionAddon {
  add_on_code: string
  unit_amount_in_cents: string
  quantity: string
}

export interface ChildAccountAddon {
  addon_code: string
  quantity: number
}

export interface ChildAccount {
  child: number
  email: string
  pending_confirmation: number
  deactivated: number
  createdAt: string
  addons: ChildAccountAddon[] | null
}

export const EMPTY_CHILD_ACCOUNT: ChildAccount = {
  child: -1,
  email: '',
  pending_confirmation: -1,
  deactivated: -1,
  createdAt: '',
  addons: null,
}

export type SubscriptionAction =
  | 'create'
  | 'update'
  | 'downgrade'
  | 'upgrade'
  | 'updateaddons'
