export const STACK_NAME: { [x: string]: string } = {
  default: 'Blank',
  php: 'PHP',
  nodejs: 'Node.js',
  ruby: 'Ruby',
  python: 'Python',
  cpp: 'C/C++',
  html: 'HTML',
  wordpress: 'Wordpress',
  java: 'Java',
  go: 'Go',
  dotnet: '.NET Core',
  custom: 'Custom template',
  rust: 'Rust',
}

export const PREDEFINED_STACKS = [
  'default',
  'php',
  'node',
  'nodejs',
  'ruby',
  'python',
  'cpp',
  'html',
  'wordpress',
  'java',
  'go',
  'dotnet',
  'rust',
]
