import * as React from 'react'
import { CopyText } from './CopyText'
import { ReferralShareOption, ShareOption } from './ReferralShareOption'
import { ReferralTitle } from './ReferralTitle'

type Props = {
  referralLink: string
}

export class ShareReferralLink extends React.PureComponent<Props> {
  render() {
    return (
      <div className="share-referral-link">
        <ReferralTitle title="Your personal referral link" level={3} />
        <CopyText content={this.props.referralLink} />
        <ReferralTitle title="Share with your friends" level={3} />
        <div className="share-options">
          <ReferralShareOption option={ShareOption.Email} />
          <ReferralShareOption option={ShareOption.Facebook} />
          <ReferralShareOption option={ShareOption.Twitter} />
        </div>
      </div>
    )
  }
}
