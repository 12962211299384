import React from 'react'
import { Redirect } from 'react-router-dom'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Page } from '../App'
import { Heading, HeadingLevel } from '../components/Heading'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { ConnectionForm } from '../containers/ConnectionForm'
import { Connection } from '../containers/ConnectionsList'
import { triggerOpenedScreenEvent } from '../helpers/Util'

type Props = {
  apiClient: ApiClient
  accountDetails: AccountDetails
  remainingConnections: number
  subscriptionExpiredModalVisible: boolean

  loadingInitContent: boolean
  onDidLoadInitContent: () => void
  getConnections: () => Promise<Connection[]>
}

type State = {
  page: string
}

export class NewConnectionPage extends React.Component<Props, State> {
  public state: State = {
    page: Page.NewConnection,
  }

  private switchPage = (page: string) => {
    window.history.pushState({ prevUrl: window.location.href }, '', page)
    this.setState({ page })
  }

  componentDidMount() {
    this.props.onDidLoadInitContent()
    triggerOpenedScreenEvent(
      'openedNewConnectionScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }
  render() {
    if (this.state.page !== Page.NewConnection) {
      window.scrollTo(0, 0)
      return <Redirect to={this.state.page} />
    }

    return (
      <div className="page-content">
        <Heading
          heading="New Connection"
          level={HeadingLevel.First}
          goBack={true}
        />
        <ConnectionForm
          apiClient={this.props.apiClient}
          onCancel={() => window.history.back()}
          onFinish={async () => {
            await this.props.getConnections()
            this.switchPage(Page.Connections)
          }}
        />
        {this.renderLimitModal()}
      </div>
    )
  }

  private renderLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={
          this.props.remainingConnections <= 0 &&
          !this.props.subscriptionExpiredModalVisible
        }
        resource="Connections"
        accountDetails={this.props.accountDetails}
      />
    )
  }
}
