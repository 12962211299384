import * as React from 'react'
import { DevboxFilter } from '../containers/ContainersList'
import { Filter } from './Filter'

export type DevboxFiltersData = {
  activeFilter: DevboxFilter
  onChangeActiveFilter: (activeFilter: DevboxFilter) => void
  createdContainersAmount: number
  sharesAmount: number
}

type Props = {
  filtersData: DevboxFiltersData
}

export class DevboxFilters extends React.PureComponent<Props> {
  render() {
    const {
      activeFilter,
      onChangeActiveFilter,
      createdContainersAmount,
      sharesAmount,
    } = { ...this.props.filtersData }

    return (
      <div className="filters">
        <Filter
          name={`All Containers ${createdContainersAmount + sharesAmount}`}
          active={activeFilter === DevboxFilter.All}
          onSelect={() => onChangeActiveFilter(DevboxFilter.All)}
        />
        <Filter
          name={`My Containers ${createdContainersAmount}`}
          active={activeFilter === DevboxFilter.Created}
          onSelect={() => onChangeActiveFilter(DevboxFilter.Created)}
        />
        <Filter
          name={`Shared With Me ${sharesAmount}`}
          active={activeFilter === DevboxFilter.SharedWithMe}
          onSelect={() => onChangeActiveFilter(DevboxFilter.SharedWithMe)}
        />
      </div>
    )
  }
}
