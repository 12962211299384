import React from 'react'
import { Redirect } from 'react-router-dom'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Container, ContainersList } from '../containers/ContainersList'
import { Welcome } from '../components/Welcome'
import { ShareApiClient } from '../api-client/adapters/ShareApiClient'
import { Page } from '../App'
import { Heading, HeadingLevel } from '../components/Heading'
import { CreateIcon, CreateOption } from '../components/CreateOption'
import { Spinner } from '../components/Spinner'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ContainerDomain } from '../api-client/interface/Connection'
import { Share } from '@codeanywhere/collaboration-service-api-client'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { triggerOpenedScreenEvent } from '../helpers/Util'

type Props = {
  apiClient: ApiClient
  shareApiClient: ShareApiClient

  accountDetails: AccountDetails
  containers: Container[]
  shares: Share[]
  domains: ContainerDomain[]
  remainingContainers: number

  devboxesAlreadyFetched: boolean
  domainsAlreadyFetched: boolean
  loadingInitContent: boolean
  onDidLoadInitContent: () => void

  getDevboxes: () => Promise<void>
  getDomains: () => Promise<void>
  refreshSharesList: () => Promise<void>
  refreshAccountDetails: () => Promise<void>
  getShareByContainerId: (containerId: number) => Promise<Share>
}

type State = {
  loading: boolean
  page: string
  limitModalVisible: boolean
}

export class ContainersPage extends React.Component<Props> {
  public state: State = {
    loading: false,
    page: Page.Containers,

    limitModalVisible: false,
  }

  private switchPage = (page: string) => {
    if (page === Page.NewContainer && this.props.remainingContainers <= 0) {
      this.setState({
        limitModalVisible: true,
      })
    } else {
      window.history.pushState({ prevUrl: window.location.href }, '', page)
      this.setState({ page })
    }
  }

  componentDidMount() {
    this.getData()
    triggerOpenedScreenEvent(
      'openedContainersScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (this.state.page === Page.NewContainer) {
      window.scrollTo(0, 0)
      return <Redirect to={Page.NewContainer} />
    }

    if (this.state.page.startsWith(Page.Domains)) {
      window.scrollTo(0, 0)
      return <Redirect to={this.state.page} />
    }

    if (
      !this.props.devboxesAlreadyFetched ||
      !this.props.domainsAlreadyFetched ||
      this.props.loadingInitContent ||
      this.state.loading
    ) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    return (
      <>
        <div className="page-content">
          {!this.props.containers.length && !this.props.shares.length ? (
            <Welcome
              currentPage={Page.Containers}
              switchPage={(page: Page) => this.switchPage(page)}
            />
          ) : (
            <>
              <div className="page-group">
                <Heading heading="Create" level={HeadingLevel.First} />
                <div className="create__options">
                  <CreateOption
                    option="New Container"
                    icon={CreateIcon.Plus}
                    onClick={() => this.switchPage(Page.NewContainer)}
                  />
                  {/* <CreateOption
                  option="Clone from GitHub"
                  icon={CreateIcon.Clone}
                  onClick={() => this.switchPage(Page.CloneFromGithub)}
                /> */}
                </div>
              </div>
              <div className="page-group">
                <ContainersList
                  apiClient={this.props.apiClient}
                  shareApiClient={this.props.shareApiClient}
                  accountDetails={this.props.accountDetails}
                  containers={this.props.containers}
                  shares={this.props.shares}
                  domains={this.props.domains}
                  refreshDevboxes={this.props.getDevboxes}
                  refreshSharesList={this.props.refreshSharesList}
                  refreshAccountDetails={this.props.refreshAccountDetails}
                  getShareByContainerId={this.props.getShareByContainerId}
                  switchPage={this.switchPage}
                />
              </div>
            </>
          )}
        </div>
        {this.renderLimitModal()}
      </>
    )
  }

  private renderLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={this.state.limitModalVisible}
        resource="Containers"
        accountDetails={this.props.accountDetails}
        onClose={() => this.setState({ limitModalVisible: false })}
      />
    )
  }

  private async getData() {
    const {
      onDidLoadInitContent,
      devboxesAlreadyFetched,
      domainsAlreadyFetched,
      getDevboxes,
      getDomains,
    } = {
      ...this.props,
    }

    if (this.props.loadingInitContent) {
      await getDevboxes()
      await getDomains()
    } else if (!devboxesAlreadyFetched || !domainsAlreadyFetched) {
      this.setState({ loading: true })
      if (!devboxesAlreadyFetched) await getDevboxes()
      if (!domainsAlreadyFetched) await getDomains()
      this.setState({ loading: false })
    }

    onDidLoadInitContent()
  }
}
