import * as React from 'react'

type Props = {
  height: string
  marginTop: string
  used: number
  max: number
  color?: string
}

export class UsageBar extends React.PureComponent<Props> {
  render() {
    const usage =
      !this.props.used && !this.props.max
        ? 0
        : Math.min(this.props.used / this.props.max, 1)

    return (
      <div
        className="resource-usage-bar"
        style={{
          height: this.props.height,
          marginTop: this.props.marginTop,
          backgroundColor: 'var(--color-border-grey)',
          borderRadius: usage < 1 ? '10px 0 0 10px' : '10px',
        }}
      >
        <div
          style={{
            display: 'block',
            height: this.props.height,
            width: `${usage * 100}%`,
            borderRadius: usage < 1 ? '10px 0 0 10px' : '10px',
            backgroundColor: this.props.color
              ? this.props.color
              : usage < 0.33
              ? 'var(--color-green)'
              : usage > 0.66
              ? 'var(--color-red)'
              : 'var(--color-orange',
          }}
        ></div>
      </div>
    )
  }
}
