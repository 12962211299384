import * as React from 'react'

type Props = {
  label: string
  value: string
  placeholder?: string
  required?: boolean

  whiteBackground?: boolean
  dataRecurlyKey?: string

  onChangeInput: (input: string) => void
}

export class TextArea extends React.PureComponent<Props> {
  render() {
    return (
      <div className="input-wrapper">
        <label
          htmlFor={this.props.label}
          className={`input-label${this.props.required ? ' required' : ''}`}
        >
          {this.props.label}
        </label>
        <textarea
          id={this.props.label}
          className={`text-input${this.props.whiteBackground ? ' white' : ''}`}
          value={this.props.value}
          placeholder={
            this.props.placeholder ? this.props.placeholder : undefined
          }
          required={this.props.required}
          data-recurly={
            this.props.dataRecurlyKey ? this.props.dataRecurlyKey : undefined
          }
          onChange={e => this.props.onChangeInput(e.target.value)}
          onFocus={e => e.target.select()}
        />
      </div>
    )
  }
}
