import React from 'react'
import { ReferralTitle } from './ReferralTitle'
import { UsageBar } from './UsageBar'

type Props = {
  earnedCredit: number
}

export class ReferralTracking extends React.PureComponent<Props> {
  render() {
    const maxReferralCredits = 150

    return (
      <div className="referral-tracking">
        <ReferralTitle title="Referral tracking" level={3} />
        <UsageBar
          height="10px"
          marginTop="20px"
          used={this.props.earnedCredit}
          max={maxReferralCredits}
          color="var(--color-primary)"
        />
        <div className="referral-credits">{`$${this.props.earnedCredit.toPrecision(
          3
        )} earned / $${maxReferralCredits.toPrecision(3)} total`}</div>
      </div>
    )
  }
}
