import * as React from 'react'
import { Truncate } from './Truncate'
import { getTemplateIconSourceUrl } from '../helpers/Util'
import { TemplateData } from '../helpers/TransformTemplates'

type Props = {
  template: TemplateData
  isSelected: boolean
  onChangeSelectedTemplate: () => void
}

export class TemplateCard extends React.Component<Props> {
  render() {
    return (
      <div
        className={`template__card ${
          this.props.isSelected ? 'template__card--is-selected' : ''
        }`}
        onClick={this.props.onChangeSelectedTemplate}
      >
        <img
          src={getTemplateIconSourceUrl(this.props.template.id)}
          className="template-card-image"
          alt="Stack logo"
        />
        <Truncate>
          <span className="template__name">{this.props.template.name}</span>
        </Truncate>
      </div>
    )
  }
}
