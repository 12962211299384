import { AuthProvider } from '../interface/AuthProvider'

export class MockAuthProvider implements AuthProvider {
  public authorize() {
    //  @ts-ignore
    window.location = String(process.env.REACT_APP_BASE_URL)
  }

  public async getAccessToken(): Promise<string> {
    return String(process.env.REACT_APP_CA_TOKEN)
  }
}
