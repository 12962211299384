export interface AccountDetailsUsage {
  alwayson: number
  domains: number
  'remote-servers': number
  'custom-stacks'?: number
  ftp?: number
  ssh?: number
  gdrive?: number
  dropbox?: number
  s3?: number
  'do-droplet'?: number
  onedriveconsumer?: number
  devboxes: number
  containerTime?: number
  multipleaccounts?: number
}

export const EMPTY_ACCOUNT_DETAILS_USAGE: AccountDetailsUsage = {
  alwayson: 0,
  domains: 0,
  'remote-servers': 0,
  devboxes: 0,
  multipleaccounts: 0,
}

export interface AccountDetailsLimits {
  domains: number
  multipleaccounts?: number
  alwayson: number
  devboxes: number
  'custom-stacks'?: number
  'remote-servers': number
  'ftp-servers'?: number
  'sftp-servers'?: number
  openfiles?: number
  shares?: number
  revisions?: number
  bandwidth?: number
  'devbox-ram': string
  'devbox-hdd': string
  containerTime?: number
}

export const EMPTY_ACCOUNT_DETAILS_LIMITS: AccountDetailsLimits = {
  domains: 0,
  alwayson: 0,
  devboxes: 0,
  'remote-servers': 0,
  'devbox-ram': '0M',
  'devbox-hdd': '0G',
}

export interface AccountDetails {
  usage: AccountDetailsUsage
  planname: string
  plancode: string
  user_id: number
  is_free?: boolean
  trial: boolean
  limits: AccountDetailsLimits
  last_subscription: {
    product_id: number
    expire: string
  }
  name: string
  firstname: string
  lastname: string
  username: string
  email: string
  beta: boolean
  verified: boolean
  imageurl: string
  reg_date: string
  company: string
  user2fa: {
    enabled: boolean
    created: string
  }
  social_connected: boolean
  child_account: boolean
  admin_email?: string

  promptSetInitialPassword?: boolean
}

export const EMPTY_ACCOUNT_DETAILS: AccountDetails = {
  user_id: -1,
  username: '',
  name: '',
  firstname: '',
  lastname: '',
  company: '',
  email: '',
  imageurl: '',
  admin_email: '',
  trial: true,
  usage: {
    alwayson: 0,
    domains: 0,
    'remote-servers': 0,
    devboxes: 0,
    multipleaccounts: 0,
  },
  limits: {
    alwayson: 0,
    domains: 0,
    'remote-servers': 0,
    devboxes: 0,
    'devbox-hdd': '',
    'devbox-ram': '',
    multipleaccounts: 0,
  },
  planname: '',
  plancode: '',
  verified: false,
  beta: false,
  reg_date: '',
  user2fa: {
    enabled: false,
    created: '',
  },
  social_connected: false,
  child_account: false,
  last_subscription: {
    product_id: -1,
    expire: '',
  },
}
