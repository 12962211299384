import * as React from 'react'
import { Input, AccountLabel, InputType } from '../components/Input'
import { Button } from '../components/Button'
import { ApiClient } from '../api-client/interface/ApiClient'
import { AccountDetails } from '../api-client/interface/AccountDetails'

export type UpdatedPersonalInfo = {
  newEmail?: string
  passwordConfirmation: string
  newUsername?: string
  newFirstName?: string
  newLastName?: string
  newCompanyName?: string
}

type Props = {
  apiClient: ApiClient
  accountDetails: AccountDetails
  hasInitialPassword: boolean
  loading: boolean
  expanded: boolean
  handleUpdatePersonalInfo: (changes: UpdatedPersonalInfo) => Promise<void>
  toggleForm: (personalInfoFormExpanded: boolean) => void
}

type State = {
  updatedPersonalInfo: UpdatedPersonalInfo
}

export class UpdatePersonalInfo extends React.Component<Props, State> {
  public state: State = {
    updatedPersonalInfo: {
      newEmail: undefined,
      passwordConfirmation: '',
      newUsername: undefined,
      newFirstName: undefined,
      newLastName: undefined,
      newCompanyName: undefined,
    },
  }

  render() {
    const {
      newEmail,
      passwordConfirmation,
      newUsername,
      newFirstName,
      newLastName,
      newCompanyName,
    } = { ...this.state.updatedPersonalInfo }

    const pendingChanges =
      (!!newEmail &&
        newEmail !== this.props.accountDetails.email &&
        !!passwordConfirmation) ||
      (!!newUsername && newUsername !== this.props.accountDetails.username) ||
      (!!newFirstName &&
        newFirstName !== this.props.accountDetails.firstname) ||
      (!!newLastName && newLastName !== this.props.accountDetails.lastname) ||
      (!!newCompanyName && newCompanyName !== this.props.accountDetails.company)

    return (
      <div className="account-section">
        <form className="update-personal-info" onSubmit={this.onSubmitChanges}>
          <Input
            label={AccountLabel.Username}
            value={
              newUsername !== undefined
                ? newUsername
                : this.props.accountDetails.username
            }
            collapsed={!this.props.expanded}
            onChangeInput={newUsername =>
              this.setState({
                updatedPersonalInfo: {
                  ...this.state.updatedPersonalInfo,
                  newUsername,
                },
              })
            }
          />
          <div
            className={`two-inputs-container${
              !this.props.expanded ? ' collapsed' : ''
            }`}
            style={{
              display:
                this.props.expanded ||
                (this.props.accountDetails.firstname &&
                  this.props.accountDetails.lastname)
                  ? 'flex'
                  : 'none',
            }}
          >
            <Input
              label={AccountLabel.FirstName}
              value={
                newFirstName !== undefined
                  ? newFirstName
                  : this.props.accountDetails.firstname
              }
              collapsed={!this.props.expanded}
              onChangeInput={newFirstName =>
                this.setState({
                  updatedPersonalInfo: {
                    ...this.state.updatedPersonalInfo,
                    newFirstName,
                  },
                })
              }
            />
            <Input
              label={AccountLabel.LastName}
              value={
                newLastName !== undefined
                  ? newLastName
                  : this.props.accountDetails.lastname
              }
              collapsed={!this.props.expanded}
              onChangeInput={newLastName =>
                this.setState({
                  updatedPersonalInfo: {
                    ...this.state.updatedPersonalInfo,
                    newLastName,
                  },
                })
              }
            />
          </div>
          <Input
            label={AccountLabel.CompanyName}
            value={
              newCompanyName !== undefined
                ? newCompanyName
                : this.props.accountDetails.company
            }
            collapsed={!this.props.expanded}
            hide={!this.props.expanded && !this.props.accountDetails.company}
            onChangeInput={newCompanyName =>
              this.setState({
                updatedPersonalInfo: {
                  ...this.state.updatedPersonalInfo,
                  newCompanyName,
                },
              })
            }
          />
          <Input
            label={AccountLabel.Email}
            value={
              newEmail !== undefined
                ? newEmail
                : this.props.accountDetails.email
            }
            type={InputType.Email}
            collapsed={!this.props.expanded}
            disabled={!this.props.hasInitialPassword}
            onChangeInput={newEmail =>
              this.setState({
                updatedPersonalInfo: {
                  ...this.state.updatedPersonalInfo,
                  newEmail,
                },
              })
            }
          />
          <Input
            label={AccountLabel.ChangeEmailConfirmation}
            value={passwordConfirmation}
            required={!!newEmail}
            type={InputType.Password}
            disabled={!this.props.hasInitialPassword}
            hide={!this.props.expanded}
            onChangeInput={passwordConfirmation =>
              this.setState({
                updatedPersonalInfo: {
                  ...this.state.updatedPersonalInfo,
                  passwordConfirmation,
                },
              })
            }
          />
          {this.props.expanded ? (
            <>
              <Button
                value="Cancel"
                style={{ marginRight: '1rem' }}
                onClick={() => {
                  this.props.toggleForm(false)
                  this.setState({
                    updatedPersonalInfo: {
                      newEmail: undefined,
                      passwordConfirmation: '',
                      newUsername: undefined,
                      newFirstName: undefined,
                      newLastName: undefined,
                      newCompanyName: undefined,
                    },
                  })
                }}
              />
              <Button
                value="Update"
                type="success"
                submit={true}
                disabled={!pendingChanges || this.props.loading}
                loading={this.props.loading}
              />
            </>
          ) : (
            <Button
              value="Edit"
              type="primary"
              onClick={() => this.props.toggleForm(true)}
            />
          )}
        </form>
      </div>
    )
  }

  private onSubmitChanges = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    await this.props.handleUpdatePersonalInfo(this.state.updatedPersonalInfo)
  }
}
