import moment from 'moment'
import * as React from 'react'
import { ContainerDomain } from '../api-client/interface/Connection'
import { Truncate } from '../components/Truncate'
import { DestroyIcon } from '../helpers/image-imports/IconComponent'
import { onKey } from '../helpers/Util'

type Props = {
  domains: ContainerDomain[]
  onRemoveDomain: (domainId: number) => Promise<void>
}

export class DomainsList extends React.Component<Props> {
  render() {
    return (
      <div className="table-wrapper">
        <table className="table table--is-domains">
          <thead>
            <tr className="table-header">
              <th className="table-column">Domain</th>
              <th className="table-column">Internal port</th>
              <th className="table-column">External port</th>
              <th className="table-column">Created at</th>
              <th className="table-column"></th>
            </tr>
          </thead>
          <tbody className="table-body">
            {!this.props.domains.length ? (
              <tr className="no-domains">
                <td>No domains</td>
              </tr>
            ) : (
              <>
                {this.props.domains.map(domain => (
                  <tr className="table-row" key={domain.id}>
                    <td className="table-column">
                      <Truncate>
                        <a
                          href={`//${domain.domain}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link"
                        >
                          {domain.domain}
                        </a>
                      </Truncate>
                    </td>
                    <td className="table-column">{domain.internalPort}</td>
                    <td className="table-column">{domain.externalPort}</td>
                    <td className="table-column">
                      {moment(domain.createdAt).format('MMM Do YYYY')}
                    </td>
                    <td className="table-column">
                      <DestroyIcon
                        className="tab-focus"
                        onClick={() => this.handleRemoveDomain(domain.id)}
                        onKeyDown={e =>
                          onKey(e, 'Enter', () =>
                            this.handleRemoveDomain(domain.id)
                          )
                        }
                        onKeyUp={e =>
                          onKey(e, ' ', () =>
                            this.handleRemoveDomain(domain.id)
                          )
                        }
                        tabIndex={0}
                      />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  private handleRemoveDomain = (domainId: number) => {
    this.props.onRemoveDomain(domainId)
  }
}
