import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { SyntheticEvent } from 'react'
import {
  NotificationIcon,
  CloseIcon,
} from '../helpers/image-imports/IconComponent'

type Props = {
  id?: number
  message: string
  description?: string
  duration?: number
  type?: 'success' | 'warning' | 'error'
  width?: number | 'auto'
  onClick?: (e?: Event) => Promise<void> | void
  onClose?: (e?: Event) => Promise<void> | void
}

type DefaultProps = {
  duration: number
  type: 'success' | 'warning' | 'error'
  width: 'auto'
  onClick: (e?: Event) => Promise<void> | void
  onClose: (e?: Event) => Promise<void> | void
}

type NotificationData = Props & {
  id: number
  key: number
  destroy: (id: number) => void
}

class Notification extends React.PureComponent<NotificationData> {
  private static defaultProps: DefaultProps = {
    type: 'success',
    duration: 3500,
    width: 'auto',
    onClick: () => {},
    onClose: () => {},
  }

  private onClick = (e?: SyntheticEvent) => {
    e?.stopPropagation()
    this.props.onClick?.()
    return e
  }

  private onClose = (e?: SyntheticEvent) => {
    e?.stopPropagation()
    this.props.destroy(this.props.id)
    this.props.onClose?.()
    return e
  }

  public componentDidMount = () => {
    setTimeout(() => {
      this.props.destroy(this.props.id)
    }, this.props.duration)
  }

  render() {
    return (
      <article
        role="alert"
        className={`notification notification--type-is-${this.props.type}`}
        onClick={(e: SyntheticEvent) => this.onClick(e)}
        style={{ width: this.props.width }}
      >
        <div className="notification__icon">
          <div className="notification__icon">
            <NotificationIcon />
          </div>
        </div>
        <div className="notification__content">
          <div className="notification__title">{this.props.message}</div>
          {this.props.description && (
            <div className="notification__description">
              {typeof this.props.description === 'string'
                ? this.props.description
                : JSON.stringify(this.props.description)}
            </div>
          )}
        </div>
        <div
          className="notification__close-icon"
          onClick={(e: SyntheticEvent) => this.onClose(e)}
        >
          <CloseIcon />
        </div>
      </article>
    )
  }
}

class NotificationManager {
  private readonly parent: HTMLDivElement
  private notifications: NotificationData[] = []
  private ID: number

  constructor() {
    /*
     * Creates and appends a container for
     * holding multiple notifications
     */
    const container = document.createElement('div') as HTMLDivElement
    container.classList.add('notification-list')
    document.body.insertAdjacentElement('beforeend', container)
    this.parent = container

    /*
     * Auto-increment unique key for each and
     * every displayed notification
     * */
    this.ID = 0
  }

  public error = (options: Props): number => {
    return this.show({
      ...options,
      type: 'error',
    })
  }

  public success = (options: Props): number => {
    return this.show({
      ...options,
      type: 'success',
    })
  }

  public warn = (options: Props): number => {
    return this.show({
      ...options,
      type: 'warning',
    })
  }

  public show = (options: Props): number => {
    const id = options.id || ++this.ID

    const notification: NotificationData = {
      ...options,
      destroy: () => this.destroy(id),
      key: id,
      id,
    }

    this.notifications = [...this.notifications, notification]

    this.render()
    return id
  }

  public destroy = (id?: number) => {
    if (id) {
      this.notifications = this.notifications.filter(
        (notification: Props) => notification.id !== id
      )

      this.render()
      return id
    }
  }

  private render = (): void => {
    const notifications = this.notifications.map((props: NotificationData) => (
      <Notification {...props} />
    ))

    ReactDOM.render(notifications, this.parent)
  }
}

export const notification = new NotificationManager()
