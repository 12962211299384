import * as React from 'react'
import {
  AddOptionIcon,
  CloneOptionIcon,
  AddConnectionOptionIcon,
  GoogleDriveIcon,
} from '../helpers/image-imports/IconComponent'
import { onKey } from '../helpers/Util'

export enum CreateIcon {
  Plus,
  Clone,
  AddConnection,
  GoogleDrive,
}

type Props = {
  option: string
  icon: CreateIcon
  onClick: () => void
}

export class CreateOption extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className="create__option tab-focus"
        onClick={this.props.onClick}
        onKeyDown={e => onKey(e, 'Enter', this.props.onClick)}
        onKeyUp={e => onKey(e, ' ', this.props.onClick)}
        tabIndex={0}
      >
        {this.props.icon === CreateIcon.Plus && <AddOptionIcon />}
        {this.props.icon === CreateIcon.Clone && <CloneOptionIcon />}
        {this.props.icon === CreateIcon.AddConnection && (
          <AddConnectionOptionIcon />
        )}
        {this.props.icon === CreateIcon.GoogleDrive && <GoogleDriveIcon />}
        <span>{this.props.option}</span>
      </div>
    )
  }
}
