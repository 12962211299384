import * as React from 'react'
import { Button } from 'antd'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'
import mixpanel from 'mixpanel-browser'
import { ILLUSTRATION } from '../../helpers/image-imports/Illustration'

type Props = {
  userId: number
  email: string

  username: string
  firstName: string

  displayStep: boolean
  goToNextStep: () => void
}

export class CancelBetaStep extends React.Component<Props> {
  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowBetaEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
    mixpanel.track('cancelFlowBetaEnter', {
      userId: this.props.userId,
      emailHash: String(sha256(this.props.email)),
    })
  }

  render() {
    return this.props.displayStep ? (
      <>
        <div className="cancellation__plan">
          <h1 className="cancellation__plan-title">
            {this.props.firstName !== ''
              ? this.props.firstName
              : this.props.username}
            , have you checked out the Beta program for our newest platform?
          </h1>
          <h2 className="cancellation__plan-subtitle">
            On release, users that keep their subscriptions on our current
            platform will have a huge discount when changing to new
            subscriptions. If you cancel now, you will miss out on this unique
            opportunity.
          </h2>
        </div>
        <div className="cancellation__bullets feature__bullets">
          <h2 className="feature__bullets-title">What are the benefits?</h2>
          <ul className="cancellation__bullets-list">
            <li className="cancellation__bullets-list-item">
              <img
                src={ILLUSTRATION['Checkmark']}
                alt="Question icon"
                className="icon"
              />
              Standardized fully-featured environments
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={ILLUSTRATION['Checkmark']}
                alt="Question icon"
                className="icon"
              />
              Editor you already know and love
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={ILLUSTRATION['Checkmark']}
                alt="Question icon"
                className="icon"
              />
              Easily see and share the results of your work
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={ILLUSTRATION['Checkmark']}
                alt="Question icon"
                className="icon"
              />
              Scale effortlessly
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={ILLUSTRATION['Checkmark']}
                alt="Question icon"
                className="icon"
              />
              No fear of network loss
            </li>
            <li className="cancellation__bullets-list-item">
              <img
                src={ILLUSTRATION['Checkmark']}
                alt="Question icon"
                className="icon"
              />
              <a
                href="https://codeanywhere.com/beta"
                target="_self"
                rel="noopener noreferrer"
              >
                And so much more!
              </a>
            </li>
          </ul>
        </div>
        <div className="cancellation__plan-spacing"></div>
        <div className="cancellation__actions">
          <Button
            id="cancel-membership-beta--button"
            className="button cancellation__action cancellation__action--type-cancel"
            onClick={() => {
              document.querySelector('body')?.scrollTo(0, 0)
              this.props.goToNextStep()
            }}
          >
            Cancel Membership
          </Button>
          <Button
            id="keep-membership-beta--button"
            type="primary"
            className="button cancellation__action cancellation__action--type-success"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'cancelFlowTryBeta',
                  userId: this.props.userId,
                  emailHash: String(sha256(this.props.email)),
                },
              })
              mixpanel.track('cancelFlowTryBeta', {
                userId: this.props.userId,
                emailHash: String(sha256(this.props.email)),
              })
            }}
          >
            <a
              href="https://beta.codeanywhere.com"
              target="_self"
              rel="noopener noreferrer"
            >
              Try Beta
            </a>
          </Button>
        </div>
      </>
    ) : null
  }
}
