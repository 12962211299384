import React from 'react'
import './/assets/styles/styles.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ApiClient, ApiClientEvent } from './api-client/interface/ApiClient'
import { ShareApiClient } from './api-client/adapters/ShareApiClient'
import { RecurlyClient } from './api-client/interface/RecurlyClient'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import TagManager from 'react-gtm-module'
import md5 from 'md5'

import { Header } from './containers/Header'
import { SidebarNavigation } from './components/SidebarNavigation'
import { SubscriptionExpiredModal } from './components/SubscriptionExpiredModal'
import { Footer } from './components/Footer'

import { CheckoutPage } from './pages/CheckoutPage'
import { HomePage } from './pages/HomePage'
import { ContainersPage } from './pages/ContainersPage'
import { ConnectionsPage } from './pages/ConnectionsPage'
import { TemplatesPage } from './pages/TemplatesPage'
import { AccountPage } from './pages/AccountPage'
import { BillingPage, PlanPeriod } from './pages/BillingPage'
import { DomainsPage } from './pages/DomainsPage'
import { TeamPage } from './pages/TeamPage'
import { ReferFriendPage } from './pages/ReferFriendPage'
import { NewContainerPage } from './pages/NewContainerPage'
import { PaymentHistoryPage } from './pages/PaymentHistoryPage'
import { NewConnectionPage } from './pages/NewConnectionPage'
import { CustomTemplatePage } from './pages/CustomTemplatePage'
import { CancelMembershipPage } from './pages/CancelMembershipPage'

import { notification } from './components/Notification'
import { Container, EMPTY_SHARE } from './containers/ContainersList'
import { ContainerDomain } from './api-client/interface/Connection'
import { Connection } from './containers/ConnectionsList'
import { extractAuthLink, getPlanPeriodFromCode } from './helpers/Util'
import sha256 from 'crypto-js/sha256'
import {
  AddonName,
  ChildAccount,
  Subscription,
} from './api-client/interface/Subscription'
import {
  AccountDetails,
  AccountDetailsLimits,
  AccountDetailsUsage,
  EMPTY_ACCOUNT_DETAILS,
  EMPTY_ACCOUNT_DETAILS_LIMITS,
  EMPTY_ACCOUNT_DETAILS_USAGE,
} from './api-client/interface/AccountDetails'
import { Share } from '@codeanywhere/collaboration-service-api-client'
import { PulseLoader } from './components/PulseLoader'
import { InvalidPage } from './pages/InvalidPage'
import {
  EMPTY_TEMPLATES,
  Templates,
  transformTemplates,
} from './helpers/TransformTemplates'
import { AccountConnection } from './api-client/interface/AccountConnection'

export enum Page {
  Home = '/',
  Containers = '/containers',
  NewContainer = '/new-container',
  CloneFromGithub = '/clone-from-github',
  Domains = '/domains',
  NewConnection = '/new-connection',
  Templates = '/templates',
  CustomTemplate = '/custom-template',
  Connections = '/connections',
  Team = '/team',
  Account = '/account',
  Billing = '/billing',
  PaymentHistory = '/payment-history',
  ReferFriend = '/refer-a-friend',
  Checkout = '/checkout',
  CancelMembership = '/cancel-membership',
  Invalid = '/',
}

type Props = {
  apiClient: ApiClient
  recurlyClient: RecurlyClient
  shareApiClient: ShareApiClient
}

type State = {
  validatingToken: boolean
  loadingAccountDetails: boolean
  loadingSubscription: boolean
  loadingDevboxes: boolean
  loadingTemplates: boolean
  loadingDomains: boolean
  loadingConnections: boolean
  loadingConnectedAccounts: boolean
  loadingInitContent: boolean

  mobileNavigationExpanded: boolean
  profileDropdownExpanded: boolean

  fetchedAccountDetails: AccountDetails
  accountDetails: AccountDetails
  hasInitialPassword: boolean
  accountResourceLimits: AccountDetailsLimits
  usedTeamResources: AccountDetailsUsage

  planPeriod: PlanPeriod
  subscription: Subscription | null
  teamAccounts: ChildAccount[]

  containers: Container[]
  shares: Share[]
  connections: Connection[]
  templates: Templates
  domains: ContainerDomain[]
  connectedAccounts: AccountConnection[]

  subscriptionExpiredModalVisible: boolean
}

export class App extends React.Component<Props, State> {
  public state: State = {
    validatingToken: true,
    loadingAccountDetails: true,
    loadingSubscription: true,
    loadingDevboxes: true,
    loadingTemplates: true,
    loadingDomains: true,
    loadingConnections: true,
    loadingConnectedAccounts: true,
    loadingInitContent: true,

    mobileNavigationExpanded: false,
    profileDropdownExpanded: false,

    fetchedAccountDetails: EMPTY_ACCOUNT_DETAILS,
    accountDetails: EMPTY_ACCOUNT_DETAILS,
    hasInitialPassword: true,
    accountResourceLimits: EMPTY_ACCOUNT_DETAILS_LIMITS,
    usedTeamResources: EMPTY_ACCOUNT_DETAILS_USAGE,

    planPeriod: PlanPeriod.INVALID,
    subscription: null,
    teamAccounts: [],

    containers: [],
    shares: [],
    connections: [],
    templates: EMPTY_TEMPLATES,
    domains: [],
    connectedAccounts: [],

    subscriptionExpiredModalVisible: false,
  }

  private refreshInterval?: number

  componentDidMount() {
    this.getAppData()

    this.setupDevboxesRefreshing()
    this.attachApiClientEventListeners()
    this.attachOtherEventListeners()
  }

  componentWillUnmount() {
    this.terminateDevboxesRefreshing()
  }

  render() {
    if (
      this.state.validatingToken ||
      this.state.loadingAccountDetails ||
      this.state.loadingSubscription
    ) {
      return <PulseLoader />
    }

    const { usage, limits } = { ...this.state.accountDetails }

    return (
      <>
        {this.state.loadingInitContent && <PulseLoader />}
        <div className="page">
          <Router>
            <Header
              apiClient={this.props.apiClient}
              accountDetails={this.state.accountDetails}
              hasInitialPassword={this.state.hasInitialPassword}
              profileDropdownExpanded={this.state.profileDropdownExpanded}
              mobileNavigationExpanded={this.state.mobileNavigationExpanded}
              onToggleMobileNavigation={(state: boolean) =>
                this.setState({ mobileNavigationExpanded: state })
              }
              onToggleProfileDropdown={(state: boolean) =>
                this.setState({ profileDropdownExpanded: state })
              }
            />
            <SidebarNavigation
              apiClient={this.props.apiClient}
              hide={
                this.state.loadingAccountDetails ||
                this.state.loadingSubscription ||
                this.state.loadingInitContent ||
                window.location.pathname.includes('checkout') ||
                window.location.pathname.includes('cancel-membership')
              }
              accountDetails={this.state.accountDetails}
              mobileNavigationExpanded={this.state.mobileNavigationExpanded}
              expandMobileNavigation={() =>
                this.setState({ mobileNavigationExpanded: true })
              }
              collapseMobileNavigation={() =>
                this.setState({ mobileNavigationExpanded: false })
              }
              onChangePage={() => {
                window.scrollTo(0, 0)
                this.setState({ mobileNavigationExpanded: false })
              }}
            />
            <SubscriptionExpiredModal
              visible={this.state.subscriptionExpiredModalVisible}
              onFreeTrial={this.state.accountDetails.trial}
              checkSubscription={this.getSubscription}
              accountDetails={this.state.accountDetails}
            />
            <main className="main">
              <Switch /*TODO: add route for invalid page */>
                <Route
                  path={Page.Containers}
                  exact
                  render={() => (
                    <ContainersPage
                      apiClient={this.props.apiClient}
                      shareApiClient={this.props.shareApiClient}
                      accountDetails={this.state.accountDetails}
                      containers={this.state.containers}
                      shares={this.state.shares}
                      domains={this.state.domains}
                      remainingContainers={limits.devboxes - usage.devboxes}
                      devboxesAlreadyFetched={!this.state.loadingDevboxes}
                      domainsAlreadyFetched={!this.state.loadingDomains}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getDevboxes={this.getDevboxes}
                      getDomains={this.getDomains}
                      refreshSharesList={this.refreshSharesList}
                      refreshAccountDetails={this.refreshAccountDetails}
                      getShareByContainerId={this.getShareByContainerId}
                    />
                  )}
                />
                <Route
                  path={Page.NewContainer}
                  exact
                  render={() => (
                    <NewContainerPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      templates={this.state.templates}
                      remainingAlwaysOn={limits.alwayson - usage.alwayson}
                      remainingContainers={limits.devboxes - usage.devboxes}
                      subscriptionExpiredModalVisible={
                        this.state.subscriptionExpiredModalVisible
                      }
                      templatesAlreadyFetched={!this.state.loadingTemplates}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getTemplates={this.getTemplates}
                      refreshVerificationStatus={this.refreshVerificationStatus}
                    />
                  )}
                />
                <Route
                  path={Page.Domains}
                  render={() => (
                    <DomainsPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      domains={this.state.domains}
                      remainingDomains={limits.domains - usage.domains}
                      containersAlreadyFetched={!this.state.loadingDevboxes}
                      domainsAlreadyFetched={!this.state.loadingDomains}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getContainers={this.getDevboxes}
                      getDomains={this.getDomains}
                      refreshAccountDetails={this.refreshAccountDetails}
                      getContainerName={this.getContainerName}
                    />
                  )}
                />
                <Route
                  path={Page.Templates}
                  exact
                  render={() => (
                    <TemplatesPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      containers={this.state.containers}
                      remainingContainers={limits.devboxes - usage.devboxes}
                      templates={this.state.templates}
                      remainingCustomTemplates={
                        limits['custom-stacks']! - usage['custom-stacks']!
                      }
                      containersAlreadyFetched={!this.state.loadingDevboxes}
                      templatesAlreadyFetched={!this.state.loadingTemplates}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getContainers={this.getDevboxes}
                      getTemplates={this.getTemplates}
                    />
                  )}
                />
                <Route
                  path={Page.CustomTemplate}
                  exact
                  render={() => (
                    <CustomTemplatePage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      containers={this.state.containers}
                      remainingCustomTemplates={
                        limits['custom-stacks']! - usage!['custom-stacks']!
                      }
                      containersAlreadyFetched={!this.state.loadingDevboxes}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getContainers={this.getDevboxes}
                    />
                  )}
                />
                <Route
                  path={Page.Connections}
                  exact
                  render={() => (
                    <ConnectionsPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      connections={this.state.connections}
                      containers={this.state.containers}
                      googleAuthLink={extractAuthLink(
                        this.state.connectedAccounts,
                        'google'
                      )}
                      remainingConnections={
                        limits['remote-servers'] - usage['remote-servers']
                      }
                      connectionsAlreadyFetched={!this.state.loadingConnections}
                      devboxesAlreadyFetched={!this.state.loadingDevboxes}
                      connectedAccountsAlreadyFetched={
                        !this.state.loadingConnectedAccounts
                      }
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getConnections={this.getConnections}
                      getDevboxes={this.getDevboxes}
                      getConnectedAccounts={this.getConnectedAccounts}
                    />
                  )}
                />
                <Route
                  path={Page.NewConnection}
                  exact
                  render={() => (
                    <NewConnectionPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      remainingConnections={
                        limits['remote-servers'] - usage['remote-servers']
                      }
                      subscriptionExpiredModalVisible={
                        this.state.subscriptionExpiredModalVisible
                      }
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getConnections={this.getConnections}
                    />
                  )}
                />
                <Route
                  path={Page.Team}
                  exact
                  render={() => (
                    <TeamPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      teamAccounts={this.state.teamAccounts}
                      usedTeamResources={this.state.usedTeamResources}
                      purchasedAddons={
                        this.state.subscription?.subscription_add_ons
                          .subscription_add_on
                      }
                      hasPremiumSubscription={
                        this.state.accountDetails.planname === 'Premium'
                      }
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      refreshManagedAccounts={this.refreshResourceLimits}
                    />
                  )}
                />
                <Route
                  path={Page.Account}
                  exact
                  render={() => (
                    <AccountPage
                      apiClient={this.props.apiClient}
                      hasActiveSubscription={this.state.subscription !== null}
                      accountDetails={this.state.accountDetails}
                      hasInitialPassword={this.state.hasInitialPassword}
                      connectedAccounts={this.state.connectedAccounts}
                      connectedAccountsAlreadyFetched={
                        !this.state.loadingConnectedAccounts
                      }
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getConnectedAccounts={this.getConnectedAccounts}
                      refreshAccountDetails={this.refreshAccountDetails}
                      refreshHasInitialPassword={this.refreshHasInitialPassword}
                    />
                  )}
                />
                <Route
                  path={Page.Billing}
                  exact
                  render={() => (
                    <BillingPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      subscription={this.state.subscription}
                      onMonthlyPlan={
                        this.state.planPeriod === PlanPeriod.MONTHLY
                      }
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                    />
                  )}
                />
                <Route
                  path={Page.PaymentHistory}
                  exact
                  render={() => (
                    <PaymentHistoryPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                    />
                  )}
                />
                <Route
                  path={Page.ReferFriend}
                  exact
                  render={() => (
                    <ReferFriendPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                    />
                  )}
                />
                <Route
                  path={Page.Checkout}
                  exact
                  render={() => (
                    <CheckoutPage
                      apiClient={this.props.apiClient}
                      recurlyClient={this.props.recurlyClient}
                      accountDetails={this.state.accountDetails}
                      currentPlanPeriod={this.state.planPeriod}
                      subscription={this.state.subscription}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                    />
                  )}
                />
                <Route
                  path={Page.CancelMembership}
                  exact
                  render={() => (
                    <CancelMembershipPage
                      apiClient={this.props.apiClient}
                      accountDetails={this.state.accountDetails}
                      subscription={this.state.subscription}
                      planPeriod={this.state.planPeriod}
                      alwaysOnContainers={this.state.containers.filter(
                        container => container.alwaysOn
                      )}
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                    />
                  )}
                />
                <Route
                  path={Page.Home}
                  exact
                  render={() => (
                    <HomePage
                      apiClient={this.props.apiClient}
                      shareApiClient={this.props.shareApiClient}
                      accountDetails={this.state.accountDetails}
                      containers={this.state.containers}
                      shares={this.state.shares}
                      domains={this.state.domains}
                      connections={this.state.connections}
                      googleAuthLink={extractAuthLink(
                        this.state.connectedAccounts,
                        'google'
                      )}
                      remainingContainers={limits.devboxes - usage.devboxes}
                      remainingConnections={
                        limits['remote-servers'] - usage['remote-servers']
                      }
                      devboxesAlreadyFetched={!this.state.loadingDevboxes}
                      domainsAlreadyFetched={!this.state.loadingDomains}
                      connectionsAlreadyFetched={!this.state.loadingConnections}
                      connectedAccountsAlreadyFetched={
                        !this.state.loadingConnectedAccounts
                      }
                      loadingInitContent={this.state.loadingInitContent}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                      getDevboxes={this.getDevboxes}
                      getDomains={this.getDomains}
                      getConnections={this.getConnections}
                      getConnectedAccounts={this.getConnectedAccounts}
                      refreshAccountDetails={this.refreshAccountDetails}
                      refreshSharesList={this.refreshSharesList}
                      getShareByContainerId={this.getShareByContainerId}
                    />
                  )}
                />
                <Route
                  path={Page.Invalid}
                  render={() => (
                    <InvalidPage
                      accountDetails={this.state.accountDetails}
                      onDidLoadInitContent={this.onDidLoadInitContent}
                    />
                  )}
                />
              </Switch>
              <Footer />
            </main>
          </Router>
        </div>
      </>
    )
  }

  private async getAppData() {
    if (!(await this.validateToken())) {
      return
    }
    await this.setupShareSocket()

    await this.hasInitialPassword()
    const accountDetails = await this.getAccountDetails()
    const registeredWithinLastHour =
      moment().diff(accountDetails.reg_date, 'hour') < 1
    const isOAuthUser =
      document.referrer.includes('accounts.google') ||
      document.referrer.includes('github') ||
      document.referrer.includes('bitbucket') ||
      document.referrer.includes('facebook')

    if (isOAuthUser && registeredWithinLastHour) {
      this.handleNewOAuthUsers(accountDetails)
    }

    this.triggerLoginEvent(accountDetails)

    const { usedTeamResources, accountResourceLimits } =
      await this.factorInTeamResources(accountDetails)
    this.setAccountDetails(
      accountDetails,
      usedTeamResources,
      accountResourceLimits
    )

    if (!accountDetails.child_account) {
      await this.getSubscription(accountDetails)
    } else {
      this.setState({
        loadingSubscription: false,
      })
    }

    if (
      document.referrer.includes(Page.Checkout) ||
      document.referrer.includes(Page.CancelMembership)
    ) {
      setTimeout(() => {
        this.refreshAccountDetailsAfterSubscriptionChange()
      }, 3000)
    }
  }

  private handleNewOAuthUsers(accountDetails: AccountDetails) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'accountCreated',
        userId: accountDetails.user_id,
        emailHash: String(sha256(accountDetails.email)),
      },
    })

    //@ts-ignore
    tap('customer', accountDetails.user_id)

    window.location.href = Page.Checkout
  }

  private async validateToken() {
    try {
      await this.props.apiClient.validateToken()
      this.setState({
        validatingToken: false,
      })
      return true
    } catch (e) {
      window.location.href = `${String(
        process.env.REACT_APP_LOGIN_URL
      )}?callback_uri=${window.location.href}`
      return false
    }
  }

  private async setupShareSocket() {
    //TODO: hah
    try {
      await this.props.shareApiClient.setupSocket()
    } catch (e) {
      notification.error({
        message: 'Could not connect to share notification socket.',
      })
    }
  }

  private async getSubscription(accountDetails?: AccountDetails) {
    try {
      const accountInfo = accountDetails || (await this.getAccountDetails())
      const subscription = await this.props.apiClient.getAccountSubscription(
        true
      )

      this.setSubscriptionExpiredModalVisibility(subscription, accountInfo)

      const planname = subscription
        ? subscription.plan_name.substring(
            subscription.plan_name.indexOf(' ') + 1,
            subscription.plan_name.lastIndexOf(' ')
          )
        : 'Free'

      this.setState({
        accountDetails: {
          ...this.state.accountDetails,
          planname,
        },
        subscription,
        loadingSubscription: false,
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your account subscription.',
      })
    }
  }

  private setSubscriptionExpiredModalVisibility = (
    subscription: Subscription | null,
    accountInfo: AccountDetails
  ) => {
    const pageWithoutModal =
      window.location.href.includes(Page.Account) ||
      window.location.href.includes(Page.Billing) ||
      window.location.href.includes(Page.PaymentHistory) ||
      window.location.href.includes(Page.Checkout)

    const subscriptionExpiredModalVisible =
      subscription === null &&
      !accountInfo.child_account &&
      !accountInfo.trial &&
      !pageWithoutModal

    if (subscriptionExpiredModalVisible) {
      document.body.classList.add('subscription-expired')
    }

    this.setState({
      subscriptionExpiredModalVisible,
    })
  }

  private triggerLoginEvent(accountDetails: AccountDetails) {
    if (
      document.referrer.includes('codeanywhere') ||
      document.referrer.includes('accounts.google') ||
      document.referrer.includes('github') ||
      document.referrer.includes('bitbucket') ||
      document.referrer.includes('facebook')
    ) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'successfulLogin',
          userId: accountDetails.user_id,
          emailHash: md5(accountDetails.email),
        },
      })
    }
  }

  private setAccountDetails = (
    accountDetails: AccountDetails,
    usedTeamResources: AccountDetailsUsage,
    accountResourceLimits: AccountDetailsLimits,
    preserveFetchedAccountDetails?: boolean
  ) => {
    this.setState({
      accountDetails: {
        ...accountDetails,
        planname: this.state.accountDetails.planname,
        usage: {
          ...accountDetails.usage,
          multipleaccounts: usedTeamResources.multipleaccounts,
        },
        limits: {
          ...accountDetails.limits,
          ...accountResourceLimits,
        },
      },
      planPeriod: getPlanPeriodFromCode(accountDetails.plancode),
      fetchedAccountDetails: preserveFetchedAccountDetails
        ? this.state.fetchedAccountDetails
        : accountDetails,
      loadingAccountDetails: false,
    })

    Sentry.setUser({
      id: accountDetails.user_id.toString(),
      email: accountDetails.email,
      username: accountDetails.username,
      firstName: accountDetails.firstname,
      lastName: accountDetails.lastname,
    })
  }

  private refreshAccountDetails = async () => {
    const accountDetails = await this.getAccountDetails()
    this.setAccountDetails(
      accountDetails,
      this.state.usedTeamResources,
      this.state.accountResourceLimits
    )
  }

  private refreshAccountDetailsAfterSubscriptionChange = async () => {
    const accountDetails = await this.getAccountDetails()
    const { usedTeamResources, accountResourceLimits } =
      await this.factorInTeamResources(accountDetails)
    this.setAccountDetails(
      accountDetails,
      usedTeamResources,
      accountResourceLimits
    )
  }

  private getAccountDetails = async () => {
    try {
      return await this.props.apiClient.getAccountDetails()
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your account info',
      })
      return EMPTY_ACCOUNT_DETAILS
    }
  }

  private refreshVerificationStatus = async () => {
    try {
      const accountDetails = await this.props.apiClient.getAccountDetails()

      this.setState({
        accountDetails: {
          ...this.state.accountDetails,
          verified: accountDetails.verified,
        },
      })
    } catch (e) {
      notification.error({
        message: 'An error occurred while getting your verification status.',
      })
    }
  }

  private hasInitialPassword = async () => {
    try {
      this.setState({
        hasInitialPassword: await this.props.apiClient.hasInitialPassword(),
      })
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while getting your account information.',
        description: e,
      })
    }
  }

  private refreshHasInitialPassword = async () => {
    this.setState(
      { loadingAccountDetails: true },
      async () => await this.hasInitialPassword()
    )
    this.setState({ loadingAccountDetails: false })
  }

  private factorInTeamResources = async (accountDetails: AccountDetails) => {
    let teamAccounts: ChildAccount[] = []
    try {
      teamAccounts = (await this.props.apiClient.getMultipleAccounts()).filter(
        acc => acc.deactivated !== 1
      )
    } catch (e) {
      //no child accounts
    }

    if (!teamAccounts.length) {
      this.setState({
        teamAccounts: [],
        usedTeamResources: EMPTY_ACCOUNT_DETAILS_USAGE,
        accountResourceLimits: accountDetails.limits,
      })
      return {
        usedTeamResources: EMPTY_ACCOUNT_DETAILS_USAGE,
        accountResourceLimits: accountDetails.limits,
      }
    }

    let assignedAddons: { [x: string]: number } = {
      additionalcontainer: 0,
      devboxalwayson: 0,
      extra15remote: 0,
      extra15domains: 0,
    }

    teamAccounts.forEach(account => {
      if (account.addons) {
        account.addons.forEach(addon => {
          switch (addon.addon_code) {
            case AddonName.AdditionalContainer:
              assignedAddons.additionalcontainer += addon.quantity
              break
            case AddonName.DevboxAlwaysOn:
              assignedAddons.devboxalwayson += addon.quantity
              break
            case AddonName.Extra15Remote:
              assignedAddons.extra15remote += addon.quantity
              break
            case AddonName.Extra15Domains:
              assignedAddons.extra15domains += addon.quantity
              break
          }
        })
      }
    })

    const usedTeamResources: AccountDetailsUsage = {
      devboxes: assignedAddons.additionalcontainer,
      alwayson: assignedAddons.devboxalwayson,
      'remote-servers': assignedAddons.extra15remote,
      domains: assignedAddons.extra15domains,
      multipleaccounts: teamAccounts.length,
    }

    const { limits } = { ...accountDetails }
    const accountResourceLimits: AccountDetailsLimits = {
      devboxes: limits.devboxes - usedTeamResources.devboxes,
      alwayson: limits.alwayson - usedTeamResources.alwayson,
      'remote-servers':
        limits['remote-servers'] - usedTeamResources['remote-servers'] * 15,
      domains: limits.domains - usedTeamResources.domains * 15,
      'devbox-hdd': limits['devbox-hdd'],
      'devbox-ram': limits['devbox-ram'],
      multipleaccounts: limits.multipleaccounts,
    }

    this.setState({
      teamAccounts,
      usedTeamResources,
      accountResourceLimits,
    })

    return { usedTeamResources, accountResourceLimits }
  }

  private refreshResourceLimits = async () => {
    const { usedTeamResources, accountResourceLimits } =
      await this.factorInTeamResources(this.state.fetchedAccountDetails)
    this.setAccountDetails(
      this.state.fetchedAccountDetails,
      usedTeamResources,
      accountResourceLimits,
      true
    )
  }

  private getDevboxes = async () => {
    this.setState({
      containers: await this.getContainersAsShares(),
      shares: await this.getSharedWithMe(),
      loadingDevboxes: false,
    })
  }

  private async getContainersAsShares() {
    try {
      const containers = await this.props.apiClient.getContainerList()
      const myShares = await this.getMyShares()

      const sharesMap = new Map<number, Share>()
      myShares.forEach(share => sharesMap.set(share.containerId, share))

      return containers
        ? await Promise.all(
            containers.map(async container => {
              const share = sharesMap.get(container.id)

              return {
                ...container,
                loading: this.mapContainerStateToLoadingStatus(container.state),
                processingAction: false,
                refreshingCollaborators: false,
                key: container.id.toString(),
                shareId: share ? share.id : '',
                invites: share ? share.invites : [],
                collaborators: share ? share.users : [],
              } as Container
            })
          )
        : []
    } catch (e) {
      notification.error({
        message: 'An error ocurred while getting your list of containers.',
      })
      return []
    }
  }

  private async getMyShares() {
    try {
      return await this.props.shareApiClient.getMyShares()
    } catch (e) {
      return []
    }
  }

  private getShareByContainerId = async (containerId: number) => {
    try {
      return await this.props.shareApiClient.getShareByContainerId(containerId)
    } catch {
      //share not created yet
      return EMPTY_SHARE
    }
  }

  private async getSharedWithMe() {
    try {
      return await this.props.shareApiClient?.getSharedWithMe()
    } catch (e) {
      notification.error({
        message:
          'An error ocurred while getting the list of containers that are shared with you.',
      })
      return []
    }
  }

  private refreshSharesList = async () => {
    this.setState({
      shares: await this.getSharedWithMe(),
    })
  }

  private getConnections = async () => {
    const previousConnections = this.state.connections

    try {
      const connections = (await this.props.apiClient.getConnectionList())
        .filter(
          connection =>
            connection.type === 'ssh' ||
            connection.type === 'ftp' ||
            connection.type === 'ftps' ||
            connection.type === 'gdrive'
        )
        .map(connection => {
          return { ...connection, processingAction: false } as Connection
        })

      this.setState({
        connections: connections
          ? connections.map(connection => {
              return { key: connection.id.toString(), ...connection }
            })
          : [],
        loadingConnections: false,
      })
    } catch (e) {
      notification.error({
        message: 'An error ocurred while getting your list of connections.',
      })
    } finally {
      return previousConnections
    }
  }

  private getTemplates = async () => {
    try {
      this.setState({
        templates: transformTemplates(
          await this.props.apiClient.getContainerStacks()
        ),
        loadingTemplates: false,
      })
    } catch (e) {
      notification.error({
        message: 'An error ocurred while loading templates.',
      })
    }
  }

  private getDomains = async () => {
    try {
      this.setState({
        domains: await this.props.apiClient.getDomainList(),
        loadingDomains: false,
      })
    } catch (e) {
      notification.error({
        message: 'An error ocurred while getting the list of domains.',
      })
    }
  }

  private getConnectedAccounts = async () => {
    try {
      this.setState({
        connectedAccounts: await this.props.apiClient.getConnectedAccounts(),
        loadingConnectedAccounts: false,
      })
    } catch (e) {
      notification.error({
        message:
          'An error ocurred while getting the list of your connected accounts.',
      })
    }
  }

  private mapContainerStateToLoadingStatus(state: number) {
    return state !== 10 && state !== 20 && state !== 80
  }

  private async updateContainerState(containerId: number, state: number) {
    if (state) {
      this.setState({
        containers: this.state.containers.map(container => {
          if (container.id !== containerId) {
            return container
          }
          return {
            ...container,
            loading: this.mapContainerStateToLoadingStatus(state),
            state,
          }
        }),
      })
    } else {
      await this.getDevboxes()
    }
  }

  private async redirectToContainer(containerId: number) {
    const containerSlug = await this.props.apiClient.getContainerSlug(
      containerId
    )

    if (!containerSlug) {
      setTimeout(() => this.redirectToContainer(containerId), 1000)
      return
    }

    window.open(
      `${process.env.REACT_APP_IDE_URL}/${containerSlug.toLowerCase()}`
    )
  }

  private getContainerName = (containerId: number) => {
    return this.state.containers.find(container => container.id === containerId)
      ?.name
  }

  private hideProfileDropdown = (e: MouseEvent) => {
    const profileDropdown = document.getElementById('profile-dropdown')
    const headerProfile = document.getElementById('header-profile')

    if (
      this.state.profileDropdownExpanded &&
      profileDropdown &&
      headerProfile &&
      !profileDropdown.contains(e.target as Node) &&
      !headerProfile.contains(e.target as Node)
    ) {
      this.setState({
        profileDropdownExpanded: false,
      })
    }
  }

  private setupDevboxesRefreshing() {
    this.refreshInterval = window.setInterval(() => {
      if (this.state.containers.length) {
        this.getDevboxes()
      }
    }, 1000 * 60)

    // Use browser online event instead of constant polling API
    window.addEventListener('online', this.getDevboxes)
  }

  private terminateDevboxesRefreshing() {
    clearInterval(this.refreshInterval)
    window.removeEventListener('online', this.getDevboxes)
  }

  private attachApiClientEventListeners() {
    this.props.apiClient.on(
      ApiClientEvent.ContainerStateChanged,
      async event => {
        this.updateContainerState(event.containerId, event.state)

        //deploying container
        if (event.state === 103 && document.hasFocus()) {
          this.redirectToContainer(event.containerId)
        }

        //destroyed container
        if (event.state === 90 || event.state === undefined) {
          //TODO: check || event.state === undefined
          await this.refreshAccountDetails()
        }

        //template created successfully
        if (event.state === 200) {
          notification.success({
            message: `The custom template you created from the container '${this.getContainerName(
              event.containerId
            )}'' is now ready to use.`,
          })
          await this.getTemplates()
        }
      }
    )

    this.props.shareApiClient.on(ApiClientEvent.InvitedToShare, () => {
      this.refreshSharesList()
    })
  }

  private attachOtherEventListeners() {
    document.addEventListener('mouseup', this.hideProfileDropdown)
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.setState({
          profileDropdownExpanded: false,
          mobileNavigationExpanded: false,
        })
      }
    })
  }

  private onDidLoadInitContent = () => {
    this.setSubscriptionExpiredModalVisibility(
      this.state.subscription,
      this.state.accountDetails
    )

    if (this.state.loadingInitContent) {
      this.setState({ loadingInitContent: false })
    }
  }
}
