import * as React from 'react'
import { CHECKOUT } from '../helpers/image-imports/Checkout'
import { PlanPeriod } from '../pages/BillingPage'
import { BLACK_FRIDAY_COUPON } from '../pages/CheckoutPage'

type Props = {
  valid: boolean
  couponCode: string
  loading: boolean
  currentPlanPeriod: PlanPeriod
  onCouponCodeChange: (couponCode: string) => void
  onApplyClick: () => void
  onCancelClick: () => void
}

type State = {
  editing: boolean
}

export class CheckoutCoupon extends React.Component<Props, State> {
  public state: State = {
    editing: true,
  }

  componentDidMount() {
    if (this.props.couponCode !== '') {
      this.setState({ editing: false })
    }
  }

  render() {
    return this.props.valid ? (
      <div className="applied-coupon">
        <div className={'coupon-success'}>
          <img src={CHECKOUT['SuccessInfo']} alt="Coupon success icon" /> Coupon
          code will be applied on checkout.
        </div>
        <button className={'coupon-badge'} onClick={this.props.onCancelClick}>
          {this.props.couponCode}{' '}
          <img src={CHECKOUT['CouponClose']} alt="Coupon close icon" />
        </button>
      </div>
    ) : (
      <div
        className={`billing-information-field ant-col coupon ${
          !this.props.valid && !this.state.editing && !this.props.loading
            ? ' error'
            : ''
        }`}
      >
        <label>Enter coupon code</label>
        <div className={'coupon-row'}>
          <input
            type="text"
            value={this.props.couponCode}
            data-recurly="coupon"
            onChange={e => {
              this.props.onCouponCodeChange(e.target.value)
              if (!this.state.editing) {
                this.setState({ editing: true })
              }
            }}
          />
          <button
            onClick={() => {
              this.props.onApplyClick()
              this.setState({
                editing: false,
              })
            }}
            className={`checkout-button${
              !this.props.couponCode || this.props.loading ? ' inactive' : ''
            } coupon-button ${this.props.loading ? 'loading' : ''}`}
          >
            <span>Apply</span>
            <span className={'loader'}></span>
          </button>
        </div>
        <div className="input-error">
          <div>
            <img src={CHECKOUT['WarningSign']} alt="Validation warning icon" />
          </div>
          {this.props.couponCode === BLACK_FRIDAY_COUPON
            ? 'Our Black Friday deal is not valid for monthly plans.'
            : 'Coupon code not valid!'}
        </div>
      </div>
    )
  }
}
