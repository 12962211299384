import React from 'react'
import { Redirect } from 'react-router-dom'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Page } from '../App'
import { Heading, HeadingLevel } from '../components/Heading'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { Spinner } from '../components/Spinner'
import { NewContainerForm } from '../containers/NewContainerForm'
import { Templates } from '../helpers/TransformTemplates'
import { triggerOpenedScreenEvent } from '../helpers/Util'

type Props = {
  apiClient: ApiClient

  accountDetails: AccountDetails
  templates: Templates
  remainingAlwaysOn: number
  remainingContainers: number
  subscriptionExpiredModalVisible: boolean

  templatesAlreadyFetched: boolean
  loadingInitContent: boolean
  onDidLoadInitContent: () => void

  getTemplates: () => Promise<void>
  refreshVerificationStatus: () => Promise<void>
}

type State = {
  loading: boolean
  page: string
}

export class NewContainerPage extends React.Component<Props, State> {
  public state: State = {
    loading: false,
    page: Page.NewContainer,
  }

  private switchPage = (page: string) => {
    window.history.pushState({ prevUrl: window.location.href }, '', page)
    this.setState({ page })
  }

  componentDidMount() {
    this.getData()
    triggerOpenedScreenEvent(
      'openedNewContainerScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (this.state.page !== Page.NewContainer) {
      window.scrollTo(0, 0)
      return <Redirect to={this.state.page} />
    }

    if (
      !this.props.templatesAlreadyFetched ||
      this.props.loadingInitContent ||
      this.state.loading
    ) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    return (
      <div className="page-content">
        <Heading
          heading="New Container"
          level={HeadingLevel.First}
          goBack={true}
        />
        <NewContainerForm
          apiClient={this.props.apiClient}
          accountDetails={this.props.accountDetails}
          templates={this.props.templates}
          remainingAlwaysOn={this.props.remainingAlwaysOn}
          switchPage={this.switchPage}
          refreshVerificationStatus={this.props.refreshVerificationStatus}
        />
        {this.renderLimitModal()}
      </div>
    )
  }

  private renderLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={
          this.props.remainingContainers <= 0 &&
          !this.props.subscriptionExpiredModalVisible
        }
        resource="Containers"
        accountDetails={this.props.accountDetails}
      />
    )
  }

  private async getData() {
    const { onDidLoadInitContent, templatesAlreadyFetched, getTemplates } = {
      ...this.props,
    }

    if (this.props.loadingInitContent || !templatesAlreadyFetched) {
      this.setState({ loading: true })
      await getTemplates()
      this.setState({ loading: false })
    }
    onDidLoadInitContent()
  }
}
