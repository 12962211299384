import React from 'react'
import { range } from 'lodash'
import {
  LeftArrowIcon,
  RightArrowIcon,
} from '../helpers/image-imports/IconComponent'
import { SelectInput } from './SelectInput'
import { onKey } from '../helpers/Util'

const LEFT_DOTS = -2
const RIGHT_DOTS = -1
const SIBLINGS_COUNT = 1

export enum PaginationSize {
  Nine = 9,
  Fifteen = 15,
  Thirty = 30,
}

type Props = {
  total: number
  paginationSize: PaginationSize
  currentPage: number
  onChangePageSize: (size: PaginationSize) => void
  onChangePage: (page: number) => void
}

export class Pagination extends React.Component<Props> {
  render() {
    const pageNumbers = this.getDisplayedPageNumbers()

    return (
      <div className="pagination-container">
        <ul className="pagination">
          <li
            className={`pagination-left-arrow tab-focus${
              this.props.currentPage === 1 ? ' disabled' : ''
            }`}
            onClick={() => this.handleDecreasePageEvent()}
            onKeyDown={e => this.handleDecreasePageEvent(e, 'Enter')}
            onKeyUp={e => this.handleDecreasePageEvent(e, ' ')}
            tabIndex={0}
          >
            <LeftArrowIcon />
          </li>
          {pageNumbers.map(pageNumber => {
            if (pageNumber === LEFT_DOTS || pageNumber === RIGHT_DOTS) {
              return (
                <li className="pagination-item dots" key={pageNumber}>
                  &#8230;
                </li>
              )
            }

            return (
              <li
                key={pageNumber}
                className={`pagination-item tab-focus${
                  pageNumber === this.props.currentPage ? ' active' : ''
                }`}
                onClick={() => this.props.onChangePage(pageNumber)}
                onKeyDown={e =>
                  onKey(e, 'Enter', () => this.props.onChangePage(pageNumber))
                }
                onKeyUp={e =>
                  onKey(e, ' ', () => this.props.onChangePage(pageNumber))
                }
                tabIndex={0}
              >
                {pageNumber}
              </li>
            )
          })}
          <li
            className={`pagination-right-arrow tab-focus${
              this.props.currentPage === pageNumbers[pageNumbers.length - 1]
                ? ' disabled'
                : ''
            }`}
            onClick={() =>
              this.handleIncreasePageEvent(pageNumbers[pageNumbers.length - 1])
            }
            onKeyDown={e =>
              this.handleIncreasePageEvent(
                pageNumbers[pageNumbers.length - 1],
                e,
                'Enter'
              )
            }
            onKeyUp={e =>
              this.handleIncreasePageEvent(
                pageNumbers[pageNumbers.length - 1],
                e,
                ' '
              )
            }
            tabIndex={0}
          >
            <RightArrowIcon />
          </li>
        </ul>
        <SelectInput
          value={this.props.paginationSize}
          values={[
            { value: PaginationSize.Nine, label: '9 per page' },
            { value: PaginationSize.Fifteen, label: '15 per page' },
            { value: PaginationSize.Thirty, label: '30 per page' },
          ]}
          className="pagination-size"
          onChangeValue={size => this.props.onChangePageSize(size)}
        />
      </div>
    )
  }

  private getDisplayedPageNumbers() {
    const totalPageCount = Math.ceil(
      this.props.total / this.props.paginationSize
    )

    //first + current + last + siblings + (2*dots or 1dot and extra sibling)
    const maxPaginationElementsCount = SIBLINGS_COUNT + 5

    //there is less pages than the maximum number of pagination elements
    if (totalPageCount <= maxPaginationElementsCount) {
      return range(1, totalPageCount + 1) //incude end of range
    }

    const displayLeftDots = this.props.currentPage >= 1 + 2 + 2 * SIBLINGS_COUNT
    const displayRightDots =
      totalPageCount - this.props.currentPage >= 2 + 2 * SIBLINGS_COUNT

    if (!displayLeftDots && displayRightDots) {
      let leftRangeEnd = 3 + 2 * SIBLINGS_COUNT
      let leftRange = range(1, leftRangeEnd + 1)
      return [...leftRange, RIGHT_DOTS, totalPageCount]
    } else if (displayLeftDots && !displayRightDots) {
      let rightRangeStart = totalPageCount - 2 - 2 * SIBLINGS_COUNT
      let rightRange = range(rightRangeStart, totalPageCount + 1)
      return [1, LEFT_DOTS, ...rightRange]
    } else {
      let middleRange = range(
        this.props.currentPage - 1,
        this.props.currentPage + 1 + 1
      )
      return [1, LEFT_DOTS, ...middleRange, RIGHT_DOTS, totalPageCount]
    }
  }

  private handleDecreasePageEvent = (e?: React.KeyboardEvent, key?: string) => {
    if (this.props.currentPage === 1) {
      return undefined
    } else if (!e || !key) {
      this.props.onChangePage(this.props.currentPage - 1)
    } else {
      onKey(e, key, () => this.props.onChangePage(this.props.currentPage - 1))
    }
  }

  private handleIncreasePageEvent = (
    lastPage: number,
    e?: React.KeyboardEvent,
    key?: string
  ) => {
    if (this.props.currentPage === lastPage) {
      return undefined
    } else if (!e || !key) {
      this.props.onChangePage(this.props.currentPage + 1)
    } else {
      onKey(e, key, () => this.props.onChangePage(this.props.currentPage + 1))
    }
  }
}
