import * as React from 'react'
import { PAYMENT } from '../helpers/image-imports/Payment'

export class AcceptedCreditCards extends React.PureComponent {
  render() {
    return (
      <div className="accepted-credit-cards">
        <img src={PAYMENT['Maestro']} alt="Maestro credit card" />
        <img src={PAYMENT['VisaElectron']} alt="Visa Electron credit card" />
        <img src={PAYMENT['MasterCard']} alt="MasterCard credit card" />
        <img src={PAYMENT['Visa']} alt="Visa credit card" />
        <img src={PAYMENT['Amex']} alt="Amex credit card" />
        <img src={PAYMENT['Discover']} alt="Discover credit card" />
      </div>
    )
  }
}
