import * as React from 'react'
import { Button } from '../Button'
import moment from 'moment'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'
import mixpanel from 'mixpanel-browser'

type Props = {
  userId: number
  email: string
  cancelMembership: () => Promise<void>
  refundPeriodExpired: boolean
  subscriptionExpirationDate: string
  displayStep: boolean
  loading: boolean
  cancellationConfirmed: boolean
}

export class CancelConfirmStep extends React.Component<Props> {
  componentDidMount() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cancelFlowConfirmEnter',
        userId: this.props.userId,
        emailHash: String(sha256(this.props.email)),
      },
    })
    mixpanel.track('cancelFlowConfirmEnter', {
      userId: this.props.userId,
      emailHash: String(sha256(this.props.email)),
    })
  }

  render() {
    return this.props.displayStep ? (
      <>
        <div className="cancellation__plan">
          <h1 className="cancellation__plan-title">
            Confirm membership cancellation
          </h1>
        </div>
        <div className="cancel-options-container">
          <div className="cancel-option">
            <div className="cancel-option-description">
              <h3 className="cancel-option__title">Keep my membership</h3>
              <h4 className="cancel-option__subtitle">
                {!this.props.refundPeriodExpired
                  ? 'You will continue enjoying all the benefits of Codeanywhere.'
                  : `Since your refund period ended, it makes more sense to keep your subscription until ${moment(
                      this.props.subscriptionExpirationDate
                    ).format('MMM Do, YYYY')}.`}{' '}
              </h4>
            </div>
            <Button
              type="success"
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'cancelFlowKeep',
                    userId: this.props.userId,
                    emailHash: String(sha256(this.props.email)),
                  },
                })
                mixpanel.track('cancelFlowKeep', {
                  userId: this.props.userId,
                  emailHash: String(sha256(this.props.email)),
                })
                window.location.href = '/'
              }}
              disabled={this.props.cancellationConfirmed || this.props.loading}
              value={'Keep Membership'}
            />
          </div>
          <div className="cancel-option-divider"></div>
          <div className="cancel-option">
            <div className="cancel-option-description">
              <h3 className="cancel-option__title">Cancel my membership</h3>
              <h4 className="cancel-option__subtitle">
                {!this.props.refundPeriodExpired
                  ? 'In compliance with our 7-day money-back guarantee, you will be refunded in full within 10 days.'
                  : 'Your subscription will be terminated immediately and you will lose access to all your containers.'}
              </h4>
            </div>
            <Button
              type={'danger'}
              onClick={() => {
                this.props.cancelMembership()
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'cancelFlowCanceled',
                    userId: this.props.userId,
                    emailHash: String(sha256(this.props.email)),
                  },
                })
                mixpanel.track('cancelFlowCanceled', {
                  userId: this.props.userId,
                  emailHash: String(sha256(this.props.email)),
                })
              }}
              loading={this.props.loading}
              disabled={this.props.cancellationConfirmed || this.props.loading}
              value={'End now'}
            />
          </div>
        </div>
      </>
    ) : null
  }
}
