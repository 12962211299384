import * as React from 'react'
import { Tooltip, TooltipPosition } from './Tooltip'

type Props = {
  state: string
  color: string
}

export class ContainerStateIndicator extends React.PureComponent<Props> {
  render() {
    return (
      <Tooltip
        position={TooltipPosition.Right}
        content={
          <>
            Container status:
            <span
              className={`container-status__tooltip--color-${this.props.color}`}
            >
              {' '}
              {this.props.state}
            </span>
          </>
        }
      >
        <div className={'container-status'}>
          <div
            className={`container-status-icon container-status-icon--type-${this.props.color}`}
          />
        </div>
      </Tooltip>
    )
  }
}
