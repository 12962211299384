import * as React from 'react'
import { Spinner } from './Spinner'

/**
 * TODO:
 * declare button states via type
 * fix html
 */
type Props = {
  value?: string
  color?: string
  submit?: boolean
  disabled?: boolean
  loading?: boolean
  style?: any
  onClick?: () => Promise<void> | void
  type?: string
  LEGACY_children?: any
  autoFocus?: boolean
}

export class Button extends React.PureComponent<Props> {
  render() {
    return (
      <button
        type={this.props.submit ? 'submit' : 'button'}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        className={`button tab-focus${
          this.props?.type ? ` button--type-${this.props.type}` : ''
        }${this.props?.loading ? ` button--type-loading` : ''}${
          this.props?.disabled ? ` button--state-disabled` : ''
        }`}
        style={this.props?.style}
        autoFocus={this.props.autoFocus}
      >
        {this.props.LEGACY_children
          ? this.props.LEGACY_children
          : this.props.value}
        {this.props.loading && (
          <span className={'button__loader'}>
            <Spinner className="button-spinner-white" />
          </span>
        )}
      </button>
    )
  }
}
