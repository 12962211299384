import * as React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Link } from 'react-router-dom'
import {
  ContainerConnection,
  ContainerDomain,
  CreateCustomStack,
} from '../api-client/interface/Connection'
import { ContainerCard } from './ContainerCard'
import { Heading, HeadingLevel } from '../components/Heading'
import {
  Share,
  ShareInvite,
  ShareUser,
} from '@codeanywhere/collaboration-service-api-client'
import { ShareApiClient } from '../api-client/adapters/ShareApiClient'
import { ShareCard } from '../components/ShareCard'
import { parse as parseQs } from 'querystring'
import { notification } from '../components/Notification'
import { Modal } from '../components/Modal'
import {
  ContainerLabel,
  CustomTemplateLabel,
  Input,
  InputType,
} from '../components/Input'
import { Button } from '../components/Button'
import { Spinner } from '../components/Spinner'
import { Collaborator, CollaboratorType } from '../components/Collaborator'
import marked from 'marked'
import DOMPurify from 'dompurify'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { DevboxFiltersData } from '../components/DevboxFilters'
import { CardOption } from '../components/CardOptions'
import { Pagination, PaginationSize } from '../components/Pagination'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { focusOnElement } from '../helpers/Util'
import { EmptyList } from '../components/EmptyList'

export const EMPTY_SHARE: Share = {
  id: '',
  admin: {
    id: -1,
    email: '',
    username: '',
  },
  containerId: -1,
  invites: [],
  users: [],
  link: '',
}

export enum DevboxFilter {
  All = 'all',
  Created = 'created',
  SharedWithMe = 'shared-with-me',
}

export type Container = ContainerConnection & {
  loading: boolean
  processingAction: boolean
  refreshingCollaborators: boolean
  key: string
  shareId: string
  invites: ShareInvite[]
  collaborators: ShareUser[]
}

type Props = {
  apiClient: ApiClient
  shareApiClient: ShareApiClient
  accountDetails: AccountDetails
  inHome?: boolean
  containers: Container[]
  shares: Share[]
  domains: ContainerDomain[]
  refreshDevboxes: () => Promise<void>
  refreshSharesList: () => Promise<void>
  refreshAccountDetails: () => Promise<void>
  getShareByContainerId: (containerId: number) => Promise<Share>
  switchPage: (page: string) => void
}

type State = {
  alwaysOnLimitDialogVisible: boolean
  activeFilter: DevboxFilter

  selectedContainer?: Container
  selectedShare?: Share & { processingAction?: boolean }
  modalGeneralInfo: string
  loadingModalGeneralInfo: boolean

  renameModalVisible: boolean
  collaboratorsModalVisible: boolean
  createTemplateModalVisible: boolean
  destroyModalVisible: boolean
  infoModalVisible: boolean
  leaveShareModalVisible: boolean

  newContainerName?: string
  customTemplate: CreateCustomStack
  invitee: string
  destroyConfirmation: string

  currentPaginationPage: number
  paginationSize: PaginationSize
}

export class ContainersList extends React.Component<Props, State> {
  public state: State = {
    alwaysOnLimitDialogVisible: false,
    activeFilter: DevboxFilter.All,

    selectedContainer: undefined,
    selectedShare: undefined,
    modalGeneralInfo: '',
    loadingModalGeneralInfo: false,

    renameModalVisible: false,
    collaboratorsModalVisible: false,
    createTemplateModalVisible: false,
    destroyModalVisible: false,
    infoModalVisible: false,
    leaveShareModalVisible: false,

    newContainerName: undefined,
    customTemplate: {
      id: -1,
      name: '',
      description: '',
    },
    invitee: '',
    destroyConfirmation: '',

    currentPaginationPage: 1,
    paginationSize: PaginationSize.Nine,
  }

  componentDidMount() {
    this.parseDevboxesFilterFromUrl()
  }

  render() {
    const devboxFiltersData: DevboxFiltersData = {
      activeFilter: this.state.activeFilter,
      onChangeActiveFilter: this.handleChangeDevboxesFilter,
      createdContainersAmount: this.props.containers.length,
      sharesAmount: this.props.shares.length,
    }

    const filteredDevboxes = this.applyDevboxesFilter()
    const displayedDevboxes = this.props.inHome
      ? filteredDevboxes.slice(0, 5)
      : filteredDevboxes.slice(
          (this.state.currentPaginationPage - 1) * this.state.paginationSize,
          this.state.currentPaginationPage * this.state.paginationSize
        )

    return (
      <>
        <Heading
          heading="Containers"
          level={HeadingLevel.First}
          devboxFiltersData={devboxFiltersData}
          divider={true}
        />

        {displayedDevboxes.length ? (
          <div className="card-grid">
            {displayedDevboxes.map(devbox => {
              if (this.isContainer(devbox)) {
                const container = devbox as Container
                return (
                  <div className="card-grid__item" key={container.id}>
                    <ContainerCard
                      apiClient={this.props.apiClient}
                      container={container}
                      accountDetails={this.props.accountDetails}
                      onStartContainer={this.handleStartContainer}
                      onStopContainer={this.handleStopContainer}
                      onChangeAlwaysOn={this.handleChangeAlwaysOn}
                      displayModal={this.displayContainerModal}
                      switchPage={this.props.switchPage}
                    />
                  </div>
                )
              } else {
                const share = devbox as Share
                return (
                  <div className="card-grid__item" key={share.id}>
                    <ShareCard
                      shareApiClient={this.props.shareApiClient}
                      share={share}
                      displayModal={this.displayShareModal}
                    />
                  </div>
                )
              }
            })}
            {this.props.inHome && filteredDevboxes.length > 5 && (
              <div className="card-grid__item">
                <Link
                  to={`/containers?containers-filter=${this.state.activeFilter}`}
                  className="container-card"
                >
                  <div className="see-all card">
                    <span>See all</span>
                  </div>
                </Link>
              </div>
            )}
          </div>
        ) : (
          <EmptyList resource="containers" />
        )}
        {!!filteredDevboxes.length && !this.props.inHome && (
          <Pagination
            total={filteredDevboxes.length}
            paginationSize={this.state.paginationSize}
            currentPage={this.state.currentPaginationPage}
            onChangePageSize={paginationSize =>
              this.setState({ paginationSize, currentPaginationPage: 1 })
            }
            onChangePage={currentPaginationPage =>
              this.setState({ currentPaginationPage })
            }
          />
        )}

        {this.renderRenameModal()}
        {this.renderCollaboratorsModal()}
        {this.renderCreateTemplateModal()}
        {this.renderDestroyModal()}
        {this.renderInfoModal()}
        {this.renderLeaveShareModal()}
        {this.renderAlwaysOnLimitModal()}
      </>
    )
  }

  private renderRenameModal = () => {
    if (this.state.renameModalVisible) {
      return (
        <Modal
          visible={this.state.renameModalVisible}
          closeable={true}
          onClose={() => {
            this.setState({
              renameModalVisible: false,
              newContainerName: undefined,
              selectedContainer: undefined,
            })
          }}
        >
          <Modal.Header>
            Change container name for{' '}
            <span className="purple-highlight">
              {this.state.selectedContainer?.name}
            </span>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleChangeContainerName}>
              <Input
                label={ContainerLabel.Name}
                value={this.state.newContainerName}
                autofocus={true}
                onChangeInput={newContainerName =>
                  this.setState({
                    newContainerName,
                  })
                }
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.selectedContainer?.processingAction}
                  onClick={() =>
                    this.setState({
                      renameModalVisible: false,
                      newContainerName: undefined,
                      selectedContainer: undefined,
                    })
                  }
                />
                <Button
                  value="Save"
                  type="success"
                  submit={true}
                  disabled={
                    this.state.selectedContainer?.processingAction ||
                    !this.state.newContainerName ||
                    this.state.newContainerName ===
                      this.state.selectedContainer?.name
                  }
                  loading={this.state.selectedContainer?.processingAction}
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )
    }

    return null
  }

  private renderCreateTemplateModal = () => {
    if (this.state.createTemplateModalVisible) {
      return (
        <Modal
          visible={this.state.createTemplateModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              createTemplateModalVisible: false,
              customTemplate: {
                id: -1,
                name: '',
                description: '',
              },
              selectedContainer: undefined,
            })
          }
        >
          <Modal.Header>
            Create custom template from{' '}
            <span className="purple-highlight">
              {this.state.selectedContainer?.name}
            </span>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleCreateCustomTemplate}>
              <Input
                label={CustomTemplateLabel.Name}
                value={this.state.customTemplate?.name}
                required={true}
                autofocus={true}
                onChangeInput={name =>
                  this.setState({
                    customTemplate: { ...this.state.customTemplate, name },
                  })
                }
              />
              <Input
                label={CustomTemplateLabel.Description}
                value={this.state.customTemplate.description}
                required={true}
                onChangeInput={description =>
                  this.setState({
                    customTemplate: {
                      ...this.state.customTemplate,
                      description,
                    },
                  })
                }
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.selectedContainer?.processingAction}
                  onClick={() =>
                    this.setState({
                      createTemplateModalVisible: false,
                      customTemplate: {
                        id: -1,
                        name: '',
                        description: '',
                      },
                      selectedContainer: undefined,
                    })
                  }
                />
                <Button
                  value="Create"
                  type="success"
                  submit={true}
                  disabled={
                    this.state.selectedContainer?.processingAction ||
                    !this.state.customTemplate?.name ||
                    !this.state.customTemplate.description
                  }
                  loading={this.state.selectedContainer?.processingAction}
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )
    }

    return null
  }

  private renderCollaboratorsModal = () => {
    if (this.state.collaboratorsModalVisible) {
      if (!this.state.selectedContainer && !this.state.selectedShare) {
        this.setState({
          collaboratorsModalVisible: false,
        })
        notification.error({
          message:
            'Unfortunately, you are unable to view your collaborators at this moment.',
          description: 'Please contact support for more information.',
        })
        return
      }

      const owner = this.state.selectedContainer
        ? this.props.accountDetails
        : this.state.selectedShare!.admin

      const collaborators = this.state.selectedContainer
        ? this.state.selectedContainer.collaborators
        : this.state.selectedShare!.users
      const invites = this.state.selectedContainer
        ? this.state.selectedContainer.invites
        : this.state.selectedShare!.invites

      return (
        <Modal
          visible={this.state.collaboratorsModalVisible}
          closeable={true}
          onClose={() => {
            this.setState({
              collaboratorsModalVisible: false,
              invitee: '',
              selectedContainer: undefined,
            })
          }}
        >
          <Modal.Header>
            {this.state.selectedContainer ? (
              <>
                Invite people to collaborate on{' '}
                <span className="purple-highlight">
                  {this.state.selectedContainer?.name}
                </span>
              </>
            ) : (
              <>
                Collaborators on{' '}
                <span className="purple-highlight">
                  {this.state.selectedShare!.containerName}
                </span>
              </>
            )}
          </Modal.Header>
          <Modal.Body>
            <div className="collaborators-modal-body">
              {this.state.selectedContainer && (
                <>
                  <form onSubmit={this.handleInviteCollaborators}>
                    <div className="invite-collaborator">
                      <Input
                        label={ContainerLabel.InviteeEmail}
                        value={this.state.invitee}
                        placeholder="e.g. example@domain.com"
                        required={true}
                        type={InputType.Email}
                        whiteBackground={true}
                        autofocus={true}
                        onChangeInput={invitee =>
                          this.setState({
                            invitee,
                          })
                        }
                      />
                      <Button
                        value="Invite"
                        type="success"
                        submit={true}
                        loading={this.state.selectedContainer.processingAction}
                        disabled={
                          this.state.selectedContainer.processingAction ||
                          !this.state.invitee
                        }
                      />
                    </div>
                  </form>
                  <span className="collaborators-title">
                    Current collaborators
                  </span>
                </>
              )}
              <div className="collaborators-list">
                {this.state.selectedContainer?.refreshingCollaborators ? (
                  <div className="component__spinner-container">
                    <Spinner size={'medium'} />
                  </div>
                ) : (
                  <>
                    <Collaborator
                      displayName={owner.name}
                      email={owner.email}
                      imageUrl={owner.imageurl}
                      role={CollaboratorType.Owner}
                      activeUser={!!this.state.selectedContainer}
                      key={owner.email}
                    />
                    {collaborators.map(collaborator => (
                      <Collaborator
                        displayName={
                          !!collaborator.name
                            ? collaborator.name
                            : collaborator.username
                        }
                        email={collaborator.email}
                        imageUrl={collaborator.imageurl}
                        role={
                          collaborator.isProtected
                            ? CollaboratorType.Protected
                            : CollaboratorType.Collaborator
                        }
                        activeUser={
                          collaborator.email === this.props.accountDetails.email
                        }
                        onRemove={
                          this.state.selectedContainer
                            ? () =>
                                this.handleRemoveCollaborator(
                                  collaborator.email
                                )
                            : undefined
                        }
                        key={collaborator.userId}
                      />
                    ))}
                    {invites.map(invite => (
                      <Collaborator
                        email={invite.email}
                        role={CollaboratorType.Pending}
                        onRemove={
                          this.state.selectedContainer
                            ? () => this.handleRemoveCollaborator(invite.email)
                            : undefined
                        }
                        key={invite.email}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="button-row bottom-margin">
              <Button
                value="Go back"
                onClick={() => {
                  this.setState({
                    collaboratorsModalVisible: false,
                    invitee: '',
                    selectedContainer: undefined,
                  })
                }}
              />
            </div>
          </Modal.Body>
          {this.state.selectedContainer && (
            <Modal.Footer>
              Your teammates will get an email that gives them access to the
              container.
            </Modal.Footer>
          )}
        </Modal>
      )
    }

    return null
  }

  private renderDestroyModal = () => {
    if (this.state.destroyModalVisible) {
      return (
        <Modal
          visible={this.state.destroyModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              destroyModalVisible: false,
              destroyConfirmation: '',
              selectedContainer: undefined,
            })
          }
        >
          <Modal.Header>
            Destroy{' '}
            <span className="purple-highlight">
              {this.state.selectedContainer?.name}
            </span>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleDestroyContainer}>
              <Input
                label={ContainerLabel.DestroyConfirmation}
                value={this.state.destroyConfirmation}
                autofocus={true}
                onChangeInput={destroyConfirmation =>
                  this.setState({
                    destroyConfirmation,
                  })
                }
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.selectedContainer?.processingAction}
                  onClick={() =>
                    this.setState({
                      destroyModalVisible: false,
                      destroyConfirmation: '',
                      selectedContainer: undefined,
                    })
                  }
                />
                <Button
                  value="Destroy"
                  type="danger"
                  submit={true}
                  disabled={
                    this.state.selectedContainer?.processingAction ||
                    this.state.destroyConfirmation !==
                      this.state.selectedContainer?.name
                  }
                  loading={this.state.selectedContainer?.processingAction}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            You are about to destroy{' '}
            <strong>{this.state.selectedContainer?.name}</strong>. This action
            is irreversible.
          </Modal.Footer>
        </Modal>
      )
    }

    return null
  }

  private renderInfoModal = () => {
    if (this.state.infoModalVisible) {
      return (
        <Modal
          visible={this.state.infoModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              infoModalVisible: false,
              selectedContainer: undefined,
            })
          }
        >
          <Modal.Header>
            Information about{' '}
            <span className="purple-highlight">
              {this.state.selectedContainer?.name}
            </span>
          </Modal.Header>
          <Modal.Body className={'modal__content--has-html'}>
            {this.state.loadingModalGeneralInfo ? (
              <Spinner />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.modalGeneralInfo,
                }}
              ></div>
            )}
            <div className="button-row">
              <Button
                value="Back"
                onClick={() =>
                  this.setState({
                    infoModalVisible: false,
                  })
                }
              />
            </div>
          </Modal.Body>
        </Modal>
      )
    }

    return null
  }

  private renderLeaveShareModal = () => {
    if (this.state.leaveShareModalVisible) {
      return (
        <Modal
          visible={this.state.leaveShareModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              leaveShareModalVisible: false,
              selectedShare: undefined,
            })
          }
        >
          <Modal.Header>Warning</Modal.Header>
          <Modal.Body>
            <div className="modal-warning-message">
              Are you sure you want to leave{' '}
              <span className="purple-highlight">
                {this.state.selectedShare?.containerName}
              </span>
            </div>
            <div className="button-row">
              <Button
                value="Cancel"
                disabled={this.state.selectedShare?.processingAction}
                onClick={() =>
                  this.setState({
                    leaveShareModalVisible: false,
                    selectedShare: undefined,
                  })
                }
              />
              <Button
                value="Leave"
                type="danger"
                onClick={this.handleLeaveShare}
                disabled={this.state.selectedShare?.processingAction}
                loading={this.state.selectedShare?.processingAction}
              />
            </div>
          </Modal.Body>
        </Modal>
      )
    }

    return null
  }

  private renderAlwaysOnLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={this.state.alwaysOnLimitDialogVisible}
        resource="Always-on add-ons"
        accountDetails={this.props.accountDetails}
        onClose={() => this.setState({ alwaysOnLimitDialogVisible: false })}
      />
    )
  }

  private displayContainerModal = async (
    option: CardOption,
    container: Container
  ) => {
    this.setState(
      {
        selectedContainer: container,
      },
      async () => {
        switch (option) {
          case CardOption.Rename: {
            this.setState({ renameModalVisible: true })
            break
          }
          case CardOption.Collaborators: {
            try {
              this.setState({
                collaboratorsModalVisible: true,
                selectedContainer: {
                  ...container,
                  refreshingCollaborators: true,
                },
              })

              const share = await this.props.shareApiClient.getShare(
                container.id,
                this.props.accountDetails.admin_email
              )

              this.setState({
                selectedContainer: {
                  ...container,
                  shareId: share.id,
                  collaborators: share.users,
                  invites: share.invites,
                  refreshingCollaborators: false,
                },
              })
            } catch (e: any) {
              notification.error({
                message:
                  'An error occurred while getting information about the shared container.',
                description: e.response ? e.response.data.message : e,
              })
            }
            break
          }
          case CardOption.CreateTemplate: {
            this.setState({
              createTemplateModalVisible: true,
              customTemplate: {
                ...this.state.customTemplate,
                id: container.id,
              },
            })
            break
          }
          case CardOption.Destroy: {
            this.setState({ destroyModalVisible: true })
            break
          }
          case CardOption.Info: {
            this.setState({
              infoModalVisible: true,
              modalGeneralInfo: await this.getContainerGeneralInfo(
                container.id
              ),
            })
            break
          }
          default: {
            break
          }
        }
      }
    )
  }

  private displayShareModal = async (option: CardOption, share: Share) => {
    this.setState(
      {
        selectedShare: share,
      },
      () => {
        switch (option) {
          case CardOption.Collaborators: {
            this.setState({ collaboratorsModalVisible: true })
            break
          }
          case CardOption.Leave: {
            this.setState({ leaveShareModalVisible: true })
            break
          }
          default: {
            break
          }
        }
      }
    )
  }

  private handleChangeContainerName = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!this.state.selectedContainer || !this.state.newContainerName) {
      this.setState({
        renameModalVisible: false,
        newContainerName: undefined,
        selectedContainer: undefined,
      })
      notification.error({
        message: 'An error occurred while changing the container name.',
      })
      return
    }

    this.setState({
      selectedContainer: {
        ...this.state.selectedContainer,
        processingAction: true,
      },
    })

    try {
      await this.props.apiClient.renameConnection(
        this.state.selectedContainer.id,
        this.state.newContainerName
      )

      notification.success({
        message: `You have successfully changed the container name.`,
      })
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while changing the container name.',
        description: e,
      })
      focusOnElement(ContainerLabel.Name)
    } finally {
      this.setState({
        renameModalVisible: false,
        newContainerName: undefined,
        selectedContainer: undefined,
      })
    }
  }

  private handleCreateCustomTemplate = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!this.state.selectedContainer) {
      this.setState({
        createTemplateModalVisible: false,
        customTemplate: {
          id: -1,
          name: '',
          description: '',
        },
        selectedContainer: undefined,
      })
      notification.error({
        message: 'An error occurred while creating a custom template.',
      })
      return
    }

    try {
      this.setState({
        selectedContainer: {
          ...this.state.selectedContainer,
          processingAction: true,
        },
      })

      await this.props.apiClient.createCustomStack(this.state.customTemplate)

      notification.success({
        message: 'Your custom template will be ready to use in a few minutes.',
      })
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while creating the custom stack.',
        description: e,
      })
      focusOnElement(CustomTemplateLabel.Name)
    } finally {
      this.setState({
        createTemplateModalVisible: false,
        customTemplate: {
          id: -1,
          name: '',
          description: '',
        },
        selectedContainer: undefined,
      })
    }
  }

  private handleInviteCollaborators = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!this.state.selectedContainer) {
      this.setState({
        collaboratorsModalVisible: false,
        invitee: '',
        selectedContainer: undefined,
      })
      notification.error({
        message: 'An error occurred while inviting new collaborators.',
      })
      return
    }

    if (this.state.invitee === this.props.accountDetails.email) {
      notification.error({
        message: 'An error occurred while sending an invitation.',
        description: 'You are the owner of the container.',
      })

      return
    }

    this.setState({
      selectedContainer: {
        ...this.state.selectedContainer,
        processingAction: true,
      },
    })

    try {
      await this.props.shareApiClient.inviteUserToShare(
        this.state.selectedContainer.shareId,
        this.state.invitee
      )

      this.setState({
        selectedContainer: {
          ...this.state.selectedContainer,
          processingAction: false,
          refreshingCollaborators: true,
        },
      })

      notification.success({
        message: `The invitation has been sent successfully.`,
      })

      await this.props.refreshDevboxes()
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while sending an invitation.',
        description: e.response ? e.response.data.message : String(e),
      })
    } finally {
      if (this.state.selectedContainer) {
        const containerAsShare = await this.props.getShareByContainerId(
          this.state.selectedContainer.id
        )
        this.setState({
          selectedContainer: {
            ...this.state.selectedContainer,
            collaborators: containerAsShare.users,
            invites: containerAsShare.invites,
            refreshingCollaborators: false,
            processingAction: false,
          },
        })
      }
    }
  }

  private handleRemoveCollaborator = async (collaboratorEmail: string) => {
    if (!this.state.selectedContainer) {
      this.setState({
        collaboratorsModalVisible: false,
        invitee: '',
        selectedContainer: undefined,
      })
      notification.error({
        message: 'An error occurred while removing collaborators.',
      })
      return
    }

    this.setState({
      selectedContainer: {
        ...this.state.selectedContainer,
        processingAction: true,
      },
    })

    try {
      await this.props.shareApiClient.removeUserFromShare(
        this.state.selectedContainer.shareId,
        collaboratorEmail
      )

      this.setState({
        selectedContainer: {
          ...this.state.selectedContainer,
          processingAction: false,
          refreshingCollaborators: true,
        },
      })

      notification.success({
        message: `${collaboratorEmail} is no longer a collaborator on this container`,
      })

      await this.props.refreshDevboxes()
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while removing a collaborator.',
        description: e.response ? e.response.data.message : String(e),
      })
    } finally {
      const containerAsShare = await this.props.getShareByContainerId(
        this.state.selectedContainer.id
      )
      this.setState({
        selectedContainer: {
          ...this.state.selectedContainer,
          collaborators: containerAsShare.users,
          invites: containerAsShare.invites,
          refreshingCollaborators: false,
          processingAction: false,
        },
      })
    }
  }

  private handleDestroyContainer = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!this.state.selectedContainer) {
      this.setState({
        destroyModalVisible: false,
        destroyConfirmation: '',
        selectedContainer: undefined,
      })
      notification.error({
        message: 'An error occurred while destroying the container.',
      })
      return
    }

    this.setState({
      selectedContainer: {
        ...this.state.selectedContainer,
        processingAction: true,
      },
    })

    try {
      await this.props.apiClient.removeConnection(
        this.state.selectedContainer.id
      )

      notification.success({
        message: 'Your container will be destroyed.',
      })
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while destroying the container.',
        description: e,
      })
      focusOnElement(ContainerLabel.DestroyConfirmation)
    } finally {
      this.setState({
        destroyModalVisible: false,
        destroyConfirmation: '',
        selectedContainer: undefined,
      })
    }
  }

  private handleLeaveShare = async () => {
    if (!this.state.selectedShare) {
      this.setState({
        leaveShareModalVisible: false,
        selectedShare: undefined,
      })
      notification.error({
        message: 'An error occurred while leaving the shared container.',
      })
      return
    }

    try {
      this.setState({
        selectedShare: {
          ...this.state.selectedShare,
          processingAction: true,
        },
      })

      await this.props.shareApiClient.leaveShare(this.state.selectedShare.id)
      notification.success({
        message: `You are no longer collaborating on ${this.state.selectedShare.containerName}.`,
      })
      await this.props.refreshSharesList()
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while leaving the shared container.',
        description: e.response ? e.response.data.message : String(e),
      })
    } finally {
      this.setState({
        leaveShareModalVisible: false,
        selectedShare: undefined,
      })
    }
  }

  private getContainerGeneralInfo = async (containerId: number) => {
    try {
      this.setState({ loadingModalGeneralInfo: true })
      const info = await this.props.apiClient.getContainerInfo(containerId)

      const md = marked.parse(info)
      const sanitized = DOMPurify.sanitize(md)

      return sanitized
    } catch (e: any) {
      notification.error({
        message:
          'An error occurred while getting the container general information.',
        description: e,
      })
      return ''
    } finally {
      this.setState({ loadingModalGeneralInfo: false })
    }
  }

  private handleStartContainer = async (containerId: number) => {
    await this.props.apiClient.startContainer(containerId)
  }

  private handleStopContainer = async (containerId: number) => {
    await this.props.apiClient.stopContainer(containerId)
  }

  private handleChangeAlwaysOn = async (
    containerId: number,
    newToggleStatus: boolean
  ) => {
    try {
      await this.props.apiClient.setContainerAlwaysOn(
        containerId,
        newToggleStatus
      )
      await this.props.refreshDevboxes()
      await this.props.refreshAccountDetails()
    } catch (e) {
      this.setState({ alwaysOnLimitDialogVisible: true })
    }
  }

  private applyDevboxesFilter() {
    let filteredDevboxes: (Container | Share)[] = []
    const containers = this.props.containers.slice().reverse()
    const shares = this.props.shares.slice().reverse()

    if (this.state.activeFilter === DevboxFilter.Created) {
      return filteredDevboxes.concat(...containers)
    } else if (this.state.activeFilter === DevboxFilter.SharedWithMe) {
      return filteredDevboxes.concat(...shares)
    } else {
      return filteredDevboxes.concat(...containers).concat(...shares)
    }
  }

  private parseDevboxesFilterFromUrl() {
    const query = parseQs(window.location.search.slice(1))

    if (query['containers-filter']) {
      this.setState({
        activeFilter: query['containers-filter'] as DevboxFilter,
      })
    }

    return query
  }

  private handleChangeDevboxesFilter = (activeFilter: DevboxFilter) => {
    const currentQuery = this.parseDevboxesFilterFromUrl()
    const connectionsQuery = currentQuery['connections-filter']
      ? `connections-filter=${currentQuery['connections-filter']}`
      : ''

    const newQuery =
      !document.location.search || !connectionsQuery
        ? `?containers-filter=${activeFilter}`
        : `?containers-filter=${activeFilter}&${connectionsQuery}`

    window.history.replaceState(
      {},
      '',
      `${document.location.pathname}${newQuery}`
    )
    this.setState({ activeFilter })
  }

  private isContainer = (object: Container | Share) => {
    return (object as Container).type !== undefined
  }
}
