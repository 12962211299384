import {
  CardElement,
  useRecurly,
  UseRecurlyInstance,
} from '@recurly/react-recurly'
import React from 'react'
import { BillingInfo } from '../api-client/interface/BillingInfo'
import { Button } from './Button'
import { CountrySelectInput } from './CountrySelectInput'
import { BillingLabel, Input } from './Input'

type Props = {
  billingInfo: BillingInfo
  loading: boolean
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  setRecurly: (recurly: UseRecurlyInstance) => void
  collapseForm: () => void
  onBillingInfoInputChange: (dataKey: string, value: string) => void
  onAddressInputChange: (dataKey: string, value: string) => void
  onCountrySelectChange: (value: string) => void
}

export function UpdateBillingInfoForm(props: Props) {
  const {
    billingInfo,
    loading,
    onSubmit,
    setRecurly,
    collapseForm,
    onBillingInfoInputChange,
    onAddressInputChange,
    onCountrySelectChange,
  } = props

  const recurly = useRecurly()
  setRecurly(recurly)

  return (
    <form className="update-payment-method" onSubmit={onSubmit}>
      <input
        type="hidden"
        name="recurly-token"
        data-recurly="token"
        value=""
      ></input>
      <div className="input-wrapper credit-card-info">
        <label className="input-label required">Credit card information</label>
        <CardElement inputType="select" />
      </div>
      <div className="two-inputs-container">
        <Input
          label={BillingLabel.FirstName}
          value={billingInfo.firstName || ''}
          required={true}
          dataRecurlyKey="first_name"
          onChangeInput={value => onBillingInfoInputChange('firstName', value)}
        />
        <Input
          label={BillingLabel.LastName}
          value={billingInfo.lastName || ''}
          required={true}
          dataRecurlyKey="last_name"
          onChangeInput={value => onBillingInfoInputChange('lastName', value)}
        />
      </div>
      <Input
        label={BillingLabel.Address1}
        value={billingInfo.address?.street1 || ''}
        dataRecurlyKey="address1"
        onChangeInput={value => onAddressInputChange('street1', value)}
      />
      <Input
        label={BillingLabel.Address2}
        value={billingInfo.address?.street2 || ''}
        dataRecurlyKey="address2"
        onChangeInput={value => onAddressInputChange('street2', value)}
      />
      <div className="two-inputs-container">
        <Input
          label={BillingLabel.City}
          value={billingInfo.address?.city || ''}
          dataRecurlyKey="city"
          onChangeInput={value => onAddressInputChange('city', value)}
        />
        <Input
          label={BillingLabel.ZipCode}
          value={billingInfo.address?.postalCode || ''}
          dataRecurlyKey="postal_code"
          onChangeInput={value => onAddressInputChange('postalCode', value)}
        />
      </div>
      <div className="two-inputs-container">
        <CountrySelectInput
          value={billingInfo?.address?.country || ''}
          onChangeInput={value => onCountrySelectChange(value)}
        />
        <Input
          label={BillingLabel.State}
          value={billingInfo?.address?.region || ''}
          dataRecurlyKey="state"
          onChangeInput={value => onAddressInputChange('region', value)}
        />
      </div>
      <div className="actions">
        <Button
          value="Save"
          type="success"
          submit={true}
          disabled={loading}
          loading={loading}
        />
        <Button value="Cancel" onClick={collapseForm} />
      </div>
    </form>
  )
}
