import React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { Page } from '../App'
import { Button } from '../components/Button'
import { ILLUSTRATION } from '../helpers/image-imports/Illustration'
import { triggerOpenedScreenEvent } from '../helpers/Util'

type Props = {
  accountDetails: AccountDetails
  onDidLoadInitContent: () => void
}

export class InvalidPage extends React.Component<Props> {
  componentDidMount() {
    this.props.onDidLoadInitContent()

    triggerOpenedScreenEvent(
      'openedInvalidScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    return (
      <div className="page-content">
        <div className="page-ilustration">
          <div className="title">Oops! Why are you here?</div>
          <div className="subtitle">
            We're sorry, the page you are looking for could not be found or no
            longer exists.
          </div>
          <Button
            value="Back to home"
            onClick={() => {
              window.location.href = Page.Home
            }}
          />
          <img src={ILLUSTRATION['PageNotFound']} alt="Page not found" />
        </div>
      </div>
    )
  }
}
