import React from 'react'

type Props = {
  paypalAgreement?: string
  amazonPayAgreement?: string
  type?: string
  inCheckout: boolean
  expanded?: boolean
  billingInfoEmpty?: boolean
}

export class BillingAgreementNotice extends React.Component<Props> {
  render() {
    const paymentMethod =
      this.props.type === 'paypal'
        ? 'PayPal'
        : this.props.type === 'apple_pay'
        ? 'Apple Pay'
        : this.props.type === 'amazon_pay'
        ? 'Amazon Pay'
        : 'Venmo'

    return (
      <>
        <div
          className={
            this.props.inCheckout
              ? 'ant-col ant-col-24 payment-information-saved__field paypal-notice'
              : 'paypal-notice'
          }
          style={{
            display:
              this.props.paypalAgreement &&
              !this.props.expanded &&
              !this.props.billingInfoEmpty
                ? 'block'
                : 'none',
            marginTop: '5px',
          }}
        >
          You have a PayPal billing agreement to pay for your subscription. For
          more information about your PayPal billing agreement and to review
          preapproved payments,{' '}
          <a href="https://www.paypal.com/cgi-bin/customerprofileweb?cmd=_manage-paylist">
            visit your Profile on the PayPal website
          </a>
        </div>
        <div
          className={
            this.props.inCheckout
              ? 'ant-col ant-col-24 payment-information-saved__field paypal-notice'
              : 'paypal-notice'
          }
          style={{
            display:
              this.props.type === 'amazon_pay' &&
              !this.props.expanded &&
              !this.props.billingInfoEmpty
                ? 'block'
                : 'none',
            marginTop: '5px',
          }}
        >
          You have an Amazon Pay billing agreement to pay for your subscription.
          For more information about your Amazon Pay billing agreement and to
          review preapproved payments, visit your Profile on the Amazon Pay
          website
        </div>
        <div
          className={
            this.props.inCheckout
              ? 'ant-col ant-col-24 payment-information-saved__field paypal-notice'
              : 'paypal-notice'
          }
          style={{
            display:
              (this.props.type === 'apple_pay' ||
                this.props.type === 'venmo') &&
              !this.props.expanded &&
              !this.props.billingInfoEmpty
                ? 'block'
                : 'none',
            marginTop: '-10px',
          }}
        >
          {`Your billing information is attached to your ${paymentMethod} account.
          For more information, visit your Profile on the ${paymentMethod} website`}
        </div>
      </>
    )
  }
}
