import * as React from 'react'
import { PlanPeriodAbbreviation } from '../containers/CheckoutStep1Container'
import { AddonNumberPicker } from './AddonNumberPicker'

type Props = {
  planPeriod: PlanPeriodAbbreviation
  title: string
  addonKey?: string
  monthlyPrice: number
  discount: string
  iconSrc: string
  iconAlt: string
  addonNumber?: number
  onAddonNumberChange?: (addonNumber: number) => void
}

export class CheckoutSummaryItem extends React.PureComponent<Props> {
  render() {
    const planPeriodText =
      this.props.planPeriod === PlanPeriodAbbreviation.MONTHLY
        ? 'Monthly plan'
        : this.props.planPeriod === PlanPeriodAbbreviation.YEARLY
        ? 'Yearly plan'
        : '2-Year plan'
    const priceMultiplier =
      this.props.planPeriod === PlanPeriodAbbreviation.MONTHLY
        ? 1
        : this.props.planPeriod === PlanPeriodAbbreviation.YEARLY
        ? 12
        : 24

    return (
      <div className="summary-holder">
        <div className="summary">
          <div className="summary__icon">
            <img src={this.props.iconSrc} alt={this.props.iconAlt} />
          </div>
          <div className="summary__content">
            <div>
              <div className="summary__info">
                <div className="summary__description">
                  <h3 className="summary__title">{this.props.title}</h3>
                  <p className="summary__description">
                    {planPeriodText} (${this.props.monthlyPrice.toFixed(2)}/mo)
                  </p>
                </div>
                <div className="summary__details">
                  {this.props.addonNumber && this.props.onAddonNumberChange ? (
                    <AddonNumberPicker
                      addonNumber={this.props.addonNumber}
                      onAddonNumberChange={this.props.onAddonNumberChange}
                      disabled={this.props.monthlyPrice === -1}
                      addonKey={this.props.addonKey}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="summary__price">
                  $
                  {(
                    this.props.monthlyPrice *
                    priceMultiplier *
                    (this.props.addonNumber || 1)
                  ).toFixed(2)}
                </div>
              </div>
              <div
                className="summary__discount"
                style={{
                  display: this.props.discount !== '0' ? 'flex' : 'none',
                }}
              >
                <div className="summary-discount__badge">
                  Discount -{parseFloat(this.props.discount).toFixed(2)}%
                </div>
                <del className="summary-discount__amount">
                  $
                  {(
                    ((this.props.monthlyPrice * priceMultiplier) /
                      (100 - parseFloat(this.props.discount))) *
                    100
                  ).toFixed(2)}
                </del>
              </div>
            </div>
          </div>
        </div>
        {this.props.onAddonNumberChange && (
          <div className="checkout-action">
            <span onClick={() => this.props.onAddonNumberChange!(0)}>
              Remove
            </span>
          </div>
        )}
      </div>
    )
  }
}
