import { PlanPeriodAbbreviation } from '../../containers/CheckoutStep1Container'

export type PricingPlanFeature = {
  name: string
  value: string
}
export interface PricingPlan {
  code: string
  name: string
  price: {
    month: number
    year: number
    biyear: number
  }
  limits: {
    container: number
    alwayson: number
    ramPerContainer: number
    hddPerContainer: number
    remote: number
    teamLicence: number
    customDomains: number
  }

  legacy: boolean
}

export const ComparePlans = (p1: string, p2: string) => {
  if (!p2) {
    return 1
  }

  if (p2.includes(p1)) {
    return 0
  }

  if (p1.startsWith('free') && !p1.startsWith('freelancer')) {
    return -1
  }

  if (p1.startsWith('s')) {
    return -1
  }

  if (p1.startsWith('f')) {
    if (p2.startsWith('s')) {
      return 1
    } else {
      return -1
    }
  }

  if (p1.startsWith('p')) {
    return 1
  }

  return 0
}

export const ComparePlansNew = (p1: string, p2: PricingPlanCode) => {
  if (p1.includes('basic_buy')) {
    if (p2 === 'basic_buy') {
      return 0
    }

    return -1
  }

  if (p1.includes('standard_buy')) {
    if (p2 === 'basic_buy') {
      return 1
    }

    if (p2 === 'standard_buy') {
      return 0
    }

    return -1
  }

  if (p2 === 'premium_buy') {
    return 0
  }

  return 1
}

export const PRICING_PLANS: PricingPlan[] = [
  {
    code: 'free_buy',
    name: 'Free',
    legacy: false,
    limits: {
      alwayson: 0,
      container: 1,
      customDomains: 1,
      hddPerContainer: 3,
      ramPerContainer: 2,
      remote: 5,
      teamLicence: 0,
    },
    price: {
      month: 0,
      year: 0,
      biyear: 0,
    },
  },
  {
    code: 'starter_buy',
    name: 'Starter',
    legacy: false,
    limits: {
      alwayson: 0,
      container: 1,
      customDomains: 1,
      hddPerContainer: 3,
      ramPerContainer: 2,
      remote: 5,
      teamLicence: 0,
    },
    price: {
      month: 3,
      year: 2.5,
      biyear: 2,
    },
  },
  {
    code: 'freelancer_buy',
    name: 'Freelancer',
    legacy: false,
    limits: {
      alwayson: 1,
      container: 2,
      customDomains: 2,
      hddPerContainer: 10,
      ramPerContainer: 4,
      remote: 50,
      teamLicence: 20,
    },
    price: {
      month: 10,
      year: 8,
      biyear: 6,
    },
  },
  {
    code: 'professional_buy',
    name: 'Professional',
    legacy: false,
    limits: {
      alwayson: 2,
      container: 4,
      customDomains: 4,
      hddPerContainer: 20,
      ramPerContainer: 8,
      remote: 1000,
      teamLicence: 50,
    },
    price: {
      month: 25,
      year: 20,
      biyear: 15,
    },
  },
]

export const PRICING_PLANS_NEW: PricingPlan[] = [
  {
    code: 'basic_buy',
    name: 'Basic',
    legacy: false,
    limits: {
      alwayson: 0,
      container: 1,
      customDomains: 1,
      hddPerContainer: 3,
      ramPerContainer: 1,
      remote: 5,
      teamLicence: 0,
    },
    price: {
      month: 6,
      year: 4.8,
      biyear: 3.6,
    },
  },
  {
    code: 'standard_buy',
    name: 'Standard',
    legacy: false,
    limits: {
      alwayson: 1,
      container: 3,
      customDomains: 2,
      hddPerContainer: 10,
      ramPerContainer: 4,
      remote: 50,
      teamLicence: 20,
    },
    price: {
      month: 15,
      year: 12,
      biyear: 9,
    },
  },
  {
    code: 'premium_buy',
    name: 'Premium',
    legacy: false,
    limits: {
      alwayson: 2,
      container: 6,
      customDomains: 4,
      hddPerContainer: 20,
      ramPerContainer: 8,
      remote: 1000,
      teamLicence: 50,
    },
    price: {
      month: 40,
      year: 32,
      biyear: 24,
    },
  },
]

export type PricingPlanCode = 'basic_buy' | 'standard_buy' | 'premium_buy'

export const calculateDiscount = (
  plan: PricingPlan,
  planPeriod: PlanPeriodAbbreviation
) => {
  if (planPeriod === PlanPeriodAbbreviation.MONTHLY) {
    return '0'
  }

  return (
    (1 -
      (planPeriod === PlanPeriodAbbreviation.YEARLY
        ? plan.price.year
        : plan.price.biyear) /
        plan.price.month) *
    100
  ).toFixed(0)
}

export const calculateBiYearDiscountPrice = (
  plan: PricingPlan,
  planPeriod: PlanPeriodAbbreviation
) => {
  switch (planPeriod) {
    case PlanPeriodAbbreviation.MONTHLY:
      return plan.price.month * 24
    case PlanPeriodAbbreviation.YEARLY:
      return plan.price.year * 24
    case PlanPeriodAbbreviation.BIYEARLY:
      return plan.price.biyear * 24
  }
}
