import * as React from 'react'

export enum InputType {
  Text = 'text',
  Password = 'password',
  Email = 'email',
  Number = 'number',
}

export enum AccountLabel {
  Email = 'Email',
  ChangeEmailConfirmation = 'Enter password to confirm your new email address',
  Username = 'Username',
  FirstName = 'First name',
  LastName = 'Last name',
  CompanyName = 'Company name',
  OldPassword = 'Old password',
  NewPassword = 'New password',
  RepeatNewPassword = 'Repeat new password',
  UsernameConfirmation = 'Please enter the account username to confirm',
  DeleteAccountConfirmation = 'Enter your password to confirm deleting your account',
  VerificationCode = 'Enter your verification code',
  AccountNameVerification = 'Please enter your account name to confirm',
}

export enum ConnectionLabel {
  Name = 'Connection name',
  Type = 'Type',
  Host = 'Host name',
  Password = 'Password',
  PrivateKey = 'Private key',
  Username = 'Username',
  Timeout = 'Timeout',
  Port = 'Port',
  WebURL = 'Web URL',
  InitialDir = 'Initial directory',
  RootDir = 'Root directory',
  RemoveConfirmation = 'Please enter the connection name to confirm',
}

export enum ContainerLabel {
  Name = 'Container name',
  InviteeEmail = 'Email address',
  DestroyConfirmation = 'Please enter the container name to confirm',
  DomainName = 'Domain',
  DomainInternalPort = 'Internal port',
  DomainExternalPort = 'External port',
}

export enum CustomTemplateLabel {
  Name = 'Name',
  Description = 'Description',
  RemoveConfirmation = 'Please enter the template name to confirm',
}

export enum TeamLabel {
  ChildAccountEmail = 'Email',
}

export enum BillingLabel {
  CardNumber = 'Credit card number',
  ExpirationDate = 'Expiration date',
  CVV = 'CVV',
  FirstName = 'First name',
  LastName = 'Last name',
  Address1 = 'Address 1',
  Address2 = 'Address 2',
  City = 'City',
  ZipCode = 'Postal/Zip code',
  Country = 'Country',
  State = 'State/Province',
}

type Props = {
  label: string
  value: any
  placeholder?: string
  required?: boolean
  type?: InputType
  autofocus?: boolean
  collapsed?: boolean
  disabled?: boolean
  hide?: boolean
  small?: boolean

  whiteBackground?: boolean
  dataRecurlyKey?: string

  onChangeInput: (input: any) => void
}

export class Input extends React.PureComponent<Props> {
  render() {
    if (this.props.hide) {
      return null
    }

    return (
      <div
        className={`input-wrapper${
          this.props.disabled && !this.props.collapsed ? ' disabled' : ''
        }${this.props.small && !this.props.collapsed ? ' small' : ''}`}
      >
        <label
          htmlFor={this.props.label}
          className={`input-label${this.props.required ? ' required' : ''}`}
        >
          {this.props.label}
        </label>
        {this.props.collapsed ? (
          <span className="collapsed-input">{this.props.value}</span>
        ) : (
          <input
            type={this.props.type ? this.props.type : InputType.Text}
            id={this.props.label}
            className={`text-input${
              this.props.whiteBackground ? ' white' : ''
            }`}
            value={this.props.value}
            placeholder={
              this.props.placeholder ? this.props.placeholder : undefined
            }
            required={this.props.required}
            autoFocus={this.props.autofocus}
            disabled={this.props.disabled}
            data-recurly={
              this.props.dataRecurlyKey ? this.props.dataRecurlyKey : undefined
            }
            minLength={
              this.props.type === InputType.Password &&
              this.props.label !== ConnectionLabel.Password
                ? 8
                : undefined
            }
            onChange={e => this.props.onChangeInput(e.target.value)}
            onFocus={e => e.target.select()}
          />
        )}
      </div>
    )
  }
}
