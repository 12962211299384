import moment from 'moment'
import * as React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { Subscription } from '../api-client/interface/Subscription'
import { Page } from '../App'
import { convertMemoryToGB } from '../helpers/Util'
import { Button } from './Button'
import { PlanBenefit } from './PlanBenefit'
import { PlanStatusTab } from './PlanStatusTab'

export const PLAN_DESCRIPTIONS: { [x: string]: string } = {
  Free: 'Upgrade your subscription to unlock features.',
  Basic: 'Basic plan with limited capabilties',
  Standard: 'Starter plan with standard features.',
  Premium: 'Premium package with advanced features',
}

type Props = {
  subscription: Subscription | null
  accountDetails: AccountDetails
}

export class CurrentPlan extends React.Component<Props> {
  render() {
    const { planname, limits } = { ...this.props.accountDetails }

    return (
      <>
        <div className="current-plan-info">
          <div>
            <div className="general">
              <span className="plan-name">{planname}</span>
              <span className="plan-description">
                {PLAN_DESCRIPTIONS[planname]}
              </span>
            </div>
            <div className="benefits">
              <PlanBenefit
                benefit={`RAM: ${convertMemoryToGB(limits['devbox-ram'])}GB`}
              />
              <PlanBenefit
                benefit={`HDD: ${convertMemoryToGB(limits['devbox-hdd'])}GB`}
              />
              <PlanBenefit benefit="Tech support" />
            </div>
          </div>
          {!this.props.accountDetails.child_account && (
            <div className="actions">
              <Button
                value="Compare plans"
                onClick={() =>
                  window
                    .open('https://codeanywhere.com/pricing', '_blank')
                    ?.focus()
                }
              />
              <Button
                value={`${
                  this.props.accountDetails.planname === 'Free'
                    ? 'Upgrade'
                    : 'Manage'
                }`}
                type="success"
                onClick={() => {
                  window.location.href = Page.Checkout
                }}
              />
            </div>
          )}
        </div>
        {this.props.subscription && (
          <div className="subscription-status">
            <PlanStatusTab
              title="Status"
              value={
                this.props.subscription.state.charAt(0).toUpperCase() +
                this.props.subscription.state.slice(1)
              }
            />
            <PlanStatusTab
              title="Start date"
              value={moment(
                this.props.subscription.current_period_started_at
              ).format('MMM Do YYYY')}
            />
            <PlanStatusTab
              title="Recurring date"
              value={moment(
                this.props.subscription.current_period_ends_at
              ).format('MMM Do YYYY')}
            />
          </div>
        )}
      </>
    )
  }
}
