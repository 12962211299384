import * as React from 'react'
import { ToggleSwitch } from './ToggleSwitch'
import { QuestionMarkTooltip } from './QuestionMarkTooltip'

type Props = {
  id: string
  toggled: boolean
  loading?: boolean
  onChangeAlwaysOn: () => Promise<void> | void
  disabled?: number
}

export class AlwaysOn extends React.PureComponent<Props> {
  render() {
    return (
      <div className="always-on">
        <ToggleSwitch
          id={this.props.id}
          label="Always-On"
          toggled={this.props.toggled}
          disabled={!!this.props.disabled}
          loading={this.props.loading}
          onChangeToggleState={this.props.onChangeAlwaysOn}
        />
        <QuestionMarkTooltip content="Regular containers will be stopped when you sign out. Containers with the applied “Always-On” option will keep running even if you’re not working." />
      </div>
    )
  }
}
