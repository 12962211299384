import * as React from 'react'
import { Modal } from './Modal'
import { Button } from './Button'
import { Page } from '../App'
import { AccountDetails } from '../api-client/interface/AccountDetails'

type Props = {
  visible: boolean
  onFreeTrial: boolean
  checkSubscription: () => void
  accountDetails: AccountDetails
}

type State = {}

export class SubscriptionExpiredModal extends React.Component<Props, State> {
  private onClick = () => {
    const checkout = window.open(Page.Checkout, '_blank')

    if (checkout) {
      checkout.onunload = () => {
        this.props.checkSubscription()
      }
    }
  }

  render() {
    if (!this.props.visible) return null

    // TODO: Raspravit o dizajnu ovoga
    return (
      <Modal visible={true} closeable={false} className={'expired-modal'}>
        <Modal.Header>
          <Modal.Title>
            {this.props.onFreeTrial
              ? 'Trial expired!'
              : 'Subscription expired!'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.prepareContent()}</Modal.Body>
        {!this.props.accountDetails.child_account && (
          <Modal.Footer>
            <Button
              type="primary"
              value={'Upgrade'}
              onClick={this.onClick}
              autoFocus={true}
            />
          </Modal.Footer>
        )}
      </Modal>
    )
  }

  private prepareContent() {
    const content = `Your ${
      this.props.onFreeTrial ? 'trial' : 'subscription'
    } has expired. To continue using Codeanywhere,${
      this.props.accountDetails.child_account &&
      this.props.accountDetails.admin_email
        ? ` please contact ${this.props.accountDetails.admin_email} to`
        : ''
    } upgrade to one of our subscription plans.`

    return content
  }
}
