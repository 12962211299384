import * as React from 'react'
import { notification } from '../components/Notification'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Tooltip, TooltipPosition } from '../components/Tooltip'
import { CONTAINER_STATE_COLOR } from '../helpers/ContainerStateColor'
import { CONTAINER_STATE } from '../helpers/ContainerState'
import {
  HDDIcon,
  RAMIcon,
  PeopleIcon,
} from '../helpers/image-imports/IconComponent'
import { AlwaysOn } from '../components/AlwaysOn'
import { ContainerStateIndicator } from '../components/ContainerStateIndicator'
import { Truncate } from '../components/Truncate'
import { Container } from './ContainersList'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import {
  convertMemoryToGB,
  getTemplateIconSourceUrl,
  getTemplateTooltip,
  onKey,
} from '../helpers/Util'
import { CardOption, CardOptions } from '../components/CardOptions'
import { Page } from '../App'
import { OpenIDEButton } from '../components/OpenIDEButton'
const { Option, Separator } = CardOptions

type Props = {
  apiClient: ApiClient
  container: Container
  accountDetails: AccountDetails
  onStartContainer: (containerId: number) => Promise<void>
  onStopContainer: (containerId: number) => Promise<void>
  onChangeAlwaysOn: (containerId: number, alwaysOn: boolean) => Promise<void>
  displayModal: (
    option: CardOption,
    container: Container
  ) => void | Promise<void>
  switchPage: (page: string) => void
}

type State = {
  processingAlwaysOnChange: boolean
}

export class ContainerCard extends React.Component<Props, State> {
  public state: State = {
    processingAlwaysOnChange: false,
  }

  render() {
    const { usage, limits } = { ...this.props.accountDetails }
    const remainingCustomTemplates =
      limits['custom-stacks']! - usage['custom-stacks']!

    const containerStarted = this.props.container.state === 10
    const containerDestroyed =
      this.props.container.state >= 90 && this.props.container.state <= 93

    const openIDEEnabled =
      (this.props.container.state >= 10 && this.props.container.state <= 13) ||
      this.props.container.state === 20 ||
      this.props.container.state === 80

    return (
      <div className={`card ${containerDestroyed ? ' card--is-disabled' : ''}`}>
        <div className="card-header">
          <ContainerStateIndicator
            state={CONTAINER_STATE[this.props.container.state]}
            color={CONTAINER_STATE_COLOR(this.props.container.state)}
          />
          <div className="card-title">
            <Truncate>{this.props.container.name}</Truncate>
          </div>
          <div className="card-options">
            <CardOptions>
              <Option
                onClick={() =>
                  this.props.displayModal(
                    CardOption.Rename,
                    this.props.container
                  )
                }
              >
                {CardOption.Rename}
              </Option>
              <Option
                onClick={() =>
                  this.props.displayModal(
                    CardOption.Collaborators,
                    this.props.container
                  )
                }
                disabled={this.props.container.processingAction}
              >
                {CardOption.Collaborators}
              </Option>
              <Option
                onClick={() =>
                  this.props.displayModal(
                    CardOption.CreateTemplate,
                    this.props.container
                  )
                }
                disabled={
                  this.props.container.processingAction ||
                  remainingCustomTemplates <= 0
                }
              >
                {CardOption.CreateTemplate}
              </Option>
              <Option
                onClick={() =>
                  this.props.switchPage(
                    `${Page.Domains}?id=${this.props.container.id}`
                  )
                }
                disabled={this.props.container.processingAction}
              >
                {CardOption.Domains}
              </Option>
              <Separator />
              <Option
                onClick={this.handleStopContainer}
                disabled={!containerStarted}
              >
                {CardOption.Stop}
              </Option>
              <Option onClick={this.handleRestartContainer}>
                {containerStarted
                  ? CardOption.Restart
                  : CardOption.ForceRestart}
              </Option>
              <Option
                onClick={() =>
                  this.props.displayModal(
                    CardOption.Destroy,
                    this.props.container
                  )
                }
              >
                {CardOption.Destroy}
              </Option>
              <Separator />
              <Option
                onClick={() =>
                  this.props.displayModal(CardOption.Info, this.props.container)
                }
                disabled={this.props.container.processingAction}
              >
                {CardOption.Info}
              </Option>
            </CardOptions>
          </div>
        </div>
        <div className="container-always-on">
          <AlwaysOn
            id={`${this.props.container.id}-always-on`}
            toggled={this.props.container.alwaysOn === 1}
            loading={this.state.processingAlwaysOnChange}
            onChangeAlwaysOn={() =>
              this.onChangeAlwaysOn(
                this.props.container.id,
                this.props.container.alwaysOn !== 1
              )
            }
          />
          <div
            className="collaborators tab-focus"
            onClick={() =>
              this.props.displayModal(
                CardOption.Collaborators,
                this.props.container
              )
            }
            onKeyDown={e =>
              onKey(e, 'Enter', () =>
                this.props.displayModal(
                  CardOption.Collaborators,
                  this.props.container
                )
              )
            }
            onKeyUp={e =>
              onKey(e, ' ', () =>
                this.props.displayModal(
                  CardOption.Collaborators,
                  this.props.container
                )
              )
            }
          >
            <Tooltip position={TooltipPosition.Top} content="Collaborators">
              <PeopleIcon />
              <span>
                {' '}
                {
                  1 +
                    this.props.container.collaborators.length +
                    this.props.container.invites
                      .length /*owner+collaborators+invites*/
                }
              </span>
            </Tooltip>
          </div>
        </div>

        <div className="container-info">
          <div className="container-resources">
            <Tooltip position={TooltipPosition.Top} content="HDD">
              <div className="container-resource">
                <HDDIcon />
                <span>{convertMemoryToGB(limits['devbox-hdd'])}GB</span>
              </div>
            </Tooltip>
            <Tooltip position={TooltipPosition.Top} content="RAM">
              <div className="container-resource">
                <RAMIcon />
                <span>{convertMemoryToGB(limits['devbox-ram'])}GB</span>
              </div>
            </Tooltip>
          </div>
          <Tooltip
            position={TooltipPosition.Left}
            content={getTemplateTooltip(this.props.container.stack)}
          >
            <img
              src={getTemplateIconSourceUrl(this.props.container.stack)}
              className="container-card-template"
              alt="Stack logo"
            />
          </Tooltip>
        </div>
        <OpenIDEButton
          enabled={openIDEEnabled}
          onClick={() => {
            if (!containerDestroyed) {
              this.handleContainerActionButtonClick()
            }
          }}
        />
      </div>
    )
  }

  private handleContainerActionButtonClick = async () => {
    if (
      this.props.container.id <= 2427937 &&
      process.env.REACT_APP_API_BASE_URL !== undefined &&
      !String(process.env.REACT_APP_API_BASE_URL).includes('web2014')
    ) {
      window.open(String(process.env.REACT_APP_EDITOR_URL))
      return
    }

    switch (this.props.container.state) {
      case 10:
      case 11:
      case 12:
      case 13:
        this.openIDE()
        break
      case 20:
      case 80:
        await this.handleStartContainer()
        this.openIDE()
        break
      default:
        break
    }
  }

  private openIDE = () => {
    const container = this.props.container

    window.open(
      String(
        container.slug
          ? `${process.env.REACT_APP_IDE_URL}/${container.slug.toLowerCase()}`
          : process.env.REACT_APP_EDITOR_URL
      )
    )
  }

  private handleStartContainer = async () => {
    try {
      await this.props.onStartContainer(this.props.container.id)
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while starting the container.',
        description: e,
      })
    }
  }

  private handleStopContainer = async () => {
    try {
      await this.props.apiClient.stopContainer(this.props.container.id)
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while stopping the container.',
        description: e,
      })
    }
  }

  private handleRestartContainer = async () => {
    try {
      await this.props.apiClient.restartContainer(this.props.container.id)
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while restarting the container.',
        description: e,
      })
    }
  }

  private onChangeAlwaysOn = async (
    containerId: number,
    newToggleStatus: boolean
  ) => {
    try {
      this.setState({ processingAlwaysOnChange: true })
      await this.props.onChangeAlwaysOn(containerId, newToggleStatus)
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while restarting the container.',
        description: e,
      })
    } finally {
      this.setState({ processingAlwaysOnChange: false })
    }
  }
}
