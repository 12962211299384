/* eslint-disable no-func-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-sequences */
/* eslint-disable strict */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
export const PayWithAmazon = (function (t) {
  var e = {}

  function n(o) {
    if (e[o]) return e[o].exports
    var i = (e[o] = {
      i: o,
      l: !1,
      exports: {},
    })
    return t[o].call(i.exports, i, i.exports, n), (i.l = !0), i.exports
  }
  return (
    (n.m = t),
    (n.c = e),
    (n.d = function (t, e, o) {
      n.o(t, e) ||
        Object.defineProperty(t, e, {
          enumerable: !0,
          get: o,
        })
    }),
    (n.r = function (t) {
      'undefined' != typeof Symbol &&
        Symbol.toStringTag &&
        Object.defineProperty(t, Symbol.toStringTag, {
          value: 'Module',
        }),
        Object.defineProperty(t, '__esModule', {
          value: !0,
        })
    }),
    (n.t = function (t, e) {
      if ((1 & e && (t = n(t)), 8 & e)) return t
      if (4 & e && 'object' == typeof t && t && t.__esModule) return t
      var o = Object.create(null)
      if (
        (n.r(o),
        Object.defineProperty(o, 'default', {
          enumerable: !0,
          value: t,
        }),
        2 & e && 'string' != typeof t)
      )
        for (var i in t)
          n.d(
            o,
            i,
            function (e) {
              return t[e]
            }.bind(null, i)
          )
      return o
    }),
    (n.n = function (t) {
      var e =
        t && t.__esModule
          ? function () {
              return t.default
            }
          : function () {
              return t
            }
      return n.d(e, 'a', e), e
    }),
    (n.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e)
    }),
    (n.p = '/build/'),
    n((n.s = 1))
  )
})([
  function (t, e, n) {
    function o(t) {
      if (t)
        return (function (t) {
          for (var e in o.prototype) t[e] = o.prototype[e]
          return t
        })(t)
    }
    ;(t.exports = o),
      (o.prototype.on = o.prototype.addEventListener =
        function (t, e) {
          return (
            (this._callbacks = this._callbacks || {}),
            (this._callbacks['$' + t] = this._callbacks['$' + t] || []).push(e),
            this
          )
        }),
      (o.prototype.once = function (t, e) {
        function n() {
          this.off(t, n), e.apply(this, arguments)
        }
        return (n.fn = e), this.on(t, n), this
      }),
      (o.prototype.off =
        o.prototype.removeListener =
        o.prototype.removeAllListeners =
        o.prototype.removeEventListener =
          function (t, e) {
            if (
              ((this._callbacks = this._callbacks || {}), 0 == arguments.length)
            )
              return (this._callbacks = {}), this
            var n,
              o = this._callbacks['$' + t]
            if (!o) return this
            if (1 == arguments.length)
              return delete this._callbacks['$' + t], this
            for (var i = 0; i < o.length; i++)
              if ((n = o[i]) === e || n.fn === e) {
                o.splice(i, 1)
                break
              }
            return 0 === o.length && delete this._callbacks['$' + t], this
          }),
      (o.prototype.emit = function (t) {
        this._callbacks = this._callbacks || {}
        for (
          var e = new Array(arguments.length - 1),
            n = this._callbacks['$' + t],
            o = 1;
          o < arguments.length;
          o++
        )
          e[o - 1] = arguments[o]
        if (n) {
          o = 0
          for (var i = (n = n.slice(0)).length; o < i; ++o) n[o].apply(this, e)
        }
        return this
      }),
      (o.prototype.listeners = function (t) {
        return (
          (this._callbacks = this._callbacks || {}),
          this._callbacks['$' + t] || []
        )
      }),
      (o.prototype.hasListeners = function (t) {
        return !!this.listeners(t).length
      })
  },
  function (t, e, n) {
    'use strict'
    n.r(e),
      n.d(e, 'default', function () {
        return d
      })
    var o = n(0)

    function i(t) {
      return (i =
        'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
          ? function (t) {
              return typeof t
            }
          : function (t) {
              return t &&
                'function' == typeof Symbol &&
                t.constructor === Symbol &&
                t !== Symbol.prototype
                ? 'symbol'
                : typeof t
            })(t)
    }

    function r(t, e) {
      for (var n = 0; n < e.length; n++) {
        var o = e[n]
        ;(o.enumerable = o.enumerable || !1),
          (o.configurable = !0),
          'value' in o && (o.writable = !0),
          Object.defineProperty(t, o.key, o)
      }
    }

    function s(t, e) {
      return (s =
        Object.setPrototypeOf ||
        function (t, e) {
          return (t.__proto__ = e), t
        })(t, e)
    }

    function a(t) {
      var e = (function () {
        if ('undefined' == typeof Reflect || !Reflect.construct) return !1
        if (Reflect.construct.sham) return !1
        if ('function' == typeof Proxy) return !0
        try {
          return (
            Date.prototype.toString.call(
              Reflect.construct(Date, [], function () {})
            ),
            !0
          )
        } catch (t) {
          return !1
        }
      })()
      return function () {
        var n,
          o = u(t)
        if (e) {
          var i = u(this).constructor
          n = Reflect.construct(o, arguments, i)
        } else n = o.apply(this, arguments)
        return l(this, n)
      }
    }

    function l(t, e) {
      return !e || ('object' !== i(e) && 'function' != typeof e) ? c(t) : e
    }

    function c(t) {
      if (void 0 === t)
        throw new ReferenceError(
          "this hasn't been initialised - super() hasn't been called"
        )
      return t
    }

    function u(t) {
      return (u = Object.setPrototypeOf
        ? Object.getPrototypeOf
        : function (t) {
            return t.__proto__ || Object.getPrototypeOf(t)
          })(t)
    }
    var d = (function (t) {
      !(function (t, e) {
        if ('function' != typeof e && null !== e)
          throw new TypeError(
            'Super expression must either be null or a function'
          )
        ;(t.prototype = Object.create(e && e.prototype, {
          constructor: {
            value: t,
            writable: !0,
            configurable: !0,
          },
        })),
          e && s(t, e)
      })(d, t)
      var e,
        n,
        o,
        u = a(d)

      function d(t) {
        var e, n, o, i
        if (
          ((function (t, e) {
            if (!(t instanceof e))
              throw new TypeError('Cannot call a class as a function')
          })(this, d),
          (e = u.call(this)),
          (n = c(e)),
          (i = '2.1.0'),
          (o = 'version') in n
            ? Object.defineProperty(n, o, {
                value: i,
                enumerable: !0,
                configurable: !0,
                writable: !0,
              })
            : (n[o] = i),
          e.configure(t),
          (e.billingAgreementId = null),
          (e.consent = void 0),
          (e.widgets = {}),
          (e._status = e.status()),
          (e.setBillingAgreementId = e.setBillingAgreementId.bind(c(e))),
          (e.initWallet = e.initWallet.bind(c(e))),
          (e.initConsent = e.initConsent.bind(c(e))),
          (e.setConsent = e.setConsent.bind(c(e))),
          (e.error = e.error.bind(c(e))),
          (e.init = e.init.bind(c(e))),
          'OffAmazonPayments' in window)
        )
          e.init()
        else {
          window.onAmazonLoginReady = e.init
          var r = document.createElement('script')
          ;(r.src = e.assetPath), document.head.appendChild(r)
        }
        return l(e, c(e))
      }
      return (
        (e = d),
        (n = [
          {
            key: 'configure',
            value: function (t) {
              if ('object' !== i(t))
                throw new Error('opts must be provided as an object.')
              if (!t.sellerId) throw new Error('opts.sellerId is required.')
              if (!t.clientId) throw new Error('opts.clientId is required.')
              if (!t.button) throw new Error('opts.button is required.')
              if (!t.wallet) throw new Error('opts.wallet is required.')
              if (!t.consent) throw new Error('opts.consent is required.')
              'string' == typeof t.button &&
                (t.button = {
                  id: t.button,
                }),
                'string' == typeof t.wallet &&
                  (t.wallet = {
                    id: t.wallet,
                  }),
                'string' == typeof t.consent &&
                  (t.consent = {
                    id: t.consent,
                  }),
                'string' == typeof t.addressBook &&
                  (t.addressBook = {
                    id: t.addressBook,
                  }),
                'string' == typeof t.additionalLoginScope &&
                  (t.additionalLoginScope = [t.additionalLoginScope]),
                'login' === t.button.kind
                  ? (t.button.type =
                      'small' === t.button.type ? 'Login' : 'LwA')
                  : (t.button.type = 'small' === t.button.type ? 'Pay' : 'PwA'),
                (t.button.color = t.button.color || 'Gold'),
                (t.wallet.width || t.wallet.height) &&
                  (t.wallet.dimensions = {
                    width: f(t.wallet.width || 400),
                    height: f(t.wallet.height || 260),
                  }),
                (t.consent.width || t.consent.height) &&
                  (t.consent.dimensions = {
                    width: f(t.consent.width || 400),
                    height: f(t.consent.height || 140),
                  }),
                t.addressBook &&
                  (t.addressBook.width || t.addressBook.height) &&
                  (t.addressBook.dimensions = {
                    width: f(t.addressBook.width || 400),
                    height: f(t.addressBook.height || 260),
                  }),
                (t.production =
                  'boolean' == typeof t.production && t.production),
                (t.openedClass = t.openedClass || 'open'),
                (t.additionalLoginScope = t.additionalLoginScope || []),
                (this.config = t)
            },
          },
          {
            key: 'init',
            value: function () {
              window.amazon.Login.setClientId(this.config.clientId)
              var t = this,
                e = setInterval(function () {
                  window.OffAmazonPayments.Button &&
                    (clearTimeout(e), t.initButton())
                }, 200)
            },
          },
          {
            key: 'status',
            value: function () {
              var t = this.billingAgreementId,
                e = this.consent,
                n = {}
              return (
                t || (n.error = 'Billing agreement ID has not been set.'),
                void 0 !== e && (n.consent = e),
                void 0 === e
                  ? (n.error = 'Billing consent not yet given.')
                  : e || (n.error = 'Billing consent not given.'),
                n.error || (n.id = t),
                n
              )
            },
          },
          {
            key: 'check',
            value: function () {
              var t = this.status()
              JSON.stringify(t) !== JSON.stringify(this._status) &&
                ((this._status = t), this.emit('change', t))
            },
          },
          {
            key: 'initButton',
            value: function () {
              var t = this,
                e = this.config.button.type,
                n = this.config.button.color,
                o = ['profile', 'payments:widget', 'payments:shipping_address']
                  .concat(this.config.additionalLoginScope)
                  .join(' ')
              this.widgets.button = new window.OffAmazonPayments.Button(
                this.config.button.id,
                this.config.sellerId,
                {
                  type: e,
                  color: n,
                  authorization: function () {
                    var e = {
                      scope: o,
                      popup: !0,
                    }
                    window.amazon.Login.authorize(e, function (e) {
                      if (e.error) return t.error(e.error)
                      t.emit('login', e), t.initAddressBook()
                    })
                  },
                  onError: this.error,
                }
              )
            },
          },
          {
            key: 'initAddressBook',
            value: function () {
              var t = this
              if (!this.config.addressBook) return this.initWallet()
              var e = {
                agreementType: 'BillingAgreement',
                sellerId: this.config.sellerId,
                onReady: function (e) {
                  t.emit('ready.addressBook'), t.setBillingAgreementId(e)
                },
                onAddressSelect: this.initWallet,
                design: h(this.config.addressBook),
                onError: this.error,
              }
              ;(this.widgets.addressBook =
                new window.OffAmazonPayments.Widgets.AddressBook(e)),
                this.widgets.addressBook.bind(this.config.addressBook.id),
                this.opened(this.config.addressBook.id)
            },
          },
          {
            key: 'initWallet',
            value: function () {
              var t = this,
                e = {
                  amazonBillingAgreementId: this.billingAgreementId,
                  sellerId: this.config.sellerId,
                  design: h(this.config.wallet),
                  onReady: function (e) {
                    t.emit('ready.wallet'),
                      t.billingAgreementId || t.setBillingAgreementId(e)
                  },
                  onPaymentSelect: function () {
                    t.initConsent(), t.check()
                  },
                  onError: this.error,
                }
              this.billingAgreementId || (e.agreementType = 'BillingAgreement'),
                (this.widgets.wallet =
                  new window.OffAmazonPayments.Widgets.Wallet(e)),
                this.widgets.wallet.bind(this.config.wallet.id),
                this.opened(this.config.wallet.id)
            },
          },
          {
            key: 'initConsent',
            value: function () {
              var t = this,
                e = {
                  amazonBillingAgreementId: this.billingAgreementId,
                  sellerId: this.config.sellerId,
                  design: h(this.config.consent),
                  onReady: function (e) {
                    t.emit('ready.consent'), t.setConsent(e)
                  },
                  onConsent: this.setConsent,
                  onError: this.error,
                }
              ;(this.widgets.consent =
                new window.OffAmazonPayments.Widgets.Consent(e)),
                this.widgets.consent.bind(this.config.consent.id),
                this.opened(this.config.consent.id)
            },
          },
          {
            key: 'setBillingAgreementId',
            value: function (t) {
              ;(this.billingAgreementId = t.getAmazonBillingAgreementId()),
                this.check()
            },
          },
          {
            key: 'setConsent',
            value: function (t) {
              void 0 !== t.getConsentStatus &&
                ((this.consent = 'true' === t.getConsentStatus()), this.check())
            },
          },
          {
            key: 'opened',
            value: function (t) {
              var e = document.getElementById(t)
              e && (e.className = e.className + ' ' + this.config.openedClass)
            },
          },
          {
            key: 'error',
            value: function (t) {
              var e = {
                code: t.getErrorCode(),
                message: t.getErrorMessage(),
              }
              console &&
                (Error.captureStackTrace &&
                  Error.captureStackTrace(e, this.error),
                console.error(e)),
                this.emit('error', e)
            },
          },
          {
            key: 'assetPath',
            get: function () {
              var t = this.config,
                e = t.production,
                n = t.region,
                o = e ? '' : '/sandbox'
              return 'eu' === n
                ? 'https://static-eu.payments-amazon.com/OffAmazonPayments/eur'
                    .concat(o, '/lpa/js/Widgets.js?sellerId=')
                    .concat(this.config.sellerId)
                : 'uk' === n
                ? 'https://static-eu.payments-amazon.com/OffAmazonPayments/gbp'
                    .concat(o, '/lpa/js/Widgets.js?sellerId=')
                    .concat(this.config.sellerId)
                : 'https://static-na.payments-amazon.com/OffAmazonPayments/us'
                    .concat(o, '/js/Widgets.js?sellerId=')
                    .concat(this.config.sellerId)
            },
          },
        ]) && r(e.prototype, n),
        o && r(e, o),
        d
      )
    })(n.n(o).a)

    function f(t) {
      return (
        (t += '') + (isNaN(parseInt(t.charAt(t.length - 1), 10)) ? '' : 'px')
      )
    }

    function h(t) {
      return t.dimensions
        ? {
            size: t.dimensions,
          }
        : {
            designMode: 'responsive',
          }
    }
  },
]).default
