import * as React from 'react'
import { ProfileImage } from './ProfileImage'
import { Truncate } from './Truncate'
import { DestroyIcon } from '../helpers/image-imports/IconComponent'
import { Tooltip, TooltipPosition } from './Tooltip'

export enum CollaboratorType {
  Owner = 'owner',
  Collaborator = 'collaborator',
  Pending = 'pending',
  Protected = 'protected',
}

type Props = {
  displayName?: string
  email: string
  imageUrl?: string //share invite doesn't have imageUrl
  activeUser?: boolean
  role: CollaboratorType
  onRemove?: () => void
}

export class Collaborator extends React.PureComponent<Props> {
  render() {
    return (
      <div className="collaborator-component tab-focus" tabIndex={0}>
        <div className="collaborator-info">
          <ProfileImage
            profileImageUrl={this.props.imageUrl}
            email={this.props.email}
          />
          <div
            className={`collaborator-identifiers${
              !this.props.displayName ? ' pending' : ''
            }`}
          >
            <Truncate>
              {this.props.displayName && (
                <span className="name">{`${this.props.displayName}${
                  this.props.activeUser ? ' (me)' : ''
                }`}</span>
              )}
            </Truncate>
            <Truncate>
              <span className="email">{this.props.email}</span>
            </Truncate>
          </div>
        </div>
        {this.props.role === CollaboratorType.Protected ? (
          <Tooltip
            position={TooltipPosition.Left}
            content="Your team admin is protected and can not be removed"
            style={{ marginLeft: 'auto' }}
          >
            <div className={`collaborator-role ${this.props.role}`}>
              {this.props.role}
            </div>
          </Tooltip>
        ) : (
          <div className={`collaborator-role ${this.props.role}`}>
            {this.props.role}
          </div>
        )}

        {this.props.role !== CollaboratorType.Protected &&
          this.props.onRemove && (
            <DestroyIcon
              onClick={this.props.onRemove}
              className="remove-collaborator"
            />
          )}
      </div>
    )
  }
}
