import * as React from 'react'
import { Heading, HeadingLevel } from './Heading'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Button } from './Button'
import { CopyText } from './CopyText'

type Props = {
  apiClient: ApiClient
  publicSSHKey: string
}

type State = {
  expanded: boolean
}

export class SSHPublicKey extends React.Component<Props, State> {
  public state: State = {
    expanded: false,
  }

  render() {
    return (
      <div className="account-section">
        <Heading heading="SSH Public Key" level={HeadingLevel.Second} />
        <Heading
          heading="Use this key to authorize Codeanywhere to access your resources."
          level={HeadingLevel.Third}
        />
        <Button
          value={this.state.expanded ? 'Hide' : 'Show'}
          type="primary"
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        />
        {this.state.expanded && <CopyText content={this.props.publicSSHKey} />}
      </div>
    )
  }
}
