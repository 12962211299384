import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  HomeIcon,
  ContainerIcon,
  TemplateIcon,
  ConnectionIcon,
  TeamIcon,
  AccountIcon,
  BillingIcon,
  ReferIcon,
} from '../helpers/image-imports/IconComponent'
import { AccountResources } from './AccountResources'
import { Page } from '../App'
import { AccountDetails } from '../api-client/interface/AccountDetails'

type Props = {
  apiClient: ApiClient
  hide: boolean
  accountDetails: AccountDetails
  mobileNavigationExpanded: boolean
  expandMobileNavigation: () => void
  collapseMobileNavigation: () => void
  onChangePage: () => void
}

export class SidebarNavigation extends React.Component<Props> {
  render() {
    const { usage, limits, child_account } = {
      ...this.props.accountDetails,
    }

    return (
      <div
        id="sidebar"
        className={`sidebar${
          this.props.hide
            ? ' hide'
            : this.props.mobileNavigationExpanded
            ? ' expanded'
            : ''
        }`}
      >
        <nav className="navigation">
          <ul className="navigation__menu">
            <li className="navigation-menu__item">
              <NavLink
                className="navigation-menu__link"
                to={Page.Home}
                exact
                activeClassName="active"
                id="home--navigation-link"
                onClick={this.props.onChangePage}
              >
                <div className="navigation-menu-link__icon">
                  <HomeIcon />
                </div>
                Home
              </NavLink>
            </li>
            <li className="navigation-menu__item">
              <NavLink
                className="navigation-menu__link"
                to={Page.Containers}
                exact
                activeClassName="active"
                onClick={this.props.onChangePage}
              >
                <div className="navigation-menu-link__icon">
                  <ContainerIcon />
                </div>
                Containers
              </NavLink>
            </li>
            <li className="navigation-menu__item">
              <NavLink
                className="navigation-menu__link"
                to={Page.Templates}
                exact
                activeClassName="active"
                onClick={this.props.onChangePage}
              >
                <div className="navigation-menu-link__icon">
                  <TemplateIcon />
                </div>
                Container Templates
              </NavLink>
            </li>
            <li className="navigation-menu__item">
              <NavLink
                className="navigation-menu__link"
                to={Page.Connections}
                exact
                activeClassName="active"
                onClick={this.props.onChangePage}
              >
                <div className="navigation-menu-link__icon">
                  <ConnectionIcon />
                </div>
                Connections
              </NavLink>
            </li>
            {!child_account && (
              <li className="navigation-menu__item">
                <NavLink
                  className="navigation-menu__link"
                  to={Page.Team}
                  exact
                  activeClassName="active"
                  onClick={this.props.onChangePage}
                >
                  <div className="navigation-menu-link__icon">
                    <TeamIcon />
                  </div>
                  Team
                </NavLink>
              </li>
            )}
            <li className="navigation-menu__item">
              <NavLink
                className="navigation-menu__link"
                to={Page.Account}
                exact
                activeClassName="active"
                onClick={this.props.onChangePage}
              >
                <div className="navigation-menu-link__icon">
                  <AccountIcon />
                </div>
                Account
              </NavLink>
            </li>
            {!child_account && (
              <li className="navigation-menu__item">
                <NavLink
                  className="navigation-menu__link"
                  to={Page.Billing}
                  exact
                  activeClassName="active"
                  onClick={this.props.onChangePage}
                >
                  <div className="navigation-menu-link__icon">
                    <BillingIcon />
                  </div>
                  Billing
                </NavLink>
              </li>
            )}
            <li className="navigation-menu__item">
              <NavLink
                className="navigation-menu__link"
                to={Page.ReferFriend}
                exact
                activeClassName="active"
                onClick={this.props.onChangePage}
                onFocus={() => {
                  if (!this.props.mobileNavigationExpanded) {
                    this.props.expandMobileNavigation()
                  }
                }}
                onBlur={() => {
                  if (this.props.mobileNavigationExpanded) {
                    this.props.collapseMobileNavigation()
                  }
                }}
              >
                <div className="navigation-menu-link__icon">
                  <ReferIcon />
                </div>
                Refer a Friend
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="sidebar-plan-details">
          {this.props.accountDetails.child_account &&
          this.props.accountDetails.admin_email ? (
            <div className="profile-dropdown__admin">
              This account is managed by {this.props.accountDetails.admin_email}
            </div>
          ) : (
            <a href="/checkout">
              <div className="plan-row">
                <span className="upgrade">
                  {`${
                    this.props.accountDetails.planname === 'Free'
                      ? 'Upgrade'
                      : 'Manage'
                  }`}{' '}
                  Your Plan
                </span>
              </div>
            </a>
          )}
          <AccountResources usage={usage} limits={limits} />
        </div>
      </div>
    )
  }
}
