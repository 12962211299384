import * as React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { LogoutIcon } from '../helpers/image-imports/IconComponent'
import { onKey } from '../helpers/Util'
import { ProfileImage } from './ProfileImage'
import { Truncate } from './Truncate'

type Props = {
  accountDetails: AccountDetails
  profileDropdownExpanded: boolean
  collapseDropdown: () => void
  expandDropdown: () => void
}

export class HeaderProfileDropdown extends React.Component<Props> {
  render() {
    const {
      admin_email,
      email,
      imageurl,
      firstname,
      lastname,
      username,
      planname,
    } = {
      ...this.props.accountDetails,
    }

    return (
      <div
        id="profile-dropdown"
        tabIndex={2}
        className={`profile-dropdown${
          this.props.profileDropdownExpanded
            ? ' profile-dropdown--is-expanded'
            : ''
        }${admin_email ? ' child-account' : ''}`}
      >
        {admin_email ? (
          <>
            <div className="profile-dropdown__admin">
              This account is managed by {admin_email}
            </div>
            <div className="profile-dropdown__divider" />
          </>
        ) : null}
        <div className="profile-dropdown__user">
          <div className="profile-dropdown__user-email">{email}</div>
          <div className="profile-dropdown__user-plan">
            <ProfileImage
              profileImageUrl={imageurl}
              email={email}
              inHeader={true}
            />
            <div className="profile-dropdown__user-info">
              <Truncate>
                <div className="profile-dropdown__user-name">
                  {!firstname && !lastname
                    ? username
                    : `${firstname} ${lastname}`}
                </div>
              </Truncate>
              <div className="profile-dropdown__user-plan-name">
                {`${planname} plan`}
              </div>
            </div>
          </div>
        </div>
        {!this.props.accountDetails.child_account && (
          <div
            className="button button--type-success tab-focus"
            onClick={this.handleManageSubscriptionClick}
            onKeyDown={e =>
              onKey(e, 'Enter', this.handleManageSubscriptionClick)
            }
            onKeyUp={e => onKey(e, ' ', this.handleManageSubscriptionClick)}
            onFocus={() => {
              if (!this.props.profileDropdownExpanded) {
                this.props.expandDropdown()
              }
            }}
            onBlur={() => {
              if (this.props.profileDropdownExpanded) {
                this.props.collapseDropdown()
              }
            }}
            tabIndex={0}
          >
            {`${
              this.props.accountDetails.planname === 'Free'
                ? 'Upgrade'
                : 'Manage'
            }`}
          </div>
        )}
        <div className="profile-dropdown__divider" />
        <a
          className="profile-dropdown__logout"
          href="https://codeanywhere.com/signout"
          onFocus={() => {
            if (!this.props.profileDropdownExpanded) {
              this.props.expandDropdown()
            }
          }}
          onBlur={() => {
            if (this.props.profileDropdownExpanded) {
              this.props.collapseDropdown()
            }
          }}
        >
          <LogoutIcon />
          <span>Log Out</span>
        </a>
      </div>
    )
  }

  private handleManageSubscriptionClick = () => {
    window.open('/checkout', '_self')
  }
}
