import * as React from 'react'
import { LockIcon } from '../helpers/image-imports/IconComponent'
import { RESOURCE_UNLIMITED } from './AccountResources'
import { UsageBar } from './UsageBar'

type Props = {
  resource: string
  used: number
  max: number
  children?: React.ReactNode
}

export class ResourceUsage extends React.PureComponent<Props> {
  render() {
    const unlimited = this.props.max === RESOURCE_UNLIMITED

    return (
      <div className="account-resource">
        <div className={`resource-info${!this.props.max ? ' disabled' : ''}`}>
          <span>
            {this.props.resource} {this.props.children}
          </span>

          {this.props.max ? (
            <span>
              {unlimited ? 'unlimited' : `${this.props.used}/${this.props.max}`}
            </span>
          ) : (
            <LockIcon />
          )}
        </div>
        <UsageBar
          height="4px"
          marginTop="3px"
          used={unlimited ? this.props.max : this.props.used}
          max={this.props.max}
          color={unlimited ? 'var(--color-green)' : undefined}
        />
      </div>
    )
  }
}
