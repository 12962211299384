import { PlanPeriod } from '../pages/BillingPage'
import axios from 'axios'
import * as gravatar from 'gravatar'
import { PREDEFINED_STACKS, STACK_NAME } from './StackName'
import { STACK_TYPE } from './image-imports/StackType'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'
import { AccountConnection } from '../api-client/interface/AccountConnection'
import _ from 'lodash'

export const convertMemoryToGB = (memory: string) => {
  return memory.substring(memory.length - 1) === 'G'
    ? parseInt(memory.substring(0, memory.length - 1))
    : parseInt(memory.substring(0, memory.length - 1)) / 1024
}

export const getPlanPeriodFromCode = (planCode: string) => {
  if (planCode.includes('_m_') || planCode.startsWith('m_'))
    return PlanPeriod.MONTHLY
  if (planCode.includes('_y_') || planCode.startsWith('y_'))
    return PlanPeriod.YEARLY
  if (planCode.includes('_2y_')) return PlanPeriod.BIYEARLY
  if (planCode.startsWith('3y_')) return PlanPeriod.THREE_YEAR
  if (planCode.startsWith('5y_')) return PlanPeriod.FIVE_YEAR

  if (planCode === 'p01') return PlanPeriod.MONTHLY
  if (planCode === 'p02') return PlanPeriod.YEARLY

  return PlanPeriod.INVALID
}

export const getGravatar = async (email: string) => {
  try {
    //if gravatar doesn't exist, an error will be caught
    await axios.get(
      gravatar.url(email, {
        size: '200',
        d: '404',
        protocol: 'https',
      })
    )
    return gravatar.url(email, {
      size: '200',
      d: '404',
      protocol: 'https',
    })
  } catch (e) {
    return ''
  }
}

export const getTemplateIconSourceUrl = (template: string) => {
  return PREDEFINED_STACKS.includes(template)
    ? STACK_TYPE[template]
    : STACK_TYPE['custom']
}

export const getTemplateTooltip = (template: string) => {
  return PREDEFINED_STACKS.includes(template)
    ? STACK_NAME[template]
    : STACK_NAME['custom']
}

export const isDomainPortValid = (port: number) => {
  return port === 80 || port === 443 || (port >= 1024 && port <= 9999)
}

export const focusOnElement = (element: string) => {
  document.getElementById(element)?.focus()
}

export const triggerOpenedScreenEvent = (
  event: string,
  userId: number,
  email: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      userId,
      emailHash: String(sha256(email)),
    },
  })
}

export const extractAuthorizationsFromConnectedAccounts = (
  connectedAccounts: AccountConnection[]
) => {
  return connectedAccounts
    ? _.flatMap(
        connectedAccounts.filter(account => account.authorized),
        account => account.authorizations
      )
    : []
}

export const extractAuthLink = (
  connectedAccounts: AccountConnection[],
  provider: string
) => {
  if (!connectedAccounts) {
    return undefined
  }

  return connectedAccounts.find(account => account.provider === provider)
    ?.authorization_link
}

export const onKey = (
  e: React.KeyboardEvent,
  key: string,
  callback: () => void
) => {
  if (e.key === ' ') {
    e.preventDefault()
  }
  if (e.key === key) {
    callback()
  }
}
