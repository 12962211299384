import * as React from 'react'
import { AddonName, ChildAccount } from '../api-client/interface/Subscription'
import { TeamResourceNumberInput } from './TeamResourceNumberInput'
import { Truncate } from './Truncate'
import { DestroyIcon } from '../helpers/image-imports/IconComponent'
import { onKey } from '../helpers/Util'

type Props = {
  account: ChildAccount
  hasPremiumSubscription: boolean
  processingAction: boolean
  onAddonQuantityChange: (
    addonName: AddonName,
    addonQuantity: number,
    assigning: boolean
  ) => Promise<void>
  onRemoveAccount: () => void
  refreshManagedAccountsList: () => Promise<void>
}

export class TeamAccount extends React.Component<Props> {
  render() {
    const notVerified = this.props.account.pending_confirmation === 1
    return (
      <tr className="table-row">
        <td className="table-column">
          <Truncate>{`${this.props.account.email}${
            this.props.account.pending_confirmation ? ' (Pending)' : ''
          }`}</Truncate>
        </td>
        <td className="table-column center">
          <TeamResourceNumberInput
            value={
              this.props.account.addons?.find(
                addon => addon.addon_code === AddonName.AdditionalContainer
              )?.quantity || 0
            }
            disabled={notVerified}
            processingAction={this.props.processingAction}
            onChangeValue={(containersAmount: number, assigning: boolean) =>
              this.props.onAddonQuantityChange(
                AddonName.AdditionalContainer,
                containersAmount,
                assigning
              )
            }
          />
        </td>
        <td className="table-column center">
          <TeamResourceNumberInput
            value={
              this.props.account.addons?.find(
                addon => addon.addon_code === AddonName.DevboxAlwaysOn
              )?.quantity || 0
            }
            disabled={notVerified}
            processingAction={this.props.processingAction}
            onChangeValue={(alwaysOnAmount: number, assigning: boolean) =>
              this.props.onAddonQuantityChange(
                AddonName.DevboxAlwaysOn,
                alwaysOnAmount,
                assigning
              )
            }
          />
        </td>
        <td className="table-column center">
          <TeamResourceNumberInput
            value={
              this.props.account.addons?.find(
                addon => addon.addon_code === AddonName.Extra15Domains
              )?.quantity || 0
            }
            disabled={notVerified}
            processingAction={this.props.processingAction}
            onChangeValue={(domainsAmount: number, assigning: boolean) =>
              this.props.onAddonQuantityChange(
                AddonName.Extra15Domains,
                domainsAmount,
                assigning
              )
            }
          />
        </td>
        <td className="table-column center">
          {this.props.hasPremiumSubscription ? (
            <span className="unlimited-resource">Unlimited</span>
          ) : (
            <TeamResourceNumberInput
              value={
                this.props.account.addons?.find(
                  addon => addon.addon_code === AddonName.Extra15Remote
                )?.quantity || 0
              }
              disabled={notVerified}
              processingAction={this.props.processingAction}
              onChangeValue={(connectionsAmount: number, assigning: boolean) =>
                this.props.onAddonQuantityChange(
                  AddonName.Extra15Remote,
                  connectionsAmount,
                  assigning
                )
              }
            />
          )}
        </td>
        <td className="table-column remove-child">
          <DestroyIcon
            className="tab-focus"
            onClick={this.props.onRemoveAccount}
            onKeyDown={e => onKey(e, 'Enter', this.props.onRemoveAccount)}
            onKeyUp={e => onKey(e, ' ', this.props.onRemoveAccount)}
            tabIndex={0}
          />
        </td>
      </tr>
    )
  }
}
