import { EventEmitter } from 'eventemitter3'
import { AuthProvider } from '../interface/AuthProvider'
import { ShareApi } from '@codeanywhere/collaboration-service-api-client'
import { ApiClientEvent } from '../interface/ApiClient'
import io, { Socket } from 'socket.io-client'
import { ShareNotification } from '../interface/ShareNotifications'
import { notification } from 'antd'

export class ShareApiClient extends EventEmitter {
  private shareApi: ShareApi
  public authProvider: AuthProvider

  private socket?: typeof Socket
  private readonly notificationSocketUrl: string

  constructor(params: {
    shareApi: ShareApi
    authProvider: AuthProvider
    notificationSocketUrl: string
  }) {
    super()
    this.shareApi = params.shareApi
    this.authProvider = params.authProvider
    this.notificationSocketUrl = params.notificationSocketUrl
  }

  public async setupSocket() {
    this.socket = io(this.notificationSocketUrl, {
      query: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    this.socket.on('notification', (data: unknown) => {
      if (
        Object.entries(data as any).find(
          entry =>
            entry[0] === 'event' && (entry[1] as string).includes('share')
        )
      ) {
        const notification = data as ShareNotification

        switch (notification.event) {
          case 'share:invited':
            if (notification.data) {
              this.emit(ApiClientEvent.InvitedToShare, {
                shareId: notification.data.shareId,
                shareLink: notification.data.shareLink,
                shareOwnerUsername: notification.data.shareOwnerUsername,
              })
            }
            break
          default:
            break
        }
      } else {
        // console.log('Unknown notification type', data)
      }
    })

    this.socket.on('error', (error: unknown) => {
      notification.error({
        message: 'Error connecting to notification socket',
      })
    })
  }

  public async getSharedWithMe() {
    return (
      await this.shareApi.getSharedWithMe({
        headers: {
          Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
        },
      })
    ).data
  }

  public async getMyShares() {
    return (
      await this.shareApi.getMyShares({
        headers: {
          Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
        },
      })
    ).data
  }

  public async getShare(containerId: number, adminEmail?: string) {
    try {
      const share = (
        await this.shareApi.getShareByContainerId(containerId, {
          headers: {
            Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
          },
        })
      ).data

      if (!this.socket) {
        this.setupSocket().catch(console.error)
      }
      return share
    } catch (e: any) {
      if (!e.response) {
        throw new Error(e)
      }

      if (e.response.status === 404) {
        const share = await this.createShare(containerId)

        if (adminEmail) {
          await this.addProtectedUser(share.id, adminEmail)
        }

        if (!this.socket) {
          this.setupSocket().catch(console.error)
        }
        return share
      } else {
        throw new Error(e)
      }
    }
  }

  public async createShare(containerId: number) {
    return (
      await this.shareApi.createShare(
        { containerId },
        {
          headers: {
            Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
          },
        }
      )
    ).data
  }

  public async addProtectedUser(shareId: string, email: string) {
    return (
      await this.shareApi.addProtectedUser(
        shareId,
        { email },
        {
          headers: {
            Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
          },
        }
      )
    ).data
  }

  public async inviteUserToShare(shareId: string, email: string) {
    await this.shareApi.inviteUserToShare(
      shareId,
      { email },
      {
        headers: {
          Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
        },
      }
    )
  }

  public async getShareByContainerId(containerId: number) {
    return (
      await this.shareApi.getShareByContainerId(containerId, {
        headers: {
          Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
        },
      })
    ).data
  }

  public async removeUserFromShare(shareId: string, email: string) {
    await this.shareApi.removeUserFromShare(shareId, email, {
      headers: {
        Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
      },
    })
  }

  public async leaveShare(shareId: string) {
    await this.shareApi.leaveShare(shareId, {
      headers: {
        Authorization: `Bearer ${await this.authProvider.getAccessToken()}`,
      },
    })
  }
}
