import axios, { AxiosInstance } from 'axios'
import { EventEmitter } from 'eventemitter3'
import { AuthProvider } from '../interface/AuthProvider'
import { BillingInfo } from '../interface/BillingInfo'
import io, { Socket } from 'socket.io-client'
import { Invoice } from '../interface/Invoice'
import { AccountConnection } from '../interface/AccountConnection'
import {
  ApiClient,
  ApiClientEvent,
  ReferralWidgetData,
} from '../interface/ApiClient'
import {
  ContainerNotification,
  SSHConnection,
  FTPConnection,
  FTPSConnection,
  Connection,
  CreateContainer,
  ContainerStackList,
  CreateCustomStack,
  ContainerDomain,
  CreateContainerDomain,
  CancelSubscriptionFeedback,
  ConnectionType,
  ContainerConnection,
} from '../interface/Connection'
import {
  ChildAccount,
  Subscription,
  SubscriptionAction,
  SubscriptionAddons,
} from '../interface/Subscription'
import { PRICING_PLANS_NEW } from '../interface/PricingPlan'
import { notification } from '../../components/Notification'
import { GoogleSpreadsheet } from 'google-spreadsheet'

type Project = {
  id: number
  name: string
}

const DASHBOARD_PROJECT = 'Codeanywhere Dashboard'

export type ConnectionError = {
  error: string
  log: string
}

export class ProductionApiClient extends EventEmitter implements ApiClient {
  public authProvider: AuthProvider

  private socket?: typeof Socket
  private readonly baseUrl: string
  private readonly notificationSocketUrl: string
  private readonly credentialsUrl: string
  private readonly axiosApiClient: AxiosInstance
  private readonly axiosCredentialsApiClient: AxiosInstance

  constructor(params: {
    baseUrl: string
    authProvider: AuthProvider
    credentialsUrl: string
    notificationSocketUrl: string
  }) {
    super()
    this.baseUrl = params.baseUrl
    this.authProvider = params.authProvider
    this.credentialsUrl = params.credentialsUrl
    this.notificationSocketUrl = params.notificationSocketUrl

    this.axiosApiClient = axios.create({
      baseURL: this.baseUrl,
    })
    this.axiosCredentialsApiClient = axios.create({
      baseURL: this.credentialsUrl,
    })

    // TODO: Clean this up
    this.axiosApiClient.interceptors.response.use(
      (response: any) => {
        return response
      },
      (error: any) => {
        if (error && error.response && error.response.status === 401) {
          this.authProvider.authorize()
          return Promise.reject('Unauthorized. Please log in again.')
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.config.url.includes('listMultipleAccounts')) {
            return Promise.resolve([])
          }

          return Promise.reject(error.response.data.message)
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.err
        ) {
          if (
            error.response.data.err.error_code ===
            'three_d_secure_action_required'
          ) {
            return Promise.reject(error.response.data.err)
          }

          // Hah for empty billing info
          if (
            error.response.data.err &&
            error.response.data.err.message &&
            error.response.data.err.message.includes(
              "Couldn't find BillingInfo with account_code"
            )
          ) {
            return Promise.reject(error)
          }

          if (
            error.config.url === '/connection/add' &&
            error.response &&
            error.response.data &&
            error.response.data.upgrade
          ) {
            return Promise.reject(error.response.data)
          }

          if (
            error.config.url === '/connection/add' &&
            error.response &&
            error.response.data &&
            error.response.data.log
          ) {
            const connectionError: ConnectionError = {
              error: error.response.data.err,
              log: error.response.data.log,
            }

            return Promise.reject(connectionError)
          }

          if (
            error.config.url === '/connection/add' &&
            error.response &&
            error.response.data &&
            error.response.data.err &&
            error.response.data.err.includes('verify your account')
          ) {
            const error = {
              notVerified: true,
            }

            return Promise.reject(error)
          }

          if (
            (error.config.url.includes('setChildAddons') ||
              error.config.url.includes('addChildAccount')) &&
            error.response &&
            error.response.data &&
            error.response.data.err &&
            (error.response.data.err.includes('Exceeded') ||
              error.response.data.err.includes('maximum'))
          ) {
            return Promise.reject({
              exceeded: true,
            })
          }

          const errorDescription = error.response.data.err.message
            ? error.response.data.err.message
            : error.response.data.err &&
              error.response.data.err instanceof Object
            ? 'Please try again or contact support'
            : error.response.data.err

          // notification.error({
          //   message: 'An error occurred',
          //   description: errorDescription
          // })
          return Promise.reject(errorDescription)
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.message
        ) {
          return Promise.reject(String(error.response.data.data.message))
        } else {
          return Promise.reject(String(error))
        }
      }
    )

    this.axiosCredentialsApiClient.interceptors.response.use(
      (response: any) => {
        return response
      },
      (error: any) => {
        if (error && error.response && error.response.status === 401) {
          this.authProvider.authorize()
          return Promise.reject(error)
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.config.url.includes('listMultipleAccounts')) {
            return Promise.resolve([])
          }

          return Promise.reject(error.response.data.message)
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.err
        ) {
          // Hah for empty billing info
          if (
            error.response.data.err &&
            error.response.data.err.message &&
            error.response.data.err.message.includes(
              "Couldn't find BillingInfo with account_code"
            )
          ) {
            return Promise.reject(error)
          }

          if (
            error.config.url === '/connection/add' &&
            error.response &&
            error.response.data &&
            error.response.data.upgrade
          ) {
            return Promise.reject(error.response.data)
          }

          if (
            error.config.url === '/connection/add' &&
            error.response &&
            error.response.data &&
            error.response.data.log
          ) {
            const connectionError: ConnectionError = {
              error: error.response.data.err,
              log: error.response.data.log,
            }

            return Promise.reject(connectionError)
          }

          if (
            error.config.url === '/connection/add' &&
            error.response &&
            error.response.data &&
            error.response.data.err &&
            error.response.data.err.includes('verify your account')
          ) {
            const error = {
              notVerified: true,
            }

            return Promise.reject(error)
          }

          if (
            (error.config.url.includes('setChildAddons') ||
              error.config.url.includes('addChildAccount')) &&
            error.response &&
            error.response.data &&
            error.response.data.err &&
            (error.response.data.err.includes('Exceeded') ||
              error.response.data.err.includes('maximum'))
          ) {
            return Promise.reject({
              exceeded: true,
            })
          }

          const errorDescription = error.response.data.err.message
            ? error.response.data.err.message
            : error.response.data.err &&
              error.response.data.err instanceof Object
            ? 'Please try again or contact support'
            : error.response.data.err

          // notification.error({
          //   message: 'An error occurred',
          //   description: errorDescription
          // })
          return Promise.reject(errorDescription)
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.message
        ) {
          return Promise.reject(String(error.response.data.data.message))
        } else {
          return Promise.reject(String(error))
        }
      }
    )
  }

  private async setupSocket() {
    this.socket = io(this.notificationSocketUrl, {
      query: {
        token: await this.authProvider.getAccessToken(),
      },
      forceNew: true,
    })

    this.socket.on('notification', (data: unknown) => {
      if (
        Object.entries(data as any).find(
          entry =>
            entry[0] === 'action' && (entry[1] as string).includes('connection')
        )
      ) {
        const notification = data as ContainerNotification

        switch (notification.action) {
          case 'connection:change':
          case 'connection:add':
          case 'connection:remove':
            if (notification.data) {
              this.emit(ApiClientEvent.ContainerStateChanged, {
                containerId: notification.data.id,
                state: notification.data.state,
              })
            }
            break
          default:
            break
        }
      } else {
        // console.log('Unknown notification type', data)
      }
    })

    this.socket.on('error', (error: unknown) => {
      notification.error({
        message: 'Error connecting to notification socket',
      })
    })
  }

  public async validateToken() {
    await this.axiosApiClient.get('/user/validate', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })
  }

  public async updateAccount(params: {
    firstname: string
    lastname: string
    username: string
    company: string
  }) {
    await this.axiosCredentialsApiClient.post('/account/edit', {
      token: await this.authProvider.getAccessToken(),
      ...params,
    })
  }

  public async getAccountDetails() {
    const response = await this.axiosApiClient.get('/user/userdata', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    if (!this.socket) {
      this.setupSocket()
    }

    return response.data
  }

  public async deleteAccount(confirmPassword: string) {
    await this.axiosCredentialsApiClient.post('/account/close', {
      token: await this.authProvider.getAccessToken(),
      password: confirmPassword,
    })
  }

  public async updateAccountPassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    await this.axiosCredentialsApiClient.post('/account/changepassword', {
      token: await this.authProvider.getAccessToken(),
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    })
  }

  public async changeEmail(newEmail: string, password: string) {
    await this.axiosCredentialsApiClient.post('/account/changeemail', {
      token: await this.authProvider.getAccessToken(),
      password,
      new_email: newEmail,
    })
  }

  public async hasInitialPassword() {
    const response = await this.axiosApiClient.get('/user/userdata', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    if (response.data.promptSetInitialPassword) {
      return false
    }

    return true
  }

  public async setInitialPassword(password: string) {
    await this.axiosApiClient.post('/user/setInitialPass', {
      token: await this.authProvider.getAccessToken(),
      password,
    })
  }

  public async getConnectedAccounts(): Promise<AccountConnection[]> {
    const response = await this.axiosApiClient.get(
      '/credentials/getAuthorizationList',
      {
        params: {
          token: await this.authProvider.getAccessToken(),
        },
      }
    )
    return response.data
  }

  public async removeConnectedAccount(provider: string, authId: string) {
    await this.axiosCredentialsApiClient.post('/credentials/revoke', {
      token: await this.authProvider.getAccessToken(),
      provider,
      auth_id: authId,
    })
  }

  public async sendResetPasswordLink() {
    throw new Error('Method not implemented.')
  }

  public async has2FAEnabled(): Promise<boolean> {
    const response = await this.axiosApiClient.get('/user/userdata', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })
    return response.data.user2fa.enabled
  }

  public async disable2FA() {
    await this.axiosApiClient.post('/user/secondStepAuthDisable', {
      token: await this.authProvider.getAccessToken(),
    })
  }

  public async get2FAQRCode() {
    const response = await this.axiosApiClient.get(
      '/user/secondStepAuthGetCode',
      {
        params: {
          token: await this.authProvider.getAccessToken(),
        },
      }
    )
    return {
      code: response.data.secret,
      qrFullPath: response.data.url,
    }
  }

  public async enable2FA(code: string) {
    await this.axiosApiClient.post('/user/secondStepAuthEnable', {
      token: await this.authProvider.getAccessToken(),
      code,
    })
  }

  public async resendVerificationEmail() {
    await this.axiosApiClient.post('/user/resendverificationemail', {
      token: await this.authProvider.getAccessToken(),
    })
  }

  public async getAccountSSHPublicKey() {
    const response = await this.axiosApiClient.get('/user/publickey', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })
    return response.data.publicKey
  }

  public async getConnectionList() {
    const response = await this.axiosApiClient.get('/connection/list', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    const connections: (Connection | ConnectionType)[] = response.data.map(
      (connection: any) => {
        switch (connection.type) {
          case 'devbox':
            return connection as ContainerConnection
          case 'ssh':
            return connection as SSHConnection
          case 'ftp':
            return connection as FTPConnection
          case 'ftps':
            return connection as FTPSConnection
        }

        return connection as Connection
      }
    )

    return connections
  }

  public async getConnection(connectionId: number) {
    const response = await this.axiosApiClient.get('/connection/info', {
      params: {
        token: await this.authProvider.getAccessToken(),
        connectionId,
      },
    })
    return response.data
  }

  public async removeConnection(connectionId: number) {
    await this.axiosApiClient.post(
      '/connection/remove',
      {},
      {
        params: {
          token: await this.authProvider.getAccessToken(),
          connectionId,
        },
      }
    )
  }

  public async renameConnection(connectionId: number, newName: string) {
    await this.axiosApiClient.post(
      '/connection/rename',
      {},
      {
        params: {
          connectionId: connectionId,
          name: newName,
          token: await this.authProvider.getAccessToken(),
        },
      }
    )
  }

  public async addConnection(
    connection: SSHConnection | FTPConnection | FTPSConnection
  ) {
    const projects = await this.getProjects()

    const containerDump = projects.find(
      project => project.name === DASHBOARD_PROJECT
    )

    await this.axiosApiClient.post('/connection/add', {
      token: await this.authProvider.getAccessToken(),
      projectId: containerDump
        ? containerDump.id
        : await this.createContainerDumpAndReturnId(),
      ...connection,
    })
  }

  public async editConnection(
    connection: SSHConnection | FTPConnection | FTPSConnection
  ) {
    const { id, ...withoutId } = { ...connection }

    await this.axiosApiClient.post(
      '/connection/edit',
      {},
      {
        params: {
          token: await this.authProvider.getAccessToken(),
          connectionId: connection.id,
          ...withoutId,
        },
      }
    )
  }

  public async getContainerList() {
    return (await this.getConnectionList()).filter(
      (connection: Connection | ConnectionType) => connection.type === 'devbox'
    ) as ContainerConnection[]
  }

  public async startContainer(containerId: number) {
    await this.axiosApiClient.post(
      '/devbox/start',
      {},
      {
        params: {
          token: await this.authProvider.getAccessToken(),
          id: containerId,
        },
      }
    )
  }

  public async stopContainer(containerId: number) {
    await this.axiosApiClient.post(
      '/devbox/stop',
      {},
      {
        params: {
          token: await this.authProvider.getAccessToken(),
          id: containerId,
        },
      }
    )
  }

  public async restartContainer(containerId: number) {
    await this.axiosApiClient.post(
      '/devbox/restart',
      {},
      {
        params: {
          token: await this.authProvider.getAccessToken(),
          id: containerId,
        },
      }
    )
  }

  public async getContainerInfo(containerId: number) {
    const response = await this.axiosApiClient.get('/devbox/boxinfo', {
      params: {
        id: containerId,
        token: await this.authProvider.getAccessToken(),
      },
    })

    return response.data
  }

  public async setContainerAlwaysOn(containerId: number, alwaysOn: boolean) {
    await this.axiosApiClient.post(
      '/devbox/alwayson',
      {},
      {
        params: {
          id: containerId,
          token: await this.authProvider.getAccessToken(),
          enable: alwaysOn ? 1 : 0,
        },
      }
    )
  }

  public async createContainer(container: CreateContainer) {
    const projects = await this.getProjects()
    let { distro, ...withoutDistro } = { ...container }
    let containerToCreate: any = withoutDistro

    if (container.distro) {
      containerToCreate.distro = container.distro
    }

    const containerDump = projects.find(
      project => project.name === DASHBOARD_PROJECT
    )

    const response = await this.axiosApiClient.post(
      '/connection/add',
      {},
      {
        params: {
          token: await this.authProvider.getAccessToken(),
          projectId: containerDump
            ? containerDump.id
            : await this.createContainerDumpAndReturnId(),
          ...containerToCreate,
        },
      }
    )

    return response.data.id
  }

  public async getContainerSlug(containerId: number) {
    try {
      const containerSlug = (await this.getContainerList()).find(
        c => c.id === containerId
      )?.slug

      if (!containerSlug) {
        throw new Error()
      }

      return containerSlug
    } catch (ex) {
      return null
    }
  }

  public async getContainerStacks() {
    const response = await this.axiosApiClient.get('/devbox/stacks', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    const stacks: ContainerStackList = {
      predefined: response.data.predefined,
      custom: [],
      team: response.data.team,
    }

    const custom = response.data.custom

    if (custom.length) {
      if (custom[0].distro) {
        custom.forEach((c: any) => {
          const { distro, ...withoutDistro } = c
          stacks.custom.push({
            ...withoutDistro,
            distros: distro,
          })
        })
      } else {
        stacks.custom = custom
      }
    }

    return stacks
  }

  public async createCustomStack(stack: CreateCustomStack) {
    await this.axiosApiClient.post('/devbox/createStack', {
      ...stack,
      token: await this.authProvider.getAccessToken(),
    })
  }

  public async removeCustomStack(stackId: string): Promise<void> {
    await this.axiosApiClient.post('/devbox/stackDelete', {
      code: stackId,
      token: await this.authProvider.getAccessToken(),
    })
  }

  public async getPricingPlans() {
    return PRICING_PLANS_NEW
  }

  public async createSubscription(
    plan: string,
    addons: SubscriptionAddons,
    coupon: string
  ) {
    const form = new FormData()
    form.append('login_key', await this.authProvider.getAccessToken())
    form.append('plan', 'v21a_' + plan)

    const addonArray = Object.entries(addons).map(([key, value]) => {
      return {
        add_on_code: key,
        quantity: value.toString(),
      }
    })

    form.append(
      'addons',
      encodeURIComponent(
        JSON.stringify(addonArray.filter(addon => addon.quantity !== '0'))
      )
    )
    form.append('coupon', coupon || 'false')

    const response = await this.axiosCredentialsApiClient.post(
      '/api/rest/v2/users/createSubscription',
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )

    if (response.data.success !== undefined && response.data.success === 0) {
      throw new Error(
        'Could not create your subscription. Please try again later or contact support.'
      )
    }
  }

  public async reactivateSubscription(planUuid: string): Promise<void> {
    await this.axiosApiClient.post('/user/reactivateSubscription', {
      token: await this.authProvider.getAccessToken(),
      sub_uuid: planUuid,
    })
  }

  public async updateSubscription(
    plan: string,
    addons: SubscriptionAddons,
    type: SubscriptionAction,
    coupon?: string
  ) {
    const splitted = plan.split('_')
    const billing = plan.startsWith('m_')
      ? 'monthly'
      : plan.startsWith('y_')
      ? 'yearly'
      : 'biyearly'

    const addonArray = Object.entries(addons).map(([key, value]) => {
      return {
        add_on_code: key,
        quantity: value.toString(),
      }
    })

    await this.axiosApiClient.post('/user/updateSubscription', {
      token: await this.authProvider.getAccessToken(),
      addons: JSON.stringify(
        addonArray.filter(addon => addon.quantity !== '0')
      ),
      billing,
      plan: splitted[1],
      type,
      coupon,
    })
  }

  //TODO: remove feedback
  public async cancelSubscription(
    feedback: CancelSubscriptionFeedback,
    planUuid: string
  ): Promise<void> {
    await this.axiosApiClient.post('/user/cancelSubscription', {
      token: await this.authProvider.getAccessToken(),
      feedback,
      sub_uuid: planUuid,
    })
  }

  public async updateAddons(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  private subscriptionCache: Subscription[] = []
  private shouldRefreshCache: boolean = true
  public async getAccountSubscription(forceRefreshCache?: boolean) {
    if (!this.shouldRefreshCache && !forceRefreshCache) {
      setTimeout(() => {
        this.shouldRefreshCache = true
      }, 1000 * 60 * 10)

      return (
        this.subscriptionCache.find(
          (s: Subscription) => s.state !== 'expired'
        ) || null
      )
    }

    const response = await this.axiosApiClient.get(
      '/user/getAccountSubscriptions',
      {
        params: {
          token: await this.authProvider.getAccessToken(),
        },
      }
    )

    const subscriptions = response.data.subscriptions

    if (subscriptions && subscriptions.length > 0) {
      this.subscriptionCache = subscriptions
      this.shouldRefreshCache = false
      return (
        subscriptions.find((s: Subscription) => s.state !== 'expired') || null
      )
    }

    return null
  }

  public async getAllAccountSubscriptions() {
    return this.subscriptionCache
  }

  public async getMultipleAccounts() {
    const response = await this.axiosApiClient.get(
      '/multipleusers/listMultipleAccounts',
      {
        params: {
          token: await this.authProvider.getAccessToken(),
        },
      }
    )

    const accounts = response.data.map((account: any) => {
      return {
        ...account,
        addons: account.addons !== null ? JSON.parse(account.addons) : null,
      }
    })

    return accounts
  }

  public async addChildAccount(email: string) {
    await this.axiosApiClient.post('/multipleusers/addChildAccount', {
      child_email: email,
      token: await this.authProvider.getAccessToken(),
    })
  }

  public async closeChildAccount(childId: string) {
    await this.axiosApiClient.post('/multipleusers/closeChildAccount', {
      child: childId,
      token: await this.authProvider.getAccessToken(),
    })
  }

  public async setChildAddons(child: ChildAccount) {
    const form = new FormData()
    form.append(
      'set_addons',
      JSON.stringify([
        {
          ...child,
          child: child.child.toString(),
          sync: false,
        },
      ])
    )
    form.append('token', await this.authProvider.getAccessToken())

    await this.axiosApiClient.post('/multipleusers/setChildAddons', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  public async getBillingInfo(): Promise<BillingInfo> {
    const response = await this.axiosApiClient.get('/user/getBillingInfo', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    return {
      firstName: response.data.billing_info.first_name,
      lastName: response.data.billing_info.last_name,
      company: response.data.billing_info.company,
      vatNumber: response.data.billing_info.vat_number,
      address: {
        phone: response.data.billing_info.phone,
        street1: response.data.billing_info.address1,
        street2: response.data.billing_info.address2,
        city: response.data.billing_info.city,
        region: response.data.billing_info.state,
        postalCode: response.data.billing_info.zip,
        country: response.data.billing_info.country,
      },
      first_six: response.data.billing_info.first_six,
      last_four: response.data.billing_info.last_four,
      month: response.data.billing_info.month,
      year: response.data.billing_info.year,
      paypalAgreement:
        response.data.billing_info['paypal_billing_agreement_id'],
      amazonPayAgreement:
        response.data.billing_info['amazon_billing_agreement_id'],
      type: response.data.billing_info.type,
      cardType: response.data.billing_info.card_type,
    }
  }

  public async getInvoices(): Promise<Invoice[]> {
    const response = await this.axiosApiClient.get('/user/getInvoices', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    return response.data.invoices.map((invoice: any): Invoice => {
      return {
        id: invoice.uuid,
        number: invoice.invoice_number,
        created: invoice.created_at,
        status: invoice.state,
        total: invoice.total_in_cents,
      }
    })
  }

  public async downloadInvoicePdf(invoiceNumber: string) {
    window.open(
      `https://codeanywhere.com/api/ca6/user/downloadinvoice?token=${await this.authProvider.getAccessToken()}&invoice_number=${invoiceNumber}`
    )
  }

  public async updateBillingInfo(recurlyToken: string): Promise<void> {
    await this.axiosApiClient.get('/user/updateBillingInfo', {
      params: {
        recurly_token: recurlyToken,
        token: await this.authProvider.getAccessToken(),
      },
    })
  }

  public async getReferralWidgetData(): Promise<ReferralWidgetData> {
    const response = await this.axiosApiClient.get('/user/referralWidgetData', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    return response.data
  }

  private async getProjects(): Promise<Project[]> {
    const response = await this.axiosApiClient.get('/project/list', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    return response.data
  }

  private async createContainerDumpAndReturnId(): Promise<number> {
    const response = await this.axiosApiClient.post('/project/create', {
      name: DASHBOARD_PROJECT,
      token: await this.authProvider.getAccessToken(),
    })

    return response.data.id
  }

  public async getDomainList(): Promise<ContainerDomain[]> {
    const response = await this.axiosApiClient.get('/devbox/domainList', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    return response.data
  }

  public async addDomain(domain: CreateContainerDomain): Promise<void> {
    const response = await this.axiosApiClient.post('/devbox/domainAdd', {
      ...domain,
      token: await this.authProvider.getAccessToken(),
    })

    return response.data.id
  }

  public async removeDomain(domainId: string): Promise<void> {
    const response = await this.axiosApiClient.post('/devbox/domainRemove', {
      id: domainId,
      token: await this.authProvider.getAccessToken(),
    })

    return response.data.id
  }

  public async submitRefundTicket(
    userId: number,
    planCode: string,
    feedback: CancelSubscriptionFeedback
  ): Promise<void> {
    let message = {
      userId,
      planCode,
      cancellationReasons: feedback.reasons.join(', '),
      comment: feedback.comment,
      rivalEditor: feedback.rivalEditor,
    }

    await this.axiosApiClient.get('/support/sendticket', {
      params: {
        subject: 'Refund request',
        message: JSON.stringify(message),
        token: await this.authProvider.getAccessToken(),
      },
    })
  }

  public async submitCancellationReasons(
    userId: number,
    email: string,
    planCode: string,
    activatedAt: string,
    canceledAt: string,
    reasons: string,
    comment: string,
    rivalEditor: string
  ): Promise<void> {
    const doc = new GoogleSpreadsheet(
      String(process.env.REACT_APP_SHEET_API_SHEET_ID)
    )

    await doc.useServiceAccountAuth({
      client_email: String(process.env.REACT_APP_SHEET_API_CLIENT_EMAIL),
      private_key: String(process.env.REACT_APP_SHEET_API_PRIVATE_KEY),
    })

    await doc.loadInfo()

    const sheet = doc.sheetsByTitle['Sheet1']

    await sheet.addRow({
      userId,
      email,
      planCode,
      activatedAt,
      canceledAt,
      reasons,
      comment,
      rivalEditor,
      suggestions: '',
    })
  }

  public async cancelCreditUsed(): Promise<boolean> {
    const response = await this.axiosApiClient.get('/user/cancelCreditUsed', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    return response.data.used
  }

  public async getAccountBalanceInCents(): Promise<number> {
    const response = await this.axiosApiClient.get('/user/accountBalance', {
      params: {
        token: await this.authProvider.getAccessToken(),
      },
    })

    return response.data.balance_amount_in_cents
  }

  public async contactSupportForDiscount(): Promise<void> {
    await this.axiosApiClient.get('/support/sendticket', {
      params: {
        subject: 'Black friday 2-year upgrade',
        message: '',
        token: await this.authProvider.getAccessToken(),
      },
    })
  }
}
