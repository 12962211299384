import * as React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { CancelSubscriptionFeedback } from '../api-client/interface/Connection'
import moment from 'moment'
import { CancelBetaStep } from '../components/cancel/BetaStep'
import { CancelFeedbackStep } from '../components/cancel/FeedbackStep'
import { CancelConfirmStep } from '../components/cancel/ConfirmStep'
import TagManager from 'react-gtm-module'
import sha256 from 'crypto-js/sha256'
import { Page } from '../App'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { Subscription } from '../api-client/interface/Subscription'
import { Container } from '../containers/ContainersList'
import { Redirect } from 'react-router-dom'
import { PlanPeriod } from './BillingPage'
import { Spinner } from '../components/Spinner'
import { notification } from '../components/Notification'
import { triggerOpenedScreenEvent } from '../helpers/Util'
import mixpanel from 'mixpanel-browser'

export enum StepName {
  BETA = 1,
  FEEDBACK = 2,
  CONFIRM = 3,
}

type Props = {
  apiClient: ApiClient

  accountDetails: AccountDetails
  subscription: Subscription | null
  planPeriod: PlanPeriod
  alwaysOnContainers: Container[]

  loadingInitContent: boolean
  onDidLoadInitContent: () => void
}

export type CancellationStep = {
  step: StepName
  progressWidth: number
}

type State = {
  loading: boolean
  steps: CancellationStep[]
  currStepIndex: number
  stepActionLoading: boolean
  cancelSubscriptionFeedback: CancelSubscriptionFeedback
  cancellationConfirmed: boolean
}

export class CancelMembershipPage extends React.Component<Props, State> {
  public state: State = {
    loading: false,
    steps: [
      {
        step: StepName.BETA,
        progressWidth: 0,
      },
      {
        step: StepName.FEEDBACK,
        progressWidth: 50,
      },
      {
        step: StepName.CONFIRM,
        progressWidth: 100,
      },
    ],
    currStepIndex: 0,
    stepActionLoading: false,
    cancelSubscriptionFeedback: {
      reasons: [],
      comment: '',
    },
    cancellationConfirmed: false,
  }

  componentDidMount() {
    this.props.onDidLoadInitContent()
    triggerOpenedScreenEvent(
      'openedCancelMembershipScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
    document.body.classList.add('cancel-membership__view')
  }

  componentWillUnmount() {
    document.body.classList.remove('cancel-membership__view')
  }

  render() {
    if (this.props.loadingInitContent || this.state.loading) {
      return (
        <div className="page-content">
          <Spinner size="cover" />
        </div>
      )
    }

    if (this.props.accountDetails.trial && !this.props.subscription) {
      return <Redirect to={Page.Home} />
    }

    if (!this.props.subscription) {
      return <Redirect to={Page.Home} />
    }

    const currStep = this.state.steps[this.state.currStepIndex]

    const refundPeriodExpired = this.refundPeriodExpired()

    const { user_id, email, username, firstname } = {
      ...this.props.accountDetails,
    }

    return (
      <div
        className="page-content checkout cancel"
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="cancellation">
          <div className="cancellation__progress">
            <div
              className="cancellation__progress-bar"
              style={{
                width: currStep.progressWidth + '%',
              }}
            ></div>
            <div className="cancellation__progress-steps">
              <div
                className={`cancellation__progress-step ${
                  true ? 'cancellation__progress-step--active' : false
                }`}
              ></div>

              <div
                className={`cancellation__progress-step ${
                  currStep.progressWidth === 100
                    ? 'cancellation__progress-step--active'
                    : false
                }`}
              ></div>
            </div>
          </div>
          <CancelBetaStep
            userId={user_id}
            email={email}
            username={username}
            firstName={firstname}
            displayStep={currStep.step === StepName.BETA}
            goToNextStep={this.goToNextStep}
          />
          <CancelFeedbackStep
            userId={user_id}
            email={email}
            displayStep={currStep.step === StepName.FEEDBACK}
            feedback={this.state.cancelSubscriptionFeedback}
            onReasonsChange={reasons => {
              this.setState({
                cancelSubscriptionFeedback: {
                  ...this.state.cancelSubscriptionFeedback,
                  reasons,
                },
              })
            }}
            onRivalEditorChange={(rivalEditor: string) => {
              this.setState({
                cancelSubscriptionFeedback: {
                  ...this.state.cancelSubscriptionFeedback,
                  rivalEditor,
                },
              })
            }}
            onCommentChange={comment => {
              this.setState({
                cancelSubscriptionFeedback: {
                  ...this.state.cancelSubscriptionFeedback,
                  comment,
                },
              })
            }}
            goToNextStep={this.goToNextStep}
          />
          <CancelConfirmStep
            userId={user_id}
            email={email}
            cancelMembership={this.cancelMembership}
            refundPeriodExpired={refundPeriodExpired}
            subscriptionExpirationDate={
              this.props.subscription.current_period_ends_at
            }
            displayStep={currStep.step === StepName.CONFIRM}
            loading={this.state.stepActionLoading}
            cancellationConfirmed={this.state.cancellationConfirmed}
          />
        </div>
        <div className="back-to-dashboard">
          <a href="/" target="_self" rel="noopener noreferrer">
            &#60; Back to dashboard
          </a>
        </div>
      </div>
    )
  }

  private refundPeriodExpired() {
    let refundPeriodExpired = true
    if (this.props.subscription) {
      const refundPeriodExpirationMoment = moment(
        this.props.subscription.current_period_started_at
      ).add(7, 'day')

      refundPeriodExpired =
        moment().diff(refundPeriodExpirationMoment, 'minute') > 0
    }

    return refundPeriodExpired
  }

  private goToNextStep = () => {
    this.setState({
      currStepIndex: this.state.currStepIndex + 1,
    })
  }

  private submitRefundTicket = async () => {
    if (!this.props.subscription) {
      return
    }

    try {
      await this.props.apiClient.submitRefundTicket(
        this.props.accountDetails.user_id,
        this.props.subscription.plan_code,
        this.state.cancelSubscriptionFeedback
      )
    } catch (e) {
      notification.error({
        message:
          'There was an issue submitting your refund request. Please contact support manually.',
      })
      return
    }

    notification.success({ message: 'Refund ticket submitted successfully.' })
  }

  private submitCancellationReasons = async () => {
    if (!this.props.subscription) {
      return
    }

    try {
      await this.props.apiClient.submitCancellationReasons(
        this.props.accountDetails.user_id,
        this.props.accountDetails.email,
        this.props.subscription.plan_code,
        moment(this.props.subscription.activated_at).format(
          'DD.MM.YYYY HH:mm:ss'
        ),
        moment().format('DD.MM.YYYY HH:mm:ss'),
        this.state.cancelSubscriptionFeedback.reasons.join(','),
        this.state.cancelSubscriptionFeedback.comment,
        this.state.cancelSubscriptionFeedback.rivalEditor || ''
      )
    } catch (e) {}
  }

  private cancelMembership = async () => {
    if (!this.props.subscription) {
      return
    }

    this.setState({ stepActionLoading: true })

    try {
      await this.props.apiClient.cancelSubscription(
        this.state.cancelSubscriptionFeedback,
        this.props.subscription.uuid
      )
    } catch (e) {
      notification.error({
        message: 'An error occurred while submitting your cancel request.',
      })
      this.setState({ stepActionLoading: false })
      return
    }

    notification.success({
      message: 'Your membership has been cancelled.',
    })

    TagManager.dataLayer({
      dataLayer: {
        event: 'subscriptionCanceled',
        conversionValue: this.props.subscription.plan_name
          ? this.props.subscription.plan_code
          : 'ERROR - Not on a plan',
        userId: this.props.accountDetails.user_id,
        emailHash: String(sha256(this.props.accountDetails.email)),
      },
    })

    mixpanel.track('subscriptionCancelled', {
      conversionValue: this.props.subscription.plan_name
        ? this.props.subscription.plan_code
        : 'ERROR - Not on a plan',
      userId: this.props.accountDetails.user_id,
      emailHash: String(sha256(this.props.accountDetails.email)),
    })

    await this.submitCancellationReasons()

    if (!this.refundPeriodExpired()) {
      await this.submitRefundTicket()
    }

    this.setState({
      stepActionLoading: false,
      cancellationConfirmed: true,
    })

    setTimeout(() => (window.location.href = Page.Home), 2000)
  }
}
