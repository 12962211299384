import React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Heading, HeadingLevel } from '../components/Heading'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { Spinner } from '../components/Spinner'
import { Container } from '../containers/ContainersList'
import { CustomTemplateForm } from '../containers/CustomTemplateForm'
import { triggerOpenedScreenEvent } from '../helpers/Util'

type Props = {
  apiClient: ApiClient

  accountDetails: AccountDetails
  containers: Container[]
  remainingCustomTemplates: number

  containersAlreadyFetched: boolean
  loadingInitContent: boolean
  onDidLoadInitContent: () => void

  getContainers: () => Promise<void>
}

type State = {
  loading: boolean
}

export class CustomTemplatePage extends React.Component<Props, State> {
  public state: State = {
    loading: false,
  }

  componentDidMount() {
    this.getData()
    triggerOpenedScreenEvent(
      'openedCustomTemplateScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (
      !this.props.containersAlreadyFetched ||
      this.props.loadingInitContent ||
      this.state.loading
    ) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    return (
      <div className="page-content">
        <Heading
          heading="Create Custom Template"
          level={HeadingLevel.First}
          goBack={true}
        />
        <CustomTemplateForm
          apiClient={this.props.apiClient}
          containers={this.props.containers}
        />
        {this.renderLimitModal()}
      </div>
    )
  }

  private renderLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={this.props.remainingCustomTemplates <= 0}
        resource="Custom Templates"
        accountDetails={this.props.accountDetails}
      />
    )
  }

  private async getData() {
    const { onDidLoadInitContent, containersAlreadyFetched, getContainers } = {
      ...this.props,
    }

    if (this.props.loadingInitContent || !containersAlreadyFetched) {
      this.setState({ loading: true })
      await getContainers()
      this.setState({ loading: false })
    }
    onDidLoadInitContent()
  }
}
