import React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Heading, HeadingLevel } from '../components/Heading'
import {
  UpdatedPersonalInfo,
  UpdatePersonalInfo,
} from '../containers/UpdatePersonalInfo'
import { ChangePassword } from '../containers/ChangePassword'
import { TwoFactorAuth } from '../components/TwoFactorAuth'
import { ConnectedAccounts } from '../components/ConnectedAccounts'
import { AccountConnection } from '../api-client/interface/AccountConnection'
import { SSHPublicKey } from '../components/SSHPublicKey'
import { DeleteAccountAction } from '../components/DeleteAccountAction'
import { CancelMembershipAction } from '../components/CancelMembershipAction'
import { notification } from '../components/Notification'
import { Spinner } from '../components/Spinner'
import { Page } from '../App'
import { AccountLabel } from '../components/Input'
import { focusOnElement, triggerOpenedScreenEvent } from '../helpers/Util'

const USERNAME_REGEX = new RegExp('^[a-zA-Z0-9-]*$')

type Props = {
  apiClient: ApiClient

  hasActiveSubscription: boolean
  accountDetails: AccountDetails
  hasInitialPassword: boolean
  connectedAccounts: AccountConnection[]

  connectedAccountsAlreadyFetched: boolean
  loadingInitContent: boolean
  onDidLoadInitContent: () => void

  getConnectedAccounts: () => Promise<void>
  refreshAccountDetails: () => Promise<void>
  refreshHasInitialPassword: () => Promise<void>
}

type State = {
  loading: boolean
  loadingPersonalInfo: boolean
  loadingPassword: boolean
  loading2FA: boolean
  loadingConnectedAccounts: boolean
  loadingPublicSSHKey: boolean
  loadingDeleteAccount: boolean

  twoFactorAuthEnabled: boolean
  publicSSHKey: string

  personalInfoFormExpanded: boolean

  accountDeleted: boolean
}

export class AccountPage extends React.Component<Props, State> {
  public state: State = {
    loading: true,
    loadingPersonalInfo: false,
    loadingPassword: false,
    loading2FA: false,
    loadingConnectedAccounts: false,
    loadingPublicSSHKey: false,
    loadingDeleteAccount: false,

    twoFactorAuthEnabled: false,
    publicSSHKey: '',

    personalInfoFormExpanded: false,

    accountDeleted: false,
  }

  componentDidMount() {
    this.getData()
    triggerOpenedScreenEvent(
      'openedAccountScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (this.props.loadingInitContent || this.state.loading) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    return (
      <div className="page-content">
        <Heading heading="Account" level={HeadingLevel.First}>
          <span className="account-id">
            ID: {this.props.accountDetails.user_id}
          </span>
        </Heading>

        <UpdatePersonalInfo
          apiClient={this.props.apiClient}
          accountDetails={this.props.accountDetails}
          hasInitialPassword={this.props.hasInitialPassword}
          loading={this.state.loadingPersonalInfo}
          expanded={this.state.personalInfoFormExpanded}
          handleUpdatePersonalInfo={this.handleUpdatePersonalInfo}
          toggleForm={(personalInfoFormExpanded: boolean) =>
            this.setState({ personalInfoFormExpanded })
          }
        />
        <ChangePassword
          apiClient={this.props.apiClient}
          hasInitialPassword={this.props.hasInitialPassword}
          loading={this.state.loadingPassword}
          handleChangePassword={this.handleChangePassword}
          handleSetInitialPassword={this.handleSetInitialPassword}
        />
        <TwoFactorAuth
          apiClient={this.props.apiClient}
          toggled={this.state.twoFactorAuthEnabled}
          loading={this.state.loading2FA}
          setToggleStatus={(twoFactorAuthEnabled: boolean) =>
            this.setState({ twoFactorAuthEnabled })
          }
        />
        <ConnectedAccounts
          connectedAccounts={this.props.connectedAccounts}
          loadingConnectedAccounts={this.state.loadingConnectedAccounts}
          onConnectAccount={this.handleConnectAccount}
          handleDisconnectAccount={this.handleDisconnectAccount}
        />
        <SSHPublicKey
          apiClient={this.props.apiClient}
          publicSSHKey={this.state.publicSSHKey}
        />
        {this.props.hasActiveSubscription &&
        !this.props.accountDetails.trial ? (
          <CancelMembershipAction
            onClick={() => window.open(Page.CancelMembership, '_blank')}
          />
        ) : (
          <DeleteAccountAction
            loading={this.state.loadingDeleteAccount}
            hasInitialPassword={this.props.hasInitialPassword}
            accountDeleted={this.state.accountDeleted}
            onDelete={this.handleDeleteAccount}
          />
        )}
      </div>
    )
  }

  private async getData() {
    await this.isTwoFactorAuthEnabled()
    await this.getPublicSSHKey()

    const {
      onDidLoadInitContent,
      connectedAccountsAlreadyFetched,
      getConnectedAccounts,
    } = {
      ...this.props,
    }

    if (this.props.loadingInitContent || !connectedAccountsAlreadyFetched) {
      this.setState({ loading: true })
      await getConnectedAccounts()
    }

    this.setState({ loading: false })
    onDidLoadInitContent()
  }

  //fetching data
  private async isTwoFactorAuthEnabled() {
    try {
      this.setState({
        twoFactorAuthEnabled: await this.props.apiClient.has2FAEnabled(),
      })
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while getting your account information.',
        description: e,
      })
    }
  }

  private getPublicSSHKey = async () => {
    try {
      this.setState({
        publicSSHKey: await this.props.apiClient.getAccountSSHPublicKey(),
      })
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while getting your public SSH key.',
        description: e,
      })
    }
  }

  //handle changes
  private handleUpdatePersonalInfo = async (changes: UpdatedPersonalInfo) => {
    const validUsername = USERNAME_REGEX.test(
      changes.newUsername || this.props.accountDetails.username
    )
    if (!validUsername) {
      notification.warn({
        message: 'You have entered an invalid username.',
        description:
          'Usernames can contain letters (a-z), numbers (0-9) and dashes (-).',
      })
      focusOnElement(AccountLabel.Username)
      return
    }

    this.setState({ loadingPersonalInfo: true })

    //update general info
    try {
      await this.props.apiClient.updateAccount({
        firstname: changes.newFirstName || this.props.accountDetails.firstname,
        lastname: changes.newLastName || this.props.accountDetails.lastname,
        username: changes.newUsername || this.props.accountDetails.username,
        company: changes.newCompanyName || this.props.accountDetails.company,
      })
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while updating your account information.',
        description: e,
      })
      focusOnElement(AccountLabel.Username)
      this.setState({
        loadingPersonalInfo: false,
      })
      return
    }

    //update email
    if (
      changes.newEmail &&
      changes.newEmail !== this.props.accountDetails.email
    ) {
      try {
        await this.props.apiClient.changeEmail(
          changes.newEmail,
          changes.passwordConfirmation
        )
      } catch (e: any) {
        notification.error({
          message: 'An error ocurred while updating your email address.',
          description: e,
        })
        this.setState({
          loadingPersonalInfo: false,
        })
        return
      }
    }

    await this.props.refreshAccountDetails()
    this.setState({
      loadingPersonalInfo: false,
      personalInfoFormExpanded: false,
    })

    notification.success({
      message: 'You have successfully updated your account details.',
    })
  }

  private handleChangePassword = async (
    oldPassword: string,
    newPassword: string,
    repeatNewPassword: string
  ) => {
    if (newPassword !== repeatNewPassword) {
      notification.warn({
        message: 'The password and confirmation password do not match.',
      })
      focusOnElement(AccountLabel.NewPassword)
      return false
    }

    this.setState({ loadingPassword: true })

    try {
      await this.props.apiClient.updateAccountPassword(
        oldPassword,
        newPassword,
        repeatNewPassword
      )
      this.setState({
        loadingPassword: false,
      })

      notification.success({
        message: 'You have successfully changed your password.',
      })

      return true
    } catch (e) {
      notification.error({
        message: 'An error ocurred while changing your password.',
        description: 'Wrong existing password',
      })
      focusOnElement(AccountLabel.OldPassword)
      this.setState({
        loadingPassword: false,
      })
      return false
    }
  }

  private handleSetInitialPassword = async (
    newPassword: string,
    repeatNewPassword: string
  ) => {
    if (newPassword !== repeatNewPassword) {
      notification.warn({
        message: 'The password and confirmation password do not match.',
      })
      focusOnElement(AccountLabel.NewPassword)
      return false
    }

    this.setState({ loadingPassword: true })

    try {
      await this.props.apiClient.setInitialPassword(newPassword)
      await this.props.refreshHasInitialPassword()
      this.setState({
        loadingPassword: false,
      })

      notification.success({
        message: 'You have successfully set your initial password.',
      })

      return true
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while setting your password.',
        description: e,
      })
      focusOnElement(AccountLabel.NewPassword)
      this.setState({
        loadingPassword: false,
      })
      return false
    }
  }

  private handleConnectAccount = async (authLink: string) => {
    let authTab = window.open(authLink)

    if (authTab) {
      const timer = setInterval(async () => {
        if (authTab && authTab.closed) {
          await this.props.getConnectedAccounts()
          clearInterval(timer)
        }
      }, 500)
    }
  }

  private handleDisconnectAccount = async (
    provider: string,
    authId: string
  ) => {
    try {
      this.setState({ loadingConnectedAccounts: true })

      await this.props.apiClient.removeConnectedAccount(provider, authId)
      await this.props.getConnectedAccounts()

      notification.success({
        message: 'You have successfully disconnected the account.',
      })
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while disconnecting the account.',
        description: e,
      })
      focusOnElement(AccountLabel.UsernameConfirmation)
    } finally {
      this.setState({ loadingConnectedAccounts: false })
    }
  }

  private handleDeleteAccount = async (passwordConfirmation: string) => {
    this.setState({ loadingDeleteAccount: true })

    try {
      await this.props.apiClient.deleteAccount(passwordConfirmation)
      this.setState(
        {
          accountDeleted: true,
          loadingDeleteAccount: false,
        },
        () => window.location.replace('https://codeanywhere.com/signout')
      )
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while deleting your account.',
        description: e,
      })
      this.setState({ loadingDeleteAccount: false })
      focusOnElement(AccountLabel.DeleteAccountConfirmation)
      return
    }
  }
}
