import * as React from 'react'
import { PAYMENT } from '../helpers/image-imports/Payment'

type Props = {
  cardType?: string
  lastFourDigits?: string
  expirationDate?: string
}

export class CreditCardInfo extends React.PureComponent<Props> {
  render() {
    return (
      <div className="current-payment-method-container">
        <span className="title">Current payment method</span>
        <span className="current-payment-method">
          {this.props.cardType && (
            <img src={PAYMENT[this.props.cardType]} alt="Payment method icon" />
          )}
          <span className="card-number">{`**** **** **** ${this.props.lastFourDigits}`}</span>
          <span className="expiration-date">{this.props.expirationDate}</span>
        </span>
      </div>
    )
  }
}
