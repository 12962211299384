import * as React from 'react'

type Props = {
  step: number
  description: string
}

export class ReferralStep extends React.PureComponent<Props> {
  render() {
    return (
      <div className="referral-step-card">
        <span className="step">Step {this.props.step}</span>
        <span className="description">{this.props.description}</span>
      </div>
    )
  }
}
