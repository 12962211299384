import * as React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Link, Redirect } from 'react-router-dom'
import {
  FTPConnection,
  FTPSConnection,
  GoogleDriveConnection,
  SSHConnection,
} from '../api-client/interface/Connection'
import { parse as parseQs } from 'querystring'
import { ConnectionCard } from '../components/ConnectionCard'
import { Heading, HeadingLevel } from '../components/Heading'
import { notification } from '../components/Notification'
import { Modal } from '../components/Modal'
import { ConnectionForm } from './ConnectionForm'
import { ConnectionLabel, Input } from '../components/Input'
import { Button } from '../components/Button'
import { ConnectionFiltersData } from '../components/ConnectionFilters'
import { Pagination, PaginationSize } from '../components/Pagination'
import { focusOnElement } from '../helpers/Util'
import { EmptyList } from '../components/EmptyList'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { Container } from './ContainersList'
import { Page } from '../App'
import { Truncate } from '../components/Truncate'
import { CONTAINER_STATE } from '../helpers/ContainerState'

export type Connection = (
  | SSHConnection
  | FTPConnection
  | FTPSConnection
  | GoogleDriveConnection
) & {
  processingAction: boolean
}

export enum ConnectionFilter {
  All = 'all',
  SSH = 'ssh',
  FTP = 'ftp',
  GoogleDrive = 'gdrive',
}

type Props = {
  apiClient: ApiClient
  accountDetails: AccountDetails
  inHome?: boolean
  connections: Connection[]
  containers: Container[]
  sshAmount?: number
  ftpAmount?: number
  gdriveAmount?: number
  refreshConnectionsList(): Promise<void>
  switchPage: (page: string) => void
}

type State = {
  activeFilter: ConnectionFilter

  selectedConnection?: Connection
  editModalVisible: boolean
  removeModalVisible: boolean
  renameModalVisible: boolean

  removeConfirmation: string
  newConnectionName: string

  currentPaginationPage: number
  paginationSize: PaginationSize

  chooseContainerModalVisible: boolean
  selectedContainer?: Container
  createContainerModalVisible: boolean
  purchaseContainersModalVisible: boolean

  page: string
}

export class ConnectionsList extends React.Component<Props, State> {
  public state: State = {
    activeFilter: ConnectionFilter.All,

    selectedConnection: undefined,
    editModalVisible: false,
    removeModalVisible: false,
    renameModalVisible: false,

    removeConfirmation: '',
    newConnectionName: '',

    currentPaginationPage: 1,
    paginationSize: PaginationSize.Nine,

    chooseContainerModalVisible: false,
    createContainerModalVisible: false,
    purchaseContainersModalVisible: false,

    page: '',
  }

  componentDidMount() {
    this.parseConnectionsFilterFromUrl()
  }

  private switchPage = (page: string) => {
    window.history.pushState({ prevUrl: window.location.href }, '', page)
    this.setState({ page })
  }

  render() {
    if (this.state.page === Page.NewContainer) {
      window.scrollTo(0, 0)
      return <Redirect to={Page.NewContainer} />
    }

    const connectionFiltersData: ConnectionFiltersData = {
      activeFilter: this.state.activeFilter,
      onChangeActiveFilter: this.handleChangeConnectionsFilter,
      sshAmount: this.props.sshAmount || 0,
      ftpAmount: this.props.ftpAmount || 0,
      gdriveAmount: this.props.gdriveAmount || 0,
    }

    const filteredConnections = this.applyConnectionsFilter()
    const displayedConnections = this.props.inHome
      ? filteredConnections.slice(0, 5)
      : filteredConnections.slice(
          (this.state.currentPaginationPage - 1) * this.state.paginationSize,
          this.state.currentPaginationPage * this.state.paginationSize
        )

    return (
      <>
        <Heading
          heading="Connections"
          level={HeadingLevel.First}
          connectionFiltersData={connectionFiltersData}
          divider={true}
        />
        {displayedConnections.length ? (
          <div className="card-grid">
            {displayedConnections.map(connection => (
              <div className="card-grid__item" key={connection.id}>
                <ConnectionCard
                  connection={connection}
                  displayEditModal={() =>
                    this.setState({
                      editModalVisible:
                        connection.type === 'gdrive' ? false : true,
                      renameModalVisible:
                        connection.type === 'gdrive' ? true : false,
                      selectedConnection: connection,
                    })
                  }
                  displayRemoveModal={() =>
                    this.setState({
                      removeModalVisible: true,
                      selectedConnection: connection,
                    })
                  }
                  onOpenEditorClick={() =>
                    this.handleOpenEditorClick(connection)
                  }
                />
              </div>
            ))}
            {this.props.inHome && filteredConnections.length > 5 && (
              <div className="card-grid__item">
                <Link
                  to={`/connections?connections-filter=${this.state.activeFilter}`}
                  className="connection-card container-card"
                >
                  <div className="see-all card">
                    <span>See all</span>
                  </div>
                </Link>
              </div>
            )}
          </div>
        ) : (
          <EmptyList resource="connections" />
        )}
        {!!filteredConnections.length && !this.props.inHome && (
          <Pagination
            total={filteredConnections.length}
            paginationSize={this.state.paginationSize}
            currentPage={this.state.currentPaginationPage}
            onChangePageSize={paginationSize =>
              this.setState({ paginationSize, currentPaginationPage: 1 })
            }
            onChangePage={currentPaginationPage =>
              this.setState({ currentPaginationPage })
            }
          />
        )}
        {this.renderEditModal()}
        {this.renderRemoveModal()}
        {this.renderRenameModal()}
        {this.renderChooseContainerModal()}
        {this.renderCreateContainerModal()}
        {this.renderPurchaseContainersModalVisible()}
      </>
    )
  }

  private renderEditModal = () => {
    if (this.state.selectedConnection && this.state.editModalVisible) {
      return (
        <Modal
          visible={this.state.editModalVisible}
          closeable={true}
          onClose={this.hideEditConnectionModal}
        >
          <Modal.Header>
            Edit connection{' '}
            <span className="purple-highlight">
              {this.state.selectedConnection.name}
            </span>
          </Modal.Header>
          <Modal.Body>
            <ConnectionForm
              apiClient={this.props.apiClient}
              editConnection={this.state.selectedConnection}
              onCancel={this.hideEditConnectionModal}
              onFinish={() => {
                this.hideEditConnectionModal()
                this.props.refreshConnectionsList()
              }}
            />
          </Modal.Body>
        </Modal>
      )
    }
  }

  private renderRemoveModal = () => {
    if (this.state.selectedConnection && this.state.removeModalVisible) {
      return (
        <Modal
          visible={this.state.removeModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              removeModalVisible: false,
              removeConfirmation: '',
              selectedConnection: undefined,
            })
          }
        >
          <Modal.Header>
            Remove{' '}
            <span className="purple-highlight">
              {this.state.selectedConnection.name}
            </span>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleRemoveConnection}>
              <Input
                label={ConnectionLabel.RemoveConfirmation}
                value={this.state.removeConfirmation}
                autofocus={true}
                onChangeInput={removeConfirmation =>
                  this.setState({
                    removeConfirmation,
                  })
                }
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.selectedConnection.processingAction}
                  onClick={() =>
                    this.setState({
                      removeModalVisible: false,
                      removeConfirmation: '',
                      selectedConnection: undefined,
                    })
                  }
                />
                <Button
                  value="Remove"
                  type="danger"
                  submit={true}
                  disabled={
                    this.state.selectedConnection.processingAction ||
                    this.state.removeConfirmation !==
                      this.state.selectedConnection.name
                  }
                  loading={this.state.selectedConnection.processingAction}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            You are about to destroy{' '}
            <strong>{this.state.selectedConnection.name}</strong>. This action
            is irreversible.
          </Modal.Footer>
        </Modal>
      )
    }
  }

  private renderRenameModal = () => {
    if (this.state.renameModalVisible) {
      return (
        <Modal
          visible={this.state.renameModalVisible}
          closeable={true}
          onClose={() => {
            this.setState({
              renameModalVisible: false,
              newConnectionName: '',
              selectedConnection: undefined,
            })
          }}
        >
          <Modal.Header>
            Rename{' '}
            <span className="purple-highlight">
              {this.state.selectedConnection?.name}
            </span>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleRenameGoogleDriveConnection}>
              <Input
                label={ConnectionLabel.Name}
                value={this.state.newConnectionName}
                autofocus={true}
                onChangeInput={newConnectionName =>
                  this.setState({
                    newConnectionName,
                  })
                }
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.selectedConnection?.processingAction}
                  onClick={() =>
                    this.setState({
                      renameModalVisible: false,
                      newConnectionName: '',
                      selectedConnection: undefined,
                    })
                  }
                />
                <Button
                  value="Save"
                  type="success"
                  submit={true}
                  disabled={
                    this.state.selectedConnection?.processingAction ||
                    !this.state.newConnectionName ||
                    this.state.newConnectionName ===
                      this.state.selectedConnection?.name
                  }
                  loading={this.state.selectedConnection?.processingAction}
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )
    }

    return null
  }

  private handleRemoveConnection = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!this.state.selectedConnection) {
      this.setState({
        removeModalVisible: false,
        removeConfirmation: '',
        selectedConnection: undefined,
      })
      notification.error({
        message: 'An error occurred while removing the connection.',
      })
      return
    }

    this.setState({
      selectedConnection: {
        ...this.state.selectedConnection,
        processingAction: true,
      },
    })

    try {
      await this.props.apiClient.removeConnection(
        this.state.selectedConnection.id
      )
      await this.props.refreshConnectionsList()

      notification.success({
        message: 'You have successfully removed the connection.',
      })
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while removing the connection.',
        description: e,
      })
      focusOnElement(ConnectionLabel.RemoveConfirmation)
    } finally {
      this.setState({
        removeModalVisible: false,
        removeConfirmation: '',
        selectedConnection: undefined,
      })
    }
  }

  private handleRenameGoogleDriveConnection = async (
    e: React.SyntheticEvent
  ) => {
    e.preventDefault()

    if (!this.state.selectedConnection) {
      this.setState({
        renameModalVisible: false,
        newConnectionName: '',
        selectedConnection: undefined,
      })
      notification.error({
        message: 'An error occurred while renaming the connection.',
      })
      return
    }

    this.setState({
      selectedConnection: {
        ...this.state.selectedConnection,
        processingAction: true,
      },
    })

    try {
      await this.props.apiClient.renameConnection(
        this.state.selectedConnection.id,
        this.state.newConnectionName
      )
      await this.props.refreshConnectionsList()

      notification.success({
        message: 'You have successfully renamed the connection.',
      })
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while renaming the connection.',
        description: e,
      })
      focusOnElement(ConnectionLabel.Name)
    } finally {
      this.setState({
        renameModalVisible: false,
        newConnectionName: '',
        selectedConnection: undefined,
      })
    }
  }

  private renderChooseContainerModal = () => {
    if (this.state.chooseContainerModalVisible) {
      return (
        <Modal
          visible={this.state.chooseContainerModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              selectedConnection: undefined,
              selectedContainer: undefined,
              chooseContainerModalVisible: false,
            })
          }
        >
          <Modal.Header>Choose the editor you want to open</Modal.Header>
          <Modal.Body>
            <div className="table-wrapper">
              <table className="table table--is-connection-editors">
                <thead>
                  <tr className="table-header">
                    <th className="table-column">Container name</th>
                    <th className="table-column">State</th>
                    <th className="table-column"></th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {this.props.containers.map(container => (
                    <tr
                      key={container.id}
                      className={`table-row${
                        this.canOpenIDE(container.state) ? '' : ' disabled'
                      }`}
                    >
                      <td className="table-column">
                        <Truncate>{container.name}</Truncate>
                      </td>
                      <td className="table-column">
                        {CONTAINER_STATE[container.state]}
                      </td>
                      <td className="table-column">
                        <Button
                          type="success"
                          value="Open IDE"
                          disabled={!this.canOpenIDE(container.state)}
                          onClick={() => this.openIDE(container.slug)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )
    }
  }

  private renderCreateContainerModal = () => {
    if (this.state.createContainerModalVisible) {
      return (
        <Modal
          visible={this.state.createContainerModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              selectedConnection: undefined,
              createContainerModalVisible: false,
            })
          }
        >
          <Modal.Header>Warning</Modal.Header>
          <Modal.Body>
            <span className="modal-warning-message">
              You have to create a container before you can open a connection in
              the editor.
            </span>
            <div className="button-row bottom-margin">
              <Button
                value="Cancel"
                onClick={() =>
                  this.setState({
                    selectedConnection: undefined,
                    createContainerModalVisible: false,
                  })
                }
              />
              <Button
                value="Take me there"
                type="success"
                onClick={() =>
                  this.setState(
                    {
                      selectedConnection: undefined,
                      createContainerModalVisible: false,
                    },
                    () => this.switchPage(Page.NewContainer)
                  )
                }
              />
            </div>
          </Modal.Body>
        </Modal>
      )
    }
  }

  private renderPurchaseContainersModalVisible = () => {
    return (
      <ReachedLimitModal
        visible={this.state.purchaseContainersModalVisible}
        resource="Containers"
        accountDetails={this.props.accountDetails}
        onClose={() =>
          this.setState({
            selectedConnection: undefined,
            purchaseContainersModalVisible: false,
          })
        }
      />
    )
  }

  private applyConnectionsFilter() {
    const fetchedConnections = this.props.connections.slice().reverse()

    if (this.state.activeFilter === ConnectionFilter.SSH) {
      return fetchedConnections.filter(connection => connection.type === 'ssh')
    } else if (this.state.activeFilter === ConnectionFilter.FTP) {
      return fetchedConnections.filter(
        connection => connection.type === 'ftp' || connection.type === 'ftps'
      )
    } else if (this.state.activeFilter === ConnectionFilter.GoogleDrive) {
      return fetchedConnections.filter(
        connection => connection.type === 'gdrive'
      )
    } else {
      return fetchedConnections
    }
  }

  private parseConnectionsFilterFromUrl() {
    const query = parseQs(window.location.search.slice(1))

    if (query['connections-filter']) {
      this.setState({
        activeFilter: query['connections-filter'] as ConnectionFilter,
      })
    }

    return query
  }

  private handleChangeConnectionsFilter = (activeFilter: ConnectionFilter) => {
    const currentQuery = this.parseConnectionsFilterFromUrl()
    const containersQuery = currentQuery['containers-filter']
      ? `containers-filter=${currentQuery['containers-filter']}`
      : ''

    const newQuery =
      !document.location.search || !containersQuery
        ? `?connections-filter=${activeFilter}`
        : `?${containersQuery}&connections-filter=${activeFilter}`

    window.history.replaceState(
      {},
      '',
      `${document.location.pathname}${newQuery}`
    )
    this.setState({ activeFilter })
  }

  private hideEditConnectionModal = () => {
    this.setState({
      editModalVisible: false,
      selectedConnection: undefined,
    })
  }

  private handleOpenEditorClick = async (connection: Connection) => {
    this.setState(
      {
        selectedConnection: connection,
      },
      () => {
        if (!this.props.accountDetails.limits.devboxes) {
          this.setState({
            purchaseContainersModalVisible: true,
          })
        } else if (!this.props.containers.length) {
          this.setState({
            createContainerModalVisible: true,
          })
        } else if (this.props.containers.length === 1) {
          this.openIDE(this.props.containers[0].slug)
        } else {
          this.setState({
            chooseContainerModalVisible: true,
          })
        }
      }
    )
  }

  private openIDE = (containerSlug?: string) => {
    window.open(
      String(
        containerSlug
          ? `${
              process.env.REACT_APP_IDE_URL
            }/${containerSlug.toLowerCase()}?connections=true`
          : process.env.REACT_APP_EDITOR_URL
      )
    )

    this.setState({
      selectedConnection: undefined,
      selectedContainer: undefined,
      chooseContainerModalVisible: false,
    })
  }

  private canOpenIDE(containerState: number) {
    return containerState < 90 || containerState > 93
  }
}
