import * as React from 'react'
import { SpinnerIcon } from '../helpers/image-imports/IconComponent'

type Props = {
  size?: string
  className?: string
}

export class Spinner extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={`spinner${
          this.props.className ? ` ${this.props.className}` : ''
        }${this.props.size ? ' spinner--size-' + this.props.size : ''}`}
      >
        <SpinnerIcon />
      </div>
    )
  }
}
