import * as React from 'react'
import { Spinner } from './Spinner'

type Props = {
  enabled: boolean
  containerId?: number
  onClick: () => Promise<void> | void
}

export class OpenIDEButton extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={`button button--type-success button--type-block card-button tab-focus${
          !this.props.enabled ? ' button--state-disabled' : ''
        }`}
        onClick={this.props.onClick}
        tabIndex={0}
      >
        {this.props.enabled ? (
          <span>
            {this.props.containerId &&
            this.props.containerId <= 2427937 &&
            process.env.REACT_APP_API_BASE_URL !== undefined &&
            !String(process.env.REACT_APP_API_BASE_URL).includes('web2014')
              ? 'Open Legacy IDE'
              : 'Open IDE'}
          </span>
        ) : (
          <Spinner className="button-spinner-white" />
        )}
      </div>
    )
  }
}
