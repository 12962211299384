import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { onKey } from '../helpers/Util'
import { notification } from './Notification'

type Props = {
  content: string
}

export class CopyText extends React.PureComponent<Props> {
  render() {
    return (
      <div className="copy-component">
        <span className="content">{this.props.content}</span>
        <CopyToClipboard
          text={this.props.content}
          onCopy={() => {
            notification.success({
              message: 'Copied successfully!',
              duration: 1500,
            })
          }}
        >
          <span
            id="copy-button"
            className="button copy-component__button tab-focus"
            onKeyDown={e =>
              onKey(e, 'Enter', () =>
                document.getElementById('copy-button')?.click()
              )
            }
            onKeyUp={e =>
              onKey(e, ' ', () =>
                document.getElementById('copy-button')?.click()
              )
            }
            tabIndex={0}
          >
            Copy
          </span>
        </CopyToClipboard>
      </div>
    )
  }
}
