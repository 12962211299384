import React from 'react'
import { Redirect } from 'react-router-dom'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Page } from '../App'
import { Button } from '../components/Button'
import { CreateIcon, CreateOption } from '../components/CreateOption'
import { Heading, HeadingLevel } from '../components/Heading'
import { CustomTemplateLabel, Input } from '../components/Input'
import { Modal } from '../components/Modal'
import { notification } from '../components/Notification'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { Spinner } from '../components/Spinner'
import { Container } from '../containers/ContainersList'
import { TemplatesList } from '../containers/TemplatesList'
import { Templates } from '../helpers/TransformTemplates'
import { focusOnElement, triggerOpenedScreenEvent } from '../helpers/Util'

type Props = {
  apiClient: ApiClient

  accountDetails: AccountDetails
  containers: Container[]
  remainingContainers: number
  templates: Templates
  remainingCustomTemplates: number

  containersAlreadyFetched: boolean
  templatesAlreadyFetched: boolean
  loadingInitContent: boolean
  onDidLoadInitContent: () => void

  getContainers: () => Promise<void>
  getTemplates: () => Promise<void>
}

type State = {
  loading: boolean
  page: Page
  processingAction: boolean

  customTemplatesLimitModalVisible: boolean
  createContainerModalVisible: boolean
  newContainerName: string
  alwaysOn: boolean

  removeModalVisible: boolean
  removeTemplateName: string
  removeTemplateId: string
  removeConfirmation: string
}

export class TemplatesPage extends React.Component<Props, State> {
  public state: State = {
    loading: false,
    page: Page.Templates,
    processingAction: false,

    customTemplatesLimitModalVisible: false,
    createContainerModalVisible: false,
    newContainerName: '',
    alwaysOn: false,

    removeModalVisible: false,
    removeTemplateName: '',
    removeTemplateId: '',
    removeConfirmation: '',
  }

  private switchPage = (page: Page) => {
    window.history.pushState({ prevUrl: window.location.href }, '', page)
    this.setState({ page })
  }

  componentDidMount() {
    this.getData()
    triggerOpenedScreenEvent(
      'openedTemplatesScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (this.state.page === Page.CustomTemplate) {
      window.scrollTo(0, 0)
      return <Redirect to={Page.CustomTemplate} />
    }

    if (this.state.page === Page.Containers) {
      window.scrollTo(0, 0)
      return <Redirect to={Page.Containers} />
    }

    if (
      !this.props.containersAlreadyFetched ||
      !this.props.templatesAlreadyFetched ||
      this.props.loadingInitContent ||
      this.state.loading
    ) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    return (
      <>
        <div className="page-content page--is-templates">
          <div className="page-group">
            <Heading heading="Create" level={HeadingLevel.First} />
            <div className="create-options-row">
              <CreateOption
                option="New Container Template"
                icon={CreateIcon.Plus}
                onClick={() => {
                  if (this.props.remainingCustomTemplates > 0) {
                    this.switchPage(Page.CustomTemplate)
                  } else {
                    this.setState({ customTemplatesLimitModalVisible: true })
                  }
                }}
              />
            </div>
          </div>
          <div className="page-group">
            <Heading heading="Container Templates" level={HeadingLevel.First} />
            <TemplatesList
              apiClient={this.props.apiClient}
              tableFormat={true}
              accountDetails={this.props.accountDetails}
              fetchedTemplates={this.props.templates}
              creatingTemplates={this.getTemplatesGettingCreated()}
              onRemoveTemplate={(removeTemplateName, removeTemplateId) =>
                this.setState({
                  removeTemplateName,
                  removeTemplateId,
                  removeModalVisible: true,
                })
              }
            />
          </div>
        </div>
        {this.renderRemoveModal()}
        {this.renderCustomTemplatesLimitModal()}
      </>
    )
  }

  private renderRemoveModal = () => {
    if (this.state.removeModalVisible) {
      return (
        <Modal
          visible={this.state.removeModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              removeModalVisible: false,
              removeTemplateName: '',
              removeTemplateId: '',
              removeConfirmation: '',
            })
          }
        >
          <Modal.Header>
            Remove{' '}
            <span className="purple-highlight">
              {this.state.removeTemplateName}
            </span>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleRemoveTemplate}>
              <Input
                label={CustomTemplateLabel.RemoveConfirmation}
                value={this.state.removeConfirmation}
                autofocus={true}
                onChangeInput={removeConfirmation =>
                  this.setState({
                    removeConfirmation,
                  })
                }
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.processingAction}
                  onClick={() =>
                    this.setState({
                      removeModalVisible: false,
                      removeTemplateName: '',
                      removeTemplateId: '',
                      removeConfirmation: '',
                    })
                  }
                />
                <Button
                  value="Remove"
                  type="danger"
                  submit={true}
                  disabled={
                    this.state.processingAction ||
                    this.state.removeConfirmation !==
                      this.state.removeTemplateName
                  }
                  loading={this.state.processingAction}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            You are about to remove{' '}
            <strong>{this.state.removeTemplateName}</strong>. This action is
            irreversible.
          </Modal.Footer>
        </Modal>
      )
    }
  }

  private renderCustomTemplatesLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={this.state.customTemplatesLimitModalVisible}
        resource="Custom Templates"
        accountDetails={this.props.accountDetails}
        onClose={() =>
          this.setState({ customTemplatesLimitModalVisible: false })
        }
      />
    )
  }

  private async getData() {
    const {
      onDidLoadInitContent,
      containersAlreadyFetched,
      templatesAlreadyFetched,
      getContainers,
      getTemplates,
    } = {
      ...this.props,
    }

    if (this.props.loadingInitContent) {
      await getContainers()
      await getTemplates()
    } else if (!containersAlreadyFetched || !templatesAlreadyFetched) {
      this.setState({ loading: true })
      if (!containersAlreadyFetched) await getContainers()
      if (!templatesAlreadyFetched) await getTemplates()
      this.setState({ loading: false })
    }
    onDidLoadInitContent()
  }

  private handleRemoveTemplate = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.setState({ processingAction: true })

    try {
      await this.props.apiClient.removeCustomStack(this.state.removeTemplateId)
      await this.props.getTemplates()

      notification.success({
        message: 'You have successfully removed the template.',
      })
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while removing the template.',
        description: e,
      })
      focusOnElement(CustomTemplateLabel.RemoveConfirmation)
    } finally {
      this.setState({
        processingAction: false,
        removeModalVisible: false,
        removeTemplateName: '',
        removeTemplateId: '',
        removeConfirmation: '',
      })
    }
  }

  private getTemplatesGettingCreated() {
    return this.props.containers
      .filter(container => container.state >= 201 && container.state <= 203)
      .map(container => container.name)
  }
}
