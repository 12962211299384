export const CONTAINER_STATE_COLOR = (state: number) => {
  switch (state) {
    case 10:
      return 'green'
    case 20:
      return 'red'
    case 11:
    case 12:
    case 13:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
      return 'orange'
    default:
      return 'purple'
  }
}
