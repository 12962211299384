import * as React from 'react'
import { Heading, HeadingLevel } from './Heading'
import { ApiClient } from '../api-client/interface/ApiClient'
import { ToggleSwitch } from './ToggleSwitch'
import { Modal } from './Modal'
import { Button } from './Button'
import QRCode from 'qrcode.react'
import { Input, AccountLabel } from './Input'
import { notification } from './Notification'
import { Spinner } from './Spinner'
import { focusOnElement } from '../helpers/Util'

type Props = {
  apiClient: ApiClient
  toggled: boolean
  loading: boolean
  setToggleStatus: (toggled: boolean) => void
}

type State = {
  enableModalVisible: boolean
  disableModalVisible: boolean
  loadingQRCode: boolean
  processingAction: boolean
  qrCode: string
  qrCodeAlternative: string
  confirmationCode: string
}

export class TwoFactorAuth extends React.Component<Props, State> {
  public state: State = {
    enableModalVisible: false,
    disableModalVisible: false,
    loadingQRCode: false,
    processingAction: false,
    qrCode: '',
    qrCodeAlternative: '',
    confirmationCode: '',
  }

  render() {
    return (
      <>
        <div className="account-section">
          <Heading
            heading="Two-Factor Authentication (2FA)"
            level={HeadingLevel.Second}
          />
          <Heading
            heading="Ensure top-level security for your account with Two-Factor Authentication."
            level={HeadingLevel.Third}
          />
          <div className="toggle-2fa-card">
            <div className="info">
              <span className="title">Authentication App</span>
              <span className="description">
                Use your authentication app (e.g. Google Authenticator or Duo)
                to scan the code.
              </span>
            </div>
            <ToggleSwitch
              id="2fa-toggle"
              toggled={this.props.toggled}
              disabled={this.state.loadingQRCode || this.state.processingAction}
              onChangeToggleState={this.handle2FAToggle}
              label={this.props.toggled ? 'Disable 2FA' : 'Enable 2FA'}
            />
          </div>
        </div>
        {this.renderEnable2FAModal()}
        {this.renderDisable2FAModal()}
      </>
    )
  }

  private renderEnable2FAModal = () => {
    if (this.state.enableModalVisible) {
      return (
        <Modal
          visible={this.state.enableModalVisible}
          closeable={true}
          onClose={() => this.setState({ enableModalVisible: false })}
        >
          <Modal.Header>
            Scan the QR code using your authentication app.
          </Modal.Header>
          <Modal.Body>
            {this.state.loadingQRCode ? (
              <Spinner />
            ) : (
              <form className="qr-modal-body" onSubmit={this.handleEnable2FA}>
                <div className="content">
                  <QRCode value={this.state.qrCode} />
                  <Input
                    label={AccountLabel.VerificationCode}
                    placeholder="Code"
                    value={this.state.confirmationCode}
                    autofocus={true}
                    onChangeInput={confirmationCode =>
                      this.setState({
                        confirmationCode,
                      })
                    }
                  />
                </div>
                <div className="button-row">
                  <Button
                    value="Set up"
                    type="success"
                    submit={true}
                    disabled={
                      this.state.processingAction ||
                      !this.state.confirmationCode
                    }
                    loading={this.state.processingAction}
                  />
                </div>
              </form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="qr-modal-footer">
              <span className="help">Trouble scanning? Enter this code:</span>
              <span className="code">{this.state.qrCodeAlternative}</span>
            </div>
          </Modal.Footer>
        </Modal>
      )
    }

    return null
  }

  private renderDisable2FAModal = () => {
    if (this.state.disableModalVisible) {
      return (
        <Modal
          visible={this.state.disableModalVisible}
          closeable={true}
          onClose={() => this.setState({ disableModalVisible: false })}
        >
          <Modal.Header>Warning</Modal.Header>
          <Modal.Body>
            <div className="modal-warning-message">
              Are you sure you want to disable two-factor authentication?
            </div>
            <div className="button-row">
              <Button
                value="Cancel"
                onClick={() => this.setState({ disableModalVisible: false })}
              />
              <Button
                value="Disable"
                type="danger"
                loading={this.state.processingAction}
                onClick={this.handleDisable2FA}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="qr-modal-footer">
              <span className="help">
                You can enable two-factor authentication again in your account
                settings.
              </span>
            </div>
          </Modal.Footer>
        </Modal>
      )
    }

    return null
  }

  private handle2FAToggle = async () => {
    if (this.props.toggled) {
      this.setState({ disableModalVisible: true })
    } else {
      this.setState({ loadingQRCode: true, enableModalVisible: true })

      const qrCode = await this.get2FAQRCode()
      this.setState({
        qrCode: qrCode.qrFullPath,
        qrCodeAlternative: qrCode.code,
        loadingQRCode: false,
      })
    }
  }

  private get2FAQRCode = async () => {
    try {
      return await this.props.apiClient.get2FAQRCode()
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while generating the QR code.',
        description: e,
      })
      return {
        code: '',
        qrFullPath: '',
      }
    }
  }

  private handleEnable2FA = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.setState({ processingAction: true })

    try {
      await this.props.apiClient.enable2FA(this.state.confirmationCode)
      this.props.setToggleStatus(true)
      this.setState({ enableModalVisible: false, confirmationCode: '' })
      notification.success({
        message: 'You have successfully enabled two-factor authentication.',
      })
    } catch (e) {
      notification.error({
        message: 'You have entered an incorrect confirmation code.',
      })
      focusOnElement(AccountLabel.VerificationCode)
    } finally {
      this.setState({ processingAction: false })
    }
  }

  private handleDisable2FA = async () => {
    this.setState({ processingAction: true })

    try {
      await this.props.apiClient.disable2FA()
      this.props.setToggleStatus(false)
      this.setState({ disableModalVisible: false })
      notification.success({
        message: 'You have disabled two-factor authentication.',
      })
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while disabling two-factor authentication.',
        description: e,
      })
    } finally {
      this.setState({ processingAction: false })
    }
  }
}
