import * as React from 'react'
import { Connection } from '../containers/ConnectionsList'
import { CONNECTION_TYPE } from '../helpers/ConnectionType'
import { CardOption, CardOptions } from './CardOptions'
import { OpenIDEButton } from './OpenIDEButton'
import { Truncate } from './Truncate'
const { Option } = CardOptions

type Props = {
  connection: Connection
  displayEditModal: () => void
  displayRemoveModal: () => void
  onOpenEditorClick: () => void
}

export class ConnectionCard extends React.Component<Props> {
  render() {
    return (
      <div className="card connection">
        <div className="card-header">
          <div className="connection-type">
            {CONNECTION_TYPE[this.props.connection.type]}
          </div>
          <div className="card-title">
            <Truncate>{this.props.connection.name}</Truncate>
          </div>
          <div className="card-extras">
            <CardOptions>
              <Option onClick={this.props.displayEditModal}>
                {this.props.connection.type === 'gdrive'
                  ? CardOption.Rename
                  : CardOption.Edit}
              </Option>
              <Option onClick={this.props.displayRemoveModal}>
                {CardOption.Disconnect}
              </Option>
            </CardOptions>
          </div>
        </div>
        <div className="connection-description">
          {this.props.connection.type === 'gdrive'
            ? ''
            : this.props.connection.hostname}
        </div>
        <OpenIDEButton enabled={true} onClick={this.props.onOpenEditorClick} />
      </div>
    )
  }
}
