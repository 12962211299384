import React from 'react'
import { Redirect } from 'react-router-dom'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  ContainerDomain,
  CreateContainerDomain,
} from '../api-client/interface/Connection'
import { Page } from '../App'
import { Button } from '../components/Button'
import { Heading, HeadingLevel } from '../components/Heading'
import { ContainerLabel, Input, InputType } from '../components/Input'
import { Modal } from '../components/Modal'
import { notification } from '../components/Notification'
import { Spinner } from '../components/Spinner'
import { DomainsList } from '../containers/DomainsList'
import { parse as parseQs } from 'querystring'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import {
  focusOnElement,
  isDomainPortValid,
  triggerOpenedScreenEvent,
} from '../helpers/Util'

const DOMAIN_REGEX = new RegExp(
  '^(?!://)([a-zA-Z0-9-_]+.)*[a-zA-Z0-9][a-zA-Z0-9-_]+.[a-zA-Z]{2,11}?$'
)

type Props = {
  apiClient: ApiClient

  accountDetails: AccountDetails
  domains: ContainerDomain[]
  remainingDomains: number

  containersAlreadyFetched: boolean
  domainsAlreadyFetched: boolean
  loadingInitContent: boolean
  onDidLoadInitContent: () => void

  getContainers: () => Promise<void>
  getDomains: () => Promise<void>
  refreshAccountDetails: () => Promise<void>
  getContainerName: (containerId: number) => string | undefined
}

type State = {
  loading: boolean
  processingAction: boolean

  addDomainModalVisible: boolean
  limitModalVisible: boolean

  domainName: string
  internalPort: number
  externalPort: number
}

export class DomainsPage extends React.Component<Props, State> {
  public state: State = {
    loading: false,
    processingAction: false,

    addDomainModalVisible: false,
    limitModalVisible: false,

    domainName: '',
    internalPort: 3000,
    externalPort: 80,
  }

  componentDidMount() {
    this.getData()
    triggerOpenedScreenEvent(
      'openedDomainsScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    if (
      !this.props.domainsAlreadyFetched ||
      this.props.loadingInitContent ||
      this.state.loading
    ) {
      return (
        <div className="page-content">
          <Spinner size={'cover'} />
        </div>
      )
    }

    const selectedContainerId = parseInt(
      String(parseQs(window.location.search.slice(1))['id'])
    )

    const selectedContainerName =
      this.props.getContainerName(selectedContainerId)

    if (!selectedContainerName) {
      window.scrollTo(0, 0)
      return <Redirect to={Page.Home} />
    }

    return (
      <div className="page-content">
        <Heading heading="Domains" level={HeadingLevel.First} goBack={true} />
        <div className="form-wrapper custom-template-form">
          <div className="domains-selected-container">
            Domains for{' '}
            <span className="purple-highlight">{selectedContainerName}</span>
          </div>
          <div className="domains-help">
            Not sure how to add your custom domain? Follow{' '}
            <a
              href="https://docs.codeanywhere.com/dashboard/containers/add-custom-domain"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              these
            </a>{' '}
            easy steps.
          </div>
          <label className="input-label">Custom domains</label>
          {this.state.loading ? (
            <Spinner />
          ) : (
            <>
              <DomainsList
                domains={this.props.domains.filter(
                  domain => domain.containerId === selectedContainerId
                )}
                onRemoveDomain={this.handleRemoveDomain}
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  onClick={() => {
                    window.history.back()
                  }}
                />
                <Button
                  value="Add domain"
                  type="success"
                  onClick={() => {
                    if (this.props.remainingDomains > 0) {
                      this.setState({ addDomainModalVisible: true })
                    } else {
                      this.setState({ limitModalVisible: true })
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
        {this.renderAddDomainModal(selectedContainerId, selectedContainerName)}
        {this.renderLimitModal()}
      </div>
    )
  }

  private renderAddDomainModal = (
    selectedContainerId: number,
    selectedContainerName: string
  ) => {
    if (this.state.addDomainModalVisible) {
      return (
        <Modal
          visible={this.state.addDomainModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              addDomainModalVisible: false,
              domainName: '',
              internalPort: 3000,
              externalPort: 80,
            })
          }
        >
          <Modal.Header>
            Add domain for{' '}
            <span className="purple-highlight">{selectedContainerName}</span>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={e => {
                e.preventDefault()
                this.handleAddDomain(selectedContainerId)
              }}
            >
              <Input
                label={ContainerLabel.DomainName}
                value={this.state.domainName}
                required={true}
                autofocus={true}
                onChangeInput={domainName =>
                  this.setState({
                    domainName,
                  })
                }
              />
              <div className="two-inputs-container">
                <Input
                  label={ContainerLabel.DomainInternalPort}
                  value={this.state.internalPort}
                  required={true}
                  type={InputType.Number}
                  onChangeInput={internalPort =>
                    this.setState({ internalPort: parseInt(internalPort) })
                  }
                />
                <Input
                  label={ContainerLabel.DomainExternalPort}
                  value={this.state.externalPort}
                  required={true}
                  type={InputType.Number}
                  onChangeInput={externalPort =>
                    this.setState({ externalPort: parseInt(externalPort) })
                  }
                />
              </div>
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.processingAction}
                  onClick={() =>
                    this.setState({
                      addDomainModalVisible: false,
                      domainName: '',
                      internalPort: 3000,
                      externalPort: 80,
                    })
                  }
                />
                <Button
                  value="Add"
                  type="success"
                  submit={true}
                  disabled={this.state.processingAction}
                  loading={this.state.processingAction}
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )
    }
  }

  private renderLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={this.state.limitModalVisible}
        resource="Domains"
        accountDetails={this.props.accountDetails}
        onClose={() => this.setState({ limitModalVisible: false })}
      />
    )
  }

  private async getData() {
    const {
      onDidLoadInitContent,
      containersAlreadyFetched,
      domainsAlreadyFetched,
      getContainers,
      getDomains,
    } = {
      ...this.props,
    }

    if (this.props.loadingInitContent) {
      await getContainers()
      await getDomains()
    } else if (!containersAlreadyFetched || !domainsAlreadyFetched) {
      this.setState({ loading: true })
      if (!containersAlreadyFetched) await getContainers()
      if (!domainsAlreadyFetched) await getDomains()
      this.setState({ loading: false })
    }
    onDidLoadInitContent()
  }

  private handleAddDomain = async (selectedContainerId: number) => {
    this.setState({ processingAction: true })
    try {
      //validate inputs
      const validDomain = DOMAIN_REGEX.test(this.state.domainName)
      const validInternalPort = isDomainPortValid(this.state.internalPort)
      const validExternalPort = isDomainPortValid(this.state.externalPort)

      if (!validDomain) {
        notification.warn({
          message: 'You have entered an invalid domain.',
        })
        focusOnElement(ContainerLabel.DomainName)
      }
      if (!validInternalPort || !validExternalPort) {
        notification.warn({
          message: 'Port must be 80, 443 or between 1024 and 9999.',
        })
        focusOnElement(
          !validInternalPort
            ? ContainerLabel.DomainInternalPort
            : ContainerLabel.DomainExternalPort
        )
      }

      //add domain
      if (validDomain && validInternalPort && validExternalPort) {
        const newDomain: CreateContainerDomain = {
          containerId: String(selectedContainerId),
          domain: this.state.domainName,
          internalPort: this.state.internalPort.toString(),
          externalPort: this.state.externalPort.toString(),
        }

        await this.props.apiClient.addDomain(newDomain)

        notification.success({
          message: 'You have successfully added a new domain.',
        })

        this.setState({
          processingAction: false,
          addDomainModalVisible: false,
          loading: true,
        })

        await this.props.getDomains()
        await this.props.refreshAccountDetails()

        this.setState({
          addDomainModalVisible: false,
          domainName: '',
          internalPort: 3000,
          externalPort: 80,
        })
      }
    } catch (e: any) {
      notification.error({
        message: 'An error ocurred while adding a new domain.',
        description: e,
      })
      focusOnElement(ContainerLabel.DomainName)
    } finally {
      this.setState({
        loading: false,
        processingAction: false,
      })
    }
  }

  private handleRemoveDomain = async (domainId: number) => {
    this.setState({ processingAction: true })
    try {
      await this.props.apiClient.removeDomain(String(domainId))

      notification.success({
        message: 'You have successfully removed the domain.',
      })

      this.setState({
        processingAction: false,
        loading: true,
      })

      await this.props.getDomains()
      await this.props.refreshAccountDetails()
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while removing the domain.',
        description: e,
      })
    } finally {
      this.setState({
        processingAction: false,
        loading: false,
      })
    }
  }
}
