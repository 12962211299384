export interface Connection {
  id: number
  type: string
  name: string
}

export enum SSHConnectionAuth {
  'Password' = 1,
  'Private Key',
  'Public Key',
}

export interface SSHConnection extends Connection {
  type: 'ssh'
  hostname: string
  username: string
  initialdir: string
  port: number
  timeout: number
  auth: SSHConnectionAuth
  previewRoot: string
  webUrl: string
  password?: string
  privateKey?: string
}

export interface FTPConnection extends Connection {
  type: 'ftp'
  hostname: string
  username: string
  initialdir: string
  port: number
  timeout: number
  previewRoot: string
  webUrl: string
  password?: string
}

export enum FTPSConnectionAuth {
  'Control' = 1,
  'Implicit',
}

export interface FTPSConnection extends Connection {
  type: 'ftps'
  hostname: string
  username: string
  initialdir: string
  port: number
  timeout: number
  auth: FTPSConnectionAuth
  previewRoot: string
  webUrl: string
  password?: string
}

export type ConnectionType =  //TODO: better name
  | FTPConnection
  | FTPSConnection
  | SSHConnection
  | ContainerConnection

export interface Distro {
  id: string
  name: string
  desc?: string
}

export interface CreateCustomStack {
  id: number
  name: string
  description: string
}

export interface ContainerStack {
  id: string
  name: string
  platform: string
  desc: string
  distros: Distro[]
  distro?: Distro[] //team stack distros
  user?: ContainerStackOwner
  tags: string[]
  allowgit: boolean
}

export interface ContainerStackOwner {
  name: string
  username: string
  email: string
}

export interface ContainerStackList {
  predefined: ContainerStack[]
  custom: ContainerStack[]
  team: ContainerStack[]
}

export const EMPTY_TEMPLATES_LIST: ContainerStackList = {
  predefined: [],
  custom: [],
  team: [],
}

export interface CreateContainer {
  type: 'devbox'
  name: string
  alwaysOn: boolean
  stack: string
  distro: string
}

export const CONTAINER_CONNECTION_STATE: { [key: number]: string } = {
  0: 'Temporarily unavailable',
  10: 'Started',
  11: 'Pending start',
  12: 'Starting',
  13: 'Starting',
  20: 'Stopped',
  21: 'Pending stop',
  22: 'Stopping',
  23: 'Stopping',
  25: 'Pending restart',
  26: 'Restart in progress',
  100: 'Created successfully',
  101: 'Pending creation',
  102: 'Creation in progress',
  103: 'Deploying Container',
  110: 'Created successfully',
  200: 'Stack created successfully',
  201: 'Pending stack creation',
  202: 'Creating custom stack',
  203: 'Creating custom stack',
  300: 'Project is running',
  301: 'Pending project execution',
  302: 'Executing project',
  30: 'Stored',
  31: 'Pending store',
  32: 'Storing image',
  33: 'Storing image',
  40: 'Restored',
  41: 'Pending restore',
  42: 'Restoring Container',
  43: 'Restoring Container',
  70: 'Initialized',
  71: 'Pending initialization',
  72: 'Initializing',
  73: 'Initializing',
  80: 'Archived',
  81: 'Pending archiving process',
  82: 'Archiving Container',
  83: 'Archiving Container',
  90: 'Destroyed',
  91: 'Pending destroy',
  92: 'Destroying',
  93: 'Destroying',
  3016: 'Error updating Container DNS',
  3021: 'Error stopping Container',
  3026: 'Error restarting Container',
  3030: 'Error dumping Container image',
  3031: 'Error creating Container image',
  3032: 'Error storing Container image',
  3033: 'Error destroying image',
  3040: 'Error restoring from image',
  3041: 'Error retrieving Container image',
  3042: 'Error restoring Container image',
  3043: 'Error restroying Container image',
  3045: 'Error updating Container status',
  3046: 'Error retrieving Container image',
  3090: 'Error destroying Container',
  3091: 'Error destroying Container',
  3100: 'Error creating Container',
  3101: 'Error creating Container - system error',
  3102: 'Error creating Container - no free Container hosts found',
  3103: 'Error creating Container - error creating DNS record',
  3300: 'Error executing project',
}

export interface ContainerConnection extends Connection {
  type: 'devbox'
  hostname: string
  username: string
  initialdir: string
  port: number
  timeout: number
  auth: SSHConnectionAuth
  previewRoot: string
  webUrl: string
  boxToken: string
  state: number
  alwaysOn: number
  slug?: string
  stack: string
}

export interface ContainerNotification {
  action: string
  data: {
    id: number
    boxToken: string
    state: number
    type: string
  }
}

export interface CreateContainerDomain {
  containerId: string
  domain: string
  externalPort: string
  internalPort: string
}

export interface ContainerDomain {
  userId: number
  domain: string
  containerId: number
  containerUrl: string
  internalPort: number
  externalPort: number
  id: number
  createdAt: Date
  updatedAt: Date
  containerName: string
}

export interface CancelSubscriptionFeedback {
  comment: string
  reasons: string[]
  rivalEditor?: string
}

export interface DropboxConnection extends Connection {
  type: 'dropbox'
}

export interface S3Connection extends Connection {
  type: 's3'
}

export interface OneDriveConnection extends Connection {
  type: 'onedriveconsumer'
}

export interface GoogleDriveConnection extends Connection {
  type: 'gdrive'
}
