import * as React from 'react'
import { PeopleIcon } from '../helpers/image-imports/IconComponent'
import { ProfileImage } from './ProfileImage'
import { ShareApiClient } from '../api-client/adapters/ShareApiClient'
import { CardOption, CardOptions } from './CardOptions'
import { Truncate } from './Truncate'
import { Tooltip, TooltipPosition } from './Tooltip'
import { Share } from '@codeanywhere/collaboration-service-api-client'
import { OpenIDEButton } from './OpenIDEButton'
import { onKey } from '../helpers/Util'
const { Option } = CardOptions

type Props = {
  shareApiClient: ShareApiClient
  share: Share
  displayModal: (option: CardOption, share: Share) => void
}

export class ShareCard extends React.Component<Props> {
  render() {
    const owner =
      !this.props.share.admin.firstname || !this.props.share.admin.lastname
        ? this.props.share.admin.username
        : `${this.props.share.admin.firstname} ${this.props.share.admin.lastname}`

    return (
      <div className="card share-card">
        <div className="card-header share-header">
          <div className="card-title">
            <Truncate>{this.props.share.containerName}</Truncate>
          </div>
          <div className="card-extras">
            {/* <div className="card-options card-option--type-pin">
              <PinIcon />
            </div> */}
            <span className="shared-tag">SHARED</span>
            <CardOptions>
              <Option
                onClick={() =>
                  this.props.displayModal(
                    CardOption.Collaborators,
                    this.props.share
                  )
                }
              >
                {CardOption.Collaborators}
              </Option>
              <Option
                onClick={() =>
                  this.props.displayModal(CardOption.Leave, this.props.share)
                }
              >
                {CardOption.Leave}
              </Option>
            </CardOptions>
          </div>
        </div>
        <div className="share-info">
          <div className="share-owner">
            <ProfileImage
              profileImageUrl={this.props.share.admin.imageurl}
              email={this.props.share.admin.email}
            />
            <Truncate>
              <span className="owner">{owner}</span>
            </Truncate>
          </div>
          <div
            className="collaborators tab-focus"
            onClick={() =>
              this.props.displayModal(
                CardOption.Collaborators,
                this.props.share
              )
            }
            onKeyDown={e =>
              onKey(e, 'Enter', () =>
                this.props.displayModal(
                  CardOption.Collaborators,
                  this.props.share
                )
              )
            }
            onKeyUp={e =>
              onKey(e, ' ', () =>
                this.props.displayModal(
                  CardOption.Collaborators,
                  this.props.share
                )
              )
            }
            tabIndex={0}
          >
            <Tooltip position={TooltipPosition.Top} content="Collaborators">
              <PeopleIcon />
              <span>
                {' '}
                {
                  1 +
                    this.props.share.users.length +
                    this.props.share.invites.length
                  /*owner+collaborators+invites*/
                }
              </span>
            </Tooltip>
          </div>
        </div>
        <OpenIDEButton
          enabled={true}
          onClick={() => {
            window.open(this.props.share.link)
          }}
        />
      </div>
    )
  }
}
