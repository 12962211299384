import * as React from 'react'
import { Modal } from './Modal'
import { Button } from './Button'
import { Page } from '../App'
import { AccountDetails } from '../api-client/interface/AccountDetails'

type Props = {
  visible: boolean
  resource: string
  onClose?: () => void
  accountDetails: AccountDetails
}

export class ReachedLimitModal extends React.PureComponent<Props> {
  render() {
    if (!this.props.visible) {
      return null
    }

    return (
      <Modal
        visible={this.props.visible}
        closeable={!!this.props.onClose}
        onClose={this.props.onClose}
      >
        <Modal.Header>Warning</Modal.Header>
        <Modal.Body>
          <span className="modal-warning-message">
            You have used the maximum number of {this.props.resource} available
            to your plan.
            {this.props.accountDetails.child_account &&
            this.props.accountDetails.admin_email
              ? ` Please contact ${this.props.accountDetails.admin_email} for more resources.`
              : ''}
          </span>
          <div className="button-row bottom-margin">
            {this.props.onClose ? (
              <Button value="Cancel" onClick={this.props.onClose} />
            ) : (
              <Button value="Go back" onClick={() => window.history.back()} />
            )}
            {!this.props.accountDetails.child_account && (
              <Button
                value="Upgrade plan"
                type="success"
                onClick={() => {
                  const checkoutWindow = window.open(Page.Checkout)

                  if (checkoutWindow && this.props.onClose) {
                    checkoutWindow.addEventListener(
                      'beforeunload',
                      this.props.onClose
                    )
                  }
                }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
