import * as React from 'react'
const classNames = require('classnames')

export enum TooltipPosition {
  Top = 'top',
  Right = 'right',
  /* Bottom = 'bottom', */
  Left = 'left',
}

type Props = {
  position: TooltipPosition
  className?: string
  content?: React.ReactNode
  onClick?: () => void | Promise<void>
  children: React.ReactNode
  style?: React.CSSProperties
}

type State = {}

export class Tooltip extends React.Component<Props, State> {
  render() {
    const contentLength = String(this.props.content).length

    return (
      <span
        className={classNames(
          'tooltip-wrapper',
          this.props.className,
          { 'tooltip-wrapper--is-short': contentLength < 15 },
          { 'tooltip-wrapper--is-long': contentLength > 100 },
          {
            ['tooltip-wrapper--position-' + this.props.position]:
              this.props.position,
          }
        )}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        {this.props.children}
        <span className="tooltip">{this.props.content}</span>
      </span>
    )
  }
}
