import React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Heading, HeadingLevel } from '../components/Heading'
import { InvoicesTable } from '../containers/InvoicesTable'
import { triggerOpenedScreenEvent } from '../helpers/Util'

type Props = {
  apiClient: ApiClient
  accountDetails: AccountDetails
  loadingInitContent: boolean
  onDidLoadInitContent: () => void
}

export class PaymentHistoryPage extends React.Component<Props> {
  componentDidMount() {
    triggerOpenedScreenEvent(
      'openedPaymentHistoryScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    return (
      <div className="page-content page--is-payment-history">
        <Heading
          heading="Payment history"
          level={HeadingLevel.First}
          goBack={true}
        />
        <InvoicesTable
          apiClient={this.props.apiClient}
          loadingInitContent={this.props.loadingInitContent}
          onDidLoadInitContent={this.props.onDidLoadInitContent}
        />
      </div>
    )
  }
}
