import * as React from 'react'
import { SyntheticEvent } from 'react'

type Props = {
  visible?: boolean
  closeable?: boolean
  animation?: boolean
  onEnter?: () => Promise<void> | void
  onExit?: () => Promise<void> | void
  onClose?: () => Promise<void> | void
  children: React.ReactNode
  className?: string
}

type HeaderProps = {
  children: React.ReactNode
}

type TitleProps = {
  children: React.ReactNode
}

type BodyProps = {
  children: React.ReactNode
  className?: string
}

type FooterProps = {
  children: React.ReactNode
}

class ModalHeader extends React.PureComponent<HeaderProps> {
  render() {
    return <div className="modal__header">{this.props?.children}</div>
  }
}

class ModalTitle extends React.PureComponent<TitleProps> {
  render() {
    return <div className="modal__title">{this.props?.children}</div>
  }
}

class ModalFooter extends React.PureComponent<FooterProps> {
  render() {
    return <div className="modal__footer">{this.props?.children}</div>
  }
}

class ModalBody extends React.PureComponent<BodyProps> {
  render() {
    return (
      <div className="modal__body">
        <div className={`modal__content ${this.props.className}`}>
          {this.props?.children}
        </div>
      </div>
    )
  }
}

export class Modal extends React.PureComponent<Props> {
  private static defaultProps: Props = {
    animation: true,
    closeable: true,
    onEnter: () => {},
    onExit: () => {},
    onClose: () => {},
    children: null,
    className: '',
  }

  public componentDidMount() {
    document.body.classList.add('modal--is-visible')
    document.addEventListener('keydown', this.onEscapeKey, false)
    this.props.onEnter?.()
    this.trapFocusInModal()
  }

  public componentWillUnmount() {
    document.body.classList.remove('modal--is-visible')
    document.removeEventListener('keydown', this.onEscapeKey, false)
    this.props.onExit?.()
  }

  private onEscapeKey = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      this.props.onClose?.()
    }
  }

  private onClose = (e: SyntheticEvent) => {
    e.stopPropagation()
    this.props.onClose?.()
  }

  static Header = (props: HeaderProps) => <ModalHeader {...props} />

  static Title = (props: TitleProps) => <ModalTitle {...props} />

  static Body = (props: BodyProps) => <ModalBody {...props} />

  static Footer = (props: FooterProps) => <ModalFooter {...props} />

  render() {
    if (this.props.visible) {
      return (
        <>
          <main
            className={`modal__backdrop ${
              this.props.animation && 'modal--has-animation'
            } ${this.props?.className}`}
            onClick={this.onClose}
          />
          <article className="modal" id="modal">
            {this.props.closeable && (
              <button
                className="modal__close tab-focus"
                onClick={this.onClose}
                /* autoFocus={true} */
              >
                <svg viewBox="0 0 16 16">
                  <polygon points="15.199 2.214 13.785 0.8 8 6.586 2.214 0.8 0.8 2.214 6.586 8 0.8 13.785 2.214 15.199 8 9.414 13.785 15.199 15.199 13.785 9.414 8 15.199 2.214" />
                </svg>
              </button>
            )}
            {this.props?.children}
          </article>
        </>
      )
    }

    return null
  }

  private trapFocusInModal = () => {
    const focusableElements =
      'button, [href], input, select, textarea, .tab-focus, [tabindex]:not([tabindex="-1"])'
    const modal: HTMLElement = document.querySelector('#modal')!

    const firstFocusableElement = modal.querySelectorAll(
      focusableElements
    )[0] as HTMLElement
    const focusableContent = modal.querySelectorAll(
      focusableElements
    ) as NodeListOf<HTMLElement>
    const lastFocusableElement = focusableContent[
      focusableContent.length - 1
    ] as HTMLElement

    modal.addEventListener('keydown', function (e) {
      let isTabPressed = e.key === 'Tab'

      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus() // add focus for the last focusable element
          e.preventDefault()
        }
      } else {
        // if tab key is pressed
        if (document.activeElement === lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus() // add focus for the first focusable element
          e.preventDefault()
        }
      }
    })

    firstFocusableElement.focus()
  }
}
