import * as React from 'react'
import { Heading, HeadingLevel } from '../components/Heading'
import { Button } from '../components/Button'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Input, AccountLabel, InputType } from '../components/Input'
import { parse as parseQs } from 'querystring'
import { Page } from '../App'

type Props = {
  apiClient: ApiClient
  hasInitialPassword: boolean
  loading: boolean
  handleChangePassword: (
    oldPassword: string,
    newPassword: string,
    repeatNewPassword: string
  ) => Promise<boolean>
  handleSetInitialPassword: (
    newPassword: string,
    repeatNewPassword: string
  ) => Promise<boolean>
}

type State = {
  oldPassword: string
  newPassword: string
  repeatNewPassword: string
  changePasswordExpanded: boolean
}

export class ChangePassword extends React.Component<Props, State> {
  public state: State = {
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    changePasswordExpanded: false,
  }

  componentDidMount() {
    if (parseQs(window.location.search.slice(1))['setInitialPassword']) {
      this.scrollSetInitialPasswordIntoView()
    }
  }

  render() {
    return (
      <div className="account-section">
        <Heading
          heading="Password"
          level={HeadingLevel.Second}
          elementId="password-heading"
          scrollTarget={true}
        />
        {this.state.changePasswordExpanded ? (
          <form
            className="change-password"
            onSubmit={e => {
              e.preventDefault()
              if (this.props.hasInitialPassword) {
                this.onSubmitChangePassword()
              } else {
                this.onSubmitInitialPassword()
              }
            }}
          >
            {this.props.hasInitialPassword && (
              <div className="update-account-detail">
                <Input
                  label={AccountLabel.OldPassword}
                  value={this.state.oldPassword}
                  type={InputType.Password}
                  required={true}
                  onChangeInput={oldPassword =>
                    this.setState({
                      oldPassword,
                    })
                  }
                />
              </div>
            )}
            <div className="update-account-detail">
              <Input
                label={AccountLabel.NewPassword}
                value={this.state.newPassword}
                type={InputType.Password}
                required={true}
                onChangeInput={newPassword =>
                  this.setState({
                    newPassword,
                  })
                }
              />
            </div>
            <div className="update-account-detail">
              <Input
                label={AccountLabel.RepeatNewPassword}
                value={this.state.repeatNewPassword}
                type={InputType.Password}
                required={true}
                onChangeInput={repeatNewPassword =>
                  this.setState({
                    repeatNewPassword,
                  })
                }
              />
            </div>
            <Button
              value="Cancel"
              style={{ marginRight: '1rem' }}
              onClick={() => {
                this.setState({
                  changePasswordExpanded: false,
                  oldPassword: '',
                  newPassword: '',
                  repeatNewPassword: '',
                })
              }}
            />
            <Button
              value="Update"
              type="success"
              submit={true}
              disabled={!this.checkIfPendingChanges() || this.props.loading}
              loading={this.props.loading}
            />
          </form>
        ) : (
          <>
            <Heading
              heading={
                this.props.hasInitialPassword
                  ? 'If you want to change your password, click the button below.'
                  : 'If you want to set your initial password, click the button below.'
              }
              level={HeadingLevel.Third}
            />
            <Button
              value={
                this.props.hasInitialPassword
                  ? 'Change password'
                  : 'Set initial password'
              }
              type="primary"
              onClick={() => this.setState({ changePasswordExpanded: true })}
            />
          </>
        )}
      </div>
    )
  }

  private async onSubmitChangePassword() {
    if (
      await this.props.handleChangePassword(
        this.state.oldPassword,
        this.state.newPassword,
        this.state.repeatNewPassword
      )
    ) {
      this.setState({
        changePasswordExpanded: false,
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      })
    }
  }
  private async onSubmitInitialPassword() {
    if (
      await this.props.handleSetInitialPassword(
        this.state.newPassword,
        this.state.repeatNewPassword
      )
    ) {
      this.setState({
        changePasswordExpanded: false,
        newPassword: '',
        repeatNewPassword: '',
      })
    }
  }

  private checkIfPendingChanges = () => {
    return (
      (!this.props.hasInitialPassword || this.state.oldPassword) &&
      this.state.newPassword &&
      this.state.repeatNewPassword
    )
  }

  private scrollSetInitialPasswordIntoView() {
    window.history.replaceState({}, '', Page.Account)

    this.setState({
      changePasswordExpanded: true,
    })
    document.getElementById('password-heading')!.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
  }
}
