import * as React from 'react'
import { ILLUSTRATION } from '../helpers/image-imports/Illustration'

type Props = {
  resource: string
}
export class EmptyList extends React.PureComponent<Props> {
  render() {
    return (
      <div className="empty-list">
        <img src={ILLUSTRATION['Empty']} alt="Empty list" />
        <div className="empty-list__message">
          You don't have any {this.props.resource}.
        </div>
      </div>
    )
  }
}
