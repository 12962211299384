import * as React from 'react'
import {
  PricingPlan,
  PricingPlanCode,
} from '../api-client/interface/PricingPlan'
import { Coupon } from '../api-client/interface/RecurlyClient'
import {
  AddonName,
  ADDON_DESCRIPTIONS,
  ADDON_INCLUDED_PRICE,
  ADDON_PRICE_NEW,
  ADDON_TITLES,
  Subscription,
  SubscriptionAddons,
} from '../api-client/interface/Subscription'
import { AddonPicker } from '../components/AddonPicker'
import { CheckoutCoupon } from '../components/CheckoutCoupon'
import { PlanPeriod } from '../pages/BillingPage'
import { ADDONS } from '../helpers/image-imports/Addons'
import { PlanPeriodAbbreviation } from './CheckoutStep1Container'

type Props = {
  addons: SubscriptionAddons
  planPeriod: PlanPeriodAbbreviation
  selectedPlan: PricingPlan
  onAddonNumberChange: (addonName: AddonName, newVal: number) => void
  onContinueToPaymentClick: () => void

  coupon: Coupon | null
  couponCode: string
  couponActive: boolean
  couponLoading: boolean
  onCouponCodeChange: (couponCode: string) => void
  onCouponApply: () => void
  onCouponActiveChange: (couponActive: boolean) => void

  onUpdateToStandardClick: () => void

  subscription: Subscription | null

  currentPlanPeriod: PlanPeriod
}

export class CheckoutStep2Container extends React.Component<Props> {
  render() {
    return (
      <>
        <h3 className="step-2-title payment-header__title">
          Also recommended for best performance and experience
        </h3>
        {Object.entries(this.props.addons)
          .filter(([key]) => key !== AddonName.MultipleAccounts)
          .map(([key, value]) => {
            const addonPrice =
              ADDON_PRICE_NEW[this.props.selectedPlan.code as PricingPlanCode][
                key as AddonName
              ][this.props.planPeriod]
            return (
              <AddonPicker
                key={key}
                iconSrc={ADDONS[key as AddonName]}
                iconAlt="Always-On icon"
                addonTitle={ADDON_TITLES[key as AddonName]}
                addonKey={key}
                addonDescription={ADDON_DESCRIPTIONS[key as AddonName]}
                addonPrice={
                  ADDON_PRICE_NEW[
                    this.props.selectedPlan.code as PricingPlanCode
                  ][key as AddonName][PlanPeriodAbbreviation.MONTHLY]
                }
                addonPriceDiscounted={addonPrice}
                addonNumber={value}
                isRecommended={key === AddonName.AdditionalContainer}
                onAddonNumberChange={newVal =>
                  this.props.onAddonNumberChange(key as AddonName, newVal)
                }
                onUpgradeToStandardClick={this.props.onUpdateToStandardClick}
                isIncluded={addonPrice === ADDON_INCLUDED_PRICE}
              />
            )
          })}
        <div className="sticky-barrier"></div>
        <div className="addon-picker-footer step-two-footer">
          <div className="ant-row">
            <div className="ant-col ant-col-24 ant-col-sm-12">
              {this.props.couponActive ? (
                <CheckoutCoupon
                  couponCode={this.props.couponCode}
                  valid={this.props.coupon ? this.props.coupon.valid : false}
                  loading={this.props.couponLoading}
                  currentPlanPeriod={this.props.currentPlanPeriod}
                  onCouponCodeChange={this.props.onCouponCodeChange}
                  onApplyClick={this.props.onCouponApply}
                  onCancelClick={() => this.props.onCouponActiveChange(false)}
                />
              ) : (
                <div
                  className="checkout-action"
                  onClick={() => this.props.onCouponActiveChange(true)}
                >
                  Got coupon?
                </div>
              )}
            </div>
            <div className="ant-col ant-col-24 ant-col-sm-12 addon-picker-footer__right">
              <div className="align-center">
                <button
                  className="checkout-button"
                  onClick={this.props.onContinueToPaymentClick}
                  id="checkout-continue-button--step2"
                >
                  Continue to Payment
                </button>
                <div>You won't be charged yet</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
