import {
  Elements,
  RecurlyProvider,
  UseRecurlyInstance,
} from '@recurly/react-recurly'
import * as React from 'react'
import { ApiClient } from '../api-client/interface/ApiClient'
import { BillingInfo } from '../api-client/interface/BillingInfo'
import { AcceptedCreditCards } from '../components/AcceptedCreditCards'
import { CreditCardInfo } from '../components/CreditCardInfo'
import { notification } from '../components/Notification'
import { Truncate } from '../components/Truncate'
import { UpdateBillingInfoForm } from '../components/UpdateBillingInfoForm'
import { onKey } from '../helpers/Util'

type Props = {
  apiClient: ApiClient
  expanded: boolean
  billingInfo: BillingInfo
  billingInfoEmpty: boolean
  onBillingInfoInputChange: (dataKey: string, value: string) => void
  onAddressInputChange: (dataKey: string, value: string) => void
  onCountrySelectChange: (value: string) => void
  refreshBillingInfo: () => Promise<void>
  toggleForm: (paymentInformationExpanded: boolean) => void
}

type State = {
  loading: boolean
}

export class PaymentInformation extends React.Component<Props, State> {
  public state: State = {
    loading: false,
  }

  private recurly?: UseRecurlyInstance

  render() {
    return (
      <>
        <div
          className="payment-information"
          style={{ display: !this.props.expanded ? 'flex' : 'none' }}
        >
          <div className="billing-information-container">
            <span className="title">Billing information</span>
            {this.props.billingInfoEmpty ? (
              <span>You have not added any payment details.</span>
            ) : (
              <>
                <Truncate>
                  <span>
                    {`${this.props.billingInfo.firstName} ${this.props.billingInfo.lastName}`}
                  </span>
                </Truncate>
                <Truncate>
                  <span>
                    {!!this.props.billingInfo.address?.city &&
                      `${this.props.billingInfo.address.city}, `}
                    {this.props.billingInfo.address?.country}
                  </span>
                </Truncate>
              </>
            )}
          </div>
          {this.props.billingInfo?.type === 'credit_card' && (
            <CreditCardInfo
              cardType={this.props.billingInfo.cardType}
              lastFourDigits={this.props.billingInfo.last_four}
              expirationDate={`${this.props.billingInfo.month?.padStart(
                2,
                '0'
              )}/${this.props.billingInfo.year?.substr(2)}`}
            />
          )}
          <span
            className="edit-billing-info tab-focus"
            onClick={this.handleTogglePaymentInfoForm}
            onKeyDown={e => onKey(e, 'Enter', this.handleTogglePaymentInfoForm)}
            onKeyUp={e => onKey(e, ' ', this.handleTogglePaymentInfoForm)}
            tabIndex={0}
          >
            {this.props.billingInfo ? 'Edit' : 'Add information'}
          </span>
        </div>
        <div style={{ display: this.props.expanded ? 'block' : 'none' }}>
          <AcceptedCreditCards />
          <RecurlyProvider
            publicKey={String(process.env.REACT_APP_RECURLY_PUBLIC_KEY)}
          >
            <Elements>
              <UpdateBillingInfoForm
                billingInfo={this.props.billingInfo}
                loading={this.state.loading}
                onSubmit={this.handleUpdatePaymentMethod}
                setRecurly={this.setRecurly}
                collapseForm={() => this.props.toggleForm(false)}
                onBillingInfoInputChange={this.props.onBillingInfoInputChange}
                onAddressInputChange={this.props.onAddressInputChange}
                onCountrySelectChange={this.props.onCountrySelectChange}
              />
            </Elements>
          </RecurlyProvider>
        </div>
      </>
    )
  }

  private handleUpdatePaymentMethod = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()

    if (this.recurly) {
      this.setState({ loading: true })

      try {
        this.recurly.token(
          document.querySelector('form')!,
          async (err, token) => {
            if (err) {
              notification.error({
                message:
                  'Error processing request. Make sure your credit card info is correct.',
              })
            } else {
              try {
                await this.props.apiClient.updateBillingInfo(token.id)
                notification.success({
                  message:
                    'You have successfully updated your payment information.',
                })
                this.props.toggleForm(false)
                this.props.refreshBillingInfo()
              } catch (e: any) {
                notification.error({
                  message: 'Error processing request.',
                  description: e,
                })
              }
            }
          }
        )
      } finally {
        this.setState({ loading: false })
      }
    } else {
      notification.error({
        message: 'Payment form error. Please contact support.',
      })
    }
  }

  private setRecurly = (recurly: UseRecurlyInstance) => {
    this.recurly = recurly
  }

  private handleTogglePaymentInfoForm = () => {
    this.props.toggleForm(true)
  }
}
