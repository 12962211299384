import * as React from 'react'

type Props = {
  title: string
  level: number
}

export class ReferralTitle extends React.PureComponent<Props> {
  render() {
    return (
      <div className={`referral-title level-${this.props.level}`}>
        {this.props.title}
      </div>
    )
  }
}
