import * as React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Spinner } from '../components/Spinner'
import { TemplateCard } from '../components/TemplateCard'
import { TemplateFilters } from '../components/TemplateFilters'
import { TemplateTableRow } from '../components/TemplateTableRow'
import { ILLUSTRATION } from '../helpers/image-imports/Illustration'
import {
  TemplateData,
  Templates,
  TemplateType,
} from '../helpers/TransformTemplates'

export const EMPTY_SELECTED_TEMPLATE: SelectedTemplate = {
  id: '',
  distro: '',
  name: '',
}

export type SelectedTemplate = {
  id: string
  distro: string
  name: string
}

type Props = {
  apiClient: ApiClient
  tableFormat?: boolean
  accountDetails?: AccountDetails
  fetchedTemplates: Templates
  creatingTemplates?: string[]
  onRemoveTemplate?: (
    removeTemplateName: string,
    removeTemplateId: string
  ) => void

  selectedTemplate?: SelectedTemplate
  onChangeSelectedTemplate?: (selectedTemplate: SelectedTemplate) => void
}

type State = {
  filteredTemplates?: TemplateData[]
  searchValue: string
  activeFilter: TemplateType | null
}

export class TemplatesList extends React.Component<Props, State> {
  public state: State = {
    filteredTemplates: undefined,
    searchValue: '',
    activeFilter: null,
  }

  componentDidMount() {
    this.setInitialSelectedTemplate()
  }

  render() {
    const displayedTemplates = this.getDisplayedTemplates()

    return (
      <div className="templates-container">
        <TemplateFilters
          onUpdateSearchValue={this.handleUpdateSearch}
          activeFilter={this.state.activeFilter}
          onChangeActiveFilter={this.handleChangeTypeFilter}
        />
        {this.props.tableFormat ? (
          <div
            className="table-wrapper"
            data-length={this.state.filteredTemplates?.length}
          >
            <table className="table table--is-templates">
              <thead>
                <tr className="table-header">
                  <th className="table-column" style={{ width: '140px' }}>
                    Name
                  </th>
                  <th className="table-column" style={{ width: '160px' }}>
                    Owner
                  </th>
                  <th className="table-column">Description</th>
                  <th className="table-column" style={{ width: '130px' }}>
                    Environment
                  </th>
                  <th className="tabe-column"></th>
                  {/* <th className="table-column"></th> */}
                </tr>
              </thead>
              <tbody className="table-body">
                {this.state.activeFilter !== TemplateType.Predefined &&
                  !!this.props.creatingTemplates &&
                  this.props.creatingTemplates.map(template => (
                    <tr key={template} className="creating-template">
                      <td className="table-column">
                        <Spinner />
                      </td>
                      <td colSpan={5} className="table-column">
                        Creating template from{' '}
                        <span className="purple-highlight">{template}</span>
                      </td>
                    </tr>
                  ))}
                {displayedTemplates.map(template => (
                  <TemplateTableRow
                    key={template.id}
                    template={template}
                    accountDetails={this.props.accountDetails!}
                    onRemoveTemplate={() =>
                      this.handleRemoveTemplate(template.id, template.name)
                    }
                  />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="templates__list">
            {displayedTemplates.map(template => (
              <TemplateCard
                key={template.id}
                template={template}
                isSelected={this.props.selectedTemplate?.id === template.id}
                onChangeSelectedTemplate={() =>
                  this.handleChangeSelectedTemplate(template)
                }
              />
            ))}
          </div>
        )}
        {!displayedTemplates.length && (
          <div className="templates__list--is-empty">
            <img src={ILLUSTRATION['Empty']} alt="No stacks" />
          </div>
        )}
      </div>
    )
  }

  private getDisplayedTemplates() {
    if (this.state.filteredTemplates !== undefined) {
      return this.state.filteredTemplates.filter(template => {
        if (template.type === TemplateType.Predefined) {
          return this.props.fetchedTemplates.predefined
            .map(temp => temp.id)
            .includes(template.id)
        } else if (template.type === TemplateType.Custom) {
          return this.props.fetchedTemplates.custom
            .map(temp => temp.id)
            .includes(template.id)
        } else {
          return this.props.fetchedTemplates.team
            .map(temp => temp.id)
            .includes(template.id)
        }
      })
    } else {
      return this.props.fetchedTemplates.custom.concat(
        ...this.props.fetchedTemplates.predefined
      )
    }
  }

  private setInitialSelectedTemplate() {
    if (!this.props.onChangeSelectedTemplate) {
      return
    }

    if (this.state.filteredTemplates === undefined) {
      const initialTemplate = this.props.fetchedTemplates.custom.length
        ? this.props.fetchedTemplates.custom[0]
        : this.props.fetchedTemplates.predefined[0]
      const selectedTemplate: SelectedTemplate = {
        id: initialTemplate.id,
        distro: this.determineSelectedTemplateDistro(initialTemplate),
        name: initialTemplate.name,
      }
      this.props.onChangeSelectedTemplate(selectedTemplate)
    } else if (!this.state.filteredTemplates.length) {
      this.props.onChangeSelectedTemplate(EMPTY_SELECTED_TEMPLATE)
    } else {
      this.checkSelectedTemplateFilteredOut(this.state.filteredTemplates)
    }
  }

  private checkSelectedTemplateFilteredOut(filteredTemplates: TemplateData[]) {
    if (
      this.props.selectedTemplate === undefined ||
      !this.props.onChangeSelectedTemplate
    ) {
      return
    }

    //set first template as the selected one unless the previously selected one is displayed
    if (
      filteredTemplates.length &&
      !filteredTemplates
        .map(template => template.id)
        .includes(this.props.selectedTemplate.id)
    ) {
      const selectedTemplate: SelectedTemplate = {
        id: filteredTemplates[0].id,
        distro: this.determineSelectedTemplateDistro(filteredTemplates[0]),
        name: filteredTemplates[0].name,
      }

      this.props.onChangeSelectedTemplate(selectedTemplate)
    }
  }

  private handleUpdateSearch = (searchValue: string) => {
    const filteredTemplates = this.applySearchFilter(searchValue)
    this.setState({
      searchValue,
      filteredTemplates,
    })

    this.checkSelectedTemplateFilteredOut(filteredTemplates)
  }

  private handleChangeTypeFilter = (activeFilter: TemplateType | null) => {
    const filteredTemplates = this.applyTemplateTypeFilter(activeFilter)
    this.setState({
      activeFilter,
      filteredTemplates,
    })

    this.checkSelectedTemplateFilteredOut(filteredTemplates)
  }

  private applySearchFilter = (searchValue: string) => {
    const typeFilteredTemplates = this.filterByTemplateType(
      this.props.fetchedTemplates.custom
        .concat(...this.props.fetchedTemplates.team)
        .concat(...this.props.fetchedTemplates.predefined),
      this.state.activeFilter
    )

    return this.filterBySearchValue(typeFilteredTemplates, searchValue)
  }

  private applyTemplateTypeFilter(activeFilter: TemplateType | null) {
    const searchFilteredTemplates = this.filterBySearchValue(
      this.props.fetchedTemplates.custom
        .concat(...this.props.fetchedTemplates.team)
        .concat(...this.props.fetchedTemplates.predefined),
      this.state.searchValue
    )

    return this.filterByTemplateType(searchFilteredTemplates, activeFilter)
  }

  private filterBySearchValue(templates: TemplateData[], searchValue: string) {
    if (searchValue === '') {
      return templates
    } else {
      return templates.filter(template => {
        return (
          template.name.toLowerCase().includes(searchValue) ||
          template.desc?.toLowerCase().includes(searchValue) ||
          template.tags?.filter(tag => tag.toLowerCase() === searchValue)
            .length > 0
        )
      })
    }
  }

  private filterByTemplateType(
    templates: TemplateData[],
    activeFilter: TemplateType | null
  ) {
    if (activeFilter === TemplateType.Predefined) {
      return templates.filter(
        template => template.type === TemplateType.Predefined
      )
    }

    if (activeFilter === TemplateType.Custom) {
      return templates.filter(template => template.type === TemplateType.Custom)
    }

    if (activeFilter === TemplateType.Team) {
      return templates.filter(template => template.type === TemplateType.Team)
    }

    return templates.filter(template => template.type !== TemplateType.Team)
  }

  private handleChangeSelectedTemplate = (template: TemplateData) => {
    if (!this.props.onChangeSelectedTemplate) {
      return
    }

    const selectedTemplate: SelectedTemplate = {
      id: template.id,
      distro: this.determineSelectedTemplateDistro(template),
      name: template.name,
    }

    this.props.onChangeSelectedTemplate(selectedTemplate)
  }

  //distro id should be empty string unless predefined template
  private determineSelectedTemplateDistro = (
    selectedTemplate: TemplateData
  ) => {
    return this.props.fetchedTemplates.predefined.find(
      cs => cs.id === selectedTemplate.id
    )
      ? selectedTemplate.distro
      : ''
  }

  private handleRemoveTemplate = (
    removeTemplateId: string,
    removeTemplateName: string
  ) => {
    this.props.onRemoveTemplate!(removeTemplateName, removeTemplateId)
  }
}
