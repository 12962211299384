import * as React from 'react'
import { AccountDetails } from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import { Page } from '../App'
import { Banner } from '../components/Banner'
import { MenuIcon } from '../helpers/image-imports/IconComponent'
import { HeaderLogo } from '../components/HeaderLogo'
import { HeaderProfile } from '../components/HeaderProfile'
import { notification } from '../components/Notification'
import { PRICING_PLANS_NEW } from '../api-client/interface/PricingPlan'
import { onKey } from '../helpers/Util'

type Props = {
  apiClient: ApiClient
  accountDetails: AccountDetails
  hasInitialPassword: boolean
  profileDropdownExpanded: boolean
  mobileNavigationExpanded: boolean
  onToggleMobileNavigation: (state: boolean) => void
  onToggleProfileDropdown: (state: boolean) => void
}

type State = {
  didCloseLegacyEditorBanner: boolean
}

export class Header extends React.Component<Props, State> {
  public state: State = {
    didCloseLegacyEditorBanner: false,
  }

  render() {
    const hideVerifyBanner =
      window.location.href.includes(Page.Checkout) ||
      window.location.href.includes(Page.CancelMembership)

    return (
      <div className="header-container">
        {!this.props.accountDetails.verified && !hideVerifyBanner ? (
          <Banner
            content={`We have sent a verification mail to ${this.props.accountDetails.email}.`}
            action="Resend"
            onClickAction={this.handleResendVerification}
          />
        ) : !this.props.hasInitialPassword ? (
          <Banner
            content="Please set your account password to unlock the full Codeanywhere experience."
            action="Take me there"
            onClickAction={() =>
              (window.location.href = `${Page.Account}?setInitialPassword=true`)
            }
          />
        ) : this.userHasOldPlan() &&
          this.props.accountDetails.user_id < 1750000 &&
          !this.state.didCloseLegacyEditorBanner &&
          !window.location.href.includes(Page.Checkout) ? (
          <Banner
            content="Looking for the legacy Codeanywhere IDE?"
            action="Open"
            onClickAction={this.openLegacyEditor}
            onCloseBanner={() =>
              this.setState({ didCloseLegacyEditorBanner: true })
            }
          />
        ) : (
          <></>
        )}
        <div className="header">
          <div
            className="header-menu__icon tab-focus"
            id="menu-icon"
            onClick={this.handleToggleMenuDropdown}
            onKeyDown={e => onKey(e, 'Enter', this.handleToggleMenuDropdown)}
            onKeyUp={e => onKey(e, ' ', this.handleToggleMenuDropdown)}
            tabIndex={0}
          >
            <MenuIcon />
          </div>
          <HeaderLogo />
          <HeaderProfile
            accountDetails={this.props.accountDetails}
            profileDropdownExpanded={this.props.profileDropdownExpanded}
            onToggleMobileNavigation={this.props.onToggleMobileNavigation}
            onToggleProfileDropdown={this.props.onToggleProfileDropdown}
          />
        </div>
      </div>
    )
  }

  private handleResendVerification = async () => {
    try {
      await this.props.apiClient.resendVerificationEmail()
      notification.success({
        message: 'A new verification email has been sent to your inbox.',
      })
    } catch (e) {
      notification.error({
        message: 'Could not resend the verification email.',
        description: 'Please try again later.',
      })
    }
  }

  private userHasOldPlan = () => {
    return !PRICING_PLANS_NEW.some(p =>
      this.props.accountDetails.plancode.includes(p.code)
    )
  }

  private openLegacyEditor = () => {
    window.open('https://codeanywhere.com/editor', '_blank')
  }

  private handleToggleMenuDropdown = () => {
    this.props.onToggleMobileNavigation(!this.props.mobileNavigationExpanded)
  }
}
