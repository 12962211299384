export const CONTAINER_STATE: { [key: number]: string } = {
  0: 'Temporarily unavailable',
  10: 'Started',
  11: 'Pending start',
  12: 'Starting',
  13: 'Starting',
  20: 'Stopped',
  21: 'Pending stop',
  22: 'Stopping',
  23: 'Stopping',
  25: 'Pending restart',
  26: 'Restart in progress',
  100: 'Created successfully',
  101: 'Pending creation',
  102: 'Creation in progress',
  103: 'Deploying Container',
  110: 'Created successfully',
  200: 'Stack created successfully',
  201: 'Pending stack creation',
  202: 'Creating custom stack',
  203: 'Creating custom stack',
  300: 'Project is running',
  301: 'Pending project execution',
  302: 'Executing project',
  30: 'Stored',
  31: 'Pending store',
  32: 'Storing image',
  33: 'Storing image',
  40: 'Restored',
  41: 'Pending restore',
  42: 'Restoring Container',
  43: 'Restoring Container',
  70: 'Initialized',
  71: 'Pending initialization',
  72: 'Initializing',
  73: 'Initializing',
  80: 'Archived',
  81: 'Pending archiving process',
  82: 'Archiving Container',
  83: 'Archiving Container',
  90: 'Destroyed',
  91: 'Pending destroy',
  92: 'Destroying',
  93: 'Destroying',
  3016: 'Error updating Container DNS',
  3021: 'Error stopping Container',
  3026: 'Error restarting Container',
  3030: 'Error dumping Container image',
  3031: 'Error creating Container image',
  3032: 'Error storing Container image',
  3033: 'Error destroying image',
  3040: 'Error restoring from image',
  3041: 'Error retrieving Container image',
  3042: 'Error restoring Container image',
  3043: 'Error restroying Container image',
  3045: 'Error updating Container status',
  3046: 'Error retrieving Container image',
  3090: 'Error destroying Container',
  3091: 'Error destroying Container',
  3100: 'Error creating Container',
  3101: 'Error creating Container - system error',
  3102: 'Error creating Container - no free Container hosts found',
  3103: 'Error creating Container - error creating DNS record',
  3300: 'Error executing project',
}
