import * as React from 'react'
import {
  AccountDetailsLimits,
  AccountDetailsUsage,
} from '../api-client/interface/AccountDetails'
import { QuestionMarkTooltip } from './QuestionMarkTooltip'
import { ResourceUsage } from './ResourceUsage'

export const RESOURCE_UNLIMITED = 999

type Props = {
  usage: AccountDetailsUsage
  limits: AccountDetailsLimits
  teams?: boolean
}

export class AccountResources extends React.Component<Props> {
  render() {
    return (
      <div className="account-limits">
        <ResourceUsage
          resource="Members"
          used={this.props.usage.multipleaccounts || 0}
          max={this.props.limits.multipleaccounts || 0}
        />
        <ResourceUsage
          resource="Containers"
          used={this.props.usage.devboxes}
          max={this.props.limits.devboxes}
        />
        <ResourceUsage
          resource="Always-On"
          used={this.props.usage.alwayson}
          max={this.props.limits.alwayson}
        />
        <ResourceUsage
          resource={`Domains${
            this.props.teams && this.props.limits.domains !== RESOURCE_UNLIMITED
              ? ' (x15)'
              : ''
          }`}
          used={this.props.usage.domains}
          max={this.props.limits.domains}
        >
          {this.props.teams &&
            this.props.limits.domains !== RESOURCE_UNLIMITED && (
              <QuestionMarkTooltip content="Domains are assigned in bundles of 15." />
            )}
        </ResourceUsage>
        <ResourceUsage
          resource={`Connections${
            this.props.teams &&
            this.props.limits['remote-servers'] !== RESOURCE_UNLIMITED
              ? ' (x15)'
              : ''
          }`}
          used={this.props.usage['remote-servers']}
          max={this.props.limits['remote-servers']}
        >
          {this.props.teams &&
            this.props.limits['remote-servers'] !== RESOURCE_UNLIMITED && (
              <QuestionMarkTooltip content="Connections are assigned in bundles of 15." />
            )}
        </ResourceUsage>
      </div>
    )
  }
}
