import * as React from 'react'

type Props = {}

export class Footer extends React.PureComponent<Props> {
  render() {
    return (
      <footer className="footer">
        <a
          className="footer__link"
          rel="noopener noreferrer"
          href="https://docs.codeanywhere.com/"
          target="_blank"
        >
          Documentation
        </a>
        <a
          className="footer__link"
          rel="noopener noreferrer"
          href="https://docs.codeanywhere.com/general/getting-started/faq"
          target="_blank"
        >
          FAQ
        </a>
        <a
          className="footer__link"
          rel="noopener noreferrer"
          href="https://codeanywhere.com/contact"
          target="_blank"
        >
          Contact
        </a>
      </footer>
    )
  }
}
