import * as React from 'react'
import { TemplateType } from '../helpers/TransformTemplates'
import { Filter } from './Filter'
import { SearchInput } from './SearchInput'

type Props = {
  onUpdateSearchValue: (searchValue: string) => void
  activeFilter: TemplateType | null
  onChangeActiveFilter: (activeFilter: TemplateType | null) => void
}

export class TemplateFilters extends React.PureComponent<Props> {
  render() {
    return (
      <div className="templates__filters-row">
        <SearchInput
          placeholder="Search container templates..."
          onUpdateSearchValue={this.props.onUpdateSearchValue}
        />
        <div className="filters">
          <Filter
            name="All"
            active={this.props.activeFilter === null}
            onSelect={() => this.props.onChangeActiveFilter(null)}
          />
          <Filter
            name="Predefined"
            active={this.props.activeFilter === TemplateType.Predefined}
            onSelect={() =>
              this.props.onChangeActiveFilter(TemplateType.Predefined)
            }
          />
          <Filter
            name="Custom"
            active={this.props.activeFilter === TemplateType.Custom}
            onSelect={() =>
              this.props.onChangeActiveFilter(TemplateType.Custom)
            }
          />
          <Filter
            name="Team"
            active={this.props.activeFilter === TemplateType.Team}
            onSelect={() => this.props.onChangeActiveFilter(TemplateType.Team)}
          />
        </div>
      </div>
    )
  }
}
