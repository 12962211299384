import * as React from 'react'
import { ILLUSTRATION } from '../helpers/image-imports/Illustration'
import { Tooltip, TooltipPosition } from './Tooltip'

type Props = {
  content: string
}

export class QuestionMarkTooltip extends React.PureComponent<Props> {
  render() {
    return (
      <Tooltip position={TooltipPosition.Top} content={this.props.content}>
        <img
          src={ILLUSTRATION['QuestionMark']}
          alt="Question icon"
          className="questionmark-tooltip-icon"
        />
      </Tooltip>
    )
  }
}
