import AmazonPay from '../../assets/img/payment/amazon-pay.svg'
import Amex from '../../assets/img/payment/amex.svg'
import ApplePayBlack from '../../assets/img/payment/apple-pay-black.svg'
import ApplePayWhite from '../../assets/img/payment/apple-pay-white.svg'
import Discover from '../../assets/img/payment/discover.svg'
import GooglePay from '../../assets/img/payment/google-pay.svg'
import Maestro from '../../assets/img/payment/maestro.svg'
import MasterCard from '../../assets/img/payment/mastercard.svg'
import PayPalBlue from '../../assets/img/payment/paypal-blue.svg'
import PayPalWhite from '../../assets/img/payment/paypal-white.svg'
import Venmo from '../../assets/img/payment/venmo.svg'
import Visa from '../../assets/img/payment/visa.svg'
import VisaElectron from '../../assets/img/payment/visa-electron.svg'

export const PAYMENT: { [x: string]: string } = {
  AmazonPay,
  Amex,
  ApplePayBlack,
  ApplePayWhite,
  Discover,
  GooglePay,
  Maestro,
  MasterCard,
  PayPalBlue,
  PayPalWhite,
  Venmo,
  Visa,
  VisaElectron,
}
