export const CONNECTION_TYPE: { [x: string]: string } = {
  'do-droplet': 'DigitalOcean',
  dropbox: 'Dropbox',
  ftp: 'FTP',
  ftps: 'FTPS',
  gdrive: 'GDrive',
  onedriveconsumer: 'OneDrive',
  s3: 'Amazon S3',
  sftp: 'SFTP',
  ssh: 'SSH',
}
