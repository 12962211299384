export type Validation = {
  valid: boolean
  errors: ValidationErrors
}

export type ValidationErrors = {
  [x: string]: string
}

export function Validate(
  toValidate: { [x: string]: string | number | undefined },
  regexValidation?: { [x: string]: RegExp },
  customErrors?: { [x: string]: string }
): Validation {
  let validation: Validation = {
    valid: true,
    errors: {},
  }

  Object.entries(toValidate).forEach(([key, value]) => {
    if (value === undefined) {
      return
    }

    if (typeof value === 'string') {
      if (!regexValidation || !regexValidation[key]) {
        if (value === '') {
          validation.valid = false
          validation.errors[key] =
            customErrors && customErrors[key]
              ? customErrors[key]
              : 'Cannot be empty.'
        }
      } else {
        if (!regexValidation[key].test(value)) {
          validation.valid = false
          validation.errors[key] =
            customErrors && customErrors[key]
              ? customErrors[key]
              : 'Not a valid value.'
        }
      }
    }
  })

  return validation
}
