import * as React from 'react'
import { LOGO } from '../helpers/image-imports/Logo'

export class PulseLoader extends React.PureComponent {
  componentDidMount() {
    document.body.classList.add('modal--is-visible')
  }

  componentWillUnmount() {
    document.body.classList.remove('modal--is-visible')
  }

  render() {
    return (
      <div className="page-content">
        <div className="pulse-container">
          <img src={LOGO['CodeanywhereFull']} alt="Logo" className="pulse" />
        </div>
      </div>
    )
  }
}
