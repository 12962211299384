import React from 'react'
import {
  AccountDetails,
  AccountDetailsLimits,
  AccountDetailsUsage,
  EMPTY_ACCOUNT_DETAILS_LIMITS,
} from '../api-client/interface/AccountDetails'
import { ApiClient } from '../api-client/interface/ApiClient'
import {
  AddonName,
  ChildAccount,
  ChildAccountAddon,
  EMPTY_CHILD_ACCOUNT,
  SubscriptionAddon,
} from '../api-client/interface/Subscription'
import { Page } from '../App'
import {
  AccountResources,
  RESOURCE_UNLIMITED,
} from '../components/AccountResources'
import { Button } from '../components/Button'
import { Heading, HeadingLevel } from '../components/Heading'
import { Input, InputType, TeamLabel } from '../components/Input'
import { Modal } from '../components/Modal'
import { notification } from '../components/Notification'
import { ReachedLimitModal } from '../components/ReachedLimitModal'
import { TeamAccountsList } from '../containers/TeamAccountsList'
import { focusOnElement, triggerOpenedScreenEvent } from '../helpers/Util'

type Props = {
  apiClient: ApiClient

  accountDetails: AccountDetails
  teamAccounts: ChildAccount[]
  usedTeamResources: AccountDetailsUsage
  purchasedAddons?: SubscriptionAddon[]
  hasPremiumSubscription: boolean

  loadingInitContent: boolean
  onDidLoadInitContent: () => void
  refreshManagedAccounts: () => Promise<void>
}

type State = {
  refreshingManagedAccounts: boolean
  processingTeamMemberAction: boolean
  processingAddonChange: boolean

  limitModalVisible: boolean
  consumedResource: string

  addTeamMemberModalVisible: boolean
  newChildEmail: string

  removeTeamMemberModalVisible: boolean
  removedTeamMember: ChildAccount
}

export class TeamPage extends React.Component<Props, State> {
  public state: State = {
    refreshingManagedAccounts: false,
    processingTeamMemberAction: false,
    processingAddonChange: false,

    limitModalVisible: false,
    consumedResource: '',

    addTeamMemberModalVisible: false,
    newChildEmail: '',

    removeTeamMemberModalVisible: false,
    removedTeamMember: EMPTY_CHILD_ACCOUNT,
  }

  componentDidMount() {
    if (this.props.accountDetails.child_account) {
      window.location.href = Page.Home + '?child=true'
    }

    this.props.onDidLoadInitContent()
    triggerOpenedScreenEvent(
      'openedTeamsScreen',
      this.props.accountDetails.user_id,
      this.props.accountDetails.email
    )
  }

  render() {
    const teamResourcesLimits = this.getTeamResourcesLimits()
    const remainingChildAccounts = this.getRemainingChildAccounts()

    return (
      <>
        <div className="page-content page--is-team">
          <Heading heading="Team" level={HeadingLevel.First} />
          <div className="teams-resources">
            <div className="title">Available resources</div>
            <AccountResources
              usage={this.props.usedTeamResources}
              limits={teamResourcesLimits}
              teams={true}
            />
          </div>
          <TeamAccountsList
            apiClient={this.props.apiClient}
            processingAction={
              this.state.processingAddonChange ||
              this.state.refreshingManagedAccounts
            }
            teamAccounts={this.props.teamAccounts}
            hasPremiumSubscription={this.props.hasPremiumSubscription}
            loading={this.state.refreshingManagedAccounts}
            onChangeAddonQuantity={(
              account: ChildAccount,
              addonName: AddonName,
              addonQuantity: number,
              assigning: boolean
            ) =>
              this.handleAddonQuantityChange(
                account,
                addonName,
                addonQuantity,
                assigning,
                teamResourcesLimits
              )
            }
            onRemoveAccount={(removedTeamMember: ChildAccount) =>
              this.setState({
                removedTeamMember,
                removeTeamMemberModalVisible: true,
              })
            }
            refreshManagedAccountsList={this.props.refreshManagedAccounts}
          />
          <div className="button-row">
            <Button
              value="Add user"
              type="success"
              onClick={() => {
                if (remainingChildAccounts > 0) {
                  this.setState({ addTeamMemberModalVisible: true })
                } else {
                  this.setState({
                    limitModalVisible: true,
                    consumedResource: 'Team accounts',
                  })
                }
              }}
              disabled={this.state.processingTeamMemberAction}
            />
          </div>
        </div>
        {this.renderAddTeamMemberModal()}
        {this.renderRemoveTeamMemberModal()}
        {this.renderLimitModal()}
      </>
    )
  }

  private renderAddTeamMemberModal = () => {
    if (this.state.addTeamMemberModalVisible) {
      return (
        <Modal
          visible={this.state.addTeamMemberModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              addTeamMemberModalVisible: false,
              newChildEmail: '',
            })
          }
        >
          <Modal.Header>Add team member</Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleAddChildAccount}>
              <Input
                label={TeamLabel.ChildAccountEmail}
                value={this.state.newChildEmail}
                required={true}
                type={InputType.Email}
                autofocus={true}
                onChangeInput={(newChildEmail: string) =>
                  this.setState({
                    newChildEmail,
                  })
                }
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.processingTeamMemberAction}
                  onClick={() =>
                    this.setState({
                      addTeamMemberModalVisible: false,
                      newChildEmail: '',
                    })
                  }
                />
                <Button
                  value="Add"
                  type="success"
                  submit={true}
                  disabled={
                    this.state.processingTeamMemberAction ||
                    !this.state.newChildEmail
                  }
                  loading={this.state.processingTeamMemberAction}
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )
    }
  }

  private renderRemoveTeamMemberModal = () => {
    if (this.state.removeTeamMemberModalVisible) {
      return (
        <Modal
          visible={this.state.removeTeamMemberModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              removeTeamMemberModalVisible: false,
              removedTeamMember: EMPTY_CHILD_ACCOUNT,
            })
          }
        >
          <Modal.Header>Warning</Modal.Header>
          <Modal.Body>
            <div className="modal-warning-message">
              Are you sure you want to remove{' '}
              <span className="purple-highlight">
                {this.state.removedTeamMember.email}
              </span>{' '}
              from your team?
            </div>
            <div className="button-row">
              <Button
                value="Cancel"
                disabled={this.state.processingTeamMemberAction}
                onClick={() =>
                  this.setState({
                    removeTeamMemberModalVisible: false,
                    removedTeamMember: EMPTY_CHILD_ACCOUNT,
                  })
                }
              />
              <Button
                value="Remove"
                type="danger"
                disabled={this.state.processingTeamMemberAction}
                loading={this.state.processingTeamMemberAction}
                onClick={this.handleRemoveChildAccount}
              />
            </div>
          </Modal.Body>
        </Modal>
      )
    }
  }

  private renderLimitModal = () => {
    return (
      <ReachedLimitModal
        visible={this.state.limitModalVisible}
        resource={this.state.consumedResource}
        accountDetails={this.props.accountDetails}
        onClose={() => this.setState({ limitModalVisible: false })}
      />
    )
  }

  private handleAddChildAccount = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.setState({ processingTeamMemberAction: true })

    try {
      await this.props.apiClient.addChildAccount(this.state.newChildEmail)

      notification.success({
        message: 'You have successfully added the user.',
      })

      this.setState({
        processingTeamMemberAction: false,
        addTeamMemberModalVisible: false,
        newChildEmail: '',
        refreshingManagedAccounts: true,
      })

      await this.props.refreshManagedAccounts()
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while adding the user to your team.',
        description: e,
      })
      focusOnElement(TeamLabel.ChildAccountEmail)
    } finally {
      this.setState({
        processingTeamMemberAction: false,
        addTeamMemberModalVisible: false,
        newChildEmail: '',
        refreshingManagedAccounts: false,
      })
    }
  }

  private handleRemoveChildAccount = async () => {
    if (this.state.removedTeamMember === EMPTY_CHILD_ACCOUNT) {
      return
    }

    this.setState({ processingTeamMemberAction: true })
    try {
      await this.props.apiClient.closeChildAccount(
        String(this.state.removedTeamMember.child)
      )

      notification.success({
        message: 'You have successfully removed the user.',
      })

      this.setState({
        processingTeamMemberAction: false,
        refreshingManagedAccounts: true,
      })

      await this.props.refreshManagedAccounts()
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while removing the user.',
        description: e,
      })
    } finally {
      this.setState({
        removeTeamMemberModalVisible: false,
        processingTeamMemberAction: false,
        refreshingManagedAccounts: false,
      })
    }
  }

  private handleAddonQuantityChange = async (
    childAccount: ChildAccount,
    addonName: AddonName,
    addonQuantity: number,
    assigning: boolean,
    teamMembersLimits: AccountDetailsLimits
  ) => {
    if (
      this.state.processingAddonChange ||
      this.state.refreshingManagedAccounts ||
      addonQuantity < 0
    )
      return

    let remainingAddonQuantity = 0
    let potentiallyConsumedAddon = ''
    switch (addonName) {
      case AddonName.AdditionalContainer:
        remainingAddonQuantity =
          teamMembersLimits.devboxes - this.props.usedTeamResources.devboxes
        potentiallyConsumedAddon = 'Containers'
        break
      case AddonName.DevboxAlwaysOn:
        remainingAddonQuantity =
          teamMembersLimits.alwayson - this.props.usedTeamResources.alwayson
        potentiallyConsumedAddon = 'Always-On addons'
        break
      case AddonName.Extra15Remote:
        remainingAddonQuantity =
          teamMembersLimits['remote-servers'] -
          this.props.usedTeamResources['remote-servers']
        potentiallyConsumedAddon = 'Connections'
        break
      case AddonName.Extra15Domains:
        remainingAddonQuantity =
          teamMembersLimits.domains - this.props.usedTeamResources.domains
        potentiallyConsumedAddon = 'Domains'
        break
    }

    if (remainingAddonQuantity <= 0 && assigning) {
      this.setState({
        limitModalVisible: true,
        consumedResource: potentiallyConsumedAddon,
      })
      return
    }

    this.setState({ processingAddonChange: true })
    try {
      const currentSelectedAddon = childAccount.addons?.find(
        addon => addon.addon_code === addonName
      )
      let updatedAddon: ChildAccountAddon

      if (!currentSelectedAddon) {
        updatedAddon = {
          addon_code: addonName,
          quantity: addonQuantity,
        }
      } else {
        updatedAddon = currentSelectedAddon
        updatedAddon.quantity = addonQuantity
      }

      if (!childAccount.addons) {
        childAccount.addons = []
      }

      if (!currentSelectedAddon) {
        childAccount.addons.push(updatedAddon)
      } else {
        childAccount.addons.map(addon => {
          if (addon.addon_code === addonName) {
            return updatedAddon
          } else {
            return addon
          }
        })
      }

      await this.props.apiClient.setChildAddons(childAccount)
      await this.props.refreshManagedAccounts()
    } catch (e: any) {
      notification.error({
        message: 'An error occurred while updating the addons.',
        description: e,
      })
    } finally {
      this.setState({
        processingAddonChange: false,
      })
    }
  }

  private getTeamResourcesLimits() {
    if (this.props.purchasedAddons) {
      let provisionedAddons: { [x: string]: number } = {
        additionalcontainer: 0,
        devboxalwayson: 0,
        extra15remote: 0,
        extra15domains: 0,
        multipleaccounts: 0,
      }

      this.props.purchasedAddons.forEach(addon => {
        provisionedAddons[addon.add_on_code] = parseInt(addon.quantity)
      })

      const accountUsage = this.props.accountDetails.usage
      const accountLimits = this.props.accountDetails.limits

      return {
        devboxes: Math.min(
          accountLimits.devboxes -
            accountUsage.devboxes +
            this.props.usedTeamResources.devboxes,
          provisionedAddons.additionalcontainer
        ),
        alwayson: Math.min(
          accountLimits.alwayson -
            accountUsage.alwayson +
            this.props.usedTeamResources.alwayson,
          provisionedAddons.devboxalwayson
        ),
        'remote-servers': this.props.hasPremiumSubscription
          ? RESOURCE_UNLIMITED
          : Math.min(
              Math.floor(
                (accountLimits['remote-servers'] -
                  accountUsage['remote-servers']) /
                  15
              ) + this.props.usedTeamResources['remote-servers'],
              provisionedAddons.extra15remote
            ),
        domains: this.props.hasPremiumSubscription
          ? RESOURCE_UNLIMITED
          : Math.min(
              Math.floor((accountLimits.domains - accountUsage.domains) / 15) +
                this.props.usedTeamResources.domains,
              provisionedAddons.extra15domains
            ),
        'devbox-hdd': '',
        'devbox-ram': '',
        multipleaccounts: provisionedAddons.multipleaccounts,
      }
    } else {
      return EMPTY_ACCOUNT_DETAILS_LIMITS
    }
  }

  private getRemainingChildAccounts = () => {
    if (this.props.purchasedAddons) {
      const provisionedChildAccounts = this.props.purchasedAddons.find(
        addon => addon.add_on_code === AddonName.MultipleAccounts
      )?.quantity

      return provisionedChildAccounts
        ? parseInt(provisionedChildAccounts) - this.props.teamAccounts.length
        : 0
    } else {
      return 0
    }
  }
}
