import * as React from 'react'

export class CheckoutTermsOfService extends React.PureComponent {
  render() {
    return (
      <>
        <div className="ant-col ant-col-24 billing-description">
          By submitting this form, you agree to our{' '}
          <a
            href="https://codeanywhere.com/tos"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://codeanywhere.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </div>
        <div className="ant-col ant-col-24 billing-description">
          See offer details and cancellation terms <a href="/here">here</a>.
        </div>
        <div className="ant-col ant-col-24 billing-description">
          If you do not wish to receive information about Codeanywhere services,
          please send us an email at{' '}
          <a href="mailto:support@codeanywhere.com">support@codeanywhere.com</a>
          .
        </div>
      </>
    )
  }
}
