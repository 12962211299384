import * as React from 'react'
import { AddonName, ChildAccount } from '../api-client/interface/Subscription'
import { Spinner } from '../components/Spinner'
import { TeamAccount } from '../components/TeamAccount'
import { ApiClient } from '../api-client/interface/ApiClient'

type Props = {
  apiClient: ApiClient
  processingAction: boolean
  teamAccounts: ChildAccount[]
  hasPremiumSubscription: boolean
  loading: boolean
  onRemoveAccount: (removedTeamMember: ChildAccount) => void
  onChangeAddonQuantity: (
    account: ChildAccount,
    addonName: AddonName,
    addonQuantity: number,
    assigning: boolean
  ) => Promise<void>
  refreshManagedAccountsList: () => Promise<void>
}

export class TeamAccountsList extends React.Component<Props> {
  render() {
    return (
      <div
        className="table-wrapper"
        data-length={this.props.teamAccounts.length}
      >
        <table className="table table--is-team-accounts">
          <thead>
            <tr className="table-header">
              <th className="table-column">Email</th>
              <th className="table-column center">Containers</th>
              <th className="table-column center">Always-On</th>
              <th className="table-column center">Domains (x15)</th>
              <th className="table-column center">Connections (x15)</th>
              <th className="table-column center"></th>
            </tr>
          </thead>
          <tbody className="table-body">
            {this.props.loading ? (
              <tr className="table__spinner-container">
                <td colSpan={6}>
                  <Spinner size={'small'} />
                </td>
              </tr>
            ) : !this.props.teamAccounts.length ? (
              <tr className="no-domains">
                <td>No team members</td>
              </tr>
            ) : (
              <>
                {this.props.teamAccounts.map(account => (
                  <TeamAccount
                    key={account.child}
                    account={account}
                    hasPremiumSubscription={this.props.hasPremiumSubscription}
                    processingAction={this.props.processingAction}
                    onAddonQuantityChange={(
                      addonName: AddonName,
                      addonQuantity: number,
                      assigning: boolean
                    ) =>
                      this.props.onChangeAddonQuantity(
                        account,
                        addonName,
                        addonQuantity,
                        assigning
                      )
                    }
                    onRemoveAccount={() => this.props.onRemoveAccount(account)}
                    refreshManagedAccountsList={
                      this.props.refreshManagedAccountsList
                    }
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
