import * as React from 'react'
import { CHECKOUT } from '../helpers/image-imports/Checkout'
import { Subscription } from '../api-client/interface/Subscription'

type Props = {
  subscription?: Subscription | null
  refundPeriodExpired: boolean
  onFirstSubscription: boolean
}

export class CheckoutBillingSecurity extends React.Component<Props> {
  render() {
    return (
      <>
        <div
          className="ant-col ant-col-24 billing-security"
          style={{
            display:
              this.props.onFirstSubscription && !this.props.refundPeriodExpired
                ? 'flex'
                : 'none',
          }}
        >
          <div>
            <img src={CHECKOUT['MoneyBackGuarantee']} alt="" />
          </div>
          You're 100% backed by our 7-day money-back guarantee.
        </div>
        <div className="ant-col ant-col-24 billing-security">
          <div>
            <img src={CHECKOUT['PurchaseSecurely']} alt="" />
          </div>
          Follow the instructions on the screen to complete your purchase
          securely.
        </div>
        <div className="ant-col ant-col-24 billing-security">
          <div>
            <img src={CHECKOUT['PaymentInformation']} alt="" />
          </div>
          Payments are charged in USD. Payment provider fees may apply. Plan
          automatically renews until cancelled.
        </div>
      </>
    )
  }
}
