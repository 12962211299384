import {
  ContainerStackList,
  ContainerStackOwner,
} from '../api-client/interface/Connection'
import _ from 'lodash'

export enum TemplateType {
  Predefined,
  Custom,
  Team,
}

export type TemplateData = {
  id: string
  distro: string
  name: string
  desc: string
  tags: string[]
  type: TemplateType
  owner?: ContainerStackOwner
}

export type Templates = {
  predefined: TemplateData[]
  custom: TemplateData[]
  team: TemplateData[]
}

export const EMPTY_TEMPLATES: Templates = {
  predefined: [],
  custom: [],
  team: [],
}

export const transformTemplates = (stacks: ContainerStackList): Templates => {
  const predefined = stacks.predefined
    ? _.flatMap(stacks.predefined, stack => {
        return stack.distros.map(distro => {
          return {
            id: stack.id,
            distro: distro.id,
            name: stack.name,
            desc: distro.desc,
            tags: stack.tags,
            type: TemplateType.Predefined,
          } as TemplateData
        })
      }).filter(removeUnwantedDistros)
    : []

  const custom = stacks.custom
    ? _.flatMap(stacks.custom, stack => {
        return stack.distros.map(distro => {
          return {
            id: stack.id,
            distro: distro.id,
            name: stack.name,
            desc: stack.desc,
            tags: stack.tags,
            type: TemplateType.Custom,
            owner: stack.user,
          } as TemplateData
        })
      })
        .filter(removeUnwantedDistros)
        .reverse()
    : []

  const team = stacks.team
    ? _.flatMap(stacks.team, stack => {
        if (stack.distro) {
          return stack.distro.map(stackDistro => {
            return {
              id: stack.id,
              distro: stackDistro.id,
              name: stack.name,
              desc: stack.desc,
              tags: stack.tags,
              type: TemplateType.Team,
              owner: stack.user,
            } as TemplateData
          })
        }
        return []
      })
        .filter(removeUnwantedDistros)
        .reverse()
    : []

  return {
    predefined,
    custom,
    team,
  }
}

const removeUnwantedDistros = (template: TemplateData) => {
  if (
    template.distro.toLowerCase().includes('centos') ||
    template.name.toLowerCase().includes('firehose') ||
    template.name.toLowerCase().includes('stemi')
  ) {
    return false
  }

  return true
}
