import * as React from 'react'
import { CloseIcon } from '../helpers/image-imports/IconComponent'
import { onKey } from '../helpers/Util'

type Props = {
  content: string
  action: string
  onClickAction: () => void
  onCloseBanner?: () => void
}

export class Banner extends React.Component<Props> {
  render() {
    return (
      <div className="banner">
        <div>
          <span className="banner__content">{this.props.content}</span>
          <span
            className="banner__action tab-focus"
            onClick={this.props.onClickAction}
            onKeyDown={e => onKey(e, 'Enter', this.props.onClickAction)}
            onKeyUp={e => onKey(e, ' ', this.props.onClickAction)}
            tabIndex={0}
          >
            {this.props.action}
          </span>
        </div>
        {this.props.onCloseBanner && (
          <CloseIcon
            className="banner__close tab-focus"
            onClick={this.props.onCloseBanner}
            onKeyDown={e => this.handleCloseBannerKeyboardEvent(e, 'Enter')}
            onKeyUp={e => this.handleCloseBannerKeyboardEvent(e, ' ')}
            tabIndex={0}
          />
        )}
      </div>
    )
  }

  public componentDidMount() {
    document.body.classList.add('banner--is-visible')
  }

  public componentWillUnmount() {
    document.body.classList.remove('banner--is-visible')
  }

  private handleCloseBannerKeyboardEvent = (
    e: React.KeyboardEvent,
    key: string
  ) => {
    onKey(e, key, () => {
      if (this.props.onCloseBanner) {
        this.props.onCloseBanner()
      }
    })
  }
}
