import * as React from 'react'
import { ILLUSTRATION } from '../helpers/image-imports/Illustration'
import { getGravatar } from '../helpers/Util'

type Props = {
  profileImageUrl?: string
  email: string
  inHeader?: boolean
  displayActiveStatus?: boolean
}

type State = {
  gravatarUrl: string
}

export class ProfileImage extends React.PureComponent<Props, State> {
  public state: State = {
    gravatarUrl: '',
  }

  componentDidMount() {
    this.getGravatarUrl()
  }

  render() {
    return (
      <div
        className={`profile-avatar${
          this.props.displayActiveStatus ? ' show-active' : ''
        }${this.props.inHeader ? ' header-avatar' : ''}`}
      >
        <img
          src={
            this.state.gravatarUrl ||
            this.props.profileImageUrl ||
            ILLUSTRATION['DefaultUserIcon']
          }
          alt="Profile icon"
          className="profile-avatar__image"
          referrerPolicy="no-referrer"
        />
      </div>
    )
  }

  private async getGravatarUrl() {
    this.setState({
      gravatarUrl: await getGravatar(this.props.email),
    })
  }
}
