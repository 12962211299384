import { Coupon, RecurlyClient } from '../interface/RecurlyClient'
import axios from 'axios'

export class ProductionRecurlyClient implements RecurlyClient {
  private publicApiKey: string

  constructor(recurlyApiKey: string) {
    this.publicApiKey = recurlyApiKey
  }

  public async validateCoupon(couponCode: string, planName: string) {
    const invalid: Coupon = {
      valid: false,
      discount: {
        amount: 0,
        type: 'percent',
      },
    }

    try {
      const coupon = await axios.get(
        `https://api.recurly.com/js/v1/plans/${planName}/coupons/${couponCode}?plan=${planName}&coupon=${couponCode}&version=3.1.1&key=${this.publicApiKey}`
      )

      if (coupon.data.error) {
        return invalid
      }

      return {
        valid: true,
        discount: {
          type: coupon.data.discount
            ? ((coupon.data.discount.type === 'percent'
                ? 'percent'
                : 'fixed') as 'fixed' | 'percent')
            : 'fixed',
          amount: coupon.data.discount
            ? coupon.data.discount.type === 'percent'
              ? coupon.data.discount.rate!
              : Object.values(coupon.data.discount.amount)[0]
            : 0,
        },
      }
    } catch (e) {
      return invalid
    }
  }
}
