import * as React from 'react'
import { AccountConnection } from '../api-client/interface/AccountConnection'
import {
  extractAuthLink,
  extractAuthorizationsFromConnectedAccounts,
} from '../helpers/Util'
import { Button } from './Button'
import { ConnectedAccountCard, PROVIDER_NAMES } from './ConnectedAccountCard'
import { CreateIcon, CreateOption } from './CreateOption'
import { Heading, HeadingLevel } from './Heading'
import { Input, AccountLabel } from './Input'
import { Modal } from './Modal'
import { Spinner } from './Spinner'

export type ConnectedAccount = {
  id: string
  provider: string
  username: string
  processingAction: boolean
}

type Props = {
  connectedAccounts: AccountConnection[]
  loadingConnectedAccounts: boolean
  onConnectAccount: (authLink: string) => Promise<void>
  handleDisconnectAccount: (provider: string, authId: string) => Promise<void>
}

type State = {
  disconnectModalVisible: boolean
  selectedAccount?: ConnectedAccount
  disconnectConfirmation: string
}

export class ConnectedAccounts extends React.Component<Props, State> {
  public state: State = {
    disconnectModalVisible: false,
    selectedAccount: undefined,
    disconnectConfirmation: '',
  }

  render() {
    const githubAuthLink = extractAuthLink(
      this.props.connectedAccounts,
      'github'
    )
    const googleAuthLink = extractAuthLink(
      this.props.connectedAccounts,
      'google'
    )
    const bitbucketAuthLink = extractAuthLink(
      this.props.connectedAccounts,
      'bitbucket'
    )

    return (
      <>
        <div className="account-section">
          <Heading heading="Connected accounts" level={HeadingLevel.Second} />
          <Heading
            heading="You can manage and customize your connected apps."
            level={HeadingLevel.Third}
          />
          {this.props.loadingConnectedAccounts ? (
            <div className="component__spinner-container is-connected-accounts">
              <Spinner size={'medium'} />
            </div>
          ) : (
            <>
              <div className="create-options-row providers">
                <CreateOption
                  option="GitHub"
                  icon={CreateIcon.Plus}
                  onClick={() => {
                    if (githubAuthLink) {
                      this.props.onConnectAccount(githubAuthLink)
                    }
                  }}
                />
                <CreateOption
                  option="Google"
                  icon={CreateIcon.Plus}
                  onClick={() => {
                    if (googleAuthLink) {
                      this.props.onConnectAccount(googleAuthLink)
                    }
                  }}
                />
                <CreateOption
                  option="Bitbucket"
                  icon={CreateIcon.Plus}
                  onClick={() => {
                    if (bitbucketAuthLink) {
                      this.props.onConnectAccount(bitbucketAuthLink)
                    }
                  }}
                />
              </div>
              <div className="connected-accounts-list">
                {extractAuthorizationsFromConnectedAccounts(
                  this.props.connectedAccounts
                ).map(authorization => {
                  if (authorization) {
                    const account: ConnectedAccount = {
                      id: authorization.id.toString(),
                      provider: authorization.provider,
                      username: authorization.username,
                      processingAction: false,
                    }

                    return (
                      <ConnectedAccountCard
                        key={account.id}
                        account={account}
                        onDisconnectClick={() =>
                          this.setState({
                            disconnectModalVisible: true,
                            selectedAccount: account,
                          })
                        }
                      />
                    )
                  }
                  return <></>
                })}
              </div>
            </>
          )}
        </div>
        {this.renderDisconnectModal()}
      </>
    )
  }

  private renderDisconnectModal = () => {
    if (this.state.disconnectModalVisible && this.state.selectedAccount) {
      return (
        <Modal
          visible={this.state.disconnectModalVisible}
          closeable={true}
          onClose={() =>
            this.setState({
              disconnectModalVisible: false,
              selectedAccount: undefined,
            })
          }
        >
          <Modal.Header>
            Are you sure you want to disconnect your{' '}
            {PROVIDER_NAMES.get(this.state.selectedAccount.provider)} account{' '}
            <span className="purple-highlight">
              {this.state.selectedAccount.username}
            </span>
            ?
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmitDisconnectAccount}>
              <Input
                label={AccountLabel.AccountNameVerification}
                value={this.state.disconnectConfirmation}
                autofocus={true}
                onChangeInput={disconnectConfirmation =>
                  this.setState({
                    disconnectConfirmation,
                  })
                }
              />
              <div className="button-row">
                <Button
                  value="Cancel"
                  disabled={this.state.selectedAccount.processingAction}
                  onClick={() =>
                    this.setState({
                      disconnectModalVisible: false,
                      selectedAccount: undefined,
                    })
                  }
                />
                <Button
                  value="Disconnect"
                  type="danger"
                  submit={true}
                  disabled={
                    this.state.selectedAccount.processingAction ||
                    this.state.disconnectConfirmation !==
                      this.state.selectedAccount.username
                  }
                  loading={this.state.selectedAccount.processingAction}
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )
    }
  }

  private handleSubmitDisconnectAccount = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (this.state.selectedAccount) {
      this.setState({
        selectedAccount: {
          ...this.state.selectedAccount,
          processingAction: true,
        },
      })

      await this.props.handleDisconnectAccount(
        this.state.selectedAccount.provider,
        this.state.selectedAccount.id
      )

      this.setState({
        disconnectModalVisible: false,
        selectedAccount: undefined,
      })
    }
  }
}
